import { AccountingTotal } from "@/root/models/accounting";
import { Modal, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { t } from "i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";

import styles from "@/modules/MainPanel/components/DashboardTable.module.scss";
import Tooth from "@/components/CaseJobs/Product/Tooth";
import InvoiceColumn from "@/modules/InvoicesPanel/components/InvoiceColumn";

interface AccountingTableProps {
  accountingChangeArray: AccountingTotal[] | [];
  selectedItem: AccountingTotal | undefined;
  setSelectedItem: Dispatch<SetStateAction<AccountingTotal | undefined>>;
}

const { confirm } = Modal;

const AccountingTable = ({
  accountingChangeArray,
  selectedItem,
  setSelectedItem,
}: AccountingTableProps) => {
  const handleDeleteTooth = (tooth: string) => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => {
        if (selectedItem) {
          const updatedTeeth = selectedItem?.teeth!.filter(
            (elem) => elem !== tooth
          );

          const updateSelectedItem = { ...selectedItem, teeth: updatedTeeth };

          setSelectedItem(updateSelectedItem);
        }
      },
    });
  };

  const itemsColumns: ColumnsType<AccountingTotal> = [
    {
      width: "25%",
      title: t("Елемент"),
      dataIndex: "itemName",
      key: "itemName",
    },
    {
      width: "20%",
      title: t("Зуби"),
      dataIndex: "teeth",
      key: "tooth",
      render: (teeth: AccountingTotal["teeth"], item: AccountingTotal) => {
        return (
          <Space>
            {teeth?.map((tooth, index) => (
              <div
                style={{
                  pointerEvents:
                    item.rowUUID !== selectedItem?.rowUUID ? "none" : "auto",
                }}
              >
                <Tooth
                  isLast={teeth!.length - 1 === index}
                  tooth={tooth}
                  handleDeleteClick={() => handleDeleteTooth(tooth)}
                />
              </div>
            ))}
          </Space>
        );
      },
    },
    {
      title: t("Кількість"),
      dataIndex: "quantity",
      key: "quantity",
      render: (quantity: AccountingTotal["quantity"]) => (
        <div style={{ textAlign: "center" }}>{quantity}</div>
      ),
    },
    {
      title: t("Ціна"),
      dataIndex: "price",
      key: "price",
    },
    {
      title: t("Сума"),
      dataIndex: "sum",
      key: "sum",
    },
    {
      title: t("Знижка %"),
      dataIndex: "discountPercent",
      key: "discountPercent",
      render: (discountPercent: AccountingTotal["discountPercent"]) => (
        <div style={{ textAlign: "center" }}>{discountPercent}</div>
      ),
    },
    {
      title: t("Сума знижки"),
      dataIndex: "discountSum",
      key: "discountSum",
      render: (discountSum: AccountingTotal["discountSum"]) => (
        <div style={{ textAlign: "center" }}>{discountSum}</div>
      ),
    },
    {
      title: t("Всього"),
      dataIndex: "total",
      key: "total",
    },
    {
      title: t("Рахунки-фактури"),
      dataIndex: "invoices",
      key: "invoice",
      render: (invoices: AccountingTotal["invoices"]) => {
        return invoices?.map((elem) => <InvoiceColumn invoiceInfo={elem} />);
      },
    },
  ];

  return (
    <Table
      rowClassName={(item) =>
        selectedItem?.rowUUID === item.rowUUID ? styles.activeRow : undefined
      }
      columns={itemsColumns}
      dataSource={accountingChangeArray?.map((item) => item)}
      pagination={false}
      rowKey={(record) => record.rowUUID}
      onRow={(item) => ({
        onClick: () => {
          setSelectedItem(item);
        },
      })}
    />
  );
};

export default AccountingTable;
