import { useEffect, useState } from "react";

import { ProductsParametersApi } from "@/api/admin/productsParameters";
import { ProductParameter } from "@/root/models/productParameter";

const useParameters = () => {
  const [parameters, setParameters] = useState<ProductParameter[]>([]);
  const [getParametersLoading, setGetParametersLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    (async () => {
      try {
        setGetParametersLoading(true);
        const parameters = await ProductsParametersApi.getParameters();
        setParameters(parameters);
      } catch (e: any) {
        setError(e.message);
      } finally {
        setGetParametersLoading(false);
      }
    })();
  }, []);

  const createParameter = (parameter: ProductParameter) => {
    setParameters((prev) => [...prev, parameter]);
  };

  const deleteParameter = (parameterUUID: string) => {
    setParameters((prev) =>
      prev.filter((p) => p.productsParameterUUID !== parameterUUID)
    );
  };

  const editParameter = (
    parameterUUID: string,
    parameter: ProductParameter
  ) => {
    setParameters((prev) =>
      prev.map((p) => {
        if (p.productsParameterUUID === parameterUUID) {
          return parameter;
        }
        return p;
      })
    );
  };

  return {
    parameters,
    getParametersLoading,
    error,
    createParameter,
    editParameter,
    deleteParameter,
  };
};

export default useParameters;
