const createCameraInput = (): HTMLInputElement => {
  const input = document.createElement("input");
  input.type = "file";
  input.capture = "camera";
  input.accept = "image/*";
  input.hidden = true;
  document.body.append(input);
  return input;
};

const createPhotoInput = (): HTMLInputElement => {
  const input = document.createElement("input");
  input.type = "file";
  input.accept = "image/*";
  input.hidden = true;
  document.body.append(input);
  return input;
};

const triggerInput = (
  input: HTMLInputElement,
  onChange: (file: FileList | null) => void
) => {
  input.onchange = (e) => {
    onChange((e.target as HTMLInputElement).files || null);
    input.onchange = null;
    input.remove();
  };

  input.click();
};

export const createPhoto = (onChange: (file: FileList | null) => void) => {
  triggerInput(createCameraInput(), onChange);
};

export const uploadPhoto = (onChange: (files: FileList | null) => void) => {
  triggerInput(createPhotoInput(), onChange);
};