import React, { FC, useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Alert, Button, DatePicker, Form, Modal, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import MaskedInput from "antd-mask-input";
import TextArea from "antd/es/input/TextArea";

import { Task } from "@/root/models/task";
import useTasks from "@/hooks/useTasks";
import { CaseStatusEnum, TaskStatusEnum } from "@/root/types";
import { timeMask } from "@/root/consts";
import useCaseNumbers from "@/hooks/useCaseNumbers";
import useTaskStatuses from "@/hooks/useTaskStatuses";
import { formatDateTime } from "@/utils/formatDataTime";
import useCaseStatuses from "@/hooks/useCaseStatuses";

interface EditTaskModalProps {
  task: Task;
}

const { Option } = Select;

const EditTaskModal: FC<EditTaskModalProps> = ({ task }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [editModal, setEditModal] = useState(false);

  const { editTask, editLoading, editError } = useTasks({
    taskNumber: task.taskNumber,
    onEditSuccess: () => setEditModal(false),
  });
  const { caseNumbers, getCaseNumbersLoading } = useCaseNumbers(
    CaseStatusEnum.New
  );
  const { taskStatuses, getStatusesLoading: getTaskStatusesLoading } =
    useTaskStatuses();
  const { statuses: caseStatuses, loading: getCaseStatusesLoading } =
    useCaseStatuses();

  const submit = () => {
    form.submit();
  };

  const openModal = () => {
    form.setFieldsValue({
      ...task,
      taskStatusUUID: task.taskStatus.taskStatusUUID,
      cases: task.cases.map((c) => c.case.caseUUID),
      courierUUID: task.courier.courierUUID,
      dueDate: task.dueDate && dayjs(new Date(task.dueDate)),
      dueDateTime: task.dueDate.slice(11),
      completionDate:
        task.completionDate && dayjs(new Date(task.completionDate)),
      completionDateTime: task.completionDate && task.completionDate.slice(11),
    });
    setEditModal(true);
  };

  const handleEdit = async (values: any) => {
    editTask(task.taskUUID, {
      responsibleUUID: task.client.clientUUID,
      trackingNumber: task.taskUUID,
      dueDate: task.dueDate,
      taskStatusUUID: values.taskStatusUUID,
      caseStatusUUID: values.caseStatusUUID,
      cases: values.cases.map((caseUUID: string) => ({ caseUUID, use: true })),
      completionDate: values.completionDate
        ? formatDateTime(values.completionDate, values.completionDateTime)
        : null,
      attachments: [],
      description: values.description || "",
    });
  };

  return (
    <>
      <Button style={{ width: "100%" }} type="primary" onClick={openModal}>
        {t("Редагувати завдання")}
      </Button>
      {
        <Modal
          maskClosable={false}
          okText={t("Зберегти")}
          title={
            <div style={{ marginBottom: 20 }}>{t("Редагування завдання")}</div>
          }
          open={editModal}
          onCancel={() => setEditModal(false)}
          onOk={submit}
          okButtonProps={{ loading: editLoading }}
        >
          <Form
            requiredMark={false}
            form={form}
            onFinish={handleEdit}
            initialValues={{
              taskStatusUUID: TaskStatusEnum["New"],
            }}
          >
            <Form.Item
              name="cases"
              label={t("Замовлення")}
              rules={[
                { required: true, message: t("Заповніть поле") as string },
              ]}
            >
              <Select
                mode="multiple"
                showSearch
                filterOption={(inputValue, option) =>
                  option?.children
                    // @ts-ignore
                    ?.toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
                loading={getCaseNumbersLoading}
              >
                {caseNumbers.map((medCase) => (
                  <Option key={medCase.caseUUID} value={medCase.caseUUID}>
                    {medCase.caseNumber}
                  </Option>
                ))}
                {task.cases
                  .filter(
                    (taskCase) =>
                      !caseNumbers.some(
                        (c) => c.caseUUID === taskCase.case.caseUUID
                      )
                  )
                  .map((medCase) => (
                    <Option
                      key={medCase.case.caseUUID}
                      value={medCase.case.caseUUID}
                    >
                      {medCase.case.caseNumber}
                    </Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="taskStatusUUID"
              label={t("Статус завдання")}
              rules={[
                { required: true, message: t("Заповніть поле") as string },
              ]}
            >
              <Select loading={getTaskStatusesLoading}>
                {taskStatuses.map((status) => (
                  <Option
                    key={status.taskStatusUUID}
                    value={status.taskStatusUUID}
                  >
                    {status.name || " "}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="caseStatusUUID" label={t("Статус замовлення")}>
              <Select
                listHeight={1000}
                loading={getCaseStatusesLoading}
                allowClear
              >
                {caseStatuses.map((status) => (
                  <Option
                    key={status.caseStatusUUID}
                    value={status.caseStatusUUID}
                  >
                    {status.name || " "}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="description" label={t("Опис")}>
              <TextArea placeholder={t("Введіть значення") as string} />
            </Form.Item>

            <Form.Item
              label={t("Дата виконання")}
              name="completionDate"
              style={{ display: "inline-block", width: "calc(50% - 8px)" }}
            >
              <DatePicker
                format="YYYY.MM.DD"
                placeholder={t("Виберіть дату") as string}
              />
            </Form.Item>
            <Form.Item
              label={t("Час")}
              name="completionDateTime"
              style={{
                display: "inline-block",
                width: 100,
                margin: "0 8px",
              }}
            >
              <MaskedInput mask={timeMask} />
            </Form.Item>
            {!!editError && <Alert message={editError} type="error" />}
          </Form>
        </Modal>
      }
    </>
  );
};

export default EditTaskModal;
