import { useQuery } from "@tanstack/react-query";

import { PricelistTypesApi } from "@/api/pricelistTypes";

const usePricelistTypes = () => {
  const {
    data: pricelistTypes,
    isLoading: getTypesLoading,
    error: getTypesError,
  } = useQuery({
    queryKey: ["pricelistTypes"],
    queryFn: PricelistTypesApi.getTypes,
  });

  return {
    pricelistTypes: pricelistTypes || [],
    getTypesLoading,
    getTypesError,
  };
};

export default usePricelistTypes;
