import React, { FC } from "react";
import { t } from "i18next";
import dayjs from "dayjs";
import classNames from "classnames";

import { DatePicker, Form } from "antd";

import styles from "./Date.module.scss";

import { DATE_FORMAT } from "@/root/consts";
import { isLaptop } from "@/utils/isLaptop";

interface DateProps {
  name: string;
  label: string;
  value: string | Date;
  onSelect: (value: string) => void;
  disabled?: boolean;
  className?: string;
  type: "due" | "arrival";
}

const DateSelect: FC<DateProps> = ({
  name,
  label,
  value,
  className,
  disabled = false,
  onSelect,
  type,
}) => {
  return (
    <div className={classNames(styles.container, className)}>
      <Form.Item
        name={name}
        className={styles.formItem}
        initialValue={dayjs(new Date(value))}
        labelCol={type === "arrival" ? { span: 10 } : undefined}
        label={label}
        rules={[{ required: true, message: t("Заповніть поле") as string }]}
      >
        <DatePicker
          format={DATE_FORMAT}
          disabled={disabled}
          onSelect={(v) => onSelect(dayjs(v).format(DATE_FORMAT))}
        />
      </Form.Item>
    </div>
  );
};

export default DateSelect;
