import { CaseFormState } from "@/components/CaseForm/CaseFormContext";
import formJobToJob from "@/components/CaseForm/formJobToJob";
import { Job } from "@/root/models/case";
import { Filter } from "@/root/models/filter";

export const formJobsToCaseJobs = (
  jobs: CaseFormState.Job[],
  filters: Filter[]
) => {
  return jobs
    .map((job: CaseFormState.Job) => formJobToJob(job, filters))
    .map<Job>((job) => {
      return {
        jobName: job.jobName,
        jobUUID: job.jobUUID,
        products: job.products.map((product) => {
          const formJob = jobs.find((j) => j.jobID === job.jobUUID);
          const formProduct = Array.from(formJob?.products || []).find(
            (p) => p.jobProductUUID === product.jobProductUUID
          );

          return {
            ...product,
            productsParametersGroups:
              product.productsParametersGroups.map<Job.ProductParameterGroup>(
                (group) => {
                  const fullGroup =
                    formProduct?.product?.productsParametersGroups.find(
                      (g) =>
                        g.productsParametersGroupUUID ===
                        group.productsParametersGroupUUID
                    );
                  return {
                    productsParametersGroup: {
                      name: fullGroup?.name || "",
                      productsParametersGroupUUID:
                        group.productsParametersGroupUUID,
                    },
                    productsParameters: group.productsParameters.map(
                      (parameter) => {
                        const fullParameter =
                          fullGroup?.productsParameters.find(
                            (p) =>
                              p.productsParameter.productsParameterUUID ===
                              parameter.productsParameterUUID
                          );
                        return {
                          value: parameter.value as string,
                          tooth: parameter.tooth,
                          productsParameter: fullParameter!.productsParameter,
                        };
                      }
                    ),
                  };
                }
              ),
            product: {
              productUUID: formProduct?.product?.productUUID || "",
              name: formProduct?.product?.name || "",
            },
          };
        }),
      };
    });
};
