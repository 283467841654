import React, { FC } from "react";

import { Form } from "antd";

import styles from "@/components/CaseForm/components/Details/Detailes.module.scss";

import { Block } from "@/components/common";
import { FileUpload } from "@/components";

const LabCaseFiles: FC = () => {
  return (
    <Block style={{ width: "100%" }} className={styles.files}>
      <Form.Item name="files">
        <FileUpload />
      </Form.Item>
    </Block>
  );
};

export default LabCaseFiles;
