import { CaseFormState } from "@/components/CaseForm/CaseFormContext";

export interface CalculateProductPriceByFormulaContext {
  product: CaseFormState.Job.Product;
  jobsCount: number;
  teethFormulaTypeUUID: string;
  productsCount: number;
}

export const calculateProductPriceByFormula = (
  context: CalculateProductPriceByFormulaContext,
  formula: string
) => {
  const jobs = {
    quantity: context.jobsCount,
  };
  const teethFormulaType = {
    teethFormulaTypeUUID: context.teethFormulaTypeUUID,
  };
  const job = {
    products: {
      quantity: context.productsCount,
    },
  };
  const product = {
    price: context.product.price,
    quantity: context.product.quantity,
    combinedProduct: context.product.combinedProduct,
    differentTeethParameters: context.product.differentTeethParameters,
    teeth: {
      quantity: context.product.teeth?.length || 0,
    },
  };

  const sum = eval(formula);

  if (typeof sum !== "number") {
    return false;
  }

  return sum;
};
