import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import styles from "../../LaboratoryCaseForm.module.scss";

import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import {
  setIsSelect,
  setTabKeyLeft,
} from "@/store/reducers/teethFormulaSelectSlice";
import { useAppDispatch } from "@/hooks/redux";

const { confirm } = Modal;

interface TeethProps {
  activeJobID: string;
  activeProductID: string;
  teeth: string[];
  productTeeth: string[] | undefined;
  isLastTeeth: boolean;
}

const Teeth: FC<TeethProps> = ({
  activeJobID,
  activeProductID,
  teeth,
  isLastTeeth,
}) => {
  const { t } = useTranslation();
  const { dispatch } = useCaseFormContext();

  const handleDeleteTooth = (tooth: string) => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => {
        dispatch({
          type: ActionCreatorTypes.SetTooth,
          payload: { activeJobID, activeProductID, tooth },
        });
      },
    });
  };

  // const allTeeth =
  //   state.teethFormulaTypeUUID === TeethFormulaTypeEnum.adult
  //     ? adultFormulaTeethIDs
  //     : childFormulaTeethIDs;

  // const isUpperJawChosen =
  //   productTeeth?.includes("FDI_AUJ") || productTeeth?.includes("FDI_CUJ");
  // const isLowerJawChosen =
  //   productTeeth?.includes("FDI_ALJ") || productTeeth?.includes("FDI_CLJ");

  // const filteredByUpperJaw = isUpperJawChosen
  //   ? allTeeth.filter((tooth) => !getUpperJawTeeth().includes(tooth))
  //   : allTeeth;
  // const filteredByLowerJaw = isLowerJawChosen
  //   ? filteredByUpperJaw.filter((tooth) => !getLowerJawTeeth().includes(tooth))
  //   : filteredByUpperJaw;

  // const isTeethFormula = state.teethFormulaTypeUUID;

  const dispatchSelect = useAppDispatch();

  const onSelectHandle = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      dispatchSelect(setIsSelect(true));
      dispatchSelect(setTabKeyLeft("summary"));
    }, 300);
  };

  return (
    <>
      <Space
        direction="vertical"
        style={{
          textAlign: "center",
          paddingBottom: isLastTeeth ? 48 : undefined,
        }}
      >
        {teeth?.map((tooth) => (
          <div key={tooth}>
            <span>{tooth.slice(4)}</span>
            <Button
              style={{ marginLeft: 10 }}
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteTooth(tooth)}
            />
          </div>
        ))}
      </Space>

      {isLastTeeth && (
        <div className={styles.addCellItem}>
          <Button
            type="primary"
            style={{ width: "100%" }}
            onClick={onSelectHandle}
          >
            Select
          </Button>
        </div>
      )}
    </>
  );
};

export default Teeth;
