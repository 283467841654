import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "antd";

import { Tasks } from "@/modules";
import { CreateTaskModal } from "@/components";

interface DeliveryProps {
  caseUUID?: string;
  save: () => Promise<true | undefined>;
}

const Delivery: FC<DeliveryProps> = ({ caseUUID, save }) => {
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();

  const handleCreateTask = async () => {
    const caseCreated = await save();
    if (caseCreated) {
      setModal(true);
    }
  };

  if (!caseUUID) {
    return <Button onClick={handleCreateTask}>{t("Створити завдання")}</Button>;
  }

  return (
    <>
      <Tasks caseUUID={caseUUID} />
      <CreateTaskModal
        open={modal}
        onCancel={() => setModal(false)}
        tasksQueryProps={{ caseUUID }}
      />
    </>
  );
};

export default Delivery;
