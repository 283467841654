import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Task } from "@/root/models/task";
import { CreateTaskPayload, EditTaskPayload, TasksApi } from "@/api/tasks";
import { ResponseDataWithPagination } from "@/api/types";

const useTasks = ({
  caseUUID,
  page,
  pageLimit,
  taskStatusUUID,
  courierUUID,
  taskNumber,
  cases,
  trackingNumber,
  completionDate,
  dueDate,
  sort,
  onCreateSuccess,
  onDeleteError,
  onEditSuccess,
}: {
  caseUUID?: string;
  page?: number;
  pageLimit?: number;
  taskStatusUUID?: string;
  courierUUID?: string;
  taskNumber?: string;
  cases?: string;
  trackingNumber?: string;
  completionDate?: string;
  dueDate?: string;
  sort?: string;
  onCreateSuccess?: () => void;
  onEditSuccess?: () => void;
  onDeleteError?: (message: string) => void;
}) => {
  const queryClient = useQueryClient();
  const {
    data: tasks,
    isFetching: getTasksLoading,
    error: getTasksError,
  } = useQuery({
    queryKey: [
      "tasks",
      caseUUID,
      page,
      taskStatusUUID,
      courierUUID,
      taskNumber,
      dueDate,
      cases,
      trackingNumber,
      completionDate,
      sort,
    ],
    queryFn: () =>
      TasksApi.getTasks(
        caseUUID,
        page,
        pageLimit,
        taskStatusUUID,
        courierUUID,
        taskNumber,
        dueDate,
        cases,
        trackingNumber,
        completionDate,
        sort
      ),
  });

  const createTaskMutation = useMutation({
    mutationFn: TasksApi.create,
    onSuccess: (task) => {
      queryClient.setQueryData(
        [
          "tasks",
          caseUUID,
          page,
          taskStatusUUID,
          courierUUID,
          taskNumber,
          dueDate,
          cases,
          trackingNumber,
          completionDate,
          sort,
        ],
        (prev: ResponseDataWithPagination<Task[]> | Task[] | undefined) => {
          if (!prev) {
            return [task];
          }

          if ("data" in prev) {
            return {
              data: [...prev.data, task],
              page: prev.page,
              pages: prev.pages,
            };
          }
          return [...prev, task];
        }
      );
      onCreateSuccess?.();
    },
  });

  const createTask = (payload: CreateTaskPayload) => {
    createTaskMutation.mutate(payload);
  };

  const createError = (createTaskMutation.error as Error)?.message as string;

  const deleteTaskMutation = useMutation({
    mutationFn: TasksApi.delete,
    onError: (error) => onDeleteError?.((error as Error).message),
    onSuccess: (_, taskUUID) => {
      queryClient.setQueryData(
        [
          "tasks",
          caseUUID,
          page,
          taskStatusUUID,
          courierUUID,
          taskNumber,
          dueDate,
          cases,
          trackingNumber,
          completionDate,
          sort,
        ],
        (prev: ResponseDataWithPagination<Task[]> | Task[] | undefined) => {
          if (!prev) {
            return [];
          }

          if ("data" in prev) {
            return {
              data: (prev?.data || []).filter((p) => p.taskUUID !== taskUUID),
              page: prev.page,
              pages: prev.pages,
            };
          }

          return (prev || []).filter((p) => p.taskUUID !== taskUUID);
        }
      );
    },
  });

  const deleteTask = async (id: string) => {
    await deleteTaskMutation.mutateAsync(id);
  };

  const deleteError = (deleteTaskMutation.error as Error)?.message as string;

  const editTaskMutation = useMutation({
    mutationFn: TasksApi.edit,
    onSuccess: (task: Task) => {
      queryClient.setQueryData(
        [
          "tasks",
          caseUUID,
          page,
          taskStatusUUID,
          courierUUID,
          taskNumber,
          dueDate,
          cases,
          trackingNumber,
          completionDate,
          sort,
        ],
        (prev: ResponseDataWithPagination<Task[]> | Task[] | undefined) => {
          onEditSuccess?.();

          if (!prev) {
            return [];
          }

          if ("data" in prev) {
            return {
              data: (prev.data || []).map((prevTask) => {
                if (prevTask.taskUUID === task.taskUUID) {
                  return task;
                }
                return prevTask;
              }),
              page: prev.page,
              pages: prev.pages,
            };
          }

          return (prev || []).map((prevTask) => {
            if (prevTask.taskUUID === task.taskUUID) {
              return task;
            }
            return prevTask;
          });
        }
      );
    },
  });

  const editTask = (
    id: string,
    payload: CreateTaskPayload | EditTaskPayload
  ) => {
    editTaskMutation.mutate({ id, payload });
  };

  const editError = (editTaskMutation.error as Error)?.message as string;
  return {
    tasks: tasks?.data || [],
    pages: tasks?.pages,
    getTasksLoading,
    getTasksError,
    createTask,
    deleteTask,
    editTask,
    createLoading: createTaskMutation.isLoading,
    editLoading: editTaskMutation.isLoading,
    deleteLoading: deleteTaskMutation.isLoading,
    editError,
    createError,
    deleteError,
  };
};

export default useTasks;
