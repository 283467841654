import { useEffect, useState } from "react";

import { ITooth } from "@/components/TeethWidget/components/Tooth";
import { groupTeethByJob } from "@/utils/groupToothByJob";
import { ChosenTooth } from "@/components/TeethWidget/index";
import {
  getChosenTeethWithExtractedJaws,
  getGroupExtremePositions,
  getToothIndexInGroupedTeethExtremePositions,
  swgTeethToWidgetTeeth,
} from "@/components/TeethWidget/utils";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import { setActiveTeeth } from "@/store/reducers/activeProductTeethSlice";

const useTeeth = ({
  formulaType,
  chosenTeeth,
}: {
  formulaType?: "child" | "adult";
  chosenTeeth: ChosenTooth[];
}) => {
  const dispatch = useAppDispatch();

  const [teeth, setTeeth] = useState<ITooth[]>(
    swgTeethToWidgetTeeth(formulaType)
  );

  const activeTeeth = useAppSelector(
    (state) => state.activeProductTeethSlice.activeTeeth
  );

  useEffect(() => {
    dispatch(setActiveTeeth([]));
  }, []);

  useEffect(() => {
    setTeeth(swgTeethToWidgetTeeth(formulaType));
  }, [formulaType]);

  useEffect(() => {
    const allTeeth: ChosenTooth[] =
      getChosenTeethWithExtractedJaws(chosenTeeth);

    const groupedTeeth = groupTeethByJob(allTeeth);
    const groupedTeethExtremePositions = getGroupExtremePositions(
      groupedTeeth,
      teeth
    );

    setTeeth(() => {
      const newTeeth = [];
      const teeth = swgTeethToWidgetTeeth(formulaType);
      for (let i = 0; i < teeth.length; i++) {
        const tooth = teeth[i];
        const chosenTeethById = allTeeth.filter((t) => t.toothID === tooth.id);
        const chosenToothIndex = allTeeth.findIndex(
          (t) => t.toothID === tooth.id
        );
        tooth.arc =
          chosenTeeth.some((t) => t.crown) &&
          groupedTeethExtremePositions.some((p) => {
            const jobToothMin = p[0];
            const jobToothMax = p[1];
            return Boolean(
              jobToothMax &&
                jobToothMin &&
                tooth.position < jobToothMax &&
                tooth.position > jobToothMin
            );
          });
        tooth.tapped = !!chosenTeethById.length;
        tooth.isCrown = chosenTeethById.some((t) => t.crown);
        const containsAnotherProduct =
          chosenTeethById[0] &&
          allTeeth
            .slice(chosenToothIndex + 1)
            .find((t) => t.toothID === chosenTeethById[0].toothID);

        tooth.isCurrent = activeTeeth.includes(tooth.id);
        tooth.containAnotherProduct = !!(
          chosenTeethById.length && containsAnotherProduct
        );
        tooth.anotherProductColor = containsAnotherProduct?.color || undefined;
        tooth.color = chosenTeethById.length ? chosenTeethById[0].color : null;
        tooth.isPoint = chosenTeethById.length
          ? chosenTeethById[0].isPoint
          : false;
        tooth.selected = chosenTeethById[0]?.selected || false;

        tooth.isConnected =
          (tooth.arc || tooth.isCrown || tooth.isCurrent) &&
          (teeth[i - 1]?.arc ||
            teeth[i - 1]?.isCrown ||
            teeth[i - 1]?.isCurrent) &&
          groupedTeeth[
            getToothIndexInGroupedTeethExtremePositions(
              tooth,
              groupedTeethExtremePositions
            )
          ] ===
            groupedTeeth[
              getToothIndexInGroupedTeethExtremePositions(
                teeth[i - 1],
                groupedTeethExtremePositions
              )
            ];
        tooth.jobProductUUID = chosenTeethById[0]?.group;
        tooth.isChainOpen = true;

        const containsProduct = chosenTeethById.some((t) => t.group);

        tooth.chainNode =
          containsProduct &&
          teeth[i - 1]?.jobProductUUID &&
          teeth[i - 1]?.jobProductUUID !== tooth.jobProductUUID
            ? undefined
            : tooth.chainNode;

        newTeeth.push(tooth);
      }
      return newTeeth;
    });
  }, [chosenTeeth.length, chosenTeeth, activeTeeth]);

  return teeth;
};

export default useTeeth;
