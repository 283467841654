import { FC } from "react";

import useProducts from "@/hooks/useProducts";
import { Product } from "@/root/models/product";
import PricelistList, {
  PricelistItem,
} from "@/components/PricelistForm/componenets/PricelistList";

interface ProductPricesProps {
  productPrices: { itemUUID: string; price: number }[];
  setProductPrices: (productPrices: any[]) => void;
  presentation?: boolean;
}

const ProductPricelist: FC<ProductPricesProps> = ({
  productPrices,
  setProductPrices,
  presentation,
}) => {
  const { products, getProductsLoading } = useProducts();

  const parseProducts = (products: Product[]): PricelistItem[] => {
    return products.map((p) => ({
      ...p,
      itemUUID: p.productUUID,
      children: p.children ? parseProducts(p.children) : undefined,
    }));
  };

  return (
    <PricelistList
      items={parseProducts(products)}
      getItemsLoading={getProductsLoading}
      itemPrices={productPrices}
      setItemPrices={setProductPrices}
      presentation={presentation}
    />
  );
};

export default ProductPricelist;
