import { Job } from "@/root/models/case";
import useProducts from "@/hooks/useProducts";
import { ChosenTooth } from "@/components/TeethWidget";

const useCombinedJobTeeth = (jobs: Job[]) => {
  const { findProductByID, getParameterGroup } = useProducts();

  return jobs.reduce((sum, job) => {
    job.products.forEach((product) => {
      const fullProduct = findProductByID(product.product.productUUID);
      const color = fullProduct
        ? getParameterGroup(fullProduct).color || null
        : null;
      const crown = product.combinedProduct;
      sum.push(
        ...product.teeth.map((toothID) => ({
          toothID,
          color,
          crown,
          selected: false,
          isPoint: fullProduct?.isPoint || false,
          group: product.jobProductUUID,
        }))
      );
    });
    return sum;
  }, [] as ChosenTooth[]);
};

export default useCombinedJobTeeth;
