import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Space, Table } from "antd";

import { ClientEmployee } from "@/root/models/clientEmployee";
import {
  CreateClientEmployee,
  TableActions,
} from "@/modules/ClientEmployees/components";
import useClientEmployees from "@/hooks/useClientEmployees";
import { useAppSelector } from "@/hooks/redux";
import { UserTypeEnum } from "@/root/types";
import useModalOpen from "@/hooks/useModalOpen";

interface ClientEmployeesProps {
  clientUUID?: string;
}

const ClientEmployees: FC<ClientEmployeesProps> = ({ clientUUID }) => {
  const { t } = useTranslation();
  const { clientEmployees, getClientEmployeesLoading } = useClientEmployees({
    clientUUID,
  });
  const userType = useAppSelector((state) => state.userReducer.user?.userType);

  const { modal, onHandleCancel, onEditSuccess, isModalOpen } = useModalOpen();

  const clientEmployeesColumn = [
    {
      title: t("Ім'я.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Логін"),
      dataIndex: "login",
      key: "login",
    },
    {
      title: t("Пошта"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("Заборонити доступ"),
      dataIndex: "disableAccess",
      key: "disableAccess",
      render: (disableAccess: boolean) => (
        <span>{disableAccess ? t("Так") : t("Ні")}</span>
      ),
    },
  ];

  (userType?.userTypeUUID === UserTypeEnum["Client's admin"] ||
    userType?.userTypeUUID === UserTypeEnum["Lab's admin"] ||
    userType?.userTypeUUID === UserTypeEnum["Employee"]) &&
    clientEmployeesColumn.push({
      key: "action", // @ts-ignore
      render: (_: any, clientEmployee: ClientEmployee) => (
        <TableActions
          modal={modal}
          onCancel={onHandleCancel}
          onEditSuccess={onEditSuccess}
          onModalOpen={() => {
            isModalOpen(clientEmployee.clientsEmployeeUUID);
          }}
          clientEmployee={clientEmployee}
          clientUUID={clientUUID}
        />
      ),
    });

  return (
    <Space direction="vertical">
      {(userType?.userTypeUUID === UserTypeEnum["Client's admin"] ||
        userType?.userTypeUUID === UserTypeEnum["Employee"] ||
        userType?.userTypeUUID === UserTypeEnum["Lab's admin"]) && (
        <CreateClientEmployee clientUUID={clientUUID} />
      )}
      <Table
        style={{ userSelect: "none" }}
        columns={clientEmployeesColumn}
        dataSource={clientEmployees.map((e) => ({
          ...e,
          key: e.clientsEmployeeUUID,
        }))}
        pagination={false}
        loading={getClientEmployeesLoading}
        onRow={(clientsEmployee) => {
          return {
            onDoubleClick: () =>
              isModalOpen(clientsEmployee.clientsEmployeeUUID),
          };
        }}
      />
    </Space>
  );
};

export default ClientEmployees;
