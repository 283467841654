const DoctorInformation = () => {
  return (
    <div style={{ marginBottom: "10px" }}>
      <div>Dr. Melanie Gilbert</div>
      <div>Sunnybrook Health Sciences Centre</div>
      <div>2075 Bayview Avenue</div>
      <div>H-wing, 1st floor, room H126</div>
      <div>Toronto ON M4N 3M5 Canada</div>
    </div>
  );
};

export default DoctorInformation;
