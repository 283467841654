import { FC, useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import { useTranslation } from "react-i18next";

import { Task } from "@/root/models/task";
import { Alert, Button, Input, Modal } from "antd";

import { CasesApi } from "@/api/client/cases";
import { Loader } from "@/components/common";
import { CaseStatusEnum } from "@/root/types";

interface AddCaseProps {
  onAdd: (c: Task.Case) => void;
  withScan: boolean;
}

const AddCase: FC<AddCaseProps> = ({ onAdd, withScan }) => {
  const { t } = useTranslation();
  const [openedModalWithType, setOpenedModalWithType] = useState<
    "byNumber" | "byQR"
  >();
  const [numberInputValue, setNumberInputValue] = useState("");
  const [findCaseLoading, setFindCaseLoading] = useState(false);
  const [findCaseError, setFindCaseError] = useState("");

  useEffect(() => {
    if (!openedModalWithType) {
      setFindCaseError("");
    }
  }, [openedModalWithType]);

  const findCase = async (value: string) => {
    try {
      setFindCaseLoading(true);
      setFindCaseError("");
      const fullCase = await CasesApi.getCases({ caseNumber: value }).then(
        (res) => res.data[0]
      );
      if (!fullCase) {
        throw new Error(t("Замовлення не знайдено") as string);
      }
      if (
        fullCase.status.caseStatusUUID !== CaseStatusEnum.New &&
        fullCase.status.caseStatusUUID !== CaseStatusEnum["Ready for pickup"]
      ) {
        throw new Error(
          t(
            "Дозволено додавати замовлення лише зі статусами Нове і Очікується з клініки"
          ) as string
        );
      }
      onAdd({
        caseUUID: fullCase.caseUUID,
        caseNumber: fullCase.caseNumber,
        status: fullCase.status,
        client: fullCase.client as {
          clientUUID: string;
          name: string;
          address: string;
        },
        patient: fullCase.patient.name,
      });
      setOpenedModalWithType(undefined);
    } catch (e: any) {
      setFindCaseError(e.message);
    } finally {
      setFindCaseLoading(false);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: 10,
          width: "100%",
          padding: "0 10px",
        }}
      >
        <Button
          size="small"
          style={{ flexGrow: 1 }}
          onClick={() => setOpenedModalWithType("byNumber")}
        >
          {t("Додати по номеру")}
        </Button>
        {withScan && (
          <Button
            size="small"
            style={{ flexGrow: 1 }}
            onClick={() => setOpenedModalWithType("byQR")}
          >
            {t("Сканувати QR")}
          </Button>
        )}
      </div>

      <Modal
        destroyOnClose
        open={openedModalWithType === "byQR"}
        onCancel={() => setOpenedModalWithType(undefined)}
        okButtonProps={{ hidden: true }}
        title={t("Відскануйте номер замовлення")}
      >
        {findCaseLoading ? (
          <Loader center />
        ) : (
          <QrReader
            constraints={{ facingMode: "environment" }}
            onResult={(result) =>
              result?.getText() && findCase(result.getText())
            }
          />
        )}
        {!!findCaseError && (
          <Alert
            style={{ marginTop: 10 }}
            message={findCaseError}
            type="error"
          />
        )}
      </Modal>

      <Modal
        destroyOnClose
        open={openedModalWithType === "byNumber"}
        onOk={() => findCase(numberInputValue)}
        onCancel={() => setOpenedModalWithType(undefined)}
        okButtonProps={{
          loading: findCaseLoading,
        }}
        title={t("Введіть номер замовлення")}
      >
        <Input
          value={numberInputValue}
          onChange={(e) => setNumberInputValue(e.target.value)}
        />
        {!!findCaseError && (
          <Alert
            style={{ marginTop: 10 }}
            message={findCaseError}
            type="error"
          />
        )}
      </Modal>
    </>
  );
};

export default AddCase;
