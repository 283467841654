import { useQuery } from "@tanstack/react-query";

import { CurrentPricelistApi } from "@/api/client/currentPricelist";

const useClientsCurrentPricelist = (
  clientUUID: string,
  pricelistTypeUUID: string
) => {
  const { data: pricelist, isLoading } = useQuery({
    queryKey: ["currentPricelist", clientUUID, pricelistTypeUUID],
    queryFn: () =>
      CurrentPricelistApi.getPricelist({ clientUUID, pricelistTypeUUID }),
    cacheTime: 0,
  });

  return {
    pricelist,
    isLoading,
  };
};

export default useClientsCurrentPricelist;
