import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { UnitsOfMeasure } from "@/root/models/unitsOfMeasure";

export interface CreateUnitsOfMeasurePayload {
  name: string;
  code: string;
  packagingRatio: number;
}

export const UnitsOfMeasureApi = {
  getUnitsOfMeasureApi: async (): Promise<UnitsOfMeasure[]> => {
    const data = await makeRequest<UnitsOfMeasure[]>({
      url: apiBaseurl + "/units-of-measure",
    });
    return data;
  },

  create: async (
    payload: CreateUnitsOfMeasurePayload
  ): Promise<UnitsOfMeasure> => {
    const data = await makeRequest<UnitsOfMeasure>({
      url: apiBaseurl + "/units-of-measure",
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async ({
    id,
    payload,
  }: {
    id: string;
    payload: CreateUnitsOfMeasurePayload;
  }): Promise<UnitsOfMeasure> => {
    const data = await makeRequest<UnitsOfMeasure>({
      url: apiBaseurl + `/units-of-measure/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/units-of-measure/${id}`,
      method: "delete",
    });
  },
};
