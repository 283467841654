import { Checkbox } from "antd";
import { t } from "i18next";
import { ActionCreatorTypes, useCaseFormContext } from "../CaseFormContext";
import { CaseStatusEnum } from "@/root/types";

const CaseStatusChange = () => {
  const { dispatch } = useCaseFormContext();

  return (
    <div style={{ display: "flex", gap: 8 }}>
      <div>{`${t("Готовий до відправки")}: `}</div>
      <Checkbox
        onChange={(e) =>
          dispatch({
            type: ActionCreatorTypes.SetStatus,
            payload: e.target.checked
              ? {
                  caseStatusUUID: CaseStatusEnum["Ready for delivery"],
                  name: "Ready for delivery",
                  progress: 72,
                }
              : null,
          })
        }
      />
    </div>
  );
};

export default CaseStatusChange;
