import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";

import useParameters from "@/modules/ProductsParameters/useParameters";
import {
  ProductParameter,
  ProductParameterType,
} from "@/root/models/productParameter";
import {
  CreateParameter,
  TableActions,
} from "@/modules/ProductsParameters/components";
import useFilters from "@/hooks/useFilters";
import { getParameterValue } from "@/utils/getParameterValue";
import useModalOpen from "@/hooks/useModalOpen";

const ProductsParameters: FC = () => {
  const { t } = useTranslation();
  const {
    parameters,
    getParametersLoading,
    createParameter,
    editParameter,
    deleteParameter,
  } = useParameters();
  const { findFilterByID } = useFilters();

  const { modal, onHandleCancel, onEditSuccess, isModalOpen } = useModalOpen();

  const columns: ColumnsType<ProductParameter> = [
    { key: "name", dataIndex: "name", title: t("Параметр") },
    {
      key: "type",
      dataIndex: "type",
      title: t("Тип значення параметру"),
      render: (p: ProductParameterType) => <span>{p.name}</span>,
    },
    {
      key: "defaultValue",
      dataIndex: "defaultValue",
      title: t("Значення за замовчуванням"),
      render: (defaultValue: ProductParameter["defaultValue"], { type }) =>
        getParameterValue(type, defaultValue as string, findFilterByID),
    },
    {
      key: "list",
      dataIndex: "list",
      title: t("Список значень"),
      render: (p: ProductParameter["list"]) => (
        <span>{p?.map((p) => p.value)?.join("; ")}</span>
      ),
    },
    {
      key: "filtersValues",
      dataIndex: "filtersValues",
      title: t("Значення фільтрів"),
      render: (p: ProductParameter["filtersValues"]) => (
        <span>{p?.map((p) => p.name)?.join("; ")}</span>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_: any, parameter: ProductParameter) => (
        <TableActions
          parameter={parameter}
          onDelete={deleteParameter}
          onEdit={editParameter}
          modal={modal}
          onCancel={onHandleCancel}
          onEditSuccess={onEditSuccess}
          onModalOpen={() => {
            isModalOpen(parameter.productsParameterUUID);
          }}
        />
      ),
    },
  ];

  const data = parameters.map((p) => ({ ...p, key: p.productsParameterUUID }));

  return (
    <Space direction="vertical">
      <CreateParameter onCreate={createParameter} />
      <Table
        style={{ userSelect: "none" }}
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={getParametersLoading}
        onRow={(productParameter) => {
          return {
            onDoubleClick: () =>
              isModalOpen(productParameter.productsParameterUUID),
          };
        }}
      />
    </Space>
  );
};

export default ProductsParameters;
