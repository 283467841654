import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { ParametersGroup } from "@/root/models/parameterGroup";

export interface CreateProductsParametersGroupPayload {
  name: string;
  productsParameters: {
    productsParameterUUID: string;
    required: boolean;
  }[];
}

export const ProductsParametersGroupApi = {
  getGroups: async (): Promise<ParametersGroup[]> => {
    const data = await makeRequest<ParametersGroup[]>({
      url: apiBaseurl + "/products-parameters-groups",
    });

    return data;
  },

  create: async (
    payload: CreateProductsParametersGroupPayload
  ): Promise<ParametersGroup> => {
    const data = await makeRequest<ParametersGroup>({
      url: apiBaseurl + "/products-parameters-groups",
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async (
    id: string,
    payload: CreateProductsParametersGroupPayload
  ): Promise<ParametersGroup> => {
    const data = await makeRequest<ParametersGroup>({
      url: apiBaseurl + `/products-parameters-groups/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/products-parameters-groups/${id}`,
      method: "delete",
    });
  },
};
