import useModalOpen from "@/hooks/useModalOpen";
import useSuppliers from "@/hooks/useSuppliers";
import { useIsLab } from "@/root/models/isLab";
import { Supplier } from "@/root/models/supplier";
import { Alert, Space } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { t } from "i18next";
import TableActions from "./TableActions";
import CreateSupplier from "./CreateSupplier";

const SuppliersTab = () => {
  const { suppliers, getSuppliersLoading, getSuppliersError } = useSuppliers();

  const { modal, onHandleCancel, onEditSuccess, isModalOpen } = useModalOpen();

  const { isAdmin } = useIsLab();

  const suppliersColumns: ColumnsType<Supplier> = [
    {
      title: t("Назва"),
      dataIndex: "name",
      key: "name",
    },
  ];

  if (isAdmin) {
    suppliersColumns.splice(1, 0, {
      title: "",
      key: "action",
      render: (supplier: Supplier) => (
        <TableActions
          onEditSuccess={onEditSuccess}
          modal={modal}
          onCancel={onHandleCancel}
          onModalOpen={() => {
            isModalOpen(supplier.supplierUUID);
          }}
          supplier={supplier}
        />
      ),
    });
  }

  return (
    <Space direction="vertical">
      {isAdmin && <CreateSupplier />}
      {getSuppliersError ? (
        <Alert type="error" message={getSuppliersError as string} />
      ) : (
        <Table
          style={{ userSelect: "none" }}
          columns={suppliersColumns}
          dataSource={suppliers.map((s) => ({ ...s, key: s.supplierUUID }))}
          pagination={false}
          loading={getSuppliersLoading}
          onRow={(supplier) => ({
            onDoubleClick: () => isModalOpen(supplier.supplierUUID),
          })}
        />
      )}
    </Space>
  );
};

export default SuppliersTab;
