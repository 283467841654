import { ResponseDataWithPagination } from "@/api/types";
import makeRequest from "./makeRequest";
import { apiBaseurl } from "./baseurl";
import { Payment } from "@/root/models/payment";

interface PaymentsItems {
  invoiceUUID: string;
  caseUUID: string;
  rowUUID: string;
  sum: number;
}

export interface PaymentEditPayloadProps {
  description: string;
  amount: number;
  clientUUID: string;
  // paymentTypeUUID: string;
  // caseUUID: string;
  items: PaymentsItems[];
}

export interface PaymentCreatePayloadProps {
  clientUUID: string;
  description: string;
  amount: number;
  items: PaymentsItems[];
}

export const PaymentsApi = {
  getPayments: async (
    page?: number,
    clientUUID?: string,
    date?: string
  ): Promise<ResponseDataWithPagination<Payment[]>> => {
    const data = await makeRequest<Payment[], true>(
      {
        url: apiBaseurl + "/payments",
        params: {
          pageLimit: 20,
          page,
          clientUUID,
          date,
        },
      },
      { pagination: true }
    );
    return data;
  },

  create: async ({
    payload,
  }: {
    payload: PaymentCreatePayloadProps;
  }): Promise<Payment> => {
    const data = await makeRequest<Payment>({
      url: apiBaseurl + `/payments`,
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async ({
    id,
    payload,
  }: {
    id: string;
    payload: PaymentEditPayloadProps;
  }): Promise<Payment> => {
    const data = await makeRequest<Payment>({
      url: apiBaseurl + `/payments/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/payments/${id}`,
      method: "delete",
    });
  },
};
