import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "antd";
import { useForm } from "antd/es/form/Form";

import { CreateTaskPayload } from "@/api/tasks";
import { TaskForm, TaskPhotos } from "@/components";
import useTasks from "@/hooks/useTasks";
import { TasksQueryProps } from "@/modules/Tasks";
import { TaskPhoto } from "@/components/TaskPhotos";

import styles from "./CreateTaskModal.module.scss";

interface CreateTaskModalProps {
  open: boolean;
  tasksQueryProps: TasksQueryProps;
  onCancel: () => void;
}

const CreateTaskModal: FC<CreateTaskModalProps> = ({
  open,
  onCancel,
  tasksQueryProps,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [photos, setPhotos] = useState<TaskPhoto[]>([]);

  const onCreateSuccess = () => {
    onCancel();
    form.resetFields();
  };

  const {
    createTask,
    createLoading: loading,
    createError: error,
  } = useTasks({ ...tasksQueryProps, onCreateSuccess });

  const handleCreate = async (values: CreateTaskPayload) => {
    createTask({
      ...values,
      attachments: photos.map(({ fileUUID, filename, description }) => ({
        filename,
        description,
        fileUUID,
      })),
    });
  };

  const submit = () => {
    form.submit();
  };

  useEffect(() => {
    if (form && open && tasksQueryProps.caseUUID) {
      form.setFieldsValue({
        cases: [tasksQueryProps.caseUUID],
      });
    }
  }, [form, open]);

  return (
    <Modal
      maskClosable={false}
      okText={t("Створити")}
      title={<div style={{ marginBottom: 20 }}>{t("Створення завдання")}</div>}
      open={open}
      onCancel={onCancel}
      onOk={submit}
      okButtonProps={{ loading }}
      centered
      className={styles.createTaskModal_content}
    >
      <TaskForm
        form={form}
        cases={[]}
        handleSubmit={handleCreate}
        error={error}
      />
      <TaskPhotos photos={photos} setPhotos={setPhotos} />
    </Modal>
  );
};

export default CreateTaskModal;
