import { RefObject, useEffect } from "react";

const useClickOutside = (
  ref: RefObject<HTMLElement>,
  onClickOutside: (e?: any) => void,
  antdDropdownCount: boolean = false
) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (
          antdDropdownCount &&
          typeof event.target.className === "string" &&
          (event.target.className.includes("ant-select-item-option") ||
            event.target.className.includes("ant-picker") ||
            event.target.closest(".Product_productDropdownContent__T124E"))
        ) {
          return;
        }

        onClickOutside();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
};

export default useClickOutside;
