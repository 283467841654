import { Button, Modal, notification } from "antd";
import { t } from "i18next";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { PaymentForm } from "./PaymentForm";
import { usePayments } from "@/hooks/usePayments";
import { PaymentCreatePayloadProps } from "@/api/payments";

const CreatePayment = () => {
  const [form] = useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCreateSuccess = () => {
    handleCancel();
    notification.success({
      message: t("Оплата була успішно створена"),
    });
  };

  const {
    createPayment,
    createError,
    createLoading: loading,
  } = usePayments({ onCreateSuccess });

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!!createError) {
      notification.error({
        message: createError,
      });
    }
  }, [createError]);

  const onSubmit = () => {
    form.submit();
  };

  const paymentCreate = async (values: PaymentCreatePayloadProps) => {
    createPayment({ ...values });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };
  return (
    <>
      <Button onClick={showModal} style={{ marginBottom: 10 }}>
        {t("Стоврити оплату")}
      </Button>
      <Modal
        centered
        destroyOnClose={true}
        width={1000}
        open={isModalOpen}
        maskClosable={false}
        title={t("Стоврити оплату")}
        onCancel={handleCancel}
        okText={t("Зберегти")}
        onOk={onSubmit}
        okButtonProps={{ loading }}
      >
        <PaymentForm
          handlePaymentCreate={paymentCreate}
          form={form}
          paymentItems={[]}
          type="create"
        />
      </Modal>
    </>
  );
};

export default CreatePayment;
