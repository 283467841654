import { Product } from "@/root/models/product";
import { useEffect, useState } from "react";

export interface FilteredProductsProps {
  parent: Product;
  children: Product[];
}

export const useProductSearch = (productsSearchValue: string | undefined) => {
  const [filteredProducts, setFilteredProducts] =
    useState<FilteredProductsProps[]>();

  function searchProducts(products: Product[], searchTerm: string) {
    const results: any = [];

    function searchInProduct(product: Product, parents = []) {
      const currentParents: any = [...parents];
      let foundParentIndex = -1;

      if (product.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        // Check if the parent already exists in results
        foundParentIndex = results.findIndex(
          (entry: any) =>
            entry.parent === currentParents[currentParents.length - 1]
        );
        if (foundParentIndex === -1) {
          results.push({
            parent: currentParents[currentParents.length - 1],
            children: [product],
          });
        } else {
          results[foundParentIndex].children.push(product);
        }
      }

      if (product.children) {
        currentParents.push(product);
        product.children.forEach((child) =>
          searchInProduct(child, currentParents)
        );
        currentParents.pop();
      }
    }

    products.forEach((product) => searchInProduct(product));

    setFilteredProducts(results);
  }

  useEffect(() => {
    if (!productsSearchValue?.length) {
      setFilteredProducts([]);
    }
  }, [productsSearchValue]);

  return { filteredProducts, searchProducts };
};
