import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { Warehouse } from "@/root/models/warehouse";

export interface CreateWarehousePayload {
  name: string;
}

export const WarehousesApi = {
  getWarehouses: async (): Promise<Warehouse[]> => {
    const data = await makeRequest<Warehouse[]>({
      url: apiBaseurl + "/warehouses",
    });
    return data;
  },

  create: async (payload: CreateWarehousePayload): Promise<Warehouse> => {
    const data = await makeRequest<Warehouse>({
      url: apiBaseurl + "/warehouses",
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async ({
    id,
    payload,
  }: {
    id: string;
    payload: CreateWarehousePayload;
  }): Promise<Warehouse> => {
    const data = await makeRequest<Warehouse>({
      url: apiBaseurl + `/warehouses/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/warehouses/${id}`,
      method: "delete",
    });
  },
};
