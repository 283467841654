import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { Supplier } from "@/root/models/supplier";

export interface CreateSupplierPayload {
  name: string;
}

export const SuppliersApi = {
  getSuppliers: async (): Promise<Supplier[]> => {
    const data = await makeRequest<Supplier[]>({
      url: apiBaseurl + "/suppliers",
    });
    return data;
  },

  create: async (payload: CreateSupplierPayload): Promise<Supplier> => {
    const data = await makeRequest<Supplier>({
      url: apiBaseurl + "/suppliers",
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async ({
    id,
    payload,
  }: {
    id: string;
    payload: CreateSupplierPayload;
  }): Promise<Supplier> => {
    const data = await makeRequest<Supplier>({
      url: apiBaseurl + `/suppliers/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/suppliers/${id}`,
      method: "delete",
    });
  },
};
