import { FC, useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button,
  Form,
  Modal,
  notification,
  Space,
  Tabs,
  TabsProps,
} from "antd";
import { useForm } from "antd/es/form/Form";

import styles from "./LaboratoryCaseForm.module.scss";

import {
  ActionCreatorTypes,
  CaseFormContext,
  caseFormInitialState,
  caseFormReducer,
  CaseFormState,
} from "@/components/CaseForm/CaseFormContext";
import { CreateCasePayload } from "@/api/client/cases";
import {
  CaseDetails,
  PrintMenu,
  Extras,
  SpareParts,
  Products,
  LabCaseFiles,
  Delivery,
  Summary,
  Warranty,
} from "@/components/LaboratoryCaseForm/components";
import { Case, EmployeeCase } from "@/root/models/case";
import { Block } from "@/components/common";
import formJobToJob from "@/components/CaseForm/formJobToJob";
import useFilters from "@/hooks/useFilters";
import { calculateGrandTotal } from "@/utils/calculateCasePrice";
import useTableScrollHeight from "@/hooks/TableHooks/useTableScrollHeight";
import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
  setIsSelect,
  setTabKeyLeft,
} from "@/store/reducers/teethFormulaSelectSlice";
import Accounting from "@/modules/Accounting";
import useAccounting from "@/hooks/useAccounting";
import { AccountingTotal } from "@/root/models/accounting";

interface CaseFormProps {
  medCase: EmployeeCase | null;
  type: "create" | "edit";
  initialState?: CaseFormState;
  loading: boolean;
  onFinish: (
    data: CreateCasePayload,
    close: boolean,
    type: "client" | "lab"
  ) => Promise<Case | undefined>;
  onCancel: () => void;
}

const LaboratoryCaseForm: FC<CaseFormProps> = ({
  initialState,
  medCase,
  loading,
  onCancel,
  onFinish,
  type,
}) => {
  const { t } = useTranslation();
  const { filters } = useFilters();
  const { confirm } = Modal;
  const [tabKey, setTabKey] = useState("products");
  const [state, dispatch] = useReducer(
    caseFormReducer,
    initialState || caseFormInitialState
  );

  const [grandTotal, setGrandTotal] = useState<number | null>(null);
  const [form] = useForm();

  useEffect(() => {
    if (!state.teethFormulaTypeUUID) {
      return;
    }

    setGrandTotal(
      calculateGrandTotal(
        state.jobs as CaseFormState.Job[],
        state.teethFormulaTypeUUID
      )
    );
  }, [state.teethFormulaTypeUUID, state.jobs]);

  const { tableScrollHeight } = useTableScrollHeight(-5);

  const dispatchSelect = useAppDispatch();

  const isSelectTeethFormula = useAppSelector(
    (state) => state.teethFormulaSelectSlice.isSelect
  );
  const isSelectedTabKey = useAppSelector(
    (state) => state.teethFormulaSelectSlice.tabKey
  );
  //Navigate to Summary modal if select button in products tab clicked
  const [isSummaryOpen, setIsSummeryOpen] = useState(false);

  useEffect(() => {
    if (isSelectTeethFormula) {
      setIsSummeryOpen(true);
    }
  }, [isSelectTeethFormula]);

  const onModalClose = () => {
    setIsSummeryOpen(false);
    dispatchSelect(setIsSelect(false));
  };

  const { accountingArrayWithTotal } = useAccounting({
    jobs: state.jobs,
    accounting: state.accounting,
  });

  const [updatedAccountingArray, setUpdatedAccountingArray] = useState<
    AccountingTotal[]
  >(accountingArrayWithTotal);

  const handleCalculateTotalClick = () => {
    if (!state.accounting.length) {
      setUpdatedAccountingArray(accountingArrayWithTotal);
      dispatch({
        type: ActionCreatorTypes.SetAccounting,
        payload: accountingArrayWithTotal,
      });
    } else if (!!state.accounting.length) {
      setUpdatedAccountingArray(accountingArrayWithTotal);

      confirm({
        title: t("Оновити розрахунок?"),
        okText: t("Оновити"),
        okType: "primary",
        cancelText: t("Скасувати"),
        onOk: () => {
          dispatch({
            type: ActionCreatorTypes.SetAccounting,
            payload: accountingArrayWithTotal,
          });
        },
      });
    }
  };

  const leftBlockItems: TabsProps["items"] = [
    {
      key: "details",
      label: t("Деталі"),
      children: (
        <Block
          style={{
            boxShadow: "none",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            overflow: "auto",
            height: tableScrollHeight,
          }}
        >
          <CaseDetails form={form} medCase={medCase} />
        </Block>
      ),
    },
    {
      key: "summary",
      label: t("Підсумок"),
      children: (
        <>
          {isSummaryOpen ? (
            <Modal
              open={isSummaryOpen}
              width={"27.5%"}
              closeIcon={null}
              footer={null}
              className={styles.summary_modal}
              style={{ left: "90px", top: "90px", margin: 0, padding: 0 }}
              onCancel={onModalClose}
            >
              <Summary />
            </Modal>
          ) : (
            <Summary />
          )}
        </>
      ),
    },
  ];

  const items: TabsProps["items"] = [
    {
      key: "products",
      label: t("Вироби"),
      children: <Products />,
    },
    {
      key: "extras",
      label: t("Додаткові роботи і послуги"),
      children: <Extras />,
    },
    {
      key: "spareParts",
      label: t("Матеріали"),
      children: <SpareParts />,
    },
    {
      key: "files",
      label: t("Файли"),
      children: <LabCaseFiles />,
    },
    {
      key: "delivery",
      label: t("Доставка"),
      children: (
        <Delivery
          save={() => handleFinish(false)}
          caseUUID={state.caseUUID || undefined}
        />
      ),
    },
    {
      key: "accounting",
      label: t("Розрахунок"),
      children: <Accounting accounting={updatedAccountingArray} />,
    },
    {
      key: "warranty",
      label: t("Гарантія"),
      children: <Warranty />,
    },
  ];

  const checkError = () => {
    for (let job of state.jobs as CaseFormState.Job[]) {
      for (let product of job.products) {
        if (!product.productsParametersGroups) {
          return { jobID: job.jobID, message: t("Оберіть виріб") };
        } else if (!product.teeth?.length) {
          return { jobID: job.jobID, message: t("Оберіть зуби") };
        } else {
          for (let group of product.productsParametersGroups) {
            for (let parameter of group.productsParameters) {
              if (parameter.required && !parameter.value) {
                const toothText = parameter.tooth ? ` ${parameter.tooth}` : "";
                return {
                  jobID: job.jobID,
                  message: t("Заповніть обов'язкові параметри") + toothText,
                };
              }
            }
          }
        }
      }
    }
  };

  const handleFinish = async (close: boolean = true) => {
    dispatch({ type: ActionCreatorTypes.ClearErrors });
    await form.validateFields();
    const error = checkError();
    if (error) {
      notification.error({ message: error.message });
      dispatch({ type: ActionCreatorTypes.SetError, payload: error });
      return;
    }
    const data = {
      clientUUID: state.client?.clientUUID,
      clientsEmployeeUUID: state.clientsEmployee?.clientsEmployeeUUID || null,
      activeHandlerUUID: state.activeHandler?.activeHandlerUUID || null,
      patientUUID: state.patient?.patientUUID,
      patientCode: state.patient?.patientCode,
      description: state.description,
      teethFormulaNumberingUUID: state.teethFormulaNumberingUUID,
      teethFormulaTypeUUID: state.teethFormulaTypeUUID,
      arrival: state.arrival,
      dueDate: state.dueDate,
      accounting: state.accounting,
      warrantyActivationDate: state.warrantyActivationDate,
      warrantyActivated: state.warrantyActivated,
      warrantyNumber: state.warrantyNumber,
      completionDate: state.completionDate,
      panUUID: state.panUUID,
      caseStatusUUID: state.status?.caseStatusUUID,
      attachments: state.attachments.map((att) => ({
        ...att,
        description: state.description,
      })),
      jobs: state.jobs.map((job: CaseFormState.Job) =>
        formJobToJob(job, filters)
      ),
    } as CreateCasePayload;
    dispatch({ type: ActionCreatorTypes.SetSaveButtonActive, payload: false });
    const medCase = await onFinish(data, close, "lab");
    if (medCase) {
      dispatch({
        type: ActionCreatorTypes.SetCaseUUID,
        payload: medCase.caseUUID,
      });
    }
    return true;
  };

  return (
    <>
      <CaseFormContext.Provider value={{ state, dispatch }}>
        <div className={styles.actionButtons}>
          <Button htmlType="button" onClick={onCancel}>
            {t("Назад")}
          </Button>
          <Space>
            {medCase && (
              <PrintMenu
                medCase={medCase}
                teethFormulaNumberingUUID={
                  state.teethFormulaNumberingUUID || ""
                }
              />
            )}
            <Button htmlType="button" disabled={loading} onClick={onCancel}>
              {t("Скасувати")}
            </Button>
            <Button
              disabled={!state.saveButtonActive}
              onClick={() => handleFinish(false)}
              loading={loading}
            >
              {t("Зберегти")}
            </Button>
            <Button onClick={() => handleFinish(true)} loading={loading}>
              {t("Зберегти і закрити")}
            </Button>
          </Space>
        </div>
        <Form form={form} requiredMark={false}>
          <div style={{ display: "flex", gap: 8 }}>
            <Tabs
              style={{ width: "30%" }}
              tabBarStyle={{ marginBottom: 0 }}
              type="card"
              items={leftBlockItems}
              activeKey={isSelectedTabKey}
              onTabClick={() => {
                if (isSelectedTabKey === "summary") {
                  dispatchSelect(setTabKeyLeft("details"));
                } else {
                  dispatchSelect(setTabKeyLeft("summary"));
                }
              }}
            />
            <Block style={{ width: "70%", boxShadow: "none" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button onClick={handleCalculateTotalClick}>
                  {t("Розрахувати загальну суму")}
                </Button>
                {grandTotal !== null && (
                  <strong>
                    {t("Загальна")}: {grandTotal}
                  </strong>
                )}
              </div>
              <Tabs
                destroyInactiveTabPane
                style={{ marginTop: 10 }}
                type="card"
                items={items}
                activeKey={tabKey}
                onTabClick={setTabKey}
              />
            </Block>
          </div>
        </Form>
      </CaseFormContext.Provider>
    </>
  );
};

export default LaboratoryCaseForm;
