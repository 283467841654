import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { Filter } from "@/root/models/filter";

export interface CreateFilterPayload {
  parentFilterUUID: string;
  name: string;
  isGroup: boolean;
}

const parseFilters = (data: Filter[], parentFilterUUID: string) => {
  return data.map((p) => {
    p.parentFilterUUID = parentFilterUUID;
    if (p.children) {
      p.children = parseFilters(p.children, p.filterUUID);
    }

    return p;
  });
};

export const FiltersApi = {
  getFilters: async (): Promise<Filter[]> => {
    const data = await makeRequest<Filter[]>({
      url: apiBaseurl + "/filters",
    });

    return parseFilters(data, "");
  },

  getFilter: async (id: string): Promise<Filter> => {
    const data = await makeRequest<Filter>({
      url: apiBaseurl + `/filter/${id}`,
    });

    return parseFilters([data], "")[0];
  },

  create: async (payload: CreateFilterPayload): Promise<Filter> => {
    const data = await makeRequest<Filter>({
      url: apiBaseurl + "/filters",
      method: "post",
      data: payload,
    });
    return { ...data, parentFilterUUID: payload.parentFilterUUID };
  },

  edit: async ({id, payload}:{id: string, payload: CreateFilterPayload}): Promise<Filter> => {
    const data = await makeRequest<Filter>({
      url: apiBaseurl + `/filters/${id}`,
      method: "put",
      data: payload,
    });
    return { ...data, parentFilterUUID: payload.parentFilterUUID };
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/filters/${id}`,
      method: "delete",
    });
  },
};
