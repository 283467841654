import { Modal } from "antd";
import { t } from "i18next";
import WarehouseForm from "./WarehouseForm";
import { FormInstance } from "antd/lib/form/Form";

interface ActionWarehouseModalProps {
  open?: boolean;
  modal?: string | null;
  warehouseUUID?: string;
  onCancel: () => void;
  type: "create" | "edit";
  handleSubmit: (values: any) => void;
  error: string;
  loading: boolean;
  form: FormInstance;
}

const ActionWarehouseModal = ({
  open,
  onCancel,
  type,
  handleSubmit,
  error,
  form,
  loading,
  warehouseUUID,
  modal,
}: ActionWarehouseModalProps) => {
  const submit = () => {
    form.submit();
  };

  return (
    <Modal
      maskClosable={false}
      okText={type === "create" ? t("Створити") : t("Редагувати")}
      title={
        <div style={{ marginBottom: 20 }}>
          {type === "create" ? t("Створити склад") : t("Редагувати склад")}
        </div>
      }
      // Open prop for Create and modal with warehouseUUID for Edit (to open edit modal of a clicked warehouse)
      open={warehouseUUID ? modal === warehouseUUID : open}
      onCancel={onCancel}
      onOk={submit}
      okButtonProps={{ loading }}
    >
      <WarehouseForm form={form} handleSubmit={handleSubmit} error={error} />
    </Modal>
  );
};

export default ActionWarehouseModal;
