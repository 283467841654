import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { InputNumber } from "antd";

interface PriceItemProps {
  element: { id: string; price: number | null; isError: boolean };
  height: number | undefined;
  onPriceChange: (elementID: string, price: number | null) => void;
}

const PriceItem: FC<PriceItemProps> = ({ element, height, onPriceChange }) => {
  const { t } = useTranslation();
  const [isError, setIsError] = useState(!element.price);

  const handlePrice = (value: string, elementId: string) => {
    if (!value || value === "0") {
      onPriceChange(elementId, null);
      setIsError(true);
    } else {
      setIsError(false);
      onPriceChange(elementId, Number(value));
    }
  };

  return (
    <div
      style={{
        height,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "15px",
      }}
    >
      <InputNumber
        status={isError ? "error" : undefined}
        style={isError ? { background: "rgba(255,0,0,.85)" } : undefined}
        min={0}
        size="small"
        type="number"
        placeholder={t("Ціна") as string}
        defaultValue={element.price ?? undefined}
        onBlur={(e) => handlePrice(e.target.value, element.id)}
      />
    </div>
  );
};

export default PriceItem;
