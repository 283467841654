import { Alert, Checkbox, Form } from "antd";
import { t } from "i18next";
import styles from "../InvoicePanel.module.scss";

interface PaidCheckboxProps {
  type: string;
  paid?: boolean;
  disable: boolean;
}

export const PaidCheckbox = ({ type, paid, disable }: PaidCheckboxProps) => {
  return (
    <div style={{ display: "flex", position: "relative" }}>
      <Form.Item
        name="paid"
        label={t("Оплачено")}
        valuePropName="checked"
        style={{ flex: 1 }}
      >
        <Checkbox disabled={disable} />
      </Form.Item>
      {paid && type === "edit" && (
        <div style={{ position: "absolute", left: 200 }}>
          <Alert
            className={styles.alert_message}
            type="error"
            message={t(
              "Для того щоб рахунок-фактура був повністю доступний для редагування, скасуйте проведення по оплаті для поточного рахунку-фактури."
            )}
            description={t(
              "Зауважте, що це може призвести до небажаних змін в оплаті!"
            )}
          />
        </div>
      )}
    </div>
  );
};
