import { useEffect, useState } from "react";

import { Part } from "@/root/models/part";
import { PartsApi } from "@/api/admin/parts";

const useParts = () => {
  const [parts, setParts] = useState<Part[]>([]);
  const [getPartsLoading, setGetPartsLoading] = useState(true);
  const [getPartsError, setGetPartsError] = useState("");

  useEffect(() => {
    (async () => {
      try {
        setGetPartsLoading(true);
        const parts = await PartsApi.getParts();
        setParts(parts);
      } catch (e: any) {
        setGetPartsError(e.message);
      } finally {
        setGetPartsLoading(false);
      }
    })();
  }, []);

  const findPartByID = (id: string) => {
    const recursiveSearch = (parts: Part[]) => {
      let part: Part | undefined;
      for (const candidate of parts) {
        if (candidate.sparePartUUID !== id) {
          if (candidate.children) {
            const foundPart = recursiveSearch(candidate.children);
            if (foundPart) {
              part = foundPart;
              break;
            }
          }
        } else {
          part = candidate;
          break;
        }
      }
      return part;
    };

    return recursiveSearch(parts);
  };

  const addPart = (part: Part) => {
    if (part.level === 1) {
      setParts((prev) => [...prev, part]);
    } else {
      const recursiveAdd = (parts: Part[]): Part[] => {
        return parts.map((prevPart) => {
          if (prevPart.sparePartUUID === part.parentSparePartUUID) {
            if (prevPart.children) {
              prevPart.children = [...prevPart.children, part];
            } else {
              prevPart.children = [part];
            }
          } else {
            if (prevPart.children) {
              prevPart.children = recursiveAdd(prevPart.children);
            }
          }
          return prevPart;
        });
      };

      setParts(recursiveAdd);
    }
  };

  const editPart = (part: Part) => {
    const recursiveEdit = (parts: Part[]): Part[] => {
      return parts.map((prevPart) => {
        if (prevPart.sparePartUUID === part.sparePartUUID) {
          return part;
        } else {
          if (prevPart.children) {
            prevPart.children = recursiveEdit(prevPart.children);
          }
          return prevPart;
        }
      });
    };

    setParts(recursiveEdit);
  };

  const deletePart = (part: Part) => {
    const recursiveFilter = (parts: Part[]): Part[] => {
      const newParts: Part[] = [];
      for (const prevPart of parts) {
        if (prevPart !== part) {
          if (prevPart.children) {
            prevPart.children = recursiveFilter(prevPart.children);
          }
          newParts.push(prevPart);
        }
      }
      return newParts;
    };

    setParts(recursiveFilter);
  };

  const editPartGroup = async (
    part: Part,
    newParentSparePartUUID: string
  ) => {
    try {
      setGetPartsLoading(true);
      await PartsApi.edit(part.sparePartUUID, {
        name: part.name,
        isGroup: part.isGroup,
        parentSparePartUUID: newParentSparePartUUID,
      });
      const parts = await PartsApi.getParts();
      setParts(parts);
    } catch (e: any) {
      setGetPartsError(e.message);
    } finally {
      setGetPartsLoading(false);
    }
  };

  const getParameterGroup = (part: Part) => {
    let firstLevelPart:Part = part;

    do {
      const candidate = findPartByID(part.parentSparePartUUID)
      if (candidate) {
        firstLevelPart = candidate
      } else{
        break
      }
    } while (firstLevelPart?.level !== 1);

    return firstLevelPart
  }

  return {
    parts,
    findPartByID,
    getPartsLoading,
    getPartsError,
    addPart,
    deletePart,
    editPart,
    editPartGroup,
    getParameterGroup
  };
};

export default useParts;
