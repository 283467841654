import { FC } from "react";

import styles from "./Tooth.module.scss";
import classNames from "classnames";

interface ToothProps {
  tooth: string;
  selected?: boolean;
  color?: string;
  error?: boolean;
  onClick?: () => void;
}

const Tooth: FC<ToothProps> = ({ tooth, selected, color, error, onClick }) => {
  return (
    <div
      className={classNames(styles.tooth, {
        [styles.selected]: selected,
        [styles.error]: error,
      })}
      style={color && selected ? { background: color } : undefined}
      onClick={onClick}
    >
      {tooth.slice(4)}
    </div>
  );
};

export default Tooth;
