import { FC } from "react";

import Job from "./Job";
import { CaseFormState } from "@/components/CaseForm/CaseFormContext";

interface JobsProps {
  withParameters: boolean;
  jobs: CaseFormState.Job[];
  onAdd: () => void;
  onDelete: (job: string) => void;
  onEdit: (jobID: string, value: string) => void;
  withLock: boolean;
}

const Jobs: FC<JobsProps> = ({
  withParameters,
  jobs,
  onDelete,
  onEdit,
  withLock,
}) => {
  return (
    <>
      {jobs.map((job) => (
        <Job
          withParameters={withParameters}
          key={job.jobID}
          job={job}
          onDelete={() => onDelete(job.jobID)}
          onEdit={(value) => onEdit(job.jobID, value)}
          withLock={withLock}
        />
      ))}
    </>
  );
};

export default Jobs;
