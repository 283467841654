import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Dropdown, MenuProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { Case } from "@/root/models/case";
import { Print } from "@/components/common";
import { LaboratoryCase } from "@/components";
import Warranty from "../../../Warranty";
import InvoiceAccountForCurrentOrder from "@/modules/InvoicesPanel/InvoicesPdf/InvoiceAccountForCurrentOrder";
import InvoiceSentToTheMailAndIsStored from "@/modules/InvoicesPanel/InvoicesPdf/InvoiceSentToTheMailAndIsStored";
import { Settings } from "@/root/models/settings";
import { SettingsApi } from "@/api/settings";

interface PrintMenuProps {
  medCase: Case;
  teethFormulaNumberingUUID: string;
}

enum PrintMenuButtons {
  Invoice = "Invoice",
  Case = "Case",
  DevelopmentForm = "DevelopmentForm",
  Warranty = "Warranty",
  InvoiceCurrentOrder = "InvoiceCurrentOrder",
  InvoiceSentToEmail = "InvoiceSentToEmail",
}

const PrintMenu: FC<PrintMenuProps> = ({
  medCase,
  teethFormulaNumberingUUID,
}) => {
  const { t } = useTranslation();
  const [printKey, setPrintKey] = useState<PrintMenuButtons>();

  const [settings, setSettings] = useState<Settings>();

  useEffect(() => {
    (async () => {
      try {
        const response = await SettingsApi.getSettings();
        setSettings(response);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    const key = e.key;

    if (key === PrintMenuButtons.Case) {
      setPrintKey(PrintMenuButtons.Case);

      // print after setting print key
      setTimeout(() => window.print());
    }

    if (key === PrintMenuButtons.Warranty) {
      setPrintKey(PrintMenuButtons.Warranty);

      // print after setting print key
      setTimeout(() => window.print());
    }

    if (key === PrintMenuButtons.InvoiceCurrentOrder) {
      setPrintKey(PrintMenuButtons.InvoiceCurrentOrder);
      // print after setting print key
      setTimeout(() => window.print());
    }
    if (key === PrintMenuButtons.InvoiceSentToEmail) {
      setPrintKey(PrintMenuButtons.InvoiceSentToEmail);
      // print after setting print key
      setTimeout(() => window.print());
    }
  };

  const items: MenuProps["items"] = [
    {
      label: t("Рахунок-фактура"),
      key: PrintMenuButtons.Invoice,
    },
    {
      label: "Інвойс_рахунок_за_поточний_ордер,_для_пакування_в_лабі.pdf",
      key: PrintMenuButtons.InvoiceCurrentOrder,
    },
    {
      label: "Інвойс_котрий_відправляється_на_пошту_і_зберігається_як_пдф.pdf",
      key: PrintMenuButtons.InvoiceSentToEmail,
    },
    {
      label: t("Замовлення", { count: 1 }),
      key: PrintMenuButtons.Case,
    },
    {
      label: t("Внутрішня форма для виробництва"),
      key: PrintMenuButtons.DevelopmentForm,
    },
    {
      label: t("Гарантія"),
      key: PrintMenuButtons.Warranty,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div>
      <Dropdown menu={menuProps}>
        <Button>
          <Space>
            {t("Друк")}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      {printKey === PrintMenuButtons.Case && (
        <Print>
          <LaboratoryCase
            medCase={medCase}
            teethFormulaNumberingUUID={teethFormulaNumberingUUID}
          />
        </Print>
      )}
      {printKey === PrintMenuButtons.Warranty && medCase.patient && (
        <Print>
          <Warranty
            warrantyNumber={medCase.warrantyNumber}
            secret={medCase.patient.secret}
            patientCode={medCase.patient.patientCode}
            settings={settings}
          />
        </Print>
      )}
      {printKey === PrintMenuButtons.InvoiceCurrentOrder && (
        <Print>
          <InvoiceAccountForCurrentOrder />
        </Print>
      )}
      {printKey === PrintMenuButtons.InvoiceSentToEmail && (
        <Print>
          <InvoiceSentToTheMailAndIsStored />
        </Print>
      )}
    </div>
  );
};

export default PrintMenu;
