import { CreateSupplierPayload, SuppliersApi } from "@/api/suppliers";
import { Supplier } from "@/root/models/supplier";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const useSuppliers = (
  onSuccess?: () => void,
  onDeleteError?: (message: string) => void
) => {
  const queryClient = useQueryClient();

  const {
    data: suppliers,
    isFetching: getSuppliersLoading,
    error: getSuppliersError,
  } = useQuery({
    queryKey: ["suppliers"],
    queryFn: SuppliersApi.getSuppliers,
  });

  const createSupplierMutation = useMutation({
    mutationFn: SuppliersApi.create,
    onSuccess: (supplier) => {
      queryClient.setQueryData(
        ["suppliers"],
        (prev: Supplier[] | undefined) => {
          if (!prev) {
            return [supplier];
          }
          return [...prev, supplier];
        }
      );
      onSuccess?.();
    },
  });

  const addSupplier = (payload: CreateSupplierPayload) => {
    createSupplierMutation.mutate(payload);
  };

  const createError = (createSupplierMutation.error as Error)
    ?.message as string;

  const editSupplierMutation = useMutation({
    mutationFn: SuppliersApi.edit,
    onSuccess: (supplier: Supplier) => {
      queryClient.setQueryData(
        ["suppliers"],
        (prev: Supplier[] | undefined) => {
          onSuccess?.();
          return (prev || []).map((prevSupplier) => {
            if (prevSupplier.supplierUUID === supplier.supplierUUID) {
              return supplier;
            }
            return prevSupplier;
          });
        }
      );
    },
  });

  const editSupplier = (id: string, payload: CreateSupplierPayload) => {
    editSupplierMutation.mutate({ id, payload });
  };

  const editError = (editSupplierMutation.error as Error)?.message as string;

  const deleteSupplierMutation = useMutation({
    mutationFn: SuppliersApi.delete,
    onError: (error) => onDeleteError?.((error as Error).message),
    onSuccess: (_, supplierUUID) => {
      queryClient.setQueryData(["suppliers"], (prev: Supplier[] | undefined) =>
        (prev || []).filter((s) => s.supplierUUID !== supplierUUID)
      );
    },
  });

  const deleteSupplier = async (id: string) => {
    await deleteSupplierMutation.mutateAsync(id);
  };

  return {
    suppliers: suppliers || [],
    getSuppliersLoading,
    getSuppliersError,
    addSupplier,
    createError,
    createLoading: createSupplierMutation.isLoading,
    editSupplier,
    editError,
    editLoading: editSupplierMutation.isLoading,
    deleteSupplier,
    deleteLoading: deleteSupplierMutation.isLoading,
  };
};

export default useSuppliers;
