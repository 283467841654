export const groupTeethByJob = <T extends { group?: string }>(teeth: T[]) => {
  const toothMap = new Map<string, T[]>();

  for (const tooth of teeth) {
    const group = tooth.group as string;
    if (toothMap.has(group)) {
      toothMap.get(group)!.push(tooth);
    } else {
      toothMap.set(group, [tooth]);
    }
  }

  return Array.from(toothMap.values());
};
