import globalStyles from "../InvoicesPdf.module.scss";
import evident_logo from "@/assets/EvidentLogo.jpg";

const EvidentMark = () => {
  return (
    <div
      style={{
        display: "flex",
        gap: "2px",
        alignItems: "center",
        fontSize: "12px",
      }}
    >
      <img
        src={evident_logo}
        alt="evident_logo"
        className={globalStyles.evident_logo}
      />
      <span>is a trademark of Evidence Inc.</span>
    </div>
  );
};

export default EvidentMark;
