import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "antd";

import { CreatePatientModal } from "@/components";

const CreatePatient: FC = () => {
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <Button onClick={() => setModal(true)}>{t("Створити пацієнта")}</Button>
      <CreatePatientModal
        open={modal}
        onCancel={() => setModal(false)}
      />
    </div>
  );
};

export default CreatePatient;
