import { apiBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";
import { TeethFormulaNumbering } from "@/root/models/teethFormulaNumbering";

export const TeethFormulaNumberingApi = {
  getNumberings: async () => {
    return await makeRequest<TeethFormulaNumbering[]>({
      url: apiBaseurl + "/teeth-formula-numbering",
    });
  },
};
