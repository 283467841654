import { CreateUserPayload, UsersApi } from "@/api/admin/users";
import { User } from "@/root/models/user";
import { useQuery } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const useUsers = ({
  onSuccess,
  onDeleteError,
  name,
  login,
  password,
  clientAdmin,
  userTypeUUID,
  disableAccess,
  email,
}: {
  onSuccess?: () => void;
  onDeleteError?: (message: string) => void;
  name?: string;
  login?: string;
  password?: string;
  clientAdmin?: boolean;
  userTypeUUID?: string;
  disableAccess?: boolean;
  email?: string;
}) => {
  const queryClient = useQueryClient();

  const {
    data: users,
    isFetching: getUsersLoading,
    error: getUsersError,
  } = useQuery({
    queryKey: [
      "users",
      name,
      login,
      password,
      clientAdmin,
      userTypeUUID,
      disableAccess,
      email,
    ],
    queryFn: UsersApi.getUsers,
  });

  const createUserMutation = useMutation({
    mutationFn: UsersApi.create,
    onSuccess: (user) => {
      queryClient.setQueryData(
        [
          "users",
          name,
          login,
          password,
          clientAdmin,
          userTypeUUID,
          disableAccess,
          email,
        ],
        (prev: User[] | undefined) => {
          if (!prev) {
            return [user];
          }

          return [...prev, user];
        }
      );
      onSuccess?.();
    },
  });

  const deleteUserMutation = useMutation({
    mutationFn: UsersApi.delete,
    onError: (error) => onDeleteError?.((error as Error).message),
    onSuccess: (_, userUUID) => {
      queryClient.setQueryData(
        [
          "users",
          name,
          login,
          password,
          clientAdmin,
          userTypeUUID,
          disableAccess,
          email,
        ],
        (prev: User[] | undefined) =>
          (prev || []).filter((p) => p.userUUID !== userUUID)
      );
    },
  });

  const editUserMutation = useMutation({
    mutationFn: UsersApi.edit,
    onSuccess: (user: User) => {
      queryClient.setQueryData(
        [
          "users",
          name,
          login,
          password,
          clientAdmin,
          userTypeUUID,
          disableAccess,
          email,
        ],
        (prev: User[] | undefined) => {
          onSuccess?.();

          return (prev || []).map((prevUser) => {
            if (prevUser.userUUID === user.userUUID) {
              return user;
            }
            return prevUser;
          });
        }
      );
    },
  });

  const addUser = (payload: CreateUserPayload) => {
    createUserMutation.mutate({ payload });
  };

  const deleteUser = async (id: string) => {
    await deleteUserMutation.mutateAsync(id);
  };

  const editUser = (id: string, payload: CreateUserPayload) => {
    editUserMutation.mutate({ id, payload });
  };

  const createUserError = (createUserMutation.error as Error)
    ?.message as string;

  const deleteUserError = (deleteUserMutation.error as Error)
    ?.message as string;

  const editUserError = (editUserMutation.error as Error)?.message as string;

  return {
    users: users || [],
    getUsersLoading,
    getUsersError,
    addUser,
    createUserLoading: createUserMutation.isLoading,
    createUserError,
    deleteUser,
    deleteUserError,
    deleteUserLoading: deleteUserMutation.isLoading,
    editUser,
    editUserError,
    editUserLoading: editUserMutation.isLoading,
  };
};

export default useUsers;
