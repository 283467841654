import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "antd";

import { CreateClientEmployeeModal } from "@/components";

const CreateClientEmployee: FC<{ clientUUID?: string }> = ({ clientUUID }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);

  return (
    <div>
      <Button onClick={() => setModal(true)}>
        {t("Створити співробітника")}
      </Button>
      <CreateClientEmployeeModal
        open={modal}
        onCancel={() => setModal(false)}
        clientUUID={clientUUID}
      />
    </div>
  );
};

export default CreateClientEmployee;
