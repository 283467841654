import { FC } from "react";

import { Button } from "antd";

import styles from "./ChooseProduct.module.scss";

import { Product } from "@/root/models/product";
import useProducts from "@/hooks/useProducts";

interface ProductProps {
  product: Product;
  onSelect: (product: Product) => void;
}

const ProductItem: FC<ProductProps> = ({ product, onSelect }) => {
  const { getParameterGroup } = useProducts();

  const color = product.color
    ? product.color
    : getParameterGroup(product).color;

  const size = product.isGroup
    ? { width: 20, height: 20 }
    : { width: 10, height: 10 };

  return (
    <div className={styles.buttonContainer}>
      <div className={styles.colorContainer}>
        <div
          style={
            color ? { background: color, ...size, borderRadius: 20 } : undefined
          }
        />
      </div>
      <Button
        className={styles.product}
        type="text"
        onClick={() => onSelect(product)}
      >
        {product.name}
      </Button>
    </div>
  );
};

export default ProductItem;
