import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { Part } from "@/root/models/part";

export interface CreatePartPayload {
  parentSparePartUUID: string;
  name: string;
  isGroup: boolean;
}

export const PartsApi = {
  getParts: async (): Promise<Part[]> => {
    const data = await makeRequest<Part[]>({
      url: apiBaseurl + "/spare-parts",
    });
    const parseData = (data: Part[], parentSparePartUUID: string) => {
      return data.map((p) => {
        p.parentSparePartUUID = parentSparePartUUID;
        if (p.children) {
          p.children = parseData(p.children, p.sparePartUUID);
        }

        return p;
      });
    };

    return parseData(data, "");
  },

  create: async (payload: CreatePartPayload): Promise<Part> => {
    const data = await makeRequest<Part>({
      url: apiBaseurl + "/spare-parts",
      method: "post",
      data: payload,
    });
    return { ...data, parentSparePartUUID: payload.parentSparePartUUID };
  },

  edit: async (id: string, payload: CreatePartPayload): Promise<Part> => {
    const data = await makeRequest<Part>({
      url: apiBaseurl + `/spare-parts/${id}`,
      method: "put",
      data: payload,
    });
    return { ...data, parentSparePartUUID: payload.parentSparePartUUID };
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/spare-parts/${id}`,
      method: "delete",
    });
  },
};
