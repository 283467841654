import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  ClientEmployeesApi,
  CreateClientEmployeePayload,
} from "@/api/client/employees";
import { ClientEmployee } from "@/root/models/clientEmployee";

const useClientEmployees = ({
  clientUUID,
  onCreateSuccess,
  onDeleteError,
  onEditSuccess,
}: {
  clientUUID?: string;
  onCreateSuccess?: () => void;
  onEditSuccess?: () => void;
  onDeleteError?: (message: string) => void;
}) => {
  const queryClient = useQueryClient();
  const {
    data: clientEmployees,
    isFetching: getClientEmployeesLoading,
    error: getClientEmployeesError,
  } = useQuery({
    queryKey: ["clientEmployees", clientUUID],
    queryFn: () => ClientEmployeesApi.getEmployees(clientUUID),
  });

  const createClientEmployeeMutation = useMutation({
    mutationFn: ClientEmployeesApi.create,
    onSuccess: (clientEmployee) => {
      queryClient.setQueryData(
        ["clientEmployees", clientUUID],
        (prev: ClientEmployee[] | undefined) => {
          if (!prev) {
            return [clientEmployee];
          }
          return [...prev, clientEmployee];
        }
      );
      onCreateSuccess?.();
    },
  });

  const createClientEmployee = (payload: CreateClientEmployeePayload) => {
    createClientEmployeeMutation.mutate({
      ...payload,
      disableAccess: false,
    });
  };

  const createError = (createClientEmployeeMutation.error as Error)
    ?.message as string;

  const deleteClientEmployeeMutation = useMutation({
    mutationFn: ClientEmployeesApi.delete,
    onError: (error) => onDeleteError?.((error as Error).message),
    onSuccess: (_, clientEmployeeUUID) => {
      queryClient.setQueryData(
        ["clientEmployees", clientUUID],
        (prev: ClientEmployee[] | undefined) =>
          (prev || []).filter(
            (p) => p.clientsEmployeeUUID !== clientEmployeeUUID
          )
      );
    },
  });

  const deleteClientEmployee = async (id: string) => {
    await deleteClientEmployeeMutation.mutateAsync(id);
  };

  const deleteError = (deleteClientEmployeeMutation.error as Error)
    ?.message as string;

  const editClientEmployeeMutation = useMutation({
    mutationFn: ClientEmployeesApi.edit,
    onSuccess: (clientEmployee: ClientEmployee) => {
      queryClient.setQueryData(
        ["clientEmployees", clientUUID],
        (prev: ClientEmployee[] | undefined) => {
          onEditSuccess?.();
          return (prev || []).map((prevClientEmployee) => {
            if (
              prevClientEmployee.clientsEmployeeUUID ===
              clientEmployee.clientsEmployeeUUID
            ) {
              return clientEmployee;
            }
            return prevClientEmployee;
          });
        }
      );
    },
  });

  const editClientEmployee = (
    id: string,
    payload: CreateClientEmployeePayload
  ) => {
    editClientEmployeeMutation.mutate({ id, payload });
  };

  const editError = (editClientEmployeeMutation.error as Error)
    ?.message as string;

  return {
    clientEmployees: clientEmployees || [],
    getClientEmployeesLoading,
    getClientEmployeesError,
    createClientEmployee,
    deleteClientEmployee,
    editClientEmployee,
    createLoading: createClientEmployeeMutation.isLoading,
    editLoading: editClientEmployeeMutation.isLoading,
    deleteLoading: deleteClientEmployeeMutation.isLoading,
    editError,
    createError,
    deleteError,
  };
};

export default useClientEmployees;
