import { FC } from "react";

import { Carousel } from "antd";

import styles from "./IntroCarousel.module.scss";

import slider1 from "@/assets/intro-slider-images/1.jpg";
import slider2 from "@/assets/intro-slider-images/2.jpg";
import slider3 from "@/assets/intro-slider-images/3.jpg";

import uadent1 from "@/assets/intro-slider-images/uadent/uadent1-min.jpg";
import uadent2 from "@/assets/intro-slider-images/uadent/uadnet2-min.jpg";
import uadent3 from "@/assets/intro-slider-images/uadent/uadent3-min.jpg";
import uadent4 from "@/assets/intro-slider-images/uadent/uadent4-min.jpg";

const defaultImages = [
  {
    name: slider1,
  },
  {
    name: slider2,
  },
  {
    name: slider3,
  },
];

const uadentImages = [
  { name: uadent1 },
  { name: uadent2 },
  { name: uadent3 },
  { name: uadent4 },
];

const IntroCarousel: FC = () => {
  const uadentLocation = window.location.href.includes("uadent.medit.com.ua");

  return (
    <div className={styles.carouselContainer}>
      <Carousel dotPosition="bottom" effect="scrollx" autoplay>
        {uadentLocation
          ? uadentImages.map((img) => (
              <div key={img.name}>
                <div
                  className={styles.carouselItem}
                  style={{
                    background: `url(${img.name}) 0% 0% / contain`,
                  }}
                />
              </div>
            ))
          : defaultImages.map((img) => (
              <div key={img.name}>
                <div
                  className={styles.carouselItem}
                  style={{
                    background: `url(${img.name}) 0% 0% / contain`,
                  }}
                />
              </div>
            ))}
      </Carousel>
    </div>
  );
};

export default IntroCarousel;
