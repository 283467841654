import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { PriceFormula } from "@/root/models/priceFormula";
import {
  CreatePriceFormulaPayload,
  PriceFormulasApi,
} from "@/api/admin/priceFormulas";

const usePriceFormulas = ({
  onCreateSuccess,
  onDeleteError,
  onEditSuccess,
}: {
  onCreateSuccess?: () => void;
  onEditSuccess?: () => void;
  onDeleteError?: (message: string) => void;
}) => {
  const queryClient = useQueryClient();
  const {
    data: priceFormulas,
    isFetching: getPriceFormulasLoading,
    error: getPriceFormulasError,
  } = useQuery({
    queryKey: ["priceFormulas"],
    queryFn: PriceFormulasApi.getPriceFormulas,
  });

  const createPriceFormulaMutation = useMutation({
    mutationFn: PriceFormulasApi.create,
    onSuccess: (priceFormula) => {
      queryClient.setQueryData(
        ["priceFormulas"],
        (prev: PriceFormula[] | undefined) => {
          if (!prev) {
            return [priceFormula];
          }
          return [...prev, priceFormula];
        }
      );
      onCreateSuccess?.();
    },
  });

  const createPriceFormula = (payload: CreatePriceFormulaPayload) => {
    createPriceFormulaMutation.mutate(payload);
  };

  const createError = (createPriceFormulaMutation.error as Error)
    ?.message as string;

  const deletePriceFormulaMutation = useMutation({
    mutationFn: PriceFormulasApi.delete,
    onError: (error) => onDeleteError?.((error as Error).message),
    onSuccess: (_, priceFormulaUUID) => {
      queryClient.setQueryData(
        ["priceFormulas"],
        (prev: PriceFormula[] | undefined) =>
          (prev || []).filter((p) => p.priceFormulaUUID !== priceFormulaUUID)
      );
    },
  });

  const deletePriceFormula = async (id: string) => {
    await deletePriceFormulaMutation.mutateAsync(id);
  };

  const deleteError = (deletePriceFormulaMutation.error as Error)
    ?.message as string;

  const editPriceFormulaMutation = useMutation({
    mutationFn: PriceFormulasApi.edit,
    onSuccess: (priceFormula: PriceFormula) => {
      queryClient.setQueryData(
        ["priceFormulas"],
        (prev: PriceFormula[] | undefined) => {
          onEditSuccess?.();
          return (prev || []).map((prevPriceFormula) => {
            if (
              prevPriceFormula.priceFormulaUUID ===
              priceFormula.priceFormulaUUID
            ) {
              return priceFormula;
            }
            return prevPriceFormula;
          });
        }
      );
    },
  });

  const editPriceFormula = (id: string, payload: CreatePriceFormulaPayload) => {
    editPriceFormulaMutation.mutate({ id, payload });
  };

  const editError = (editPriceFormulaMutation.error as Error)
    ?.message as string;

  return {
    priceFormulas: priceFormulas || [],
    getPriceFormulasLoading,
    getPriceFormulasError,
    createPriceFormula,
    deletePriceFormula,
    editPriceFormula,
    createLoading: createPriceFormulaMutation.isLoading,
    editLoading: editPriceFormulaMutation.isLoading,
    deleteLoading: deletePriceFormulaMutation.isLoading,
    editError,
    createError,
    deleteError,
  };
};

export default usePriceFormulas;
