import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { EditCase, MakeCase, Cases } from "@/modules";

const CasesPanel: FC = () => {
  return (
    <Routes>
      <Route path="create" element={<MakeCase />} />
      <Route path="edit" element={<EditCase />} />
      <Route path="*" element={<Cases />} />
    </Routes>
  );
};

export default CasesPanel;
