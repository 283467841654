import { useState } from "react";

const useModalOpen = () => {
  const [modal, setModal] = useState<string | null>(null);
  const [modalType, setModalType] = useState<"edit" | "view" | null>(null);

  const onHandleCancel = () => {
    setModal(null);
  };

  const onEditSuccess = () => {
    setModal(null);
  };

  const isModalOpen = (itemUUID: string) => {
    setModal(itemUUID);
  };

  return {
    modal,
    onHandleCancel,
    onEditSuccess,
    isModalOpen,
    modalType,
    setModalType,
  };
};

export default useModalOpen;
