import InvoicesCurrentOrderForm from "./components/InvoicesCurrentOrderForm";
import ServiceEvaluationForm from "./components/ServiceEvaluationForm";
import FooterInfo from "./components/FooterInfo";

import styles from "./InvoiceAccountForCurrentOrder.module.scss";
import globalStyles from "../InvoicesPdf.module.scss";

const InvoiceAccountForCurrentOrder = () => {
  return (
    <div className={globalStyles.page}>
      <div>
        <div className={styles.main_section}>
          <InvoicesCurrentOrderForm width="55%" />
          <InvoicesCurrentOrderForm width="45%" isPatient={true} />
        </div>
        <div className={styles.additional_section}>
          <ServiceEvaluationForm width="55%" />
          <FooterInfo />
        </div>
      </div>
    </div>
  );
};

export default InvoiceAccountForCurrentOrder;
