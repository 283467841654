import useModalOpen from "@/hooks/useModalOpen";
import { AccountingTotal } from "@/root/models/accounting";
import { Button, Form, Input, InputNumber, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { t } from "i18next";
import { Dispatch, SetStateAction, useEffect } from "react";

interface SetDiscountProps {
  selectedItem: AccountingTotal | undefined;
  setSelectedItem: Dispatch<SetStateAction<AccountingTotal | undefined>>;
}

const SetDiscount = ({ selectedItem, setSelectedItem }: SetDiscountProps) => {
  const { modal, onHandleCancel, onEditSuccess, isModalOpen } = useModalOpen();

  const [form] = useForm();

  const submit = () => {
    form.submit();
    onEditSuccess();
  };

  useEffect(() => {
    if (modal) {
      form.setFieldsValue({
        discountPercent: selectedItem?.discountPercent,
      });
    }
  }, [modal]);

  const onFinish = (values: any) => {
    if (selectedItem) {
      setSelectedItem({
        ...selectedItem,
        discountPercent: values.discountPercent,
        discountSum: Number(
          ((values.discountPercent / 100) * selectedItem.sum).toFixed(1)
        ),
        total:
          selectedItem.sum -
          Number(
            ((values.discountPercent / 100) * selectedItem.sum).toFixed(1)
          ),
      });
    }
  };

  return (
    <>
      <Button
        disabled={(!selectedItem || !!selectedItem.invoices?.length) && true}
        onClick={() => selectedItem && isModalOpen(selectedItem?.rowUUID)}
      >
        {t("Призначити знижку")}
      </Button>
      <Modal
        open={modal === selectedItem?.rowUUID}
        onCancel={onHandleCancel}
        title={t("Призначити знижку")}
        okText={t("Зберегти")}
        onOk={submit}
        width={600}
      >
        <Form
          requiredMark={false}
          form={form}
          style={{ display: "flex", marginTop: "20px", gap: "20px" }}
          initialValues={{
            ["discountType"]: t("Відсоток"),
          }}
          onFinish={onFinish}
        >
          <Form.Item name="discountType" label={t("Знижка в")}>
            <Input disabled />
          </Form.Item>
          <Form.Item name="discountPercent" label={t("Значення знижки")}>
            <InputNumber min={0} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SetDiscount;
