import styles from "../InvoiceAccountSentToTheMail.module.scss";
import stamp from "@/assets/stamp.svg";

const products = [
  {
    id: 1,
    code: "TADDEXDENT",
    productDescription: "Tooth Additional to Existing Denture",
    details: 43,
    qty: 1,
    unitCost: "$ 190.00",
    total: "$ 190.00",
  },
];

const InvoiceOrderForm = () => {
  return (
    <>
      <table
        style={{
          width: "100%",
          height: "600px",
        }}
        className={styles.table_border}
      >
        <thead style={{ opacity: "0.5", textAlign: "center" }}>
          <tr>
            <th>Code</th>
            <th>Product Description</th>
            <th>Details</th>
            <th>Qty</th>
            <th>Unit Cost</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody
          style={{
            textAlign: "center",
          }}
        >
          {products.map((product) => (
            <tr key={product.id}>
              <td>{product.code}</td>
              <td style={{ textAlign: "start" }}>
                {product.productDescription}
              </td>
              <td>{product.details}</td>
              <td>{product.qty}</td>
              <td>{product.unitCost}</td>
              <td>{product.total}</td>
            </tr>
          ))}
          <tr style={{ height: "100%" }}>
            <td></td>
          </tr>
          <tr>
            <td>Subtotal</td>
            <td colSpan={4}></td>
            <td>$ 190.00</td>
          </tr>
          <tr>
            <td>Tax</td>
            <td colSpan={4}></td>
            <td>$ 0.00</td>
          </tr>
          <tr>
            <td>Total</td>
            <td colSpan={4}></td>
            <td>$ 190.00</td>
          </tr>
        </tbody>
      </table>
      <div
        style={{
          position: "absolute",
          bottom: "390px",
          left: "220px",
        }}
      >
        <img src={stamp} alt="" style={{ height: "300px", width: "300px" }} />
      </div>
    </>
  );
};

export default InvoiceOrderForm;
