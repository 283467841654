import {
  PaymentCreatePayloadProps,
  PaymentEditPayloadProps,
} from "@/api/payments";
import { Alert, Button, Form, FormInstance, Input, Select, Spin } from "antd";
import styles from "../../InvoicesPanel/InvoicePanel.module.scss";
import { t } from "i18next";
import useAdminClients from "@/hooks/useAdminClients";
import { useEffect, useState } from "react";
import { DownOutlined, LoadingOutlined, UpOutlined } from "@ant-design/icons";
import { CustomCasesTable } from "@/components";
import { Invoice } from "@/root/models/invoice";
import CustomInvoicesTable from "./CustomInvoicesTable";
import usePaymentTables from "../hooks/usePaymentTables";
import { Payment } from "@/root/models/payment";
import useClientDeposit from "@/hooks/useClientDeposit";

interface PaymentFormProps {
  form: FormInstance;
  paymentItems: Payment.Items[];
  type: "create" | "view" | "edit";
  handlePaymentCreate?: (values: PaymentCreatePayloadProps) => Promise<void>;
  handlePaymentEdit?: (values: PaymentEditPayloadProps) => Promise<void>;
  setEditedItems?: (items: Invoice.InvoiceCase[]) => void;
}

const { TextArea } = Input;
const { Option } = Select;

export const PaymentForm = ({
  form,
  type,
  handlePaymentCreate,
  handlePaymentEdit,
  paymentItems,
  setEditedItems,
}: PaymentFormProps) => {
  const [tableOpen, setTableOpen] = useState(false);

  useEffect(() => {
    return () => {
      if (tableOpen) {
        setTableOpen(false);
      }
    };
  }, [tableOpen]);

  const { adminClients, getAdminClientsLoading } = useAdminClients({});
  const [chosenClient, setChosenClient] = useState("");

  const { deposit, depositLoading, refetch } = useClientDeposit(chosenClient);

  useEffect(() => {
    if (chosenClient) {
      refetch();
    }
  }, [chosenClient]);

  const {
    //First table
    paymentInvoiceCases,
    selectedPaymentInvoiceCases,
    setSelectedPaymentInvoiceCases,

    //Second table
    unpaidInvoices,
    selectedUnpaidInvoiceCases,
    setSelectedUnpaidInvoiceCases,

    //Cases add
    unpdaidInvoiceCasesAdd,
    //Cases remove
    paymentInvoiceCasesRemove,
  } = usePaymentTables(paymentItems, chosenClient, form);

  useEffect(() => {
    if (type === "edit" && !!paymentInvoiceCases.length) {
      setEditedItems?.(paymentInvoiceCases);
    }
  }, [paymentInvoiceCases]);

  //Payment create
  const handleSubmit = (values: any) => {
    handlePaymentCreate &&
      handlePaymentCreate({
        clientUUID: chosenClient,
        description: values.description || "",
        amount: values.amount,
        items: paymentInvoiceCases.map((item) => ({
          sum: item.sum,
          caseUUID: item.case.caseUUID,
          rowUUID: item.rowUUID,
          invoiceUUID: item.invoices[0].invoiceUUID,
        })),
      });
  };

  useEffect(() => {
    if (type === "edit" && !chosenClient) {
      setChosenClient(form.getFieldValue("client"));
    }
  }, [type, chosenClient]);

  //Recalculate sum on payment invoice cases remove
  useEffect(() => {
    form.setFieldValue(
      "amount",
      paymentInvoiceCases.reduce((prev, cur) => prev + cur.sum, 0)
    );
    form.setFieldValue(
      "deposit",
      paymentInvoiceCases.reduce((prev, cur) => prev + cur.sum, 0)
    );
  }, [paymentInvoiceCases, deposit]);

  return (
    <>
      <Form
        className={styles.formItem_input}
        requiredMark={false}
        form={form}
        labelCol={{ span: 7 }}
        onFinish={type === "edit" ? handlePaymentEdit : handleSubmit}
      >
        <Form.Item name="paymentNumber" label={t("Номер оплати")}>
          <Input disabled />
        </Form.Item>
        <div style={{ display: "flex", gap: 20 }}>
          <Form.Item
            name="client"
            label={t("Контрагент")}
            rules={[{ required: true, message: t("Заповніть поле") as string }]}
            style={{ flex: 2 }}
          >
            <Select
              listHeight={1000}
              loading={getAdminClientsLoading}
              optionFilterProp="children"
              disabled={type === "view" && true}
              onChange={(value: string) => setChosenClient(value)}
            >
              {adminClients.map((client) => (
                <Option key={client.clientUUID} value={client.clientUUID}>
                  {client.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {chosenClient && (
            <Form.Item
              name="deposit"
              label={t("Депозит")}
              style={{ position: "relative" }}
            >
              <Input disabled />
              {depositLoading && (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 20,
                        position: "absolute",
                        top: 6,
                        right: 15,
                      }}
                      spin
                    />
                  }
                />
              )}
            </Form.Item>
          )}
        </div>

        <Form.Item name="description" label={t("Опис")}>
          <TextArea disabled={type === "view" && true} rows={1} />
        </Form.Item>
        <Form.Item name="amount" label={t("Повна сума")}>
          <Input disabled={type === "view" && true} />
        </Form.Item>
      </Form>

      <Button
        onClick={() => setTableOpen(!tableOpen)}
        icon={tableOpen ? <UpOutlined /> : <DownOutlined />}
        style={{ marginBottom: 10 }}
      >
        {t("Деталі")}
      </Button>

      {tableOpen && (
        <>
          <div className={"animate__animated animate__fadeIn"}>
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                marginBottom: "10px",
              }}
            >
              {t("Замовлення на оплату")}
            </div>

            <CustomCasesTable
              tableInstancesArray={paymentInvoiceCases}
              selectedInstances={selectedPaymentInvoiceCases}
              setSelectedInstances={setSelectedPaymentInvoiceCases}
              btnLabel={t("Видалити вибране")}
              onClick={paymentInvoiceCasesRemove}
              type={type}
              instance="paymentInvoiceCases"
            />
            {(type === "create" || type === "edit") && (
              <div>
                <div
                  style={{
                    fontWeight: 600,
                    fontSize: "16px",
                    marginBottom: "10px",
                    marginTop: "40px",
                  }}
                >
                  {t("Неоплачені рахунки-фактури")}
                </div>
                {!chosenClient ? (
                  <Alert
                    type="info"
                    message={t("Оберіть спочатку контрагента")}
                    style={{ width: "50%" }}
                  />
                ) : (
                  <CustomInvoicesTable
                    tableInstancesArray={unpaidInvoices}
                    selectedInstances={selectedUnpaidInvoiceCases}
                    setSelectedInstances={setSelectedUnpaidInvoiceCases}
                    btnLabel={t("Додати вибране")}
                    onClick={unpdaidInvoiceCasesAdd}
                  />
                )}
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
