import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "antd";
import { useForm } from "antd/es/form/Form";

import { PriceFormulaForm } from "@/components";
import usePriceFormulas from "@/hooks/usePriceFormulas";
import { CreatePriceFormulaPayload } from "@/api/admin/priceFormulas";

interface CreatePriceFormulaProps {
  open: boolean;
  onCancel: () => void;
}

const CreatePriceFormula: FC<CreatePriceFormulaProps> = ({
  open,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const onCreateSuccess = () => {
    onCancel();
    form.resetFields();
  };

  const {
    createPriceFormula,
    createLoading: loading,
    createError: error,
  } = usePriceFormulas({ onCreateSuccess });

  const handleCreate = async (values: CreatePriceFormulaPayload) => {
    createPriceFormula(values);
  };

  const submit = () => {
    form.submit();
  };

  return (
    <Modal
      maskClosable={false}
      okText={t("Створити")}
      title={
        <div style={{ marginBottom: 20 }}>{t("Створення цінової формули")}</div>
      }
      open={open}
      onCancel={onCancel}
      onOk={submit}
      okButtonProps={{ loading }}
    >
      <PriceFormulaForm form={form} handleSubmit={handleCreate} error={error} />
    </Modal>
  );
};

export default CreatePriceFormula;
