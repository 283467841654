import { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./MiddlePanel.module.scss";

import { Jobs } from "@/components/CaseForm/components";
import { Block } from "@/components/common";

const MiddlePanel: FC = () => {
  const { t } = useTranslation();

  return (
    <div style={{ width: "35%" }}>
      <div className={styles.container}>
        <Jobs withParameters={false} withLock={true} />
      </div>
    </div>
  );
};

export default MiddlePanel;
