import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";

export const DepositApi = {
  getDeposit: async (clientUUID: string): Promise<number> => {
    return await makeRequest<number>({
      url: `${apiBaseurl}/deposit/${clientUUID}`,
    });
  },
};
