import {
  CreateUnitsOfMeasurePayload,
  UnitsOfMeasureApi,
} from "@/api/unitsOfMeasure";
import { UnitsOfMeasure } from "@/root/models/unitsOfMeasure";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const useUnitsOfMeasure = (
  onSuccess?: () => void,
  onDeleteError?: (message: string) => void
) => {
  const queryClient = useQueryClient();

  const {
    data: unitsOfMeasure,
    isFetching: getUnitsOfMeasureLoading,
    error: getUnitsOfMeasureError,
  } = useQuery({
    queryKey: ["unitsOfMeasure"],
    queryFn: UnitsOfMeasureApi.getUnitsOfMeasureApi,
  });

  const createUnitsOfMeasureMutation = useMutation({
    mutationFn: UnitsOfMeasureApi.create,
    onSuccess: (unitsOfMeasure) => {
      queryClient.setQueryData(
        ["unitsOfMeasure"],
        (prev: UnitsOfMeasure[] | undefined) => {
          if (!prev) {
            return [unitsOfMeasure];
          }
          return [...prev, unitsOfMeasure];
        }
      );
      onSuccess?.();
    },
  });

  const addUnitsOfMeasure = (payload: CreateUnitsOfMeasurePayload) => {
    createUnitsOfMeasureMutation.mutate(payload);
  };

  const createError = (createUnitsOfMeasureMutation.error as Error)
    ?.message as string;

  const editUnitsOfMeasureMutation = useMutation({
    mutationFn: UnitsOfMeasureApi.edit,
    onSuccess: (unitsOfMeasure: UnitsOfMeasure) => {
      queryClient.setQueryData(
        ["unitsOfMeasure"],
        (prev: UnitsOfMeasure[] | undefined) => {
          onSuccess?.();
          return (prev || []).map((prevUnitsOfMeasure) => {
            if (
              prevUnitsOfMeasure.unitOfMeasureUUID ===
              unitsOfMeasure.unitOfMeasureUUID
            ) {
              return unitsOfMeasure;
            }
            return prevUnitsOfMeasure;
          });
        }
      );
    },
  });

  const editUnitsOfMeasure = (
    id: string,
    payload: CreateUnitsOfMeasurePayload
  ) => {
    editUnitsOfMeasureMutation.mutate({ id, payload });
  };

  const editError = (editUnitsOfMeasureMutation.error as Error)
    ?.message as string;

  const deleteUnitsOfMeasureMutation = useMutation({
    mutationFn: UnitsOfMeasureApi.delete,
    onError: (error) => onDeleteError?.((error as Error).message),
    onSuccess: (_, unitOfMeasureUUID) => {
      queryClient.setQueryData(
        ["unitsOfMeasure"],
        (prev: UnitsOfMeasure[] | undefined) =>
          (prev || []).filter((u) => u.unitOfMeasureUUID !== unitOfMeasureUUID)
      );
    },
  });

  const deleteUnitsOfMeasure = async (id: string) => {
    await deleteUnitsOfMeasureMutation.mutateAsync(id);
  };

  return {
    unitsOfMeasure: unitsOfMeasure || [],
    getUnitsOfMeasureLoading,
    getUnitsOfMeasureError,
    addUnitsOfMeasure,
    createError,
    createLoading: createUnitsOfMeasureMutation.isLoading,
    editUnitsOfMeasure,
    editError,
    editLoading: editUnitsOfMeasureMutation.isLoading,
    deleteUnitsOfMeasure,
    deleteLoading: deleteUnitsOfMeasureMutation.isLoading,
  };
};

export default useUnitsOfMeasure;
