import React, { FC, useState } from "react";

import { CloseCircleOutlined } from "@ant-design/icons";
import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import { TeethFormulaNumberingEnum } from "@/root/types";
import { teethMapping } from "@/root/consts";

interface ToothProps {
  tooth: string;
  isLast: boolean;
  handleDeleteClick: () => void;
}

const Tooth: FC<ToothProps> = ({ tooth, isLast, handleDeleteClick }) => {
  const [hover, setHover] = useState(false);
  const { state } = useCaseFormContext();

  // @ts-ignore
  const toothPresentation =
    state.teethFormulaNumberingUUID === TeethFormulaNumberingEnum.FDI
      ? tooth.slice(4)
      : state.teethFormulaNumberingUUID === TeethFormulaNumberingEnum.Palmer
      ? teethMapping[tooth].Palmer
      : teethMapping[tooth].Universal;

  return (
    <span
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {toothPresentation}
      {hover && (
        <CloseCircleOutlined
          style={{ cursor: "pointer", marginLeft: 3 }}
          onClick={() => handleDeleteClick()}
        />
      )}
      {!isLast && ", "}
    </span>
  );
};

export default Tooth;
