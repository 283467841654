import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Space } from "antd";

import {
  ActivePricelist,
  PrintPricelist,
} from "@/modules/ClientPricelists/components";
import { Loader } from "@/components/common";
import { ClientPricelist } from "@/root/models/pricelist";

interface PricelistProps {
  pricelist: ClientPricelist | {} | undefined;
  loading: boolean;
}

const Pricelist: FC<PricelistProps> = ({ pricelist, loading }) => {
  const { t } = useTranslation();

  if (!loading && (!pricelist || !Object.keys(pricelist).length)) {
    return <div>{t("Прайс лист відсутній")}</div>;
  }

  return (
    <Space direction="vertical">
      <Button onClick={() => window.print()}>{t("Друк")}</Button>
      {pricelist ? (
        <>
          <ActivePricelist
            pricelist={pricelist as ClientPricelist}
            presentation
          />
          <PrintPricelist pricelist={pricelist as ClientPricelist} />
        </>
      ) : (
        loading && <Loader />
      )}
    </Space>
  );
};

export default Pricelist;
