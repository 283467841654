import { AxiosProgressEvent } from "axios";

import makeRequest from "@/api/makeRequest";
import { apiBaseurl } from "@/api/baseurl";

export const FilesApi = {
  getCaseFile: async (caseUUID: string, fileUUID: string): Promise<Blob> => {
    const data = await makeRequest({
      url: apiBaseurl + `/files/cases/${caseUUID}/${fileUUID}`,
      responseType: "blob",
    });

    // @ts-ignore
    return data as Blob;
  },

  getTaskFile: async (taskUUID: string, fileUUID: string): Promise<Blob> => {
    const data = await makeRequest({
      url: apiBaseurl + `/files/tasks/${taskUUID}/${fileUUID}`,
      responseType: "blob",
    });
    // @ts-ignore
    return data as Blob;
  },

  createFile: async (
    file: File,
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void
  ): Promise<{ fileUUID: string }> => {
    const data = await makeRequest<{ fileUUID: string }>({
      method: "post",
      url: apiBaseurl + "/files",
      data: file,
      headers: {
        "Content-Type": file.type,
      },
      onUploadProgress,
    });
    return data;
  },
};
