import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Checkbox, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined } from "@ant-design/icons";

import styles from "./CasesTable.module.scss";

import { Case } from "@/root/models/case";
import { CasesApi } from "@/api/client/cases";
import { CaseStatusEnum } from "@/root/types";

interface CasesTableProps {
  chosenCase?: Case;
  setChosenCase: (c: Case) => void;
  selectedCases: { case: Case; use: boolean }[];
  removeSelectedCase: (caseUUID: string) => void;
  setSelectedCases: (cases: { case: Case; use: boolean }[]) => void;
}

const CasesTable: FC<CasesTableProps> = ({
  chosenCase,
  setChosenCase,
  selectedCases,
  setSelectedCases,
  removeSelectedCase,
}) => {
  const { t } = useTranslation();

  const handleChooseCase = async (caseUUID: string) => {
    try {
      const fullCase = await CasesApi.getFullCase(caseUUID);
      setChosenCase(fullCase);
    } catch (e) {}
  };

  const handleCheck = (medCase: Case, checked: boolean) => {
    setSelectedCases(
      selectedCases.map((c) => {
        if (c.case.caseUUID === medCase.caseUUID) {
          c.use = checked;
        }
        return c;
      })
    );
  };

  const handleSelectedCaseDelete = (caseUUID: string) => {
    const filterOnDelete = selectedCases.filter(
      (elem) => elem.case.caseUUID !== caseUUID
    );
    setSelectedCases(filterOnDelete);
  };

  const caseColumns: ColumnsType<{ case: Case; use: boolean }> = [
    {
      title: t("Номер замовлення"),
      dataIndex: "case",
      key: "caseNumber",
      render: (medCase: Case) => <span>{medCase.caseNumber}</span>,
    },
    {
      title: t("Пацієнт"),
      dataIndex: "case",
      key: "patient",
      render: (medCase: Case) => <span>{medCase.patient.name}</span>,
    },
    {
      title: t("Статус"),
      dataIndex: "case",
      key: "status",
      render: (medCase: Case) => <span>{medCase.status.name}</span>,
    },
    {
      key: "used",
      dataIndex: "use",
      title: t("Використано"),
      render: (used: boolean, { case: medCase }) => (
        <Checkbox
          checked={used}
          onChange={(e) => handleCheck(medCase, e.target.checked)}
        />
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, medCase) => (
        <Space className="table-actions" size="middle">
          <Button
            size="small"
            icon={<DeleteOutlined />}
            danger
            onClick={() => {
              handleSelectedCaseDelete(medCase.case.caseUUID);
              removeSelectedCase(medCase.case.caseUUID);
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <Table
      rowClassName={(medCase) => {
        if (
          chosenCase?.caseUUID === medCase.case.caseUUID &&
          (medCase.case.status.caseStatusUUID === CaseStatusEnum["New"] ||
            medCase.case.status.caseStatusUUID === CaseStatusEnum["Done"] ||
            medCase.case.status.caseStatusUUID ===
              CaseStatusEnum["Ready for pickup"] ||
            medCase.case.status.caseStatusUUID ===
              CaseStatusEnum["Ready for delivery"])
        ) {
          return styles.activeRow;
        }

        if (
          chosenCase?.caseUUID === medCase.case.caseUUID &&
          medCase.case.status.caseStatusUUID !== CaseStatusEnum["New"] &&
          medCase.case.status.caseStatusUUID !== CaseStatusEnum["Done"] &&
          medCase.case.status.caseStatusUUID !==
            CaseStatusEnum["Ready for pickup"] &&
          medCase.case.status.caseStatusUUID !==
            CaseStatusEnum["Ready for delivery"]
        ) {
          return styles.activeRowWithWrongStatus;
        }

        if (
          medCase.case.status.caseStatusUUID !== CaseStatusEnum["New"] &&
          medCase.case.status.caseStatusUUID !== CaseStatusEnum["Done"] &&
          medCase.case.status.caseStatusUUID !==
            CaseStatusEnum["Ready for pickup"] &&
          medCase.case.status.caseStatusUUID !==
            CaseStatusEnum["Ready for delivery"]
        ) {
          return styles.wrongStatusRow;
        }
      }}
      columns={caseColumns}
      dataSource={selectedCases.map((c) => ({ ...c, key: c.case.caseUUID }))}
      pagination={false}
      onRow={(medCase) => {
        return {
          onClick: () => handleChooseCase(medCase.case.caseUUID),
        };
      }}
    />
  );
};

export default CasesTable;
