import { useMutation, useQueryClient } from "@tanstack/react-query";

import { FiltersApi } from "@/api/admin/filters";
import { Filter } from "@/root/models/filter";

const useDeleteFilter = (onError?: (message: string) => void) => {
  const queryClient = useQueryClient();
  const deleteFilterMutation = useMutation({
    mutationFn: FiltersApi.delete,
    onError: (error) => onError?.((error as Error).message),
    onSuccess: (_, filterUUID) => {
      queryClient.setQueryData(["filters"], (prev) => {
        const prevFilters = (prev as Filter[]) || [];
        const recursiveFilter = (filters: Filter[]): Filter[] => {
          const newFilters: Filter[] = [];
          for (const prevFilter of filters) {
            const filterCopy = { ...prevFilter };
            if (filterCopy.filterUUID !== filterUUID) {
              if (filterCopy.children) {
                filterCopy.children = recursiveFilter(filterCopy.children);
              }
              newFilters.push(filterCopy);
            }
          }
          return newFilters;
        };

        return recursiveFilter(prevFilters);
      });
    },
  });

  const deleteFilter = async (id: string) => {
    await deleteFilterMutation.mutateAsync(id);
  };

  const error = (deleteFilterMutation.error as Error)?.message as string;

  return { deleteFilter, loading: deleteFilterMutation.isLoading, error };
};

export default useDeleteFilter;
