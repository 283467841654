import { Invoice } from "@/root/models/invoice";
import { ResponseDataWithPagination } from "@/api/types";
import makeRequest from "./makeRequest";
import { apiBaseurl } from "./baseurl";

interface InvoiceCases {
  caseUUID: string;
  rowUUID: string;
  sum: number;
}

export interface InvoiceEditPayloadProps {
  description: string;
  amount: number;
  clientUUID: string;
  dueDate: string;
  cases: InvoiceCases[];
  paid: boolean;
}

export interface InvoiceCreatePayloadProps {
  clientUUID: string;
  description: string;
  dueDate: string;
  amount: number;
  paid: boolean;
  cases: InvoiceCases[];
}

interface InvoicesApiProps {
  page?: number;
  clientUUID?: string;
  date?: string;
  paid?: boolean;
  invoiceNumber?: string;
}

export const InvoicesApi = {
  getInvoices: async ({
    page,
    clientUUID,
    date,
    paid,
    invoiceNumber,
  }: InvoicesApiProps): Promise<ResponseDataWithPagination<Invoice[]>> => {
    const data = await makeRequest<Invoice[], true>(
      {
        url: apiBaseurl + "/invoices",
        params: {
          pageLimit: 20,
          page,
          clientUUID,
          date,
          paid,
          invoiceNumber,
        },
      },
      { pagination: true }
    );
    return data;
  },

  getInvoice: async (invoiceUUID: string): Promise<Invoice> => {
    const data = await makeRequest<Invoice>({
      url: apiBaseurl + `/invoices/${invoiceUUID}`,
    });
    return data;
  },

  create: async ({
    payload,
  }: {
    payload: InvoiceCreatePayloadProps;
  }): Promise<Invoice> => {
    const data = await makeRequest<Invoice>({
      url: apiBaseurl + `/invoices`,
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async ({
    id,
    payload,
  }: {
    id: string;
    payload: InvoiceEditPayloadProps;
  }): Promise<Invoice> => {
    const data = await makeRequest<Invoice>({
      url: apiBaseurl + `/invoices/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/invoices/${id}`,
      method: "delete",
    });
  },
};
