const FDI_AUJ = [
  "FDI_18",
  "FDI_17",
  "FDI_16",
  "FDI_15",
  "FDI_14",
  "FDI_13",
  "FDI_12",
  "FDI_11",
  "FDI_21",
  "FDI_22",
  "FDI_23",
  "FDI_24",
  "FDI_25",
  "FDI_26",
  "FDI_27",
  "FDI_28",
];

const FDI_ALJ = [
  "FDI_48",
  "FDI_47",
  "FDI_46",
  "FDI_45",
  "FDI_44",
  "FDI_43",
  "FDI_42",
  "FDI_41",
  "FDI_31",
  "FDI_32",
  "FDI_33",
  "FDI_34",
  "FDI_35",
  "FDI_36",
  "FDI_37",
  "FDI_38",
];

const FDI_CUJ = [
  "FDI_55",
  "FDI_54",
  "FDI_53",
  "FDI_52",
  "FDI_51",
  "FDI_61",
  "FDI_62",
  "FDI_63",
  "FDI_64",
  "FDI_65",
];

const FDI_CLJ = [
  "FDI_85",
  "FDI_84",
  "FDI_83",
  "FDI_82",
  "FDI_81",
  "FDI_71",
  "FDI_72",
  "FDI_73",
  "FDI_74",
  "FDI_75",
];

export const getJawTeeth = (
  jaw: "FDI_AUJ" | "FDI_ALJ" | "FDI_CUJ" | "FDI_CLJ"
) => {
  let teeth: string[] = [];

  switch (jaw) {
    case "FDI_AUJ":
      teeth = FDI_AUJ;
      break;
    case "FDI_ALJ":
      teeth = FDI_ALJ;
      break;
    case "FDI_CUJ":
      teeth = FDI_CUJ;
      break;
    case "FDI_CLJ":
      teeth = FDI_CLJ;
      break;
  }

  return teeth;
};

export const getUpperJawTeeth = () => {
  return [...FDI_AUJ, FDI_CUJ];
};

export const getLowerJawTeeth = () => {
  return [...FDI_ALJ, FDI_CLJ];
};
