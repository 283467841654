import { apiBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";
import { TeethFormulaType } from "@/root/models/teethFormulaType";

export const TeethFormulaTypesApi = {
  getTypes: async () => {
    const data = await makeRequest<TeethFormulaType[]>({
      url: apiBaseurl + "/teeth-formula-types",
    });
    return data;
  },
};
