export const getCalendarDates = (year: number, month: number) => {
  const date = new Date(year, month - 1, 1);

  const firstDay = date.getDay();

  const daysInMonth = new Date(year, month, 0).getDate();

  const prevMonthDays = (firstDay + 6) % 7;

  const totalDays =
    prevMonthDays + daysInMonth + (42 - daysInMonth - prevMonthDays);

  const calendarDates = [];

  for (let i = 1; i <= totalDays; i++) {
    if (i <= prevMonthDays) {
      const prevMonthDate = new Date(
        year,
        month - 2,
        daysInMonth - (prevMonthDays - i)
      );
      calendarDates.push(prevMonthDate);
    } else if (i > prevMonthDays + daysInMonth) {
      const nextMonthDate = new Date(
        year,
        month,
        i - prevMonthDays - daysInMonth
      );
      calendarDates.push(nextMonthDate);
    } else {
      const currentDate = new Date(year, month - 1, i - prevMonthDays);
      calendarDates.push(currentDate);
    }
  }

  return calendarDates;
};
