import { PansApi } from "@/api/admin/pans";
import { useQuery } from "@tanstack/react-query";

const usePans = () => {
  const {
    data: pans,
    isFetching: getPansLoading,
    error: getPansError,
    refetch
  } = useQuery({
    queryKey: ["pans"],
    queryFn: PansApi.getPans,
  });

  return {
    pans: pans || [],
    getPansLoading,
    getPansError,
    refetch
  };
};

export default usePans;
