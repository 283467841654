import React from "react";
import makeRequest from "./makeRequest";
import { apiBaseurl } from "./baseurl";
import { Case } from "@/root/models/case";
import { Invoice } from "@/root/models/invoice";

export const UnpaidCasesApi = {
  getUnpaidCases: async (
    clientUUID: string
  ): Promise<Invoice.InvoiceCase[]> => {
    const response = await makeRequest<Invoice.InvoiceCase[]>({
      url: `${apiBaseurl}/unpaid-cases/${clientUUID}`,
    });

    return response;
  },
};
