import { useAppSelector } from "@/hooks/redux";
import AdminClients from "@/components/AdminClients";
import { DATE_FORMAT } from "@/root/consts";
import { UserTypeEnum } from "@/root/types";
import { Form, DatePicker, Button, Select } from "antd";
import { t } from "i18next";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { PaymentSearchParamsGroup } from "..";

interface SearchPaymentsProps {
  onSearch: (values: PaymentSearchParamsGroup) => void;
}

interface PaymentSearchParams {
  adminClients: string[];
  date: [string, string];
}

const SearchPayment = ({ onSearch }: SearchPaymentsProps) => {
  const { RangePicker } = DatePicker;

  const [chosenAdminClients, setChosenAdminClients] = useState<string[]>([]);

  const handleSubmit = (values: PaymentSearchParams) => {
    onSearch({
      adminClients: values.adminClients.join(","),
      date: values.date
        ?.map((d) => dayjs(new Date(d)).format(DATE_FORMAT))
        .join(","),
    });
  };

  return (
    <Form requiredMark={false} onFinish={handleSubmit}>
      <Form.Item name="date" label={t("Дата_one")}>
        <RangePicker format={DATE_FORMAT} />
      </Form.Item>

      <AdminClients
        chosenAdminClients={chosenAdminClients}
        setChosenAdminClients={setChosenAdminClients}
      />
      <div style={{ margin: "10px 0" }}>
        <Button htmlType="submit">{t("Шукати")}</Button>
      </div>
    </Form>
  );
};

export default SearchPayment;
