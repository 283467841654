import styles from "./InvoiceAccountSentToTheMail.module.scss";
import InvoiceToMailHeader from "./components/InvoiceToMailHeader";
import globalStyles from "../InvoicesPdf.module.scss";
import InvoiceToMailMain from "./components/InvoiceToMailMain";
import InvoiceToMailFooter from "./components/InvoiceToMailFooter";

const InvoiceSentToTheMailAndIsStored = () => {
  return (
    <div className={globalStyles.page}>
      <InvoiceToMailHeader />
      <InvoiceToMailMain />
      <InvoiceToMailFooter />
    </div>
  );
};

export default InvoiceSentToTheMailAndIsStored;
