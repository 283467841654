import { Button, Modal, Space, notification } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { useForm } from "antd/es/form/Form";
import { useAppSelector } from "@/hooks/redux";
import { UserTypeEnum } from "@/root/types";
import { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "../../InvoicesPanel/InvoicePanel.module.scss";
import { Payment } from "@/root/models/payment";
import { PaymentEditPayloadProps } from "@/api/payments";
import { usePayments } from "@/hooks/usePayments";
import { PaymentForm } from "./PaymentForm";
import { Invoice } from "@/root/models/invoice";

const { confirm } = Modal;

interface TableActionsProps {
  payment: Payment;
  modal: string | null;
  onCancel: () => void;
  onEditSuccess: () => void;
  onModalViewOpen: () => void;
  onModalEditOpen: () => void;
  modalType: "edit" | "view" | null;
  refetch: () => void;
}

const TableActions = ({
  payment,
  modal,
  onCancel,
  onEditSuccess,
  onModalViewOpen,
  onModalEditOpen,
  modalType,
  refetch,
}: TableActionsProps) => {
  const userType = useAppSelector((state) => state.userReducer.user?.userType);

  const [form] = useForm();

  const submit = () => {
    form.submit();
  };

  const onDeleteError = (message: string) => {
    notification.error({
      message,
    });
  };

  const { deletePayment, deleteLoading, editPayment, editError, editLoading } =
    usePayments({
      onEditSuccess,
      onDeleteError,
    });

  const handleDelete = async () => {
    await deletePayment(payment.paymentUUID); //--
  };

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  const [editedItems, setEditedItems] = useState<Invoice.InvoiceCase[]>([]);

  const handleEdit = async (values: PaymentEditPayloadProps) => {
    editPayment(payment.paymentUUID, {
      description: values.description,
      amount: values.amount,
      clientUUID: payment.client.clientUUID,
      items: editedItems.map((elem) => ({
        caseUUID: elem.case.caseUUID,
        rowUUID: elem.rowUUID,
        sum: elem.sum,
        invoiceUUID: elem.invoices[0].invoiceUUID,
      })),
    });
  };

  useEffect(() => {
    if (modal) {
      form.setFieldsValue({
        paymentNumber: payment.paymentNumber,
        client: payment.client.clientUUID,
        description: payment.description,
        amount: payment.amount,
      });
    }
  }, [modal, modalType]);

  return (
    <>
      {userType?.userTypeUUID === UserTypeEnum["Employee"] ||
      userType?.userTypeUUID === UserTypeEnum["Lab's admin"] ? (
        <Space className="table-actions" direction="vertical">
          <Button
            size="small"
            icon={<EyeOutlined />}
            onClick={onModalViewOpen}
          />
          <Button
            size="small"
            icon={<EditOutlined />}
            onClick={onModalEditOpen}
          />
          <Button
            size="small"
            icon={<DeleteOutlined />}
            danger
            loading={deleteLoading}
            onClick={handleDeleteButtonClick}
          />
        </Space>
      ) : (
        <Button size="small" icon={<EyeOutlined />} onClick={onModalViewOpen} />
      )}
      <Modal
        centered
        width={1000}
        open={modal === payment.paymentUUID}
        maskClosable={false}
        title={
          modalType === "view" ? (
            <div style={{ marginBottom: 20 }}>
              {t("Оплата")}&nbsp;
              {`№${payment.paymentNumber}`}
            </div>
          ) : (
            <div>{t("Змінити оплату")}</div>
          )
        }
        onCancel={onCancel}
        okText={t("Зберегти")}
        onOk={submit}
        okButtonProps={{ loading: editLoading }}
        className={
          modalType === "view"
            ? classNames(
                styles.invoiceModal_content,
                styles.modal_footer_hidden
              )
            : styles.invoiceModal_content
        }
      >
        {modalType === "view" ? (
          <PaymentForm form={form} paymentItems={payment.items} type="view" />
        ) : (
          <PaymentForm
            form={form}
            paymentItems={payment.items}
            handlePaymentEdit={handleEdit}
            type="edit"
            setEditedItems={setEditedItems}
          />
        )}
      </Modal>
    </>
  );
};

export default TableActions;
