import React, { FC, Fragment, ReactNode, useRef } from "react";

import { Space } from "antd";

import styles from "./TeethWidget.module.scss";

import Tooth, { ITooth } from "./components/Tooth";
import useTeeth from "@/components/TeethWidget/useTeeth";

export interface ChosenTooth {
  toothID: string;
  isPoint: boolean;
  color: string | null;
  crown: boolean;
  selected: boolean;
  group?: string;
}

interface TeethWidgetProps {
  chosenTeeth: ChosenTooth[];
  formulaNumbering: string;
  toothRender?: (tooth: ITooth, teeth: ITooth[]) => ReactNode;
  viewBox?: { adult?: string; child?: string };
  onClick?: (id: ITooth) => void;
  formulaType?: "child" | "adult";
  legends?: { name: string; color: string }[];
}

const TeethWidget: FC<TeethWidgetProps> = ({
  toothRender,
  formulaNumbering,
  chosenTeeth,
  viewBox,
  formulaType,
  onClick,
  legends,
}) => {
  const teeth = useTeeth({ formulaType, chosenTeeth });

  const legendsRef = useRef<HTMLDivElement>(null);

  const height = legendsRef.current?.offsetHeight;

  const adultViewBox = viewBox?.adult ? viewBox.adult : "40 35 440 605";
  const childViewBox = viewBox?.child ? viewBox.child : "5 30 350 315";

  return (
    <>
      <svg
        style={{
          display: "block",
          height: height ? `calc(100% - ${height}px` : "100%",
        }}
        width={"100%"}
        viewBox={formulaType === "adult" ? adultViewBox : childViewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {teeth.map((tooth) => (
          <Fragment key={tooth.id}>
            {toothRender ? (
              toothRender(tooth, teeth)
            ) : (
              <Tooth
                formulaNumbering={formulaNumbering}
                tooth={{ ...tooth, chainNode: undefined }}
                onClick={() => {
                  onClick?.(tooth);
                }}
              />
            )}
          </Fragment>
        ))}
      </svg>
      {!!legends?.length && (
        <div ref={legendsRef} className={styles.legends}>
          {legends.map(
            (legend) =>
              legend.name && (
                <Space key={legend.name}>
                  <div
                    style={{
                      background: legend.color,
                      width: 20,
                      height: 20,
                      borderRadius: 20,
                    }}
                  />
                  -<span>{legend.name}</span>
                </Space>
              )
          )}
        </div>
      )}
    </>
  );
};

export default TeethWidget;
