import { CreateWarehousePayload, WarehousesApi } from "@/api/warehouses";
import { Warehouse } from "@/root/models/warehouse";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const useWarehouses = (
  onSuccess?: () => void,
  onDeleteError?: (message: string) => void
) => {
  const queryClient = useQueryClient();

  const {
    data: warehouses,
    isFetching: getWarehousesLoading,
    error: getWarehousesError,
  } = useQuery({
    queryKey: ["warehouses"],
    queryFn: WarehousesApi.getWarehouses,
  });

  const createWarehouseMutation = useMutation({
    mutationFn: WarehousesApi.create,
    onSuccess: (warehouse) => {
      queryClient.setQueryData(
        ["warehouses"],
        (prev: Warehouse[] | undefined) => {
          if (!prev) {
            return [warehouse];
          }
          return [...prev, warehouse];
        }
      );
      onSuccess?.();
    },
  });

  const addWarehouse = (payload: CreateWarehousePayload) => {
    createWarehouseMutation.mutate(payload);
  };

  const createError = (createWarehouseMutation.error as Error)
    ?.message as string;

  const editWarehouseMutation = useMutation({
    mutationFn: WarehousesApi.edit,
    onSuccess: (warehouse: Warehouse) => {
      queryClient.setQueryData(
        ["warehouses"],
        (prev: Warehouse[] | undefined) => {
          onSuccess?.();
          return (prev || []).map((prevWarehouse) => {
            if (prevWarehouse.warehouseUUID === warehouse.warehouseUUID) {
              return warehouse;
            }
            return prevWarehouse;
          });
        }
      );
    },
  });

  const editWarehouse = (id: string, payload: CreateWarehousePayload) => {
    editWarehouseMutation.mutate({ id, payload });
  };

  const editError = (editWarehouseMutation.error as Error)?.message as string;

  const deleteWarehouseMutation = useMutation({
    mutationFn: WarehousesApi.delete,
    onError: (error) => onDeleteError?.((error as Error).message),
    onSuccess: (_, warehouseUUID) => {
      queryClient.setQueryData(
        ["warehouses"],
        (prev: Warehouse[] | undefined) =>
          (prev || []).filter((w) => w.warehouseUUID !== warehouseUUID)
      );
    },
  });

  const deleteWarehouse = async (id: string) => {
    await deleteWarehouseMutation.mutateAsync(id);
  };

  return {
    warehouses: warehouses || [],
    getWarehousesLoading,
    getWarehousesError,
    addWarehouse,
    createError,
    createLoading: createWarehouseMutation.isLoading,
    editWarehouse,
    editError,
    editLoading: editWarehouseMutation.isLoading,
    deleteWarehouse,
    deleteLoading: deleteWarehouseMutation.isLoading,
  };
};

export default useWarehouses;
