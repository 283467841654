import { CSSProperties, FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Progress, Space, Table, Typography } from "antd";
import { EyeOutlined } from "@ant-design/icons";

import { FcFilledFilter } from "react-icons/fc";

import styles from "@/modules/MainPanel/components/DashboardTable.module.scss";

import { Block, Pagination, Print } from "@/components/common";
import useCases from "@/modules/Cases/useCases";
import { ColumnsType } from "antd/es/table";
import { Case } from "@/root/models/case";
import { pages as navigationPages } from "@/root/consts";
import { AdvancedSearch, ChosenCase } from "@/modules/Cases/components";
import { CaseStatusEnum, UserTypeEnum } from "@/root/types";
import { useAppSelector } from "@/hooks/redux";
import useClickOutside from "@/hooks/useClickOutside";
import TableActions from "./TableActions";
import { CasesApi } from "@/api/client/cases";
import { LaboratoryCase, Warranty } from "@/components";
import useTableScrollHeight from "@/hooks/TableHooks/useTableScrollHeight";
import useTablePaginationRange from "@/hooks/TableHooks/useTablePaginationRange";
import { Settings } from "@/root/models/settings";
import { SettingsApi } from "@/api/settings";
import { GrDocument, GrDocumentVerified } from "react-icons/gr";

const { Title } = Typography;

interface CasesTableProps {
  style?: CSSProperties;
  tabHeight: number;
  date: string;
  withAdvancedSearch?: boolean;
  activeCaseUUID?: string;
  onActiveCase: (id: string | undefined) => void;
  chosenCase?: Case;
  isTaskCreation?: boolean;
  setChosenCase: (c: Case | undefined) => void;
  selectedCases: Case[];
  setSelectedCases: (c: Case[]) => void;
  onTaskCreateClick: () => void;
  addSelectedCases?: () => void;
}

const CasesTable: FC<CasesTableProps> = ({
  withAdvancedSearch,
  isTaskCreation = false,
  tabHeight,
  style,
  date,
  onActiveCase,
  chosenCase,
  setChosenCase,
  selectedCases,
  setSelectedCases,
  addSelectedCases,
  onTaskCreateClick,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const userType = useAppSelector((state) => state.userReducer.user?.userType);
  const [print, setPrint] = useState<"warranty" | "case">();

  const [full, setFull] = useState(false);

  const searchParams = new URLSearchParams(location.search);
  const pageString = searchParams.get("page");
  const defaultPage = (pageString && parseInt(pageString)) || 1;

  const {
    cases,
    currentPage,
    pages,
    getCasesLoading,
    searchCases,
    setCurrentPage,
    setDueDate,
    deleteCase,
    setIsReadyForDel,
  } = useCases({
    defaultPage,
  });

  const { tableScrollHeight, headerRef, paginationRef, advancedSearchRef } =
    useTableScrollHeight(tabHeight);

  const { paginationRange, tableRef } =
    useTablePaginationRange(getCasesLoading);

  const [fullCaseOpen, setFullCaseOpen] = useState<Case>();

  const [settings, setSettings] = useState<Settings>();

  useEffect(() => {
    (async () => {
      try {
        const response = await SettingsApi.getSettings();
        setSettings(response);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (!print) {
      return;
    }
    window.print();
    setPrint(undefined);
  }, [print]);

  useEffect(() => {
    if (getCasesLoading) {
      return;
    }

    setCurrentPage(1);
    setDueDate(date || undefined);
    onActiveCase(undefined);
  }, [date]);

  useEffect(() => {
    if (cases) {
      cases[0] && onActiveCase(cases[0].caseUUID);
    }
  }, [cases]);

  const userPath =
    userType?.userTypeUUID === UserTypeEnum.Employee
      ? "lab"
      : userType?.userTypeUUID === UserTypeEnum["Lab's admin"]
      ? "admin"
      : "client";

  const handleEdit = async (medCase: Case) => {
    navigate(navigationPages[userPath].cases.edit.path, {
      state: medCase.caseUUID,
    });
  };

  const handleCreate = () => {
    navigate(navigationPages[userPath].cases.create.path);
  };

  const casesColumns: ColumnsType<Case> = [
    {
      width: 60,
      dataIndex: "applied",
      key: "applied",
      render: (applied: Case["applied"]) => (
        <div style={{ paddingLeft: 10, paddingTop: 5 }}>
          {applied ? (
            <GrDocumentVerified size={20} />
          ) : (
            <GrDocument size={20} />
          )}
        </div>
      ),
    },
    {
      width: 88,
      title: t("Прогрес"),
      dataIndex: "status",
      key: "caseStatus",
      render: (status: Case["status"]) => (
        <Progress size={50} type="circle" percent={status.progress} />
      ),
    },
    {
      width: 80,
      title: "№",
      dataIndex: "caseNumber",
      key: "caseNumber",
    },
    {
      width: 88,
      title: t("Дата завершення"),
      dataIndex: "dueDate",
      key: "dueDate",
      render: (date: Case["dueDate"]) => <span>{date.slice(0, 10)}</span>,
    },

    {
      title: t("Лікар"),
      dataIndex: "clientsEmployee",
      key: "clientsEmployee",
      render: (clientsEmployee: Case["clientsEmployee"]) => (
        <span>{clientsEmployee?.name}</span>
      ),
    },
    {
      title: t("Пацієнт"),
      dataIndex: "patient",
      key: "patient",
      render: (patient: Case["patient"]) => <span>{patient?.name}</span>,
    },
    {
      width: 100,
      title: t("Статус"),
      dataIndex: "status",
      key: "status",
      render: (status: Case["status"]) => <span>{status.name}</span>,
    },
    {
      className: styles.actionRow,
      width: 50,
      title: "",
      key: "action",
      render: (_: any, medCase: Case) =>
        (userType?.userTypeUUID === UserTypeEnum["Client's admin"] ||
          userType?.userTypeUUID === UserTypeEnum["Client's employee"]) &&
        medCase.status.caseStatusUUID !== CaseStatusEnum.New &&
        medCase.status.caseStatusUUID !== CaseStatusEnum["Ready for pickup"] ? (
          <div className="table-actions">
            <Button
              size="small"
              icon={<EyeOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                setFullCaseOpen(medCase);
              }}
            />
          </div>
        ) : (
          <TableActions
            medCase={medCase}
            onWatchClick={setFullCaseOpen}
            onDelete={deleteCase}
            onEditButtonClick={handleEdit}
          />
        ),
    },
  ];

  if (
    userType?.userTypeUUID === UserTypeEnum["Employee"] ||
    userType?.userTypeUUID === UserTypeEnum["Lab's admin"]
  ) {
    casesColumns.splice(1, 0, {
      title: t("Контрагент"),
      dataIndex: "client",
      key: "client",
      render: (client: Case["client"]) => <span>{client?.name}</span>,
    });
    casesColumns.splice(4, 0, {
      title: t("Відповідальний"),
      dataIndex: "activeHandler",
      key: "activeHandler",
      render: (activeHandler: Case["activeHandler"]) => (
        <span>{activeHandler.activeHandlerName}</span>
      ),
    });
  }

  const handlePage = (page: number) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", page.toString());
    navigate({ search: searchParams.toString() });
    setCurrentPage(page);
  };

  const fullCaseRef = useRef<HTMLDivElement>(null);
  useClickOutside(fullCaseRef, () => setFullCaseOpen(undefined));

  const handleChooseCase = async (caseUUID: string) => {
    try {
      const fullCase = await CasesApi.getFullCase(caseUUID);
      setChosenCase(fullCase);
    } catch (e) {}
  };

  useEffect(() => {
    if (!cases.length) {
      setChosenCase(undefined);
    }
  }, [cases.length]);

  const handleRowClick = ({
    medCase,
    ctrlKey,
    shiftKey,
  }: {
    medCase: Case;
    ctrlKey: boolean;
    shiftKey: boolean;
  }) => {
    if (shiftKey) {
      const startIndex =
        chosenCase &&
        cases.findIndex((c) => c.caseUUID === chosenCase.caseUUID);
      const endIndex = cases.findIndex((c) => c.caseUUID === medCase.caseUUID);
      if (startIndex !== 0 && !startIndex) {
        onActiveCase(medCase.caseUUID);
        setSelectedCases([medCase]);
      } else {
        setSelectedCases(
          cases.slice(
            Math.min(startIndex, endIndex),
            Math.max(startIndex, endIndex) + 1
          )
        );
      }
    } else if (ctrlKey) {
      onActiveCase(medCase.caseUUID);
      if (selectedCases.find((c) => c.caseUUID === medCase.caseUUID)) {
        setSelectedCases(
          selectedCases.filter((c) => c.caseUUID !== medCase.caseUUID)
        );
      } else {
        setSelectedCases([...selectedCases, medCase]);
      }
    } else {
      handleChooseCase(medCase.caseUUID);
      onActiveCase(medCase.caseUUID);
      setSelectedCases([medCase]);
    }
  };

  //Change cases status to Ready for delivery if it's status New
  const onSelectedHandle = () => {
    return selectedCases
      .filter((elem) => elem.status.caseStatusUUID === CaseStatusEnum["New"])
      .map(async (caseNew) => {
        try {
          setIsReadyForDel(true);
          await CasesApi.editCaseStatus(
            caseNew.caseUUID,
            CaseStatusEnum["Ready for delivery"]
          );
          setIsReadyForDel(false);
          setSelectedCases([]);
        } catch (e: any) {
          console.log(e);
        }
      });
  };

  const isDoctor =
    userType?.userTypeUUID === UserTypeEnum["Client's admin"] ||
    userType?.userTypeUUID === UserTypeEnum["Client's employee"];

  return (
    <>
      <Block style={style}>
        <div className={styles.container}>
          <div>
            <div ref={headerRef} style={{ display: "flex" }}>
              <Space>
                {withAdvancedSearch && (
                  <Button
                    onClick={() => setFull(!full)}
                    icon={<FcFilledFilter style={{ width: 20, height: 20 }} />}
                  />
                )}
                <Button onClick={handleCreate}>
                  {t("Створити замовлення")}
                </Button>
                {chosenCase && (
                  <Button onClick={() => setPrint("case")}>
                    {t("Надрукувати замовлення")}
                  </Button>
                )}
                {chosenCase && (
                  <Button onClick={() => setPrint("warranty")}>
                    {t("Надрукувати гарантію")}
                  </Button>
                )}
                {isDoctor && !!selectedCases.length && (
                  <Button
                    onClick={onSelectedHandle}
                    disabled={
                      !selectedCases.some(
                        (elem) =>
                          elem.status.caseStatusUUID === CaseStatusEnum["New"]
                      )
                    }
                  >
                    {t("Встановити вибране 'Ready for delivery'")}
                  </Button>
                )}

                {!isDoctor && (
                  <>
                    <Button onClick={onTaskCreateClick}>
                      {t("Створити завдання")}
                    </Button>
                    {isTaskCreation && (
                      <Button onClick={() => addSelectedCases?.()}>
                        {t("Додати обрані замовлення до завдання")}
                      </Button>
                    )}
                  </>
                )}
              </Space>
              <Title level={3} style={{ width: "33%", textAlign: "center" }}>
                {t("Замовлення")}
              </Title>
            </div>
            {withAdvancedSearch && (
              <div ref={advancedSearchRef} style={{ paddingBottom: 8 }}>
                <AdvancedSearch onSearch={searchCases} full={full} />
              </div>
            )}
            <Table
              style={{ userSelect: "none" }}
              rowClassName={(medCase) =>
                selectedCases.some((c) => c.caseUUID === medCase.caseUUID)
                  ? styles.activeRow
                  : undefined
              }
              size="small"
              scroll={{
                y: tableScrollHeight,
              }}
              ref={tableRef}
              columns={casesColumns}
              dataSource={cases.map((c) => ({ ...c, key: c.caseUUID }))}
              pagination={false}
              loading={getCasesLoading}
              onRow={(medCase) => ({
                onClick: (e) =>
                  handleRowClick({
                    medCase,
                    ctrlKey: e.ctrlKey,
                    shiftKey: e.shiftKey,
                  }),
                onDoubleClick: () => {
                  if (isDoctor) {
                    setFullCaseOpen(medCase);
                  } else {
                    navigate(navigationPages[userPath].cases.edit.path, {
                      state: medCase.caseUUID,
                    });
                  }
                },
              })}
            />
          </div>
          <div
            ref={paginationRef}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Pagination
              currentPage={currentPage}
              pages={pages}
              paginationRange={paginationRange}
              setCurrentPage={handlePage}
            />
          </div>
        </div>
      </Block>
      {fullCaseOpen && (
        <div ref={fullCaseRef} className={styles.fullCase}>
          <ChosenCase medCase={fullCaseOpen} />
        </div>
      )}
      {chosenCase?.patient && print === "warranty" && (
        <Print>
          <Warranty
            warrantyNumber={chosenCase.warrantyNumber}
            secret={chosenCase.patient.secret}
            patientCode={chosenCase.patient.patientCode}
            settings={settings}
          />
        </Print>
      )}
      {chosenCase && print === "case" && (
        <Print>
          <LaboratoryCase
            medCase={chosenCase}
            teethFormulaNumberingUUID={
              chosenCase.teethFormulaNumbering.teethFormulaNumberingUUID || ""
            }
          />
        </Print>
      )}
    </>
  );
};

export default CasesTable;
