import { FC } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { DatePicker, Form, Input, Select } from "antd";

import styles from "./ActivePricelist.module.scss";

import { ClientPricelist, Pricelist } from "@/root/models/pricelist";
import {
  ExtraPricelist,
  ProductPricelist,
} from "@/components/PricelistForm/componenets";
import { PricelistTypeEnum } from "@/components/PricelistForm";
import { Block } from "@/components/common";

const { Option } = Select;

interface ActivePricelistProps {
  pricelist: ClientPricelist | Pricelist;
  presentation?: boolean;
}

const ActivePricelist: FC<ActivePricelistProps> = ({
  pricelist,
  presentation,
}) => {
  const { t } = useTranslation();
  const type = pricelist.pricelistType;
  const listItems = pricelist.list.map((item) => ({
    ...item,
    itemUUID:
      "productUUID" in item.item ? item.item.productUUID : item.item.extraUUID,
  }));

  const isClientEmployee = "clientsPricelistUUID" in pricelist;

  return (
    <Block style={{ boxShadow: "none", borderRadius: 6 }}>
      <Form labelCol={{ span: 6 }} disabled={true} requiredMark={false}>
        <Form.Item label={t("Номер")}>
          <Input value={pricelist.number} />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: t("Заповніть поле") as string,
            },
          ]}
          label={t("Опис")}
        >
          <Input
            value={pricelist.description}
            placeholder={t("Введіть значення") as string}
          />
        </Form.Item>

        {isClientEmployee && (
          <Form.Item
            rules={[
              {
                required: true,
                message: t("Заповніть поле") as string,
              },
            ]}
            label={t("Контрагент")}
          >
            <Select value={pricelist.client.clientUUID}>
              <Option value={pricelist.client.clientUUID}>
                {pricelist.client.name}
              </Option>
            </Select>
          </Form.Item>
        )}

        <div style={{ display: "flex", width: "100%" }}>
          <Form.Item
            labelCol={{ span: undefined }}
            className={styles.formOneLine}
            rules={[
              {
                required: true,
                message: t("Заповніть поле") as string,
              },
            ]}
            label={t("Дата активації")}
          >
            <DatePicker
              value={dayjs(new Date(pricelist.date))}
              format="YYYY.MM.DD"
              placeholder={t("Виберіть дату") as string}
            />
          </Form.Item>
          <Form.Item
            labelCol={{ span: undefined }}
            label={t("Тип")}
            style={{ flexGrow: 1 }}
          >
            <Select value={type.pricelistTypeUUID}>
              <Option value={type.pricelistTypeUUID}>{type.name || " "}</Option>
            </Select>
          </Form.Item>
        </div>

        {type.pricelistTypeUUID === PricelistTypeEnum.ProductsCatalog && (
          <ProductPricelist
            productPrices={listItems}
            setProductPrices={() => {}}
            presentation={presentation}
          />
        )}

        {type.pricelistTypeUUID === PricelistTypeEnum.Extras && (
          <ExtraPricelist
            extraPrices={listItems}
            setExtraPrices={() => {}}
            presentation={presentation}
          />
        )}
      </Form>
    </Block>
  );
};

export default ActivePricelist;
