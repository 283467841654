import { apiBaseurl } from "@/api/baseurl";
import { Courier } from "@/root/models/courier";
import makeRequest from "@/api/makeRequest";

export const CouriersApi = {
  getCouriers: async (): Promise<Courier[]> => {
    const data = await makeRequest<Courier[]>({
      url: apiBaseurl + "/couriers",
    });
    return data;
  },
};
