import { AccountingTotal } from "@/root/models/accounting";
import { Button, Modal } from "antd";
import { t } from "i18next";
import { Dispatch, SetStateAction } from "react";

interface DeleteDiscountProps {
  selectedItem: AccountingTotal | undefined;
  setSelectedItem: Dispatch<SetStateAction<AccountingTotal | undefined>>;
}

const { confirm } = Modal;

const DeleteDiscount = ({
  selectedItem,
  setSelectedItem,
}: DeleteDiscountProps) => {
  const deleteDiscount = () => {
    confirm({
      title: t("Видалити знижку?"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => {
        if (selectedItem) {
          setSelectedItem({
            ...selectedItem,
            discountPercent: 0,
            discountSum: Number(((0 / 100) * selectedItem.sum).toFixed(1)),
            total:
              selectedItem.sum -
              Number(((0 / 100) * selectedItem.sum).toFixed(1)),
          });
        }
      },
    });
  };

  return (
    <Button
      onClick={deleteDiscount}
      disabled={(!selectedItem || !!selectedItem.invoices?.length) && true}
    >
      {t("Видалити знижку")}
    </Button>
  );
};

export default DeleteDiscount;
