import { useQuery } from "@tanstack/react-query";

import { CouriersApi } from "@/api/couriers";

const useCouriers = () => {
  const {
    data: couriers,
    isLoading: getCouriersLoading,
    error: getCouriersError,
  } = useQuery({
    queryKey: ["couriers"],
    queryFn: CouriersApi.getCouriers,
  });

  return {
    couriers: couriers || [],
    getCouriersLoading,
    getCouriersError,
  };
};

export default useCouriers;
