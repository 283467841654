import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Input, Space } from "antd";

const SearchInput: FC = () => {
  const { t } = useTranslation();

  return (
    <Space>
      <span>{t("Пошук")}:</span> <Input />
    </Space>
  );
};

export default SearchInput;
