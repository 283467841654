import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HexColorPicker } from "react-colorful";

import { Alert, Button, Input, Space, Table, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";

import { CaseStatusColor } from "@/root/models/caseStatusColor";
import useCaseStatusColors from "@/hooks/useCaseStatusColors";

const CaseStatusColors: FC = () => {
  const { t } = useTranslation();
  const {
    caseStatusColors,
    editCaseStatusColors,
    editLoading,
    editError,
    getCaseStatusColorsLoading,
  } = useCaseStatusColors({
    onEditSuccess: () => {},
  });

  const [localCaseStatusColors, setLocalCaseStatusColors] = useState<
    CaseStatusColor[]
  >([]);

  useEffect(() => {
    caseStatusColors && setLocalCaseStatusColors(caseStatusColors);
  }, [caseStatusColors]);

  const handleEditColor = (itemID: string, color: string) => {
    setLocalCaseStatusColors((prev) =>
      prev.map((i) => (i.caseStatusUUID === itemID ? { ...i, color } : i))
    );
  };

  const groupsColumns: ColumnsType<CaseStatusColor> = [
    {
      title: t("Назва"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Колір"),
      dataIndex: "color",
      key: "color",
      render: (color: string, colorItem) => (
        <Space>
          <Input
            style={{ width: 90 }}
            value={color}
            onChange={(e) => {
              handleEditColor(colorItem.caseStatusUUID, e.target.value);
            }}
          />
          <Tooltip
            title={
              <HexColorPicker
                onChange={(color) => {
                  handleEditColor(colorItem.caseStatusUUID, color.slice(1));
                }}
              />
            }
          >
            <div
              style={{
                width: 20,
                height: 20,
                borderRadius: 20,
                backgroundColor: "#" + color,
                border: "1px solid",
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const data = localCaseStatusColors.map((c) => ({
    ...c,
    key: c.caseStatusUUID,
  }));

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Table
        style={{ width: "40%" }}
        columns={groupsColumns}
        pagination={false}
        loading={getCaseStatusColorsLoading}
        dataSource={data}
      />

      {!!editError && (
        <Alert style={{ width: "40%" }} message={editError} type="error" />
      )}

      <Button
        type="primary"
        loading={editLoading}
        onClick={() => editCaseStatusColors(localCaseStatusColors)}
      >
        {t("Зберегти")}
      </Button>
    </Space>
  );
};

export default CaseStatusColors;
