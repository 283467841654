import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "../DashboardTable.module.scss";

import { notification, Tabs, TabsProps } from "antd";

import TasksTable from "../TasksTable";
import CasesTable from "../CasesTable";
import CreateTask from "../CreateTask";
import { useAppSelector } from "@/hooks/redux";
import { UserTypeEnum } from "@/root/types";
import { Case } from "@/root/models/case";

interface ContentTablesProps {
  date: string;
  widgetCollapsed: boolean;
  withAdvancedSearch?: boolean;
  setWidgetCollapsed: (c: boolean) => void;
}

const ContentTables: FC<ContentTablesProps> = ({
  date,
  widgetCollapsed,
  withAdvancedSearch = false,
  setWidgetCollapsed,
}) => {
  const { t } = useTranslation();
  const userType = useAppSelector((state) => state.userReducer.user?.userType);
  const [activeCaseUUID, setActiveCaseUUID] = useState<string>();
  const [ifCollapsedTabKey, setIfCollapsedTabKey] = useState("tasks");
  const [tabKey, setTabKey] = useState("cases");
  const [chosenCase, setChosenCase] = useState<Case | undefined>();
  const [selectedCases, setSelectedCases] = useState<Case[]>([]);
  const [confirmedSelectedCases, setConfirmedSelectedCases] = useState<Case[]>(
    []
  );

  const isDoctor =
    userType?.userTypeUUID === UserTypeEnum["Client's admin"] ||
    userType?.userTypeUUID === UserTypeEnum["Client's employee"];

  const handleTaskCreateClick = () => {
    setWidgetCollapsed(true);
    setIfCollapsedTabKey("createTask");
  };

  if (isDoctor) {
    return (
      <CasesTable
        withAdvancedSearch={withAdvancedSearch}
        date={date}
        chosenCase={chosenCase}
        setChosenCase={setChosenCase}
        selectedCases={selectedCases}
        setSelectedCases={setSelectedCases}
        onActiveCase={setActiveCaseUUID}
        onTaskCreateClick={handleTaskCreateClick}
        tabHeight={0}
      />
    );
  }

  const tabBlockStyle = {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    boxShadow: "0 12px 12px 0 rgb(0 0 0 / 10%)",
    height: "calc(100vh - 60px)",
  };

  const collapsedTabItems: TabsProps["items"] = [
    {
      key: "tasks",
      label: t("Завдання"),
      children: activeCaseUUID && (
        <TasksTable
          style={tabBlockStyle}
          caseUUID={activeCaseUUID}
          tabHeight={60}
        />
      ),
    },
    {
      key: "createTask",
      label: t("Створити завдання"),
      children: (
        <CreateTask
          style={tabBlockStyle}
          chosenCase={chosenCase}
          setChosenCase={setChosenCase}
          selectedCases={confirmedSelectedCases}
          removeSelectedCase={(caseUUID: string) =>
            setConfirmedSelectedCases((cases) =>
              cases.filter((c) => c.caseUUID !== caseUUID)
            )
          }
          clearSelectedCases={() => setConfirmedSelectedCases([])}
        />
      ),
    },
  ];

  const handleAddSelectedCases = () => {
    const uniqueSelectedCases = selectedCases.filter(
      (c) => !confirmedSelectedCases.some((pc) => pc.caseUUID === c.caseUUID)
    );

    const { sameClinic, differentClinic } = confirmedSelectedCases.length
      ? uniqueSelectedCases.reduce(
          (result, c) => {
            const { clientUUID: caseClientUUID } = c.client;

            if (
              caseClientUUID === confirmedSelectedCases[0].client.clientUUID
            ) {
              result.sameClinic.push(c);
            } else {
              result.differentClinic.push(c);
            }

            return result;
          },
          {
            sameClinic: [],
            differentClinic: [],
          } as { sameClinic: Case[]; differentClinic: Case[] }
        )
      : { sameClinic: uniqueSelectedCases, differentClinic: [] };

    if (differentClinic.length) {
      notification.error({
        message:
          t("Контрагенти не співпадають для замовлень") +
          ": " +
          differentClinic.map((c) => c.caseNumber),
      });
    }

    setSelectedCases(differentClinic);

    setConfirmedSelectedCases((prev) => [...prev, ...sameClinic]);
  };

  if (widgetCollapsed) {
    return (
      <div className={styles.content}>
        <div style={{ width: "67%" }}>
          <CasesTable
            style={{ boxShadow: "none" }}
            isTaskCreation={ifCollapsedTabKey === "createTask"}
            withAdvancedSearch={withAdvancedSearch}
            date={date}
            chosenCase={chosenCase}
            setChosenCase={setChosenCase}
            selectedCases={selectedCases}
            setSelectedCases={setSelectedCases}
            onActiveCase={setActiveCaseUUID}
            onTaskCreateClick={handleTaskCreateClick}
            addSelectedCases={handleAddSelectedCases}
            tabHeight={withAdvancedSearch ? 20 : 20}
          />
        </div>
        <div style={{ width: "35%" }}>
          <Tabs
            tabBarStyle={{ marginBottom: 0 }}
            type="card"
            items={collapsedTabItems}
            activeKey={ifCollapsedTabKey}
            onTabClick={setIfCollapsedTabKey}
          />
        </div>
      </div>
    );
  }

  const items: TabsProps["items"] = [
    {
      key: "cases",
      label: t("Замовлення"),
      children: (
        <CasesTable
          style={{ ...tabBlockStyle, boxShadow: "none" }}
          isTaskCreation={ifCollapsedTabKey === "createTask"}
          withAdvancedSearch={withAdvancedSearch}
          date={date}
          chosenCase={chosenCase}
          setChosenCase={setChosenCase}
          selectedCases={selectedCases}
          setSelectedCases={setSelectedCases}
          onActiveCase={setActiveCaseUUID}
          onTaskCreateClick={handleTaskCreateClick}
          addSelectedCases={handleAddSelectedCases}
          tabHeight={70}
        />
      ),
    },
    {
      key: "tasks",
      label: t("Завдання"),
      children: activeCaseUUID && (
        <TasksTable
          style={tabBlockStyle}
          caseUUID={activeCaseUUID}
          tabHeight={30}
        />
      ),
    },
  ];

  return (
    <Tabs
      tabBarStyle={{ marginBottom: 0 }}
      type="card"
      items={items}
      activeKey={tabKey}
      onTabClick={setTabKey}
    />
  );
};

export default ContentTables;
