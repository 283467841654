import { useEffect, useRef, useState } from "react";

const useTablePaginationRange = (loadingProcess: boolean) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const [paginationRange, setPaginationRange] = useState(1);

  useEffect(() => {
    setTimeout(() => {
      setPaginationRange(
        tableRef.current
          ? Math.floor(
              (tableRef.current.getBoundingClientRect().width - 8 - 39 * 4) / 39
            )
          : 1
      );
    });
  }, [tableRef.current, loadingProcess]);
  return { paginationRange, tableRef };
};
export default useTablePaginationRange;
