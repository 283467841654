import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "antd";

import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import { CaseJobs } from "@/components";

interface JobsProps {
  withParameters: boolean;
  withLock: boolean;
}

const { confirm } = Modal;

const Jobs: FC<JobsProps> = ({ withParameters, withLock }) => {
  const { t } = useTranslation();
  const { state, dispatch } = useCaseFormContext();

  const deleteProduct = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => {
        dispatch({ type: ActionCreatorTypes.DeleteProduct, payload: {} });
      },
    });
  };

  useEffect(() => {
    const onKey = (e: KeyboardEvent) => {
      if (e.key === "Delete") {
        deleteProduct();
      }
    };
    document.addEventListener("keydown", onKey);
    return () => {
      document.removeEventListener("keydown", onKey);
    };
  }, []);

  const handleAdd = () => {
    dispatch({ type: ActionCreatorTypes.AddJob, payload: "" });
  };

  const handleDelete = (job: string) => {
    dispatch({ type: ActionCreatorTypes.DeleteJob, payload: job });
  };

  const handleEdit = (jobID: string, value: string) => {
    dispatch({
      type: ActionCreatorTypes.EditJob,
      payload: { jobID, jobName: value },
    });
  };

  return (
    <CaseJobs
      withParameters={withParameters}
      jobs={state.jobs}
      onAdd={handleAdd}
      onEdit={handleEdit}
      onDelete={handleDelete}
      withLock={withLock}
    />
  );
};

export default Jobs;
