import React, { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Form, Space } from "antd";

import styles from "./ChosenCase.module.scss";

import { Job, Case } from "@/root/models/case";
import { Block } from "@/components/common";
import { TeethWidget } from "@/components";
import useCombinedJobTeeth from "@/hooks/useCombinedJobTeeth";
import { Product } from "@/root/models/product";
import useProducts from "@/hooks/useProducts";
import ProductItem from "@/components/CaseForm/components/Results/ProductItem";
import { CaseFormState } from "@/components/CaseForm/CaseFormContext";
import { jobsToFormJobs } from "@/utils/JobsToFormJobs";
import { TeethFormulaTypeEnum } from "@/root/types";

interface ChosenCaseProps {
  medCase: Case;
}

const ChosenCase: FC<ChosenCaseProps> = ({ medCase }) => {
  const { t } = useTranslation();
  const [activeJobs, setActiveJobs] = useState<Job[]>([]);
  const allTeeth = useCombinedJobTeeth(activeJobs);
  const { findProductByID, getParameterGroup, getProductsLoading } =
    useProducts();

  useEffect(() => {
    setActiveJobs(medCase.jobs);
  }, [medCase]);

  const products = Array.from(
    new Set(
      activeJobs
        .filter((job) => job.products.length)
        .flatMap((job) =>
          job.products.map((product) => findProductByID(product.jobProductUUID))
        )
        .filter(Boolean)
        .map((product) => getParameterGroup(product as Product))
    )
  );

  const legends = products.map((product) => ({
    name: product.name,
    color: product.color || "",
  }));

  if (getProductsLoading) {
    return null;
  }

  const jobs: CaseFormState.Job[] = jobsToFormJobs(
    medCase.jobs,
    findProductByID,
    getParameterGroup
  );

  return (
    <Form className={styles.container}>
      <Space direction="vertical">
        <Block>
          <div>
            {t("Опис")}: {medCase.description}
          </div>
          {jobs.map((job) => (
            <Fragment key={job.jobID}>
              {job.products.map((product) => (
                <ProductItem
                  displayParameters={false}
                  key={product.jobProductUUID}
                  product={product}
                />
              ))}
            </Fragment>
          ))}
        </Block>
        <Block>
          <TeethWidget
            formulaNumbering={
              medCase.teethFormulaNumbering?.teethFormulaNumberingUUID
            }
            formulaType={
              medCase.teethFormulaType.teethFormulaTypeUUID ===
              TeethFormulaTypeEnum.child
                ? "child"
                : "adult"
            }
            chosenTeeth={allTeeth}
            legends={legends}
          />
        </Block>
      </Space>
    </Form>
  );
};

export default ChosenCase;
