import { useEffect, useState } from "react";

import { Case } from "@/root/models/case";
import { CasesApi } from "@/api/client/cases";

export type SearchCasesParameters = {
  status?: string;
  date?: string;
  dueDate?: string;
  employeeUUID?: string;
  clientsEmployeeUUID?: string;
  clientUUID?: string;
  patientUUID?: string;
  caseNumber?: string;
};

type Props =
  | {
      caseNumber?: string;
      defaultPage?: number;
    }
  | undefined;

const useCases = (props: Props = {}) => {
  const [cases, setCases] = useState<Case[]>([]);
  const [pages, setPages] = useState<number>(1);
  const [sort, setSort] = useState<{
    field: "dueDate" | "arrival";
    order: "desc" | "asc";
  }>({ field: "arrival", order: "asc" });
  const [getCasesLoading, setGetCasesLoading] = useState(true);
  const [getCasesError, setGetCasesError] = useState("");
  const [caseStatuses, setCaseStatuses] = useState<string[]>();
  const [caseDate, setCaseDate] = useState<[string, string]>();
  const [dueDate, setDueDate] = useState<string>();
  const [currentPage, setCurrentPage] = useState(props?.defaultPage || 1);
  const [isReadyForDel, setIsReadyForDel] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setGetCasesLoading(true);
        const { data, pages } = await CasesApi.getCases({
          status: caseStatuses?.length ? caseStatuses?.join(",") : undefined,
          arrival: caseDate?.join(","),
          caseNumber: props.caseNumber,
          page: currentPage,
          dueDate: dueDate || undefined,
          sort: sort ? `${sort.field},${sort.order}` : undefined,
          "page-limit": "50",
        });
        setCases(data);
        setPages(Number(pages));
      } catch (e: any) {
        setGetCasesError(e.message);
      } finally {
        setGetCasesLoading(false);
      }
    })();
  }, [caseStatuses, caseDate, dueDate, currentPage, sort, isReadyForDel]);

  const searchCases = async (parameters: SearchCasesParameters) => {
    try {
      setGetCasesLoading(true);
      const { data, pages } = await CasesApi.getCases({
        ...parameters,
        "page-limit": "50",
        page: 1,
      });
      setCases(data);
      setPages(Number(pages));
    } catch (e: any) {
      setGetCasesError(e.message);
    } finally {
      setGetCasesLoading(false);
    }
  };

  const deleteCase = (id: string) => {
    setCases((prev) => prev.filter((p) => p.caseUUID !== id));
  };

  const editCase = (id: string, medCase: Case) => {
    setCases((prev) =>
      prev.map((prevCase) => {
        if (prevCase.caseUUID === id) {
          return medCase;
        }
        return prevCase;
      })
    );
  };

  return {
    cases,
    pages,
    sort,
    currentPage,
    caseStatuses,
    getCasesLoading,
    getCasesError,
    searchCases,
    deleteCase,
    editCase,
    setCaseStatuses,
    setCaseDate,
    setDueDate,
    setCurrentPage,
    setSort,
    setIsReadyForDel,
  };
};

export default useCases;
