import { ColumnsType } from "antd/es/table";
import { t } from "i18next";
import { Table } from "antd";
import { Dispatch, SetStateAction } from "react";
import { Pagination } from "@/components/common";
import useTableScrollHeight from "@/hooks/TableHooks/useTableScrollHeight";
import useTablePaginationRange from "@/hooks/TableHooks/useTablePaginationRange";
import useModalOpen from "@/hooks/useModalOpen";
import { useIsLab } from "@/root/models/isLab";
import { Payment } from "@/root/models/payment";
import TableActions from "./TableActions";
import { GrDocument, GrDocumentVerified } from "react-icons/gr";

interface PaymentsTableProps {
  tabHeight: number;
  setPage: Dispatch<SetStateAction<number>>;
  payments: Payment[];
  pages: number | undefined;
  getPaymentsLoading: boolean;
  page: number;
  refetch: () => void;
}

const PaymentsTable = ({
  tabHeight,
  setPage,
  payments,
  getPaymentsLoading,
  pages,
  page,
  refetch,
}: PaymentsTableProps) => {
  const { isLab } = useIsLab();

  const { tableScrollHeight, paginationRef } = useTableScrollHeight(tabHeight);

  const { paginationRange, tableRef } =
    useTablePaginationRange(getPaymentsLoading);

  const {
    modal,
    onHandleCancel,
    onEditSuccess,
    isModalOpen,
    modalType,
    setModalType,
  } = useModalOpen();

  const casesColumns: ColumnsType<Payment> = [
    {
      width: 60,
      dataIndex: "applied",
      key: "applied",
      render: (applied: Payment["applied"]) => (
        <div style={{ paddingLeft: 10, paddingTop: 5 }}>
          {applied ? (
            <GrDocumentVerified size={20} />
          ) : (
            <GrDocument size={20} />
          )}
        </div>
      ),
    },
    {
      width: 120,
      title: "№",
      dataIndex: "paymentNumber",
      key: "paymentNumber",
    },
    {
      title: t("Контрагент"),
      dataIndex: "client",
      key: "client",
      render: (client: Payment["client"]) => <span>{client.name}</span>,
    },
    {
      title: t("Опис"),
      dataIndex: "description",
      key: "description",
    },
    {
      width: 150,
      title: t("Повна сума"),
      dataIndex: "amount",
      key: "amount",
    },
    {
      width: 60,
      title: "",
      key: "action",
      render: (_: any, payment: Payment) => (
        <TableActions
          payment={payment}
          modal={modal}
          onModalViewOpen={() => {
            setModalType("view");
            isModalOpen(payment.paymentUUID);
          }}
          onModalEditOpen={() => {
            setModalType("edit");
            isModalOpen(payment.paymentUUID);
          }}
          modalType={modalType}
          onCancel={onHandleCancel}
          onEditSuccess={onEditSuccess}
          refetch={refetch}
        />
      ),
    },
  ];

  return (
    <div>
      <Table
        style={{ userSelect: "none" }}
        size={isLab ? "small" : "large"}
        scroll={{
          y: tableScrollHeight,
        }}
        ref={tableRef}
        columns={casesColumns}
        dataSource={payments.map((i) => ({ ...i, key: i.paymentUUID }))}
        pagination={false}
        loading={getPaymentsLoading}
        onRow={(payment) => ({
          onDoubleClick: (e) => {
            e.stopPropagation();
            setModalType("edit");
            isModalOpen(payment.paymentUUID);
          },
        })}
      />
      <div
        ref={paginationRef}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "15px",
        }}
      >
        <Pagination
          currentPage={page}
          pages={pages || 1}
          paginationRange={paginationRange}
          setCurrentPage={setPage}
        />
      </div>
    </div>
  );
};

export default PaymentsTable;
