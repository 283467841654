import { useMutation, useQueryClient } from "@tanstack/react-query";

import { ProductsApi } from "@/api/admin/products";
import { Product } from "@/root/models/product";

const useDeleteProduct = (onError?: (message: string) => void) => {
  const queryClient = useQueryClient();
  const deleteProductMutation = useMutation({
    mutationFn: ProductsApi.delete,
    onError: (error) => onError?.((error as Error).message),
    onSuccess: (_, productUUID) => {
      queryClient.setQueryData(["products"], (prev) => {
        const prevProducts = (prev as Product[]) || [];
        const recursiveProduct = (products: Product[]): Product[] => {
          const newProducts: Product[] = [];
          for (const prevProduct of products) {
            const productCopy = { ...prevProduct };
            if (productCopy.productUUID !== productUUID) {
              if (productCopy.children) {
                productCopy.children = recursiveProduct(productCopy.children);
              }
              newProducts.push(productCopy);
            }
          }
          return newProducts;
        };

        return recursiveProduct(prevProducts);
      });
    },
  });

  const deleteProduct = async (id: string) => {
    await deleteProductMutation.mutateAsync(id);
  };

  const error = (deleteProductMutation.error as Error)?.message as string;

  return { deleteProduct, loading: deleteProductMutation.isLoading, error };
};

export default useDeleteProduct;
