import { ProductParameterType } from "@/root/models/productParameter";
import { Filter } from "@/root/models/filter";
import { t } from "i18next";

export const getParameterValue = (
  type: ProductParameterType,
  value: string,
  findFilterByID: (id: string) => Filter | undefined
): string => {
  if (type.name === "Filter") {
    return findFilterByID(value as string)?.name || value;
  }

  if (type.name === "Boolean") {
    return value === "true" ? t("Так") : t("Ні");
  }

  return value;
};
