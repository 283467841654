import { Space } from "antd";
import logo from "@/assets/patients-portal-images/logo.png";
import ClinicInformation from "../../components/ClinicInformation";

const InvoiceToMailHeader = () => {
  return (
    <Space
      style={{
        width: "100%",
        justifyContent: "space-between",
        marginBottom: "40px",
      }}
    >
      <img src={logo} alt="Logo" style={{ height: "200px" }} />
      <div style={{ textAlign: "end" }}>
        <ClinicInformation />
      </div>
    </Space>
  );
};

export default InvoiceToMailHeader;
