import React, { FC } from "react";

import { getParameterValue } from "@/utils/getParameterValue";
import { CaseFormState } from "@/components/CaseForm/CaseFormContext";
import { Product } from "@/root/models/product";
import useFilters from "@/hooks/useFilters";
import { isParameterFiltered } from "@/utils/FilterParameter";

interface GroupItemProps {
  group: {
    productsParametersGroupUUID: string;
    productsParameters: CaseFormState.ProductsParameter[];
  };
  product: CaseFormState.Job.Product;
  fullProduct?: Product;
  tooth: string;
}

const GroupItem: FC<GroupItemProps> = ({
  group,
  fullProduct,
  product,
  tooth,
}) => {
  const { findFilterByID, filters } = useFilters();

  const fullGroup = fullProduct?.productsParametersGroups.find(
    (fullGroup) =>
      fullGroup.productsParametersGroupUUID ===
      group.productsParametersGroupUUID
  );
  const filterUUID = fullGroup?.productsParameters.find(
    (p) => p.productsParameter.type.name === "Filter"
  )?.productsParameter.productsParameterUUID;
  const filterParameter = group?.productsParameters.find(
    (p) =>
      p.productsParameterUUID === filterUUID &&
      (p.tooth || null) === (product.differentTeethParameters ? tooth : null)
  );

  const filterParameterValue =
    typeof filterParameter?.value === "string"
      ? filterParameter.value
      : undefined;
  return (
    <div
      key={group.productsParametersGroupUUID}
      style={{ marginLeft: 10, marginBottom: 10 }}
    >
      <div style={{ marginLeft: 20 }}>
        {group.productsParameters
          .filter((p) => !p.tooth || p.tooth === tooth)
          .map((parameter) => {
            const fullParameter = fullGroup?.productsParameters.find(
              (fullParameter) =>
                fullParameter.productsParameter.productsParameterUUID ===
                parameter.productsParameterUUID
            );

            if (fullParameter) {
              if (
                isParameterFiltered(fullParameter, filters, {
                  isFilter: !!filterParameter,
                  value: filterParameterValue,
                })
              ) {
                return null;
              }
            }

            return (
              <div key={parameter.productsParameterUUID}>
                {fullParameter?.productsParameter.name}:{" "}
                <span>
                  <span>
                    {fullParameter
                      ? getParameterValue(
                          fullParameter.productsParameter.type,
                          String(parameter.value),
                          findFilterByID
                        )
                      : parameter.value}
                  </span>
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default GroupItem;
