import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, notification, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { User } from "@/root/models/user";
import { CreateUserPayload } from "@/api/admin/users";
import UserForm from "@/components/UserForm";
import useUsers from "@/hooks/useUsers";

const { confirm } = Modal;

interface TableActionsProps {
  user: User;
  modal: string | null;
  onCancel: () => void;
  onEditSuccess: () => void;
  onModalOpen: () => void;
}

const TableActions: FC<TableActionsProps> = ({
  user,
  modal,
  onEditSuccess,
  onCancel,
  onModalOpen,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const onDeleteError = (message: string) => {
    notification.error({
      message: message,
    });
  };

  const {
    deleteUser,
    deleteUserLoading,
    editUser,
    editUserLoading,
    editUserError,
  } = useUsers({ onDeleteError, onSuccess: onEditSuccess });

  const handleEdit = async (values: CreateUserPayload) => {
    editUser(user.userUUID, {
      name: values.name,
      login: values.login,
      password: values.password,
      clientAdmin: values.clientAdmin,
      userTypeUUID: values.userTypeUUID,
      disableAccess: values.disableAccess,
      email: values.email,
    });
  };

  const submit = () => {
    form.submit();
  };

  const onHandleOpen = () => {
    onModalOpen();
  };

  useEffect(() => {
    form.setFieldsValue({
      ...user,
      userTypeUUID: user.userType.userTypeUUID,
    });
  }, [modal]);

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => deleteUser(user.userUUID),
    });
  };

  return (
    <Space className="table-actions" size="middle">
      <Button size="small" icon={<EditOutlined />} onClick={onHandleOpen} />
      <Button
        size="small"
        icon={<DeleteOutlined />}
        loading={deleteUserLoading}
        danger
        onClick={handleDeleteButtonClick}
      />
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={
          <div style={{ marginBottom: 20 }}>
            {t("Редагування співробітника")}
          </div>
        }
        open={modal === user.userUUID}
        onCancel={onCancel}
        onOk={submit}
        okButtonProps={{ loading: editUserLoading }}
      >
        <UserForm form={form} handleSubmit={handleEdit} error={editUserError} />
      </Modal>
    </Space>
  );
};

export default TableActions;
