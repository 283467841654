import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Space, Tree, TreeProps } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { DataNode } from "antd/es/tree";

import styles from "./Parts.module.scss";

import useParts from "@/hooks/useParts";
import { Part } from "@/root/models/part";
import { AddPartModal, PartItem } from "@/modules/Parts/components";
import { Loader } from "@/components/common";

const Parts: FC = () => {
  const { t } = useTranslation();
  const {
    parts,
    addPart,
    deletePart,
    editPart,
    editPartGroup,
    getPartsLoading,
  } = useParts();
  const [addModal, setAddModal] = useState(false);
  const [emptyElementDraggable, setEmptyElementDraggable] = useState(false);

  const parseParts = (parts: Part[]): TreeProps["treeData"] => {
    return parts.map((part) => ({
      key: part.sparePartUUID,
      title: (
        <PartItem
          part={part}
          onDelete={deletePart}
          onEdit={editPart}
          onAdd={addPart}
        />
      ),
      children: part.children ? parseParts(part.children) : [],
    }));
  };

  const handleAddPart = (part: Part) => {
    addPart(part);
    setAddModal(false);
  };

  const findPartByUUID = (
    parts: Part[],
    uuid: string
  ): Part | undefined => {
    let foundPart;
    for (const part of parts) {
      if (part.sparePartUUID === uuid) {
        foundPart = part;
        break;
      } else {
        if (part.children) {
          const candidate = findPartByUUID(part.children, uuid);
          if (candidate) {
            foundPart = candidate;
            break;
          }
        }
      }
    }
    return foundPart;
  };

  const handleDrop: TreeProps["onDrop"] = async ({ node, dragNode }) => {
    const dragItemUUID = dragNode.key as string;

    const part: Part | undefined = findPartByUUID(parts, dragItemUUID);

    if (part) {
      setEmptyElementDraggable(false);
      if ((node.key as string).includes("empty")) {
        await editPartGroup(part, "");
        return;
      }
      const parentSparePartUUID = node.key as string;
      await editPartGroup(part, parentSparePartUUID);
    }
  };

  const allowDrop: TreeProps["allowDrop"] = ({ dropNode }) => {
    // @ts-ignore
    const part: Part = dropNode?.title?.props?.part;
    if (part) {
      return part.isGroup && part.level < 5;
    }

    return true;
  };

  const treeData: DataNode[] = [
    {
      key: "empty-1",
      title: "",
      switcherIcon: <PlusOutlined />,
      style: emptyElementDraggable ? undefined : { pointerEvents: "none" },
    },
    ...(parseParts(parts) as DataNode[]),
    {
      key: "empty-2",
      title: "",
      switcherIcon: <PlusOutlined />,
      style: emptyElementDraggable ? undefined : { pointerEvents: "none" },
    },
  ];

  return (
    <Space direction="vertical">
      <Button size="small" onClick={() => setAddModal(true)}>
        {t("Додати")}
      </Button>
      {getPartsLoading ? (
        <Loader />
      ) : (
        <Tree
          className={styles.tree}
          selectable={false}
          defaultExpandAll
          allowDrop={allowDrop}
          draggable={{
            icon: false,
          }}
          blockNode
          treeData={treeData}
          onDrop={handleDrop}
          onDragStart={() => setEmptyElementDraggable(true)}
          onDragEnd={() => setEmptyElementDraggable(false)}
        />
      )}
      {addModal && (
        <AddPartModal
          open={addModal}
          onCancel={() => setAddModal(false)}
          onAdd={handleAddPart}
        />
      )}
    </Space>
  );
};

export default Parts;
