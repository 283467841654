import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { User } from "@/root/models/user";

export interface CreateUserPayload {
  name: string;
  login: string;
  password: string;
  clientAdmin: boolean;
  userTypeUUID: string;
  disableAccess: boolean;
  email: string;
}

export const UsersApi = {
  getUsers: async (): Promise<User[]> => {
    const data = await makeRequest<User[]>({
      url: apiBaseurl + "/users",
    });
    return data;
  },

  create: async ({
    payload,
  }: {
    payload: CreateUserPayload;
  }): Promise<User> => {
    const data = await makeRequest<User>({
      url: apiBaseurl + "/users",
      method: "post",
      data: { ...payload, email: payload.email || "" },
    });
    return data;
  },

  edit: async ({
    id,
    payload,
  }: {
    id: string;
    payload: CreateUserPayload;
  }): Promise<User> => {
    const data = await makeRequest<User>({
      url: apiBaseurl + `/users/${id}`,
      method: "put",
      data: { ...payload, email: payload.email || "" },
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/users/${id}`,
      method: "delete",
    });
  },
};
