import { useQuery } from "@tanstack/react-query";

import { TeethFormulaNumberingApi } from "@/api/teethFormulaNumbering";

const useTeethFormulaNumberings = () => {
  const {
    data: teethFormulaNumberings,
    isLoading: getNumberingsLoading,
    error: getNumberingsError,
  } = useQuery({
    queryKey: ["teethFormulaNumberings"],
    queryFn: TeethFormulaNumberingApi.getNumberings,
  });

  return {
    teethFormulaNumberings: teethFormulaNumberings || [],
    getNumberingsLoading,
    getNumberingsError,
  };
};

export default useTeethFormulaNumberings;
