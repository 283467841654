import { Form, Select } from "antd";
import { t } from "i18next";

interface PaidSelectProps {
  casePaid: string;
  setCasePaid: (paid: string) => void;
}

const { Option } = Select;

const PaidSelect = ({ casePaid, setCasePaid }: PaidSelectProps) => {
  return (
    <Form.Item name="paidCases" label={t("Оплачено")} initialValue={"No"}>
      <Select
        style={{ width: 200 }}
        listHeight={1000}
        value={casePaid}
        onChange={setCasePaid}
      >
        <Option value={"All"}>All</Option>
        <Option value={"Yes"}>Yes</Option>
        <Option value={"No"}>No</Option>
      </Select>
    </Form.Item>
  );
};

export default PaidSelect;
