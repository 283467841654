import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { ClientPricelist } from "@/root/models/pricelist";

export const CurrentPricelistApi = {
  getPricelist: async ({
    clientUUID,
    pricelistTypeUUID,
  }: {
    clientUUID: string;
    pricelistTypeUUID: string;
  }): Promise<ClientPricelist | {}> => {
    const data = await makeRequest<ClientPricelist | {}>({
      url:
        apiBaseurl +
        `/clients-current-pricelist/${clientUUID}/${pricelistTypeUUID}`,
    });
    return data;
  },
};
