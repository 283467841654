import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { Extra } from "@/root/models/extra";

export interface CreateExtraPayload {
  parentExtraUUID: string;
  name: string;
  isGroup: boolean;
}

export const ExtrasApi = {
  getExtras: async (): Promise<Extra[]> => {
    const data = await makeRequest<Extra[]>({
      url: apiBaseurl + "/extras",
    });
    const parseData = (data: Extra[], parentExtraUUID: string) => {
      return data.map((p) => {
        p.parentExtraUUID = parentExtraUUID;
        if (p.children) {
          p.children = parseData(p.children, p.extraUUID);
        }

        return p;
      });
    };

    return parseData(data, "");
  },

  create: async (payload: CreateExtraPayload): Promise<Extra> => {
    const data = await makeRequest<Extra>({
      url: apiBaseurl + "/extras",
      method: "post",
      data: payload,
    });
    return { ...data, parentExtraUUID: payload.parentExtraUUID };
  },

  edit: async (id: string, payload: CreateExtraPayload): Promise<Extra> => {
    const data = await makeRequest<Extra>({
      url: apiBaseurl + `/extras/${id}`,
      method: "put",
      data: payload,
    });
    return { ...data, parentExtraUUID: payload.parentExtraUUID };
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/extras/${id}`,
      method: "delete",
    });
  },
};
