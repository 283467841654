import { Button, Modal, notification } from "antd";
import { t } from "i18next";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import InvoiceForm from "./InvoiceForm";
import useInvoices from "@/hooks/useInvoices";
import { InvoiceCreatePayloadProps } from "@/api/invoices";

const CreateInvoice = () => {
  const [form] = useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCreateSuccess = () => {
    handleCancel();
    notification.success({
      message: t("Рахунок-фактура був успішно створений"),
    });
  };

  const {
    createInvoice,
    createError,
    createLoading: loading,
  } = useInvoices({ onCreateSuccess });

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!!createError) {
      notification.error({
        message: createError,
      });
    }
  }, [createError]);

  const onSubmit = () => {
    form.submit();
  };

  const invoiceCreate = async (values: InvoiceCreatePayloadProps) => {
    createInvoice({ ...values });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  return (
    <>
      <Button onClick={showModal} style={{ marginBottom: 10 }}>
        {t("Створити рахунок-фактуру")}
      </Button>
      <Modal
        centered
        destroyOnClose={true}
        width={1000}
        open={isModalOpen}
        maskClosable={false}
        title={t("Створити рахунок-фактуру")}
        onCancel={handleCancel}
        okText={t("Зберегти")}
        onOk={onSubmit}
        okButtonProps={{ loading }}
      >
        <InvoiceForm
          handleInvoiceCreate={invoiceCreate}
          form={form}
          invoiceCasesView={[]}
          type="create"
        />
      </Modal>
    </>
  );
};

export default CreateInvoice;
