import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { AdminClient } from "@/root/models/adminClient";

export interface CreateAdminClientPayload {
  name: string;
}

export const AdminClientsApi = {
  getClients: async (): Promise<AdminClient[]> => {
    const data = await makeRequest<AdminClient[]>({
      url: apiBaseurl + "/clients",
    });
    return data;
  },

  create: async (payload: CreateAdminClientPayload): Promise<AdminClient> => {
    const data = await makeRequest<AdminClient>({
      url: apiBaseurl + "/clients",
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async ({
    id,
    payload,
  }: {
    id: string;
    payload: CreateAdminClientPayload;
  }): Promise<AdminClient> => {
    const data = await makeRequest<AdminClient>({
      url: apiBaseurl + `/clients/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/clients/${id}`,
      method: "delete",
    });
  },
};
