import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  CaseStatusColorsApi,
  EditCaseStatusColorPayload,
} from "@/api/admin/caseStatusColors";
import { CaseStatusColor } from "@/root/models/caseStatusColor";

const useCaseStatusColors = ({
  onEditSuccess,
}: {
  onEditSuccess?: () => void;
}) => {
  const queryClient = useQueryClient();
  const {
    data: caseStatusColors,
    isFetching: getCaseStatusColorsLoading,
    error: getCaseStatusColorsError,
  } = useQuery({
    queryKey: ["caseStatusColors"],
    queryFn: CaseStatusColorsApi.getColors,
  });

  const editCaseStatusColorsMutation = useMutation({
    mutationFn: CaseStatusColorsApi.editColors,
    onSuccess: (colors: CaseStatusColor[]) => {
      queryClient.setQueryData(["caseStatusColors"], () => {
        onEditSuccess?.();
        return colors;
      });
    },
  });

  const editCaseStatusColors = (payload: EditCaseStatusColorPayload[]) => {
    editCaseStatusColorsMutation.mutate(payload);
  };

  const editError = (editCaseStatusColorsMutation.error as Error)
    ?.message as string;

  return {
    caseStatusColors: caseStatusColors,
    getCaseStatusColorsLoading,
    editLoading: editCaseStatusColorsMutation.isLoading,
    getCaseStatusColorsError,
    editCaseStatusColors,
    editError,
  };
};

export default useCaseStatusColors;
