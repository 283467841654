import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { useForm } from "antd/es/form/Form";
import { Button, DatePicker, Form, Input, Select, Space } from "antd";

import styles from "./AdvancedTasksSearchForm.module.scss";

import { DATE_FORMAT } from "@/root/consts";
import useCouriers from "@/hooks/useCourier";
import useCaseNumbers from "@/hooks/useCaseNumbers";
import useTaskStatuses from "@/hooks/useTaskStatuses";

export interface AdvancedTaskSearchParameters {
  taskStatusUUID: string[];
  courierUUID: string[];
  cases: string[];
  trackingNumber: string;
  completionDate: [string, string];
  dueDate: [string, string];
}

interface AdvancedCasesSearchFormProps {
  full: boolean;
  handleSubmit: (values: AdvancedTaskSearchParameters) => void;
}

const { RangePicker } = DatePicker;
const { Option } = Select;

const AdvancedTasksSearchForm: FC<AdvancedCasesSearchFormProps> = ({
  full,
  handleSubmit,
}) => {
  const [form] = useForm();
  const { t } = useTranslation();
  const { taskStatuses, getStatusesLoading } = useTaskStatuses();
  const { couriers, getCouriersLoading } = useCouriers();
  const { caseNumbers, getCaseNumbersLoading } = useCaseNumbers();

  return (
    <Form
      className={styles.form}
      requiredMark={false}
      form={form}
      onFinish={handleSubmit}
    >
      {full && (
        <Space
          size="large"
          style={{
            flexWrap: "wrap",
            gap: 10,
          }}
        >
          <Form.Item name="taskStatusUUID" label={t("Статус завдання")}>
            <Select
              mode="multiple"
              listHeight={1000}
              loading={getStatusesLoading}
              allowClear
              style={{ minWidth: 150, width: "fit-content" }}
            >
              {taskStatuses.map((status) => (
                <Option
                  key={status.taskStatusUUID}
                  value={status.taskStatusUUID}
                >
                  {status.name || " "}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="dueDate" label={t("Дата завершення")}>
            <RangePicker format={DATE_FORMAT} />
          </Form.Item>
          <Form.Item name="completionDate" label={t("Дата виконання")}>
            <RangePicker format={DATE_FORMAT} />
          </Form.Item>
          <Form.Item name="courierUUID" label={t("Кур'єр")}>
            <Select
              style={{ width: 200 }}
              mode="multiple"
              loading={getCouriersLoading}
              listHeight={1000}
              allowClear
            >
              {couriers?.map((courier) => (
                <Option key={courier.courierUUID} value={courier.courierUUID}>
                  {courier.name || " "}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="cases" label={t("Замовлення")}>
            <Select
              style={{ width: 200 }}
              mode="multiple"
              loading={getCaseNumbersLoading}
              listHeight={1000}
              allowClear
              showSearch
              optionFilterProp="children"
            >
              {caseNumbers?.map((caseNumber) => (
                <Option key={caseNumber.caseUUID} value={caseNumber.caseUUID}>
                  {caseNumber.caseNumber || " "}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="trackingNumber" label={t("Номер відстеження")}>
            <Input />
          </Form.Item>
          <div>
            <Button htmlType="submit">{t("Шукати")}</Button>
          </div>
        </Space>
      )}
    </Form>
  );
};

export default AdvancedTasksSearchForm;
