import { FocusEvent, MutableRefObject } from "react";
import styles from "./Product.module.scss";
import { Button, Dropdown, Input, InputNumber, Space } from "antd";
import { Block } from "@/components/common";
import { ChooseProduct } from "@/components/CaseForm/components";
import {
  CloseCircleOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { t } from "i18next";
import Tooth from "./Tooth";
import { Product } from "@/root/models/product";
import { CaseFormState } from "@/components/CaseForm/CaseFormContext";

interface ProductFormProps {
  ref?: MutableRefObject<null>;
  isLock?: boolean;
  isParams?: boolean;
  chooseProductOpen?: boolean;
  productsSearchValue: string;
  handleProductSelect?: (selected: {
    product: Product;
    teethColor: string | undefined;
  }) => void;
  setChooseProductOpen?: (choose: boolean) => void;
  setProductsSearchValue?: (value: string) => void;
  handleInputFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  product: CaseFormState.Job.Product | undefined;
  isActive?: boolean;
  handleQuantityChange?: (quantity: number | null) => void;
  handleDeleteToothClick?: (productUUID: string, tooth: string) => void;
  handleDeleteClick?: () => void;
}

const ProductCreateForm = ({
  ref,
  isLock,
  isParams,
  chooseProductOpen,
  productsSearchValue,
  handleProductSelect,
  setChooseProductOpen,
  setProductsSearchValue,
  handleInputFocus,
  product,
  isActive,
  handleQuantityChange,
  handleDeleteToothClick,
  handleDeleteClick,
}: ProductFormProps) => {
  return (
    <>
      <div className={styles.container}>
        <Space className={styles.jobInputContainer}>
          <Dropdown
            disabled={(isParams || isLock) && true}
            open={chooseProductOpen && chooseProductOpen}
            overlayStyle={styles.dropdown}
            destroyPopupOnHide
            dropdownRender={() => (
              <Block className={styles.productDropdownContent}>
                <ChooseProduct
                  productsSearchValue={productsSearchValue}
                  onSelect={handleProductSelect as () => void}
                />
                <Button
                  className={styles.close_btn}
                  onClick={() => setChooseProductOpen?.(false)}
                >
                  <CloseOutlined />
                </Button>
              </Block>
            )}
          >
            <Input
              allowClear={{
                clearIcon: (
                  <CloseCircleOutlined
                    onClick={() => setChooseProductOpen?.(true)}
                  />
                ),
              }}
              placeholder={t("Оберіть виріб, або клікніть на зуб") as string}
              className={styles.productName}
              value={productsSearchValue}
              onChange={(e) => setProductsSearchValue?.(e.target.value)}
              onFocus={handleInputFocus}
            />
          </Dropdown>
        </Space>
        {product && (
          <Space>
            <span style={{ whiteSpace: "nowrap" }}>{t("к-сть")}:</span>
            <InputNumber
              disabled={(isLock || isParams) && true}
              style={{ width: 50 }}
              type="number"
              size="small"
              value={product.quantity}
              onChange={handleQuantityChange}
              onClick={(e) => e.stopPropagation()}
            />
            <Button
              size="small"
              icon={<DeleteOutlined />}
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteClick?.();
              }}
              disabled={isParams && true}
            />
          </Space>
        )}
      </div>
      {!!product?.teeth?.length && (
        <div
          style={{
            margin: "4px 0",
            pointerEvents:
              !isActive || (isActive && isLock) || isParams
                ? "none"
                : undefined,
          }}
        >
          {t("Зуби")}:{" "}
          {product.teeth.map((tooth, index) => (
            <Tooth
              key={tooth}
              tooth={tooth}
              isLast={product!.teeth!.length - 1 === index}
              handleDeleteClick={() =>
                handleDeleteToothClick?.(product!.jobProductUUID, tooth)
              }
            />
          ))}
        </div>
      )}
    </>
  );
};

export default ProductCreateForm;
