import { apiBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";
import { Task } from "@/root/models/task";
import { ResponseDataWithPagination } from "@/api/types";

export interface CreateTaskPayload {
  dueDate: string;
  completionDate: string | null;
  description: string;
  responsibleUUID: string;
  clientUUID: string;
  cases: { caseUUID: string; use: boolean }[];
  caseStatusUUID: string;
  taskStatusUUID: string;
  trackingNumber: string;
  attachments: Task.Attachment[];
}

export interface EditTaskPayload {
  dueDate: string;
  completionDate: string | null;
  description: string;
  responsibleUUID: string;
  cases: { caseUUID: string; use: boolean }[];
  taskStatusUUID: string;
  trackingNumber: string;
  caseStatusUUID?: string;
  attachments: Task.Attachment[];
}

export const TasksApi = {
  getTasks: async (
    caseUUID?: string,
    page?: number,
    pageLimit?: number,
    taskStatusUUID?: string,
    courierUUID?: string,
    taskNumber?: string,
    dueDate?: string,
    cases?: string,
    trackingNumber?: string,
    completionDate?: string,
    sort?: string
  ): Promise<ResponseDataWithPagination<Task[]>> => {
    const data = await makeRequest<Task[], true>(
      {
        url: apiBaseurl + "/tasks",
        params: pageLimit
          ? {
              caseUUID,
              page,
              taskStatusUUID,
              courierUUID,
              taskNumber,
              dueDate,
              cases,
              trackingNumber,
              completionDate,
              sort,
              "page-limit": pageLimit,
            }
          : {
              caseUUID,
              taskNumber,
            },
      },
      { pagination: true }
    );
    return data;
  },

  create: async (payload: CreateTaskPayload): Promise<Task> => {
    const data = await makeRequest<Task>({
      url: apiBaseurl + "/tasks",
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async ({
    id,
    payload,
  }: {
    id: string;
    payload: CreateTaskPayload | EditTaskPayload;
  }): Promise<Task> => {
    const data = await makeRequest<Task>({
      url: apiBaseurl + `/tasks/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/tasks/${id}`,
      method: "delete",
    });
  },
};
