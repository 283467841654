import { UnpaidCasesApi } from "@/api/unpaidCases";
import { Invoice } from "@/root/models/invoice";
import { FormInstance } from "antd";
import { useEffect, useState } from "react";

const useInvoiсesTable = (
  invoiceCasesView: Invoice.InvoiceCase[],
  chosenClient: string,
  form: FormInstance
) => {
  const [isBtnAddClicked, setIsBtnAddClicked] = useState(false);
  const [isBtnRemoveClicked, setIsBtnRemoveClicked] = useState(false);

  //First table

  //This state is needed only to update cases in table on button click
  const [invoiceCases, setInvoiceCases] =
    useState<Invoice.InvoiceCase[]>(invoiceCasesView);

  const [selectedInvoiceCases, setSelectedInvoiceCases] = useState<
    Invoice.InvoiceCase[]
  >([]);

  //Second table

  //This state is needed only to update cases in table on button click
  const [unpaidInvoiceCases, setUnpaidInvoiceCases] =
    useState<Invoice.InvoiceCase[]>();

  const [selectedUnpaidInvoiceCases, setSelectedUnpaidInvoiceCases] = useState<
    Invoice.InvoiceCase[]
  >([]);

  //Find unpaid cases for certain clinic if Dental Clinic field is filled
  useEffect(() => {
    (async () => {
      try {
        if (chosenClient) {
          const unpaidCases = await UnpaidCasesApi.getUnpaidCases(chosenClient);
          //If case is already added to invoice, remove it from unpaid cases list
          if (
            unpaidCases?.some(
              (elem) =>
                !invoiceCases.some((item) => item.rowUUID === elem.rowUUID)
            )
          ) {
            const casesUnpaid = unpaidCases?.filter(
              (elem) =>
                !invoiceCases.some((item) => item.rowUUID === elem.rowUUID)
            );
            setUnpaidInvoiceCases(casesUnpaid);
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [chosenClient]);

  //Add cases to invoice table
  const unpdaidCasesAdd = () => {
    if (!!unpaidInvoiceCases?.length) {
      setInvoiceCases(invoiceCases?.concat(selectedUnpaidInvoiceCases));
      setUnpaidInvoiceCases(
        unpaidInvoiceCases?.filter(
          (elem) => !selectedUnpaidInvoiceCases.includes(elem)
        )
      );
      form.setFieldValue(
        "amount",
        invoiceCases.reduce((prev, cur) => prev + cur.sum, 0)
      );
      setSelectedUnpaidInvoiceCases([]);
      setIsBtnAddClicked(true);
    }
  };

  const invoiceCasesRemove = () => {
    if (!!invoiceCases?.length) {
      setUnpaidInvoiceCases(unpaidInvoiceCases?.concat(selectedInvoiceCases));
      setInvoiceCases(
        invoiceCases?.filter((elem) => !selectedInvoiceCases?.includes(elem))
      );

      setSelectedInvoiceCases([]);
      setIsBtnRemoveClicked(true);
    }
  };

  return {
    //First table
    invoiceCases,
    selectedInvoiceCases,
    setSelectedInvoiceCases,

    //Second table
    unpaidInvoiceCases,
    selectedUnpaidInvoiceCases,
    setSelectedUnpaidInvoiceCases,

    //Cases add
    unpdaidCasesAdd,
    //Cases remove
    invoiceCasesRemove,
  };
};

export default useInvoiсesTable;
