import styles from "../InvoiceAccountForCurrentOrder.module.scss";

const formHeader = [
  { id: 1, mark: "", vissible: false },
  { id: 2, mark: "Excellent", vissible: true },
  { id: 3, mark: "Good", vissible: true },
  { id: 4, mark: "Avg", vissible: true },
  { id: 5, mark: "Fair", vissible: true },
  { id: 6, mark: "Poor", vissible: true },
];

const formContent = [
  { id: 1, criterion: "Overall Design" },
  { id: 2, criterion: "Overall Fit" },
  { id: 3, criterion: "Retention" },
  { id: 4, criterion: "Rests" },
  { id: 5, criterion: "Clasps" },
  {
    id: 6,
    criterion: `Placement Maj. 
  Connector`,
  },
  { id: 7, criterion: "Finish" },
  { id: 8, criterion: "Service" },
];

const SeviceEvaluationFormTable = () => {
  const renderElementMultipleTimes = (count: number) => {
    const elements = [];
    for (let i = 0; i < count; i++) {
      elements.push(
        <td
          key={i}
          className={
            i === 0
              ? styles.table_col1
              : i === 1
              ? styles.table_col2
              : i === 2 || i === 3 || i === 4
              ? styles.table_col
              : ""
          }
        >
          <div className={styles.checkbox}></div>
        </td>
      );
    }
    return elements;
  };

  return (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          {formHeader.map((elem) => (
            <th
              key={elem.id}
              className={!elem.vissible ? styles.invis_elem : ""}
            >
              <div>{elem.mark}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {formContent.map((elem) => (
          <tr key={elem.id}>
            <td style={{ textAlign: "end" }}>
              <div>{elem.criterion}</div>
            </td>
            {renderElementMultipleTimes(5)}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SeviceEvaluationFormTable;
