import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { ClientPricelist } from "@/root/models/pricelist";

export interface CreateClientPricelistPayload {
  activationDate: string;
  description: string;
  clientUUID: string;
  list: {
    itemUUID: string;
    price: number;
  }[];
}

export const ClientPricelistsApi = {
  getPricelists: async (): Promise<ClientPricelist[]> => {
    const data = await makeRequest<ClientPricelist[]>({
      url: apiBaseurl + "/clients-pricelists",
    });
    return data;
  },

  create: async (payload: CreateClientPricelistPayload): Promise<ClientPricelist> => {
    const data = await makeRequest<ClientPricelist>({
      url: apiBaseurl + "/clients-pricelists",
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async (
    id: string,
    payload: CreateClientPricelistPayload
  ): Promise<ClientPricelist> => {
    const data = await makeRequest<ClientPricelist>({
      url: apiBaseurl + `/clients-pricelists/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/clients-pricelists/${id}`,
      method: "delete",
    });
  },
};
