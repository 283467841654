import React, { CSSProperties, FC, ReactNode, useRef, MouseEvent } from "react";
import classNames from "classnames";

import styles from "./Block.module.scss";

interface BlockProps {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

const Block: FC<BlockProps> = ({ children, style, className, onClick }) => {
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = (e: MouseEvent) => {
    if (ref.current?.contains(e.target as Node)) {
      onClick?.();
    }
  };

  return (
    <div
      ref={ref}
      className={classNames(styles.block, className)}
      style={style}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

export default Block;
