import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { Pan } from "@/root/models/pan";

export interface CreatePanPayload {
  parentPanUUID: string;
  name: string;
  isGroup: boolean;
}

export const PansApi = {
  getPans: async (): Promise<Pan[]> => {
    const data = await makeRequest<Pan[]>({
      url: apiBaseurl + "/pans",
    });
    return data;
  },

  create: async (payload: CreatePanPayload): Promise<Pan> => {
    const data = await makeRequest<Pan>({
      url: apiBaseurl + "/pans",
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async ({
    id,
    payload,
  }: {
    id: string;
    payload: CreatePanPayload;
  }): Promise<Pan> => {
    const data = await makeRequest<Pan>({
      url: apiBaseurl + `/pans/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/pans/${id}`,
      method: "delete",
    });
  },
};
