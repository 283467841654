import { Block } from "@/components/common";
import { Space, Typography } from "antd";
import { t } from "i18next";
import SearchPayment from "./components/SearchPayment";
import { useState } from "react";
import { usePayments } from "@/hooks/usePayments";
import CreatePayment from "./components/CreatePayment";
import PaymentsTable from "./components/PaymentsTable";

const { Title } = Typography;

export interface PaymentSearchParamsGroup {
  adminClients: string;
  date: string;
}

const PaymentsPanel = () => {
  const [searchParams, setSearchParams] = useState({
    adminClients: "",
    date: "",
  });

  const [page, setPage] = useState(1);

  const { payments, pages, getPaymentsLoading, refetch } = usePayments({
    page,
    clientUUID: searchParams.adminClients || undefined,
    date: searchParams.date || undefined,
  });

  const onHandleSearch = ({
    adminClients = "",
    date = "",
  }: PaymentSearchParamsGroup) => {
    setSearchParams({
      adminClients,
      date,
    });
  };

  return (
    <Block>
      <Space
        direction="vertical"
        style={{ justifyContent: "center", width: "50%" }}
      >
        <Title level={3} style={{ textAlign: "center" }}>
          {t("Оплати")}
        </Title>

        <SearchPayment onSearch={onHandleSearch} />

        <CreatePayment />
      </Space>

      <PaymentsTable
        tabHeight={136}
        payments={payments}
        pages={pages}
        getPaymentsLoading={getPaymentsLoading}
        page={page}
        setPage={setPage}
        refetch={refetch}
      />
    </Block>
  );
};

export default PaymentsPanel;
