import { useMutation, useQueryClient } from "@tanstack/react-query";

import { PansApi } from "@/api/admin/pans";
import { Pan } from "@/root/models/pan";

const useDeletePan = (onError?: (message: string) => void) => {
  const queryClient = useQueryClient();
  const deletePanMutation = useMutation({
    mutationFn: PansApi.delete,
    onError: (error) => onError?.((error as Error).message),
    onSuccess: (_, panUUID) => {
      queryClient.setQueryData(["pans"], (prev: Pan[] | undefined) =>
        (prev || []).filter((p) => p.panUUID !== panUUID)
      );
    },
  });

  const deletePan = async (id: string) => {
    await deletePanMutation.mutateAsync(id);
  };

  const error = (deletePanMutation.error as Error)?.message as string;

  return { deletePan, loading: deletePanMutation.isLoading, error };
};

export default useDeletePan;
