import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, notification, Space } from "antd";
import { useForm } from "antd/es/form/Form";

import styles from "./Product.module.scss";

import { Product } from "@/root/models/product";
import { CreateProductPayload } from "@/api/admin/products";
import { ProductForm } from "@/components";
import { AddProductModal } from "@/modules/Products/components";
import useEditProduct from "@/modules/Products/components/Product/useEditProduct";
import useDeleteProduct from "@/modules/Products/components/Product/useDeleteProduct";
import useProducts from "@/hooks/useProducts";

interface ProductProps {
  product: Product;
  price?: number;
}

const { confirm } = Modal;

const ProductItem: FC<ProductProps> = ({ product, price }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const { getParameterGroup } = useProducts();

  const onError = (message: string) => {
    notification.error({
      message: message,
    });
  };

  const onEditSuccess = () => {
    form.resetFields();
    setEditModal(false);
  };

  const {
    editProduct,
    loading: editLoading,
    error: editError,
  } = useEditProduct(onEditSuccess);
  const { deleteProduct, loading: deleteLoading } = useDeleteProduct(onError);

  const handleEdit = async (values: CreateProductPayload) => {
    editProduct(product.productUUID, {
      ...values,
      parentProductUUID: product.parentProductUUID,
      productsParametersGroups: values.productsParametersGroups || [],
      color: values.color || "",
    });
  };

  const handleDelete = async () => {
    await deleteProduct(product.productUUID);
  };

  const editSubmit = () => {
    form.submit();
  };

  const openEditModal = () => {
    form.setFieldsValue({
      name: product.name,
      isGroup: product.isGroup,
      isPoint: product.isPoint,
      productsParametersGroups: product.productsParametersGroups?.map(
        (group) => group.productsParametersGroupUUID
      ),
      color: product.color,
      priceFormulaUUID: product.priceFormula?.priceFormulaUUID,
      warrantyPeriod: product.warrantyPeriod,
    });
    setEditModal(true);
  };

  const openAddModal = () => {
    setAddModal(true);
  };

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  const handleAddProduct = () => {
    setAddModal(false);
  };

  return (
    <div className={styles.product}>
      <Space>
        <div className={styles.colorContainer}>
          <div
            style={{
              background: product.color
                ? product.color
                : getParameterGroup(product).color,
              width: product.isGroup ? 20 : 10,
              height: product.isGroup ? 20 : 10,
              borderRadius: 20,
            }}
          />
        </div>
        <span>{product.name}</span>
      </Space>
      <div>
        <span>{price}</span>
        <Space className="tree-actions">
          {product.isGroup && product.level < 5 && (
            <Button size="small" onClick={openAddModal}>
              {t("Додати")}
            </Button>
          )}
          <Button size="small" onClick={openEditModal}>
            {t("Редагувати")}
          </Button>
          <Button
            loading={deleteLoading}
            size="small"
            onClick={handleDeleteButtonClick}
          >
            {t("Видалити")}
          </Button>
        </Space>
      </div>
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={
          <div style={{ marginBottom: 20 }}>{t("Редагування виробу")}</div>
        }
        open={editModal}
        onCancel={() => setEditModal(false)}
        onOk={editSubmit}
        okButtonProps={{ loading: editLoading }}
      >
        <ProductForm
          form={form}
          handleSubmit={handleEdit}
          error={editError}
          groupDisabled={product.level === 4}
        />
      </Modal>
      {product.isGroup && product.level < 5 && (
        <AddProductModal
          product={product}
          open={addModal}
          onCancel={() => setAddModal(false)}
          onAdd={handleAddProduct}
        />
      )}
    </div>
  );
};

export default ProductItem;
