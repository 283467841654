import { useQuery } from "@tanstack/react-query";

import { TaskStatusesApi } from "@/api/taskStatuses";

const useTaskStatuses = () => {
  const {
    data: taskStatuses,
    isLoading: getStatusesLoading,
    error: getStatusesError,
  } = useQuery({
    queryKey: ["taskStatuses"],
    queryFn: TaskStatusesApi.getStatuses,
  });

  return {
    taskStatuses: taskStatuses || [],
    getStatusesLoading,
    getStatusesError,
  };
};

export default useTaskStatuses;
