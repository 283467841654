import { FC } from "react";

import PricelistList, {
  PricelistItem,
} from "@/components/PricelistForm/componenets/PricelistList";
import { Extra } from "@/root/models/extra";
import useExtras from "@/hooks/useExtras";

interface ExtraPricesProps {
  extraPrices: { itemUUID: string; price: number }[];
  setExtraPrices: (extraPrices: any[]) => void;
  presentation?: boolean;
}

const ExtraPricelist: FC<ExtraPricesProps> = ({
  extraPrices,
  setExtraPrices,
  presentation,
}) => {
  const { extras, getExtrasLoading } = useExtras();

  const parseExtras = (extras: Extra[]): PricelistItem[] => {
    return extras.map((e) => ({
      ...e,
      itemUUID: e.extraUUID,
      children: e.children ? parseExtras(e.children) : undefined,
    }));
  };

  return (
    <PricelistList
      items={parseExtras(extras)}
      getItemsLoading={getExtrasLoading}
      itemPrices={extraPrices}
      setItemPrices={setExtraPrices}
      presentation={presentation}
    />
  );
};

export default ExtraPricelist;
