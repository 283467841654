import { useQuery } from "@tanstack/react-query";

import { UserTypesApi } from "@/api/userTypes";

const useUserTypes = () => {
  const {
    data: userTypes,
    isLoading: getUserTypesLoading,
    error: getUserTypesError,
  } = useQuery({
    queryKey: ["userTypes"],
    queryFn: UserTypesApi.getUserTypes,
  });

  return {
    userTypes: userTypes || [],
    getUserTypesLoading,
    getUserTypesError,
  };
};

export default useUserTypes;
