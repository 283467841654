import { useQuery } from "@tanstack/react-query";
import { FilesApi } from "@/api/files";

const useFile = (taskUUID: string, fileUUID: string) => {
  const {
    data: file,
    isLoading: getFileLoading,
    error: getFileError,
  } = useQuery({
    queryKey: ["file", taskUUID, fileUUID],
    queryFn: () => FilesApi.getTaskFile(taskUUID, fileUUID),
  });

  return {
    file,
    getFileLoading,
    getFileError,
  };
};

export default useFile;
