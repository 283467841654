import { useQuery } from "@tanstack/react-query";

import { TeethFormulaTypesApi } from "@/api/teethFormulaType";

const useTeethFormulaTypes = () => {
  const {
    data: teethFormulaTypes,
    isLoading: getTypesLoading,
    error: getTypesError,
  } = useQuery({
    queryKey: ["teethFormulaTypes"],
    queryFn: TeethFormulaTypesApi.getTypes,
  });

  return {
    teethFormulaTypes: teethFormulaTypes || [],
    getTypesLoading,
    getTypesError,
  };
};

export default useTeethFormulaTypes;
