import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CreateFilterPayload, FiltersApi } from "@/api/admin/filters";
import { Filter } from "@/root/models/filter";

const useAddFilter = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const createFilterMutation = useMutation({
    mutationFn: FiltersApi.create,
    onSuccess: (filter) => {
      queryClient.setQueryData(["filters"], (prev) => {
        const prevFilters = (prev as Filter[]) || [];
        if (filter.level === 1) {
          return [...prevFilters, filter];
        } else {
          const recursiveAdd = (filters: Filter[]): Filter[] => {
            return filters.map((prevFilter) => {
              const filterCopy = { ...prevFilter };
              if (filterCopy.filterUUID === filter.parentFilterUUID) {
                if (filterCopy.children) {
                  filterCopy.children = [...filterCopy.children, filter];
                } else {
                  filterCopy.children = [filter];
                }
              } else {
                if (filterCopy.children) {
                  filterCopy.children = recursiveAdd(filterCopy.children);
                }
              }
              return filterCopy;
            });
          };

          return recursiveAdd(prevFilters);
        }
      });
      onSuccess();
    },
  });

  const addFilter = (payload: CreateFilterPayload) => {
    createFilterMutation.mutate(payload);
  };

  const error = (createFilterMutation.error as Error)?.message as string;

  return { addFilter, loading: createFilterMutation.isLoading, error };
};

export default useAddFilter;
