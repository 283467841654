import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CreateFilterPayload, FiltersApi } from "@/api/admin/filters";
import { Filter } from "@/root/models/filter";

const useEditFilter = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const editFilterMutation = useMutation({
    mutationFn: FiltersApi.edit,
    onSuccess: (filter) => {
      queryClient.setQueryData(["filters"], (prev) => {
        const prevFilters = (prev as Filter[]) || [];
        const recursiveEdit = (filters: Filter[]): Filter[] => {
          return filters.map((prevFilter) => {
            const filterCopy = { ...prevFilter };
            if (filterCopy.filterUUID === filter.filterUUID) {
              return filter;
            } else {
              if (filterCopy.children) {
                filterCopy.children = recursiveEdit(filterCopy.children);
              }
              return filterCopy;
            }
          });
        };

        return recursiveEdit(prevFilters);
      });
      onSuccess();
    },
  });

  const editFilter = (id: string, payload: CreateFilterPayload) => {
    editFilterMutation.mutate({ id, payload });
  };

  const error = (editFilterMutation.error as Error)?.message as string;

  return { editFilter, loading: editFilterMutation.isLoading, error };
};

export default useEditFilter;
