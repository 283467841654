import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { EventsApi } from "@/api/eventsApi";
import { EventTypes } from "@/root/models/event";
import { ResponseDataWithPagination } from "@/api/types";

const useEvents = ({
  page,
  date,
  mainEntry,
  objectType,
}: {
  page: number;
  date?: string;
  objectType?: "case" | "task";
  mainEntry?: boolean;
}) => {
  const queryClient = useQueryClient();
  const {
    data: events,
    isLoading: getEventsLoading,
    error: getEventsError,
  } = useQuery({
    queryKey: ["events", page, date, mainEntry, objectType],
    queryFn: () =>
      EventsApi.getEvents({
        "page-limit": 50,
        page,
        date,
        mainEntry,
        objectType,
      }),
  });

  const refetchMutation = useMutation({
    mutationFn: EventsApi.getEvents,
    onSuccess: (types) => {
      queryClient.setQueryData(
        ["events", page, date, mainEntry, objectType],
        (prev: ResponseDataWithPagination<EventTypes[]> | undefined) => {
          if (!prev) {
            return {
              data: [],
              page: 1,
              pages: 1,
            };
          }

          return {
            data: [...prev.data, ...types.data],
            page: types.page,
            pages: types.pages,
          };
        }
      );
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const universalTime = events?.data.at(-1)?.universalTime;

      if (!universalTime) {
        return;
      }

      refetchMutation.mutate({
        "page-limit": 50,
        page,
        date,
        mainEntry,
        objectType,
        universalTime,
      });
    }, 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [events]);

  return {
    events: events?.data,
    pages: events?.pages,
    getEventsLoading,
    getEventsError,
  };
};

export default useEvents;
