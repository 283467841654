import { CustomCasesTable } from "@/components";
import { Invoice } from "@/root/models/invoice";
import Table, { ColumnsType } from "antd/es/table";
import { t } from "i18next";

interface CustomCasesTableProps {
  tableInstancesArray: Invoice[] | undefined;
  onClick: () => void;
  btnLabel: string;
  selectedInstances: Invoice.InvoiceCase[];
  setSelectedInstances: (cases: Invoice.InvoiceCase[]) => void;
  type?: string;
}

const CustomInvoicesTable = ({
  tableInstancesArray,
  selectedInstances,
  setSelectedInstances,
  onClick,
  btnLabel,
  type,
}: CustomCasesTableProps) => {
  const invoicesColumns: ColumnsType<Invoice> = [
    {
      title: t("Рахунок-фактура №"),
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      render: (_: any, instance) => <span>{instance.invoiceNumber}</span>,
    },
    {
      title: t("Повна сума"),
      dataIndex: "amount",
      key: "amount",
      render: (_: any, instance) => <span>{instance.amount}</span>,
    },
    {
      title: t("Кейси рахунків-фактур"),
      dataIndex: "cases",
      key: "cases",
      render: (instance: Invoice["cases"], invoice) => (
        <CustomCasesTable
          tableInstancesArray={instance.map((item) => ({
            ...item,
            invoices: [
              {
                invoiceUUID: invoice.invoiceUUID,
                invoiceNumber: invoice.invoiceNumber,
                paid: invoice.paid,
              },
            ],
          }))}
          selectedInstances={selectedInstances}
          setSelectedInstances={setSelectedInstances}
          onClick={onClick}
          btnLabel={btnLabel}
          type={type}
          tableWithoutBorders={true}
        />
      ),
    },
  ];

  return (
    <Table
      columns={invoicesColumns}
      pagination={false}
      dataSource={tableInstancesArray?.map((c) => ({
        ...c,
        key: c.invoiceUUID,
      }))}
    />
  );
};

export default CustomInvoicesTable;
