import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, notification, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

import { ClientEmployee } from "@/root/models/clientEmployee";
import { CreateClientEmployeePayload } from "@/api/client/employees";
import { ClientEmployeeForm } from "@/components";
import useClientEmployees from "@/hooks/useClientEmployees";

const { confirm } = Modal;

interface TableActionsProps {
  clientEmployee: ClientEmployee;
  clientUUID?: string;
  modal: string | null;
  onCancel: () => void;
  onEditSuccess: () => void;
  onModalOpen: () => void;
}

const TableActions: FC<TableActionsProps> = ({
  clientEmployee,
  clientUUID,
  modal,
  onEditSuccess,
  onCancel,
  onModalOpen,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const onDeleteError = (message: string) => {
    notification.error({
      message,
    });
  };

  const {
    deleteClientEmployee,
    deleteLoading,
    editClientEmployee,
    editLoading,
    editError,
  } = useClientEmployees({ clientUUID, onDeleteError, onEditSuccess });

  const handleDelete = async () => {
    await deleteClientEmployee(clientEmployee.clientsEmployeeUUID);
  };

  const handleEdit = async (values: CreateClientEmployeePayload) => {
    editClientEmployee(clientEmployee.clientsEmployeeUUID, {
      ...values,
      clientUUID,
      disableAccess: false,
    });
  };

  const submit = () => {
    form.submit();
  };

  const onHandleOpen = () => {
    onModalOpen();
  };

  useEffect(() => {
    if (modal) {
      form.setFieldsValue({
        ...clientEmployee,
        userTypeUUID: clientEmployee.userType.userTypeUUID,
      });
    }
  }, [modal]);

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  return (
    <Space className="table-actions" size="middle">
      <Button size="small" icon={<EditOutlined />} onClick={onHandleOpen} />
      <Button
        size="small"
        icon={<DeleteOutlined />}
        loading={deleteLoading}
        danger
        onClick={handleDeleteButtonClick}
      />
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={
          <div style={{ marginBottom: 20 }}>
            {t("Редагування співробітника")}
          </div>
        }
        open={modal === clientEmployee.clientsEmployeeUUID}
        onCancel={onCancel}
        onOk={submit}
        okButtonProps={{ loading: editLoading }}
      >
        <ClientEmployeeForm
          form={form}
          handleSubmit={handleEdit}
          error={editError}
        />
      </Modal>
    </Space>
  );
};

export default TableActions;
