import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Checkbox, Input, InputNumber, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import styles from "./Product.module.scss";

import { Block } from "@/components/common";

import "animate.css";

const SkeletonProduct: FC = () => {
  const { t } = useTranslation();

  return (
    <Block
      style={{
        background: "rgb(251, 251, 251)",
        cursor: "pointer",
        opacity: "0.7",
      }}
      className="animate__animated animate__fadeIn"
    >
      <div className={styles.container}>
        <Space className={styles.jobInputContainer}>
          <Input
            style={{
              pointerEvents: "none",
              backgroundColor: "#f5f5f5",
            }}
          />
        </Space>

        <Space>
          <span style={{ color: "rgba(0, 0, 0, 0.25)", whiteSpace: "nowrap" }}>
            {t("к-сть")}:
          </span>
          <InputNumber
            style={{
              width: 50,
              backgroundColor: "#f5f5f5",
              pointerEvents: "none",
            }}
            type="number"
            size="small"
            value={undefined}
          />
          <Button
            size="small"
            icon={<DeleteOutlined />}
            style={{
              pointerEvents: "none",
            }}
          />
        </Space>
      </div>

      <div>
        <Checkbox
          style={{
            color: "#c0c0c0",
            pointerEvents: "none",
            marginTop: 5,
          }}
        >
          {t("Комбінований виріб")}
        </Checkbox>
      </div>
    </Block>
  );
};

export default SkeletonProduct;
