import { createSlice } from "@reduxjs/toolkit";

export const teethFormulaSelectSlice = createSlice({
  name: "teethSelect",
  initialState: {
    isSelect: false,
    tabKey: "details",
  },
  reducers: {
    setIsSelect: (state, action) => {
      state.isSelect = action.payload;
    },
    setTabKeyLeft: (state, action) => {
      state.tabKey = action.payload;
    },
  },
});

export const { setIsSelect, setTabKeyLeft } = teethFormulaSelectSlice.actions;

export default teethFormulaSelectSlice.reducer;
