import { useEffect, useState } from "react";

import { Extra } from "@/root/models/extra";
import { ExtrasApi } from "@/api/admin/extras";

const useExtras = () => {
  const [extras, setExtras] = useState<Extra[]>([]);
  const [getExtrasLoading, setGetExtrasLoading] = useState(true);
  const [getExtrasError, setGetExtrasError] = useState("");

  useEffect(() => {
    (async () => {
      try {
        setGetExtrasLoading(true);
        const extras = await ExtrasApi.getExtras();
        setExtras(extras);
      } catch (e: any) {
        setGetExtrasError(e.message);
      } finally {
        setGetExtrasLoading(false);
      }
    })();
  }, []);

  const findExtraByID = (id: string) => {
    const recursiveSearch = (extras: Extra[]) => {
      let extra: Extra | undefined;
      for (const candidate of extras) {
        if (candidate.extraUUID !== id) {
          if (candidate.children) {
            const foundExtra = recursiveSearch(candidate.children);
            if (foundExtra) {
              extra = foundExtra;
              break;
            }
          }
        } else {
          extra = candidate;
          break;
        }
      }
      return extra;
    };

    return recursiveSearch(extras);
  };

  const addExtra = (extra: Extra) => {
    if (extra.level === 1) {
      setExtras((prev) => [...prev, extra]);
    } else {
      const recursiveAdd = (extras: Extra[]): Extra[] => {
        return extras.map((prevExtra) => {
          if (prevExtra.extraUUID === extra.parentExtraUUID) {
            if (prevExtra.children) {
              prevExtra.children = [...prevExtra.children, extra];
            } else {
              prevExtra.children = [extra];
            }
          } else {
            if (prevExtra.children) {
              prevExtra.children = recursiveAdd(prevExtra.children);
            }
          }
          return prevExtra;
        });
      };

      setExtras(recursiveAdd);
    }
  };

  const editExtra = (extra: Extra) => {
    const recursiveEdit = (extras: Extra[]): Extra[] => {
      return extras.map((prevExtra) => {
        if (prevExtra.extraUUID === extra.extraUUID) {
          return extra;
        } else {
          if (prevExtra.children) {
            prevExtra.children = recursiveEdit(prevExtra.children);
          }
          return prevExtra;
        }
      });
    };

    setExtras(recursiveEdit);
  };

  const deleteExtra = (extra: Extra) => {
    const recursiveFilter = (extras: Extra[]): Extra[] => {
      const newExtras: Extra[] = [];
      for (const prevExtra of extras) {
        if (prevExtra !== extra) {
          if (prevExtra.children) {
            prevExtra.children = recursiveFilter(prevExtra.children);
          }
          newExtras.push(prevExtra);
        }
      }
      return newExtras;
    };

    setExtras(recursiveFilter);
  };

  const editExtraGroup = async (
    extra: Extra,
    newParentExtraUUID: string
  ) => {
    try {
      setGetExtrasLoading(true);
      await ExtrasApi.edit(extra.extraUUID, {
        name: extra.name,
        isGroup: extra.isGroup,
        parentExtraUUID: newParentExtraUUID,
      });
      const extras = await ExtrasApi.getExtras();
      setExtras(extras);
    } catch (e: any) {
      setGetExtrasError(e.message);
    } finally {
      setGetExtrasLoading(false);
    }
  };

  const getParameterGroup = (extra: Extra) => {
    let firstLevelExtra:Extra = extra;

    do {
      const candidate = findExtraByID(extra.parentExtraUUID)
      if (candidate) {
        firstLevelExtra = candidate
      } else{
        break
      }
    } while (firstLevelExtra?.level !== 1);

    return firstLevelExtra
  }

  return {
    extras,
    findExtraByID,
    getExtrasLoading,
    getExtrasError,
    addExtra,
    deleteExtra,
    editExtra,
    editExtraGroup,
    getParameterGroup
  };
};

export default useExtras;
