import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { CaseStatusColor } from "@/root/models/caseStatusColor";

export interface EditCaseStatusColorPayload {
  caseStatusUUID: string;
  color: string;
}

export const CaseStatusColorsApi = {
  getColors: async (): Promise<CaseStatusColor[]> => {
    return await makeRequest<CaseStatusColor[]>({
      url: apiBaseurl + "/case-statuses",
    });
  },

  editColors: async (
    payload: EditCaseStatusColorPayload[]
  ): Promise<CaseStatusColor[]> => {
    return await makeRequest<CaseStatusColor[]>({
      url: apiBaseurl + `/case-statuses`,
      method: "put",
      data: payload,
    });
  },
};
