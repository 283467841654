import globalStyles from "../InvoicesPdf.module.scss";

const ClinicInformation = () => {
  return (
    <div style={{ marginBottom: "15px" }}>
      <div className={globalStyles.font_bold}>Roshen Dental Services</div>
      <div>312 Dolomite Dr. unit #228</div>
      <div>Toronto, ON, M3J 2N2</div>
      <div>Tel: 1-416-826-4576</div>
      <div>Fax: 647-436-8030</div>
      <div>Email: info@roshendental.com</div>
    </div>
  );
};

export default ClinicInformation;
