import { Button, notification } from "antd";
import { t } from "i18next";
import { useState } from "react";
import ActionWarehouseModal from "./ActionWarehouseModal";
import useWarehouses from "@/hooks/useWarehouses";
import { useForm } from "antd/es/form/Form";

const CreateWarehouse = () => {
  const [form] = useForm();
  const [modal, setModal] = useState(false);

  const onCreateSuccess = () => {
    setModal(false);
    notification.success({
      message: t("Склад був успішно створений"),
    });
    form.resetFields();
  };

  const { addWarehouse, createError, createLoading } =
    useWarehouses(onCreateSuccess);

  return (
    <div>
      <Button onClick={() => setModal(true)}>{t("Створити склад")}</Button>
      <ActionWarehouseModal
        open={modal}
        onCancel={() => setModal(false)}
        type="create"
        error={createError}
        handleSubmit={addWarehouse}
        loading={createLoading}
        form={form}
      />
    </div>
  );
};

export default CreateWarehouse;
