import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, notification, Space } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { Supplier } from "@/root/models/supplier";
import useSuppliers from "@/hooks/useSuppliers";
import { CreateSupplierPayload } from "@/api/suppliers";
import ActionSupplierModal from "./ActionSupplierModal";

const { confirm } = Modal;

interface TableActionsProps {
  supplier: Supplier;
  modal: string | null;
  onCancel: () => void;
  onModalOpen: () => void;
  onEditSuccess: () => void;
}

const TableActions = ({
  supplier,
  modal,
  onCancel,
  onEditSuccess,
  onModalOpen,
}: TableActionsProps) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const onDeleteError = (message: string) => {
    notification.error({
      message: message,
    });
  };

  const onSuccessEdit = () => {
    onEditSuccess();
    notification.success({
      message: t("Постачальник був успішно змінений"),
    });
  };

  const {
    editSupplier,
    editError,
    editLoading,
    deleteSupplier,
    deleteLoading,
  } = useSuppliers(onSuccessEdit, onDeleteError);

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => {
        deleteSupplier(supplier.supplierUUID);
        notification.success({
          message: t("Постачальник був успішно видалений"),
        });
      },
    });
  };

  const handleEdit = (payload: CreateSupplierPayload) => {
    editSupplier(supplier.supplierUUID, payload);
  };

  const onHandleOpen = () => {
    onModalOpen();
  };

  useEffect(() => {
    modal && form.setFieldsValue(supplier);
  }, [modal]);

  return (
    <Space className="table-actions" size="middle">
      <Button size="small" icon={<EditOutlined />} onClick={onHandleOpen} />
      <Button
        size="small"
        icon={<DeleteOutlined />}
        loading={deleteLoading}
        danger
        onClick={handleDeleteButtonClick}
      />
      <ActionSupplierModal
        modal={modal}
        onCancel={onCancel}
        type="edit"
        form={form}
        handleSubmit={handleEdit}
        error={editError}
        loading={editLoading}
        supplierUUID={supplier.supplierUUID}
      />
    </Space>
  );
};

export default TableActions;
