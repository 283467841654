import { apiBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";
import { Pricelist } from "@/root/models/pricelist";

export const PricelistTypesApi = {
  getTypes: async (): Promise<Pricelist.Type[]> => {
    const data = await makeRequest<Pricelist.Type[]>({
      url: apiBaseurl + "/pricelist-types",
    });
    return data;
  },
};
