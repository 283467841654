import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { AdminClient } from "@/root/models/adminClient";
import { AdminClientsApi, CreateAdminClientPayload } from "@/api/admin/clients";

const useAdminClients = ({
  onCreateSuccess,
  onDeleteError,
  onEditSuccess,
}: {
  onCreateSuccess?: () => void;
  onEditSuccess?: () => void;
  onDeleteError?: (message: string) => void;
}) => {
  const queryClient = useQueryClient();
  const {
    data: adminClients,
    isFetching: getAdminClientsLoading,
    error: getAdminClientsError,
  } = useQuery({
    queryKey: ["adminClients"],
    queryFn: AdminClientsApi.getClients,
  });

  const createAdminClientMutation = useMutation({
    mutationFn: AdminClientsApi.create,
    onSuccess: (adminClient) => {
      queryClient.setQueryData(
        ["adminClients"],
        (prev: AdminClient[] | undefined) => {
          if (!prev) {
            return [adminClient];
          }
          return [...prev, adminClient];
        }
      );
      onCreateSuccess?.();
    },
  });

  const createAdminClient = (payload: CreateAdminClientPayload) => {
    createAdminClientMutation.mutate(payload);
  };

  const createError = (createAdminClientMutation.error as Error)
    ?.message as string;

  const deleteAdminClientMutation = useMutation({
    mutationFn: AdminClientsApi.delete,
    onError: (error) => onDeleteError?.((error as Error).message),
    onSuccess: (_, adminClientUUID) => {
      queryClient.setQueryData(
        ["adminClients"],
        (prev: AdminClient[] | undefined) =>
          (prev || []).filter((p) => p.clientUUID !== adminClientUUID)
      );
    },
  });

  const deleteAdminClient = async (id: string) => {
    await deleteAdminClientMutation.mutateAsync(id);
  };

  const deleteError = (deleteAdminClientMutation.error as Error)
    ?.message as string;

  const editAdminClientMutation = useMutation({
    mutationFn: AdminClientsApi.edit,
    onSuccess: (adminClient: AdminClient) => {
      queryClient.setQueryData(
        ["adminClients"],
        (prev: AdminClient[] | undefined) => {
          onEditSuccess?.();
          return (prev || []).map((prevAdminClient) => {
            if (prevAdminClient.clientUUID === adminClient.clientUUID) {
              return adminClient;
            }
            return prevAdminClient;
          });
        }
      );
    },
  });

  const editAdminClient = (id: string, payload: CreateAdminClientPayload) => {
    editAdminClientMutation.mutate({ id, payload });
  };

  const editError = (editAdminClientMutation.error as Error)?.message as string;

  return {
    adminClients: adminClients || [],
    getAdminClientsLoading,
    getAdminClientsError,
    createAdminClient,
    deleteAdminClient,
    editAdminClient,
    createLoading: createAdminClientMutation.isLoading,
    editLoading: editAdminClientMutation.isLoading,
    deleteLoading: deleteAdminClientMutation.isLoading,
    editError,
    createError,
    deleteError,
  };
};

export default useAdminClients;
