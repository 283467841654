import { useEffect, useState } from "react";

import { ParametersGroup } from "@/root/models/parameterGroup";
import { ProductsParametersGroupApi } from "@/api/productsParametersGroup";

const useProductsParametersGroups = () => {
  const [groups, setGroups] = useState<ParametersGroup[]>([]);
  const [loading, steLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        steLoading(true);
        const groups = await ProductsParametersGroupApi.getGroups();
        setGroups(groups);
      } catch (e) {
      } finally {
        steLoading(false);
      }
    })();
  }, []);

  return {
    groups,
    loading,
  };
};

export default useProductsParametersGroups;
