import { apiBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";
import { CouriersAvailability } from "@/root/models/couriersAvailability";

export interface EditCouriersAvailabilityPayload {
  courierUUID: string;
  courierAvailabilityStatusUUID: string;
}

export const CouriersAvailabilityApi = {
  getAvailabilities: async (
    courierUUID?: string
  ): Promise<CouriersAvailability[]> => {
    return await makeRequest<CouriersAvailability[]>({
      url: apiBaseurl + "/couriers-availability",
      params: { courierUUID },
    });
  },

  edit: async (
    payload: EditCouriersAvailabilityPayload
  ): Promise<CouriersAvailability[]> => {
    return await makeRequest<CouriersAvailability[]>({
      url: apiBaseurl + `/couriers-availability`,
      method: "put",
      data: payload,
    });
  },
};
