import React, { FC } from "react";

import { DashboardTables } from "@/modules/MainPanel/components";

const Cases: FC = () => {
  return (
    <DashboardTables
      withAdvancedSearch
      date={""}
      widgetCollapsed={true}
      setWidgetCollapsed={() => {}}
    />
  );
};

export default Cases;
