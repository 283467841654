import { Invoice } from "@/root/models/invoice";
import { Button, Checkbox } from "antd";
import Table, { ColumnsType } from "antd/es/table";
import { t } from "i18next";

import InvoiceColumn from "@/modules/InvoicesPanel/components/InvoiceColumn";

import styles from "./CustomCasesTable.module.scss";

interface CustomCasesTableProps {
  tableInstancesArray: Invoice.InvoiceCase[] | undefined;
  onClick: () => void;
  btnLabel: string;
  selectedInstances: Invoice.InvoiceCase[];
  setSelectedInstances: (cases: Invoice.InvoiceCase[]) => void;
  type?: string;
  instance?: "unpaidInvoiceCases" | "paymentInvoiceCases";
  tableWithoutBorders?: boolean;
  paid?: boolean;
}

const CustomCasesTable = ({
  tableInstancesArray,
  selectedInstances,
  setSelectedInstances,
  onClick,
  btnLabel,
  type,
  instance,
  tableWithoutBorders,
  paid,
}: CustomCasesTableProps) => {
  const casesColumns: ColumnsType<Invoice.InvoiceCase> = [
    {
      title: () => (
        <Button disabled={paid} onClick={onClick}>
          {btnLabel}
        </Button>
      ),
      width: 60,
      key: "action",

      render: (_: any, instance) => (
        <div style={{ textAlign: "center" }}>
          <Checkbox
            onChange={(e) => {
              const checked = e.target.checked;
              const selectedCase = tableInstancesArray?.find(
                (elem) => elem.rowUUID === instance.rowUUID
              );
              if (checked && selectedCase) {
                setSelectedInstances([...selectedInstances, selectedCase]);
              } else if (!checked && selectedCase) {
                setSelectedInstances(
                  selectedInstances.filter(
                    (elem) => elem.rowUUID !== selectedCase.rowUUID
                  )
                );
              }
            }}
          />
        </div>
      ),
    },
    {
      width: 100,
      title: t("Замовлення №"),
      dataIndex: "caseNumber",
      key: "caseNumber",
      render: (_: any, instance) => <span>{instance.case.caseNumber}</span>,
    },
    {
      title: t("Тип елементу"),
      dataIndex: "itemType",
      key: "itemType",
      render: (_: any, instance) => <span>{instance.itemType}</span>,
    },
    {
      width: "30%",
      title: t("Імʼя елементу"),
      dataIndex: "itemName",
      key: "itemName",
      render: (_: any, instance) => <span>{instance.item.name}</span>,
    },
    {
      title: t("Сума"),
      dataIndex: "caseSum",
      key: "caseSum",
      render: (_: any, instance) => <span>{instance.sum}</span>,
    },
  ];

  //Remove action buttons
  if (type === "view") {
    casesColumns.splice(0, 1);
  }

  //If case is added to Payment in Payment create, show Invoice number column of added cases
  if (instance === "paymentInvoiceCases") {
    casesColumns.splice(5, 0, {
      title: `${t("Рахунок-фактура")} №`,
      dataIndex: "invoices",
      key: "invoiceNumber",
      render: (instance: Invoice.InvoiceCase["invoices"]) => {
        return <span>{instance?.[0].invoiceNumber}</span>;
      },
    });
  }

  //In unpaid invoice cases on Invoice create show Invoice column if case already in Invoice
  if (instance === "unpaidInvoiceCases") {
    casesColumns.splice(5, 0, {
      title: t("Рахунки-фактури"),
      dataIndex: "invoices",
      key: "invoice",
      render: (instance: Invoice.InvoiceCase["invoices"]) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            {instance?.map((elem) => (
              <div key={elem.invoiceUUID}>
                <InvoiceColumn invoiceInfo={elem} />
              </div>
            ))}
          </div>
        );
      },
    });
  }
  return (
    <Table
      className={tableWithoutBorders && styles.table_container}
      columns={casesColumns}
      pagination={false}
      dataSource={tableInstancesArray?.map((c) => ({
        ...c,
        key: c.rowUUID,
      }))}
    />
  );
};

export default CustomCasesTable;
