import React, { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { notification } from "antd";

import { LaboratoryCaseForm, CaseForm } from "@/components";
import {
  caseFormInitialState,
  CaseFormState,
} from "@/components/CaseForm/CaseFormContext";
import { useAppSelector } from "@/hooks/redux";
import { CreateCasePayload, CasesApi } from "@/api/client/cases";
import {
  TeethFormulaNumberingEnum,
  TeethFormulaTypeEnum,
  UserTypeEnum,
} from "@/root/types";
import { pages } from "@/root/consts";

const MakeCase: FC = () => {
  const client = useAppSelector((state) => state.userReducer.user?.client);
  const userUUID = useAppSelector((state) => state.userReducer.user?.userUUID);
  const userType = useAppSelector((state) => state.userReducer.user?.userType);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const navigateToCase = () => {
    // it is safe to use -1 (back), because access to /create page is through other pages
    navigate(-1);
  };

  const handleCreate = async (
    values: CreateCasePayload,
    close: boolean,
    type: "client" | "lab"
  ) => {
    try {
      setLoading(true);
      const medCase = await CasesApi.create(values);
      if (close) {
        navigateToCase();
      } else {
        if (type === "client") {
          navigate(pages.client.cases.edit.path, {
            state: medCase.caseUUID,
            replace: true,
          });
        } else if (type === "lab" && location.pathname.includes("admin")) {
          navigate(pages.admin.cases.edit.path, {
            state: medCase.caseUUID,
            replace: true,
          });
        } else {
          navigate(pages.lab.cases.edit.path, {
            state: medCase.caseUUID,
            replace: true,
          });
        }
      }

      return medCase;
    } catch (e: any) {
      notification.error({
        message: e.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const jobID = window.self.crypto.randomUUID();
  const productID = window.crypto.randomUUID();

  const initialState: CaseFormState = {
    ...caseFormInitialState,
    client: client!,
    teethFormulaNumberingUUID: TeethFormulaNumberingEnum.FDI,
    teethFormulaTypeUUID: TeethFormulaTypeEnum.adult,
    onlyDetailsEdit:
      userType?.userTypeUUID === UserTypeEnum["Client's employee"] ||
      userType?.userTypeUUID === UserTypeEnum["Client's admin"] ||
      false,
    clientsEmployee:
      userUUID && userType?.userTypeUUID === UserTypeEnum["Client's employee"]
        ? {
            clientsEmployeeUUID: userUUID,
            name: "",
          }
        : null,
    activeJobID: jobID,
    warrantyNumber: 0,
    jobs: [
      {
        jobName: "",
        jobID: jobID,
        activeProductID: productID,
        products: [
          {
            productsParametersGroups: [],
            differentTeethParameters: false,
            combinedProduct: false,
            quantity: 1,
            jobProductUUID: productID,
          },
        ],
      },
    ],
  };

  const Form = () => {
    if (!userType) {
      return null;
    }

    switch (userType.userTypeUUID) {
      case UserTypeEnum["Client's employee"]:
      case UserTypeEnum["Client's admin"]:
        return (
          <CaseForm
            initialState={initialState}
            loading={loading}
            onFinish={handleCreate}
            onCancel={navigateToCase}
          />
        );
      case UserTypeEnum["Employee"]:
      case UserTypeEnum["Lab's admin"]:
        return (
          <LaboratoryCaseForm
            medCase={null}
            initialState={initialState}
            loading={loading}
            onFinish={handleCreate}
            onCancel={navigateToCase}
            type="create"
          />
        );
      default:
        return null;
    }
  };

  return Form();
};

export default MakeCase;
