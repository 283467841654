import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { Pricelist } from "@/root/models/pricelist";
import { PricelistTypeEnum } from "@/components/PricelistForm";

export interface CreatePricelistPayload {
  activationDate: string;
  description: string;
  list: {
    itemUUID: string;
    price: number;
  }[];
}

export const PricelistsApi = {
  getPricelists: async (): Promise<Pricelist[]> => {
    const data = await makeRequest<Pricelist[]>({
      url: apiBaseurl + "/pricelists",
    });
    return data;
  },

  getCurrentPricelist: async (
    pricelistType: PricelistTypeEnum
  ): Promise<Pricelist> => {
    const data = await makeRequest<Pricelist>({
      url: apiBaseurl + `/current-pricelist/${pricelistType}`,
    });
    return data;
  },

  create: async (payload: CreatePricelistPayload): Promise<Pricelist> => {
    const data = await makeRequest<Pricelist>({
      url: apiBaseurl + "/pricelists",
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async (
    id: string,
    payload: CreatePricelistPayload
  ): Promise<Pricelist> => {
    const data = await makeRequest<Pricelist>({
      url: apiBaseurl + `/pricelists/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/pricelists/${id}`,
      method: "delete",
    });
  },
};
