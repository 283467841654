import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Layout, Menu, MenuProps, Modal } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content } from "antd/es/layout/layout";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import { AiOutlineLogout } from "react-icons/ai";
import { ItemType } from "antd/es/menu/hooks/useItems";

import styles from "./DashboardPage.module.scss";

import { apiBaseurl } from "@/api/baseurl";
import { logout } from "@/store/reducers/user/asyncActionCreators";
import { useAppDispatch } from "@/hooks/redux";
import { pages } from "@/root/consts";

interface DashboardPageProps {
  navigation: MenuProps["items"];
  children: ReactNode;
}

const { confirm } = Modal;

const CollapsePanelContext = createContext<{ collapsePanel: () => void }>({
  collapsePanel: () => {},
});
export const useCollapsePanelContext = () => useContext(CollapsePanelContext);

const DashboardPage: FC<DashboardPageProps> = ({ navigation, children }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const logoutItem: ItemType = {
    key: "logout",
    label: t("Вийти"),
    icon: <AiOutlineLogout size={22} />,
  };

  useEffect(() => {
    if (location.pathname.includes("dashboard")) {
      setCollapsed(true);
    }
  }, [location.pathname]);

  const handleSelect = (key: string) => {
    if (key === "logout") {
      confirm({
        title: t("Підтвердити вихід"),
        okText: t("Вийти"),
        okType: "danger",
        cancelText: t("Скасувати"),
        onOk: () => {
          dispatch(logout());
          navigate(pages.login.path);
        },
      });
    } else {
      navigate(key);
    }
  };

  return (
    <Layout hasSider>
      <Sider
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
        theme="light"
        collapsed={collapsed}
      >
        <Button
          className={styles.collapseButton}
          size="small"
          onClick={toggleCollapsed}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
        {collapsed ? null : (
          <img
            style={{ minHeight: 110 }}
            className={styles.logo}
            src={apiBaseurl + "/logo"}
            onError={(e) => {
              // @ts-ignore
              e.target.style.display = "none";
            }}
            alt="logo"
          />
        )}
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={[location.pathname]}
          items={navigation && [...navigation, logoutItem]}
          onSelect={(item) => handleSelect(item.key)}
          className={styles.menu_items}
        />
      </Sider>
      <Layout
        style={{
          marginLeft: collapsed ? 80 : 200,
          minHeight: "100vh",
          transition: "ease 0.2s",
        }}
      >
        <CollapsePanelContext.Provider
          value={{ collapsePanel: () => setCollapsed(true) }}
        >
          <Content className={styles.content}>{children}</Content>
        </CollapsePanelContext.Provider>
      </Layout>
    </Layout>
  );
};

export default DashboardPage;
