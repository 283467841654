import { useQuery } from "@tanstack/react-query";

import { EmployeesApi } from "@/api/employees";

const useEmployees = () => {
  const {
    data: employees,
    isLoading: getEmployeesLoading,
    error: getEmployeesError,
  } = useQuery({
    queryKey: ["employees"],
    queryFn: EmployeesApi.getEmployees,
  });

  return {
    employees: employees || [],
    getEmployeesLoading,
    getEmployeesError,
  };
};

export default useEmployees;
