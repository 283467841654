import logo from "@/assets/patients-portal-images/logo.png";
import barcode from "@/assets/barcode.png";
import ClinicInformation from "../../components/ClinicInformation";
import DoctorInformation from "../../components/DoctorInformation";

import styles from "../InvoiceAccountForCurrentOrder.module.scss";
import globalStyles from "../../InvoicesPdf.module.scss";

const FooterInfo = () => {
  return (
    <div style={{ marginTop: "50px" }}>
      <img src={logo} alt="logo" className={styles.logo_style} />
      <ClinicInformation />

      <div>
        <span>
          <span className={globalStyles.font_bold}>Deliver To</span> ( Case
          27701 )
        </span>
        <DoctorInformation />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            margin: "5px 0",
          }}
        >
          <div>
            <span>
              Patient:{" "}
              <span className={globalStyles.font_bold}>Joseph Day</span>
            </span>
            <div className={globalStyles.font_bold}>26 July 2023 00:00</div>
          </div>
          <img src={barcode} alt="barcode" className={styles.barcode_image} />
        </div>
      </div>
    </div>
  );
};

export default FooterInfo;
