import { useEffect, useState } from "react";

import { ProductParameterType } from "@/root/models/productParameter";
import { ProductsParametersApi } from "@/api/admin/productsParameters";

const useParametersTypes = () => {
  const [types, setTypes] = useState<ProductParameterType[]>([]);
  const [loading, steLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        steLoading(true);
        const types = await ProductsParametersApi.getParameterTypes();
        setTypes(types);
      } catch (e) {
      } finally {
        steLoading(false);
      }
    })();
  }, []);

  return {
    types,
    loading,
  };
};

export default useParametersTypes;
