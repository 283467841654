import { apiBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";
import { CaseStatus } from "@/root/models/caseStatus";

export const CaseStatusApi = {
  getStatuses: async () => {
    const data = await makeRequest<CaseStatus[]>({
      url: apiBaseurl + "/case-statuses",
    });
    return data;
  },
};
