import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "antd";
import { useForm } from "antd/es/form/Form";

import { AdminClientForm } from "@/components";
import { CreateAdminClientPayload } from "@/api/admin/clients";
import useAdminClients from "@/hooks/useAdminClients";

interface CreateAdminClientModalProps {
  open: boolean;
  onCancel: () => void;
}

const CreateAdminClientModal: FC<CreateAdminClientModalProps> = ({
  open,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const onCreateSuccess = () => {
    onCancel();
    form.resetFields();
  };

  const {
    createAdminClient,
    createLoading: loading,
    createError: error,
  } = useAdminClients({ onCreateSuccess });

  const handleCreate = async (values: CreateAdminClientPayload) => {
    createAdminClient(values);
  };

  const submit = () => {
    form.submit();
  };

  return (
    <Modal
      maskClosable={false}
      okText={t("Створити")}
      title={<div style={{ marginBottom: 20 }}>{t("Створення клієнта")}</div>}
      open={open}
      onCancel={onCancel}
      onOk={submit}
      okButtonProps={{ loading }}
    >
      <AdminClientForm form={form} handleSubmit={handleCreate} error={error} />
    </Modal>
  );
};

export default CreateAdminClientModal;
