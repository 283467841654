import { FC, useEffect, useState } from "react";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Alert, DatePicker, Form, FormInstance, Input, Select } from "antd";

import styles from "./PricelistForm.module.scss";

import usePricelistTypes from "@/hooks/usePricelistTypes";
import {
  ExtraPricelist,
  ProductPricelist,
} from "@/components/PricelistForm/componenets";
import { CreateClientPricelistPayload } from "@/api/admin/clientPricelists";
import useAdminClients from "@/hooks/useAdminClients";
import { CurrentPricelistApi } from "@/api/client/currentPricelist";
import useFormAutoFocusInput from "@/hooks/useFormAutoFocusInput";

dayjs.extend(weekday);
dayjs.extend(localeData);

const { Option } = Select;

interface PricelistFormProps {
  form: FormInstance<CreateClientPricelistPayload>;
  handleSubmit: (values: CreateClientPricelistPayload) => void;
  error: string;
  formType?: "create" | "edit";
}

export enum PricelistTypeEnum {
  "ProductsCatalog" = "77b7acbd-7f3f-4ca6-93e9-f5a5ec57c8bb",
  "Extras" = "138287db-123f-418b-b44d-6d3fc0e05417",
}

const PricelistForm: FC<PricelistFormProps> = ({
  form,
  handleSubmit,
  error,
  formType = "create",
}) => {
  const { t } = useTranslation();
  const { pricelistTypes, getTypesLoading } = usePricelistTypes();
  const { adminClients: clients, getAdminClientsLoading: getClientsLoading } =
    useAdminClients({});
  const [type, setType] = useState<string>(
    form.getFieldValue("pricelistTypeUUID")
  );
  const [client, setClient] = useState<string>();
  const [listItems, setListItems] = useState<
    { itemUUID: string; price: number }[]
  >(form.getFieldValue("list") || []);
  const focusInputRef = useFormAutoFocusInput();

  useEffect(() => {
    (async () => {
      if (!type || !client || formType === "edit") {
        return;
      }

      const pricelist = await CurrentPricelistApi.getPricelist({
        clientUUID: client,
        pricelistTypeUUID: type,
      });
      if ("list" in pricelist) {
        setListItems(
          pricelist.list.map((item) => ({
            itemUUID:
              "productUUID" in item.item
                ? item.item.productUUID
                : item.item.extraUUID,
            price: item.price,
          }))
        );
      }
    })();
  }, [client, type, formType]);

  const handleFinish = (values: {
    description: string;
    activationDate: string;
    clientUUID: string;
  }) => {
    handleSubmit({
      ...values,
      list: listItems,
    });
  };

  const handlePricelistTypeSelect = (type: string) => {
    setType(type);
    setListItems([]);
  };

  const handleClientSelect = (clientUUID: string) => {
    setClient(clientUUID);
  };

  return (
    <Form
      labelCol={{ span: 6 }}
      requiredMark={false}
      form={form}
      onFinish={handleFinish}
    >
      {form.getFieldValue("number") && (
        <Form.Item name="number" label={t("Номер")}>
          <Input disabled />
        </Form.Item>
      )}
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
        name="description"
        label={t("Опис")}
      >
        <Input
          ref={focusInputRef}
          placeholder={t("Введіть значення") as string}
        />
      </Form.Item>

      <div style={{ display: "flex", width: "100%" }}>
        <Form.Item
          className={styles.formOneLine}
          labelCol={{ span: undefined }}
          rules={[
            {
              required: true,
              message: t("Заповніть поле") as string,
            },
          ]}
          name="activationDate"
          label={t("Дата активації")}
        >
          <DatePicker
            format="YYYY.MM.DD"
            placeholder={t("Виберіть дату") as string}
          />
        </Form.Item>
        <Form.Item
          style={{ flexGrow: 1 }}
          labelCol={{ span: undefined }}
          name="pricelistTypeUUID"
          label={t("Тип")}
        >
          <Select
            loading={getTypesLoading}
            onSelect={handlePricelistTypeSelect}
          >
            {pricelistTypes.map((type) => (
              <Option
                key={type.pricelistTypeUUID}
                value={type.pricelistTypeUUID}
              >
                {type.name || " "}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </div>

      <Form.Item
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
        name="clientUUID"
        label={t("Контрагент")}
      >
        <Select loading={getClientsLoading} onSelect={handleClientSelect}>
          {clients.map((client) => (
            <Option key={client.clientUUID} value={client.clientUUID}>
              {client.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {type === PricelistTypeEnum.ProductsCatalog && (
        <ProductPricelist
          productPrices={listItems}
          setProductPrices={setListItems}
        />
      )}

      {type === PricelistTypeEnum.Extras && (
        <ExtraPricelist extraPrices={listItems} setExtraPrices={setListItems} />
      )}

      {!!error && <Alert message={error} type="error" />}
    </Form>
  );
};

export default PricelistForm;
