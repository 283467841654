import React, { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Button, Calendar, DatePicker, Tabs, TabsProps } from "antd";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";

import styles from "./MainPanel.module.scss";

import { Block } from "@/components/common";
import {
  CouriersWidget,
  DashboardTables,
  Events,
} from "@/modules/MainPanel/components";
import { DATE_FORMAT } from "@/root/consts";
import { getCalendarDates } from "@/utils/getCalendarDates";
import { CalendarCasesDangerDueDates } from "@/api/calendarCasesDangerDueDates";
import { useAppSelector } from "@/hooks/redux";
import { UserTypeEnum } from "@/root/types";
import { useCollapsePanelContext } from "@/pages/DashboardPage";

const { RangePicker } = DatePicker;

const MainPanel: FC = () => {
  const { t } = useTranslation();
  const [widgetCollapsed, setWidgetCollapsed] = useState(false);
  const [date, setDate] = useState<string>("");
  const [casesDates, setCasesDated] = useState<string[]>([]);
  const [tab, setTab] = useState("events");
  const userType = useAppSelector((state) => state.userReducer.user?.userType);
  const { collapsePanel } = useCollapsePanelContext();

  const leftBlockItems: TabsProps["items"] = [
    {
      key: "events",
      label: t("Події"),
      children: (
        <Block className={styles.couriers}>
          <Events />
        </Block>
      ),
    },
    {
      key: "couriers",
      label: t("Кур'єри"),
      children: (
        <Block className={styles.couriers}>
          <CouriersWidget />
        </Block>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setCasesDated(
          await CalendarCasesDangerDueDates.getDates(
            ...(getCalendarInitialDates(date) as [string, string])
          )
        );
      } catch (e) {}
    })();
  }, [date]);

  const handleCalendarChange = (dayjs: dayjs.Dayjs) => {
    dayjs.format(DATE_FORMAT) === date
      ? setDate("")
      : setDate(dayjs.format(DATE_FORMAT));
  };

  const collapse = () => {
    collapsePanel();
    setWidgetCollapsed(true);
  };

  const dateRangeValue: [dayjs.Dayjs, dayjs.Dayjs] | undefined =
    (date &&
      (date.includes(",")
        ? [
            dayjs(new Date(date.split(",")[0])),
            dayjs(new Date(date.split(",")[1])),
          ]
        : [dayjs(new Date(date)), dayjs(new Date(date))])) ||
    undefined;
  const calendarValue =
    date && !date?.includes(",") ? dayjs(new Date(date)) : dayjs();

  return (
    <div className={styles.container}>
      {widgetCollapsed ? (
        <Block
          onClick={() => setWidgetCollapsed(false)}
          className={styles.expandWidgets}
        >
          <DoubleRightOutlined />
        </Block>
      ) : (
        <div className={styles.widgets}>
          <Block className={styles.calendarContainer}>
            <div className={styles.rangePicker}>
              <RangePicker
                value={dateRangeValue}
                onChange={(_, range) =>
                  setDate(range[0] ? range.join(",") : "")
                }
                allowClear
              />
            </div>
            <Calendar
              cellRender={(date) =>
                casesDates.includes(date.format(DATE_FORMAT)) ? (
                  <div className={styles.calendarDangerCell} />
                ) : undefined
              }
              className={styles.calendar}
              fullscreen={false}
              value={calendarValue}
              onSelect={handleCalendarChange}
            />
            <Button
              className={styles.collapseButton}
              icon={<DoubleLeftOutlined />}
              onClick={collapse}
            />
          </Block>
          {(userType?.userTypeUUID === UserTypeEnum["Employee"] ||
            userType?.userTypeUUID === UserTypeEnum["Lab's admin"]) && (
            <Tabs
              className={styles.widgetTabs}
              tabBarStyle={{ marginBottom: 0 }}
              type="card"
              items={leftBlockItems}
              activeKey={tab}
              onTabClick={setTab}
            />
          )}
        </div>
      )}
      <div
        style={{
          width: widgetCollapsed ? "calc(100% - 30px)" : "calc(100% - 408px)",
        }}
        className={styles.contentContainer}
      >
        <DashboardTables
          date={date}
          widgetCollapsed={widgetCollapsed}
          setWidgetCollapsed={setWidgetCollapsed}
        />
      </div>
    </div>
  );
};

export default MainPanel;

const getCalendarInitialDates = (dates: string) => {
  let date = new Date();

  if (dates.includes(",")) {
    return dates.split(",").map((d) => dayjs(new Date(d)).format(DATE_FORMAT));
  } else if (dates) {
    date = new Date(dates);
  }

  const calendarDates = getCalendarDates(
    date.getFullYear(),
    date.getMonth() + 1
  );
  const fistAndLastDates = [calendarDates[0], calendarDates.at(-1)];
  return fistAndLastDates.map((d) => dayjs(d).format(DATE_FORMAT));
};
