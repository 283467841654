import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button, Dropdown, Space } from "antd";
import { LogoutOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import { Search, Case, Task } from "@/modules/CourierInfo/components";
import { SearchType } from "@/modules/CourierInfo/components/Search";
import { pages } from "@/root/consts";
import { logout } from "@/store/reducers/user/asyncActionCreators";
import { useAppDispatch } from "@/hooks/redux";

const CourierInfo: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [type, setType] = useState<SearchType>("tasks");
  const [id, setId] = useState("");
  const [backTaskID, setBackID] = useState("");

  const handleSearch = ({
    type,
    value,
  }: {
    value: string;
    type: SearchType;
  }) => {
    setBackID("");
    setType(type);
    setId(value);
  };

  const handleBack = () => {
    if (backTaskID) {
      setId(backTaskID);
      setType((prev) => (prev === "tasks" ? "cases" : "tasks"));
      setBackID("");
    } else {
      navigate(pages.courier.dashboard.path);
    }
  };

  const handleCaseWatch = (caseID: string) => {
    setBackID(id);
    setId(caseID);
    setType("cases");
  };

  const handleTaskWatch = (taskID: string) => {
    setBackID(id);
    setId(taskID);
    setType("tasks");
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate(pages.login.path);
  };

  const redirectToTasks = () => {
    navigate(pages.courier.dashboard.path);
  };

  const menuItems = [
    {
      key: "tasks",
      label: <Button type="link">{t("Завдання")}</Button>,
      onClick: redirectToTasks,
    },
    {
      key: "logout",
      label: (
        <Button type="link" icon={<LogoutOutlined />}>
          {t("Вийти")}
        </Button>
      ),
      onClick: handleLogout,
    },
  ];

  return (
    <Space direction="vertical" style={{ width: "100%", padding: 8 }}>
      <Space>
        <Dropdown
          trigger={["click"]}
          menu={{
            items: menuItems,
          }}
        >
          <MenuUnfoldOutlined style={{ fontSize: 26 }} />
        </Dropdown>
        <Button onClick={handleBack}>{t("Назад")}</Button>
      </Space>
      <Search onSearch={handleSearch} />
      {type === "tasks" && id && <Task id={id} onCaseWatch={handleCaseWatch} />}
      {type === "cases" && id && <Case id={id} onTaskWatch={handleTaskWatch} />}
    </Space>
  );
};

export default CourierInfo;
