import dayjs from "dayjs";

export const formatDateTime = (date: dayjs.Dayjs, time: string) => {
  const parsedTime = time
    ? time.length === 1
      ? time + "0:00"
      : time.length === 2
      ? time + ":00"
      : time.length === 4
      ? time + "0"
      : time
    : "00:00";
  return dayjs(date).format("YYYY.MM.DD ") + parsedTime + ":00";
};
