import { Invoice } from "@/root/models/invoice";
import { ColumnsType } from "antd/es/table";
import { t } from "i18next";
import { Table } from "antd";
import { Dispatch, SetStateAction } from "react";
import { Pagination } from "@/components/common";
import TableActions from "./TableActions";
import useTableScrollHeight from "@/hooks/TableHooks/useTableScrollHeight";
import useTablePaginationRange from "@/hooks/TableHooks/useTablePaginationRange";
import useModalOpen from "@/hooks/useModalOpen";
import { useIsLab } from "@/root/models/isLab";

interface InvoicesTableProps {
  tabHeight: number;
  setPage: Dispatch<SetStateAction<number>>;
  invoices: Invoice[];
  pages: number | undefined;
  getInvoicesLoading: boolean;
  page: number;
}

const InvoicesTable = ({
  tabHeight,
  setPage,
  invoices,
  getInvoicesLoading,
  pages,
  page,
}: InvoicesTableProps) => {
  const { isLab } = useIsLab();

  const { tableScrollHeight, paginationRef } = useTableScrollHeight(tabHeight);

  const { paginationRange, tableRef } =
    useTablePaginationRange(getInvoicesLoading);

  const {
    modal,
    onHandleCancel,
    onEditSuccess,
    isModalOpen,
    modalType,
    setModalType,
  } = useModalOpen();

  const casesColumns: ColumnsType<Invoice> = [
    {
      width: 120,
      title: "№",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
    },
    {
      title: t("Опис"),
      dataIndex: "description",
      key: "description",
    },
    {
      width: 150,
      title: t("Повна сума"),
      dataIndex: "amount",
      key: "amount",
    },
    {
      width: 60,
      title: "",
      key: "action",
      render: (_: any, invoice: Invoice) => (
        <TableActions
          invoice={invoice}
          modal={modal}
          onModalViewOpen={() => {
            setModalType("view");
            isModalOpen(invoice.invoiceUUID);
          }}
          onModalEditOpen={() => {
            setModalType("edit");
            isModalOpen(invoice.invoiceUUID);
          }}
          modalType={modalType}
          onCancel={onHandleCancel}
          onEditSuccess={onEditSuccess}
        />
      ),
    },
  ];

  if (isLab) {
    casesColumns.splice(1, 0, {
      title: t("Контрагент"),
      dataIndex: "client",
      key: "client",
      render: (client: Invoice["client"]) => <span>{client.name}</span>,
    });
  }

  return (
    <div>
      <Table
        style={{ userSelect: "none" }}
        size={isLab ? "small" : "large"}
        scroll={{
          y: tableScrollHeight,
        }}
        ref={tableRef}
        columns={casesColumns}
        dataSource={invoices.map((i) => ({ ...i, key: i.invoiceUUID }))}
        pagination={false}
        loading={getInvoicesLoading}
        onRow={(invoice) => ({
          onDoubleClick: (e) => {
            e.stopPropagation();
            if (isLab) {
              setModalType("edit");
              isModalOpen(invoice.invoiceUUID);
            } else {
              setModalType("view");
              isModalOpen(invoice.invoiceUUID);
            }
          },
        })}
      />
      <div
        ref={paginationRef}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "15px",
        }}
      >
        <Pagination
          currentPage={page}
          pages={pages || 1}
          paginationRange={paginationRange}
          setCurrentPage={setPage}
        />
      </div>
    </div>
  );
};

export default InvoicesTable;
