import { getNestedFilters } from "@/components/ProductParameterForm";
import { Filter } from "@/root/models/filter";
import { ParametersGroup } from "@/root/models/parameterGroup";
import { CaseFormState } from "@/components/CaseForm/CaseFormContext";
import { groupByTooth } from "@/utils/groupParameterByTooth";
import { CreateCasePayload } from "@/api/client/cases";

const filterParameters = (
  parameters: CaseFormState.ProductsParameter[],
  fullGroup: ParametersGroup | undefined,
  filters: Filter[]
): CaseFormState.ProductsParameter[] | undefined => {
  const filterParameter = fullGroup?.productsParameters.find((p) =>
    p.productsParameter.type?.name.toLowerCase().includes("filter")
  );

  const filterParameterValues =
    filterParameter &&
    parameters.find(
      (p) =>
        p.productsParameterUUID ===
        filterParameter.productsParameter.productsParameterUUID
    );
  const filterValues =
    filterParameterValues && typeof filterParameterValues.value === "string"
      ? [filterParameterValues.value]
      : (filterParameterValues?.value as string[]);

  if (fullGroup && filterValues) {
    return parameters.filter((parameter) => {
      const fullParameter = fullGroup.productsParameters.find(
        (p) =>
          p.productsParameter.productsParameterUUID ===
          parameter.productsParameterUUID
      );
      if (!fullParameter) {
        return true;
      }
      const isFilterValues =
        fullParameter.productsParameter.filtersValues.length;

      if (!isFilterValues) {
        return true;
      }

      return filterValues.some((p) =>
        getNestedFilters(
          filters,
          fullParameter.productsParameter.filtersValues.map((v) => v.filterUUID)
        )
          .map((f) => f.filterUUID)
          .includes(p)
      );
    });
  }
};

const formJobToJob = (
  formJob: CaseFormState.Job,
  filters: Filter[]
): CreateCasePayload["jobs"][0] => {
  return {
    jobUUID: formJob.jobID,
    jobName: formJob.jobName,
    products: formJob.products!.map((product) => ({
      jobProductUUID: product.jobProductUUID,
      teeth: product.teeth || [],
      differentTeethParameters: product.differentTeethParameters,
      combinedProduct: product.combinedProduct,
      productUUID: product.product?.productUUID || "",
      quantity: product.quantity,
      price: product.price || 0,
      productsParametersGroups:
        product.productsParametersGroups?.map((group) => {
          const fullGroup = product.product?.productsParametersGroups.find(
            (g) =>
              g.productsParametersGroupUUID ===
              group.productsParametersGroupUUID
          );

          let filteredParameters;

          if (product.differentTeethParameters) {
            const groupedParameters = groupByTooth(group.productsParameters);
            filteredParameters = groupedParameters.flatMap((parameterGroup) => {
              return (
                filterParameters(parameterGroup, fullGroup, filters) ||
                parameterGroup
              );
            });
          } else {
            filteredParameters =
              filterParameters(group.productsParameters, fullGroup, filters) ||
              group.productsParameters;
          }

          return {
            productsParametersGroupUUID: group.productsParametersGroupUUID,
            productsParameters: filteredParameters.map((parameter) => ({
              productsParameterUUID: parameter.productsParameterUUID,
              value: parameter.value,
              tooth: parameter.tooth as string,
            })),
          };
        }) || [],
    })),
    extras:
      formJob.extras?.map((extra) => ({
        extraUUID: extra.extra.extraUUID,
        price: extra.price,
      })) || [],
    spareParts:
      formJob.spareParts?.map((part) => ({
        sparePartUUID: part.sparePart.sparePartUUID,
        price: part.price,
      })) || [],
  };
};

export default formJobToJob;
