import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "antd";

import { CreatePriceFormulaModal } from "@/components";

const CreatePriceFormula: FC = () => {
  const [modal, setModal] = useState(false);
  const { t } = useTranslation();

  return (
    <div>
      <Button onClick={() => setModal(true)}>
        {t("Створити цінову формулу")}
      </Button>
      <CreatePriceFormulaModal open={modal} onCancel={() => setModal(false)} />
    </div>
  );
};

export default CreatePriceFormula;
