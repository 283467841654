export namespace Pricelist {
  export interface Type {
    pricelistTypeUUID: string;
    name: string;
  }

  export namespace List {
    export function GetItemUUID(item: Item) {
      return "productUUID" in item ? item.productUUID : item.extraUUID;
    }

    export type Item =
      | {
          productUUID: string;
          name: string;
        }
      | {
          extraUUID: string;
          name: string;
        };
  }

  export interface List {
    item: List.Item;
    price: number;
  }
}

export interface Pricelist {
  number: string;
  pricelistUUID: string;
  date: string;
  applied: boolean;
  activationDate: string;
  description: string;
  pricelistType: Pricelist.Type;
  list: Pricelist.List[];
}

export interface ClientPricelist extends Omit<Pricelist, "pricelistUUID"> {
  clientsPricelistUUID: string;
  client: {
    clientUUID: string;
    name: string;
  };
}
