import React, { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { DatePicker, Select, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";

import useEvents from "@/hooks/useEvents";
import { EventTypes } from "@/root/models/event";
import { Pagination } from "@/components/common";
import useTablePaginationRange from "@/hooks/TableHooks/useTablePaginationRange";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Events: FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [date, setDate] = useState<string>();
  const [objectType, setObjectType] = useState<"case" | "task">();
  const [mainEntry, setMainEntry] = useState<boolean>();

  const { events, pages, getEventsLoading } = useEvents({
    page,
    date,
    objectType,
    mainEntry,
  });

  const columns: ColumnsType<EventTypes> = [
    {
      key: "dateTime",
      dataIndex: "dateTime",
      title: t("Дата_one"),
    },
    {
      key: "object",
      dataIndex: "object",
      title: t("Об'єкт"),
      render: (_, event) =>
        event.objectType === "Case" ? (
          <span>
            {t("Замовлення")}: {event.object.caseNumber}, {t("Контрагент")}:{" "}
            {event.object.client.name}
          </span>
        ) : event.objectType === "Task" ? (
          <span>
            {t("Завдання")}: {event.object.taskNumber}, {t("Кур'єр")}:{" "}
            {event.object.courier.name}
          </span>
        ) : null,
    },
    {
      key: "description",
      dataIndex: "description",
      title: t("Опис"),
    },
  ];

  const { paginationRange, tableRef } =
    useTablePaginationRange(getEventsLoading);

  return (
    <Space direction="vertical">
      <Space direction="vertical">
        <Space>
          <span>{t("Дата_one")}:</span>
          <RangePicker
            onChange={(_, range) =>
              setDate(range[0] ? range.join(",") : undefined)
            }
            allowClear
          />
        </Space>
        <Space>
          <Space>
            <span>{t("Тип")}:</span>
            <Select
              style={{ width: 120 }}
              allowClear
              onSelect={setObjectType}
              onClear={() => setObjectType(undefined)}
            >
              <Option value="case">{t("Замовлення")}</Option>
              <Option value="task">{t("Завдання")}</Option>
            </Select>
          </Space>

          <Space>
            <span>{t("Основний запис")}:</span>
            <Select
              style={{ width: 120 }}
              allowClear
              onSelect={setMainEntry}
              onClear={() => setMainEntry(undefined)}
            >
              <Option value={false}>{t("Ні")}</Option>
              <Option value={true}>{t("Так")}</Option>
            </Select>
          </Space>
        </Space>
      </Space>
      <Table
        ref={tableRef}
        pagination={false}
        loading={getEventsLoading}
        columns={columns}
        dataSource={(events || []).map((e) => ({ ...e, key: e.universalTime }))}
      />
      {!!pages && pages > 1 && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            currentPage={page}
            pages={pages}
            paginationRange={paginationRange}
            setCurrentPage={setPage}
          />
        </div>
      )}
    </Space>
  );
};

export default Events;
