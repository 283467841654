import logo from "@/assets/patients-portal-images/logo.png";
import InvoicesCurrentOrderTable from "./InvoicesCurrentOrderTable";
import styles from "../InvoiceAccountForCurrentOrder.module.scss";
import globalStyles from "../../InvoicesPdf.module.scss";
import ClinicInformation from "../../components/ClinicInformation";
import DoctorInformation from "../../components/DoctorInformation";
import WarningText from "../../components/WarningText";
import EvidentMark from "../../components/EvidentMark";

interface InvoicesCurrentOrderFormProps {
  width: string;
  isPatient?: boolean;
}
const InvoicesCurrentOrderForm = ({
  width,
  isPatient,
}: InvoicesCurrentOrderFormProps) => {
  return (
    <div
      style={{
        width: width,
      }}
    >
      <img src={logo} alt="Logo" className={styles.logo_style} />

      <ClinicInformation />

      <div style={{ marginBottom: "3px" }}>
        <div className={styles.border_content}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              Invoice no. <span className={globalStyles.font_bold}>27701</span>{" "}
              Pan. <span className={globalStyles.font_bold}>201</span>
            </div>
            <span>Date: 24 Jul 2023</span>
          </div>
        </div>

        <DoctorInformation />
      </div>
      <div className={styles.border_content}>
        <span>
          {!isPatient && <span>Patient: </span>}
          <span className={globalStyles.font_bold}>Joseph Day</span> @ 26 Jul
          2023 00:00
        </span>
      </div>

      {/* Table */}
      <InvoicesCurrentOrderTable />

      <div
        className={styles.border_content}
        style={{ marginBottom: "20px", marginTop: "5px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "25px",
            }}
          >
            <div className={styles.price_column}>
              <span>Sub Total:</span>
              <span>HST/GST:</span>
              <span>Total:</span>
            </div>
            <div className={styles.price_column}>
              <span>$190.00</span>
              <span>$0.00</span>
              <span>$190.00</span>
            </div>
          </div>
        </div>
      </div>
      <div style={{ fontSize: "14px", marginBottom: "10px" }}>
        <WarningText />
      </div>
      {!isPatient && <EvidentMark />}
    </div>
  );
};

export default InvoicesCurrentOrderForm;
