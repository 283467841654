import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "antd";
import { useForm } from "antd/es/form/Form";

import { CreateFilterPayload } from "@/api/admin/filters";
import { Filter } from "@/root/models/filter";
import { FilterForm } from "@/components";
import useAddFilter from "@/modules/FiltersCatalog/components/AddFilterModal/useAddFilter";

interface AddFilterModalProps {
  open: boolean;
  onCancel: () => void;
  onAdd: () => void;
  filter?: Filter;
}

const AddFilterModal: FC<AddFilterModalProps> = ({
  open,
  filter,
  onCancel,
  onAdd,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const onSuccess = () => {
    form.resetFields();
    onAdd();
  };

  const { addFilter, loading, error } = useAddFilter(onSuccess);

  const handleAdd = async (values: CreateFilterPayload) => {
    addFilter({
      ...values,
      parentFilterUUID: filter ? filter.filterUUID : "",
    });
  };

  const addSubmit = () => {
    form.submit();
  };

  return (
    <Modal
      maskClosable={false}
      okText={t("Створити")}
      title={<div style={{ marginBottom: 20 }}>{t("Створення фільтру")}</div>}
      open={open}
      onCancel={onCancel}
      onOk={addSubmit}
      okButtonProps={{ loading }}
    >
      <FilterForm
        form={form}
        handleSubmit={handleAdd}
        error={error}
        groupDisabled={filter ? filter.level === 2 : false}
      />
    </Modal>
  );
};

export default AddFilterModal;
