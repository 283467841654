import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Table } from "antd";
import { ColumnsType } from "antd/es/table";

import { CouriersAvailability } from "@/root/models/couriersAvailability";
import useCouriersAvailability from "@/hooks/useCouriersAvailability";

const CouriersWidget: FC = () => {
  const { t } = useTranslation();
  const { couriersAvailabilities, getCouriersAvailabilitiesLoading } =
    useCouriersAvailability({});

  const casesColumns: ColumnsType<CouriersAvailability> = [
    {
      dataIndex: "courierUUID",
      key: "courierUUID",
      title: t("Кур'єр"),
      render: (courier: CouriersAvailability["courierUUID"]) => (
        <span>{courier.name}</span>
      ),
    },
    {
      dataIndex: "startTime",
      key: "startTime",
      title: t("Час початку"),
    },
    {
      dataIndex: "endTime",
      key: "endTime",
      title: t("Час закінчення"),
    },
    {
      dataIndex: "courierAvailabilityStatus",
      key: "courierAvailabilityStatus",
      title: t("Статус"),
      render: (
        courierAvailabilityStatus: CouriersAvailability["courierAvailabilityStatus"]
      ) => <span>{courierAvailabilityStatus.name}</span>,
    },
  ];

  return (
    <Table
      columns={casesColumns}
      dataSource={couriersAvailabilities?.map((a) => ({
        ...a,
        key: a.courierUUID.courierUUID,
      }))}
      loading={getCouriersAvailabilitiesLoading}
      pagination={false}
    />
  );
};

export default CouriersWidget;
