import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { EventTypes } from "@/root/models/event";
import { ResponseDataWithPagination } from "@/api/types";

export const EventsApi = {
  getEvents: async (params: {
    "page-limit": number;
    page: number;
    date?: string;
    objectType?: "case" | "task";
    mainEntry?: boolean;
    universalTime?: number
  }): Promise<ResponseDataWithPagination<EventTypes[]>> => {

    return await makeRequest<EventTypes[], true>(
      {
        url: apiBaseurl + "/events",
        params,
      },
      { pagination: true }
    );
  },
};
