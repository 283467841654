import { ITooth } from "@/components/TeethWidget/components/Tooth";
import adultTeethSwg from "@/components/TeethWidget/adultTeeth";
import childTeethSwg from "@/components/TeethWidget/childTeeth";
import { ChosenTooth } from "@/components/TeethWidget/index";
import { jaws } from "@/root/consts";
import { JawType } from "@/root/types";
import { getJawTeeth } from "@/utils/getJawTeeth";

export const swgTeethToWidgetTeeth = (
  formulaType?: "child" | "adult"
): ITooth[] => {
  return (formulaType === "adult" ? adultTeethSwg : childTeethSwg).map(
    ({
      position,
      id,
      tooth,
      connector,
      chain,
      extraProductCircle,
      implantCircle,
      crown,
      currentProduct,
      number,
      jaw,
    }) => ({
      position,
      id,
      jaw,
      connectorNode: connector,
      chainNode: chain,
      toothNode: tooth,
      extraProductCircleNode: extraProductCircle,
      implantCircleNode: implantCircle,
      crownNode: crown,
      numberNode: number,
      currentProductNode: currentProduct,
      isCurrent: false,
      isPoint: false,
      tapped: false,
      arc: false,
      isCrown: false,
      selected: false,
      isConnected: false,
      isChainOpen: false,
      containAnotherProduct: false,
      color: null,
    })
  );
};

export const getChosenTeethWithExtractedJaws = (chosenTeeth: ChosenTooth[]) => {
  return chosenTeeth
    .slice()
    .reverse()
    .flatMap((tooth) => {
      if (jaws.includes(tooth.toothID as JawType)) {
        return getJawTeeth(tooth.toothID as JawType).map((toothID) => ({
          toothID,
          isPoint: tooth.isPoint,
          selected: tooth.selected,
          crown: tooth.crown,
          color: tooth.color,
          group: tooth.group,
        }));
      }
      return tooth;
    });
};

export const getGroupExtremePositions = (
  groupedTeeth: ChosenTooth[][],
  teeth: ITooth[]
) => {
  return groupedTeeth
    .filter((teethGroup) => teethGroup.every((t) => t.crown))
    .map((teethGroup) => {
      const chosenToothPositions = teethGroup.map((tooth) => {
        const foundTooth = teeth.find((t) => t.id === tooth.toothID);
        return foundTooth?.position;
      }) || [0, 0];

      const validPositions = chosenToothPositions.filter(Boolean) as number[];
      const jobToothMax = Math.max(...validPositions);
      const jobToothMin = Math.min(...validPositions);

      return [jobToothMin, jobToothMax];
    });
};

export const getToothIndexInGroupedTeethExtremePositions = (
  tooth: ITooth,
  groupedTeethExtremePositions: number[][]
) => {
  const isToothInRange = (positions: number[]) => {
    const [jobToothMin, jobToothMax] = positions;
    return Boolean(
      jobToothMax &&
        jobToothMin &&
        tooth &&
        tooth.position <= jobToothMax &&
        tooth.position >= jobToothMin
    );
  };

  return groupedTeethExtremePositions.findIndex(isToothInRange);
};
