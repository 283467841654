export const groupByTooth = <T extends { tooth: string | null }>(
  parameters: T[]
) => {
  const parameterMap = new Map<string, T[]>();

  for (const parameter of parameters) {
    const tooth = parameter.tooth as string;
    if (parameterMap.has(tooth)) {
      parameterMap.get(tooth)!.push(parameter);
    } else {
      parameterMap.set(tooth, [parameter]);
    }
  }

  return Array.from(parameterMap.values());
};
