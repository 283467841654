import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import { PriceFormula } from "@/root/models/priceFormula";

export interface CreatePriceFormulaPayload {
  name: string;
  formula: string;
}

export const PriceFormulasApi = {
  getPriceFormulas: async (): Promise<PriceFormula[]> => {
    const data = await makeRequest<PriceFormula[]>({
      url: apiBaseurl + "/price-formulas",
    });
    return data;
  },

  create: async (payload: CreatePriceFormulaPayload): Promise<PriceFormula> => {
    const data = await makeRequest<PriceFormula>({
      url: apiBaseurl + "/price-formulas",
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async ({
    id,
    payload,
  }: {
    id: string;
    payload: CreatePriceFormulaPayload;
  }): Promise<PriceFormula> => {
    const data = await makeRequest<PriceFormula>({
      url: apiBaseurl + `/price-formulas/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/price-formulas/${id}`,
      method: "delete",
    });
  },
};
