import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import styles from "./Sum.module.scss";

import { Block } from "@/components/common";

interface SumProps {
  price: string | number;
}

const Sum: FC<SumProps> = ({ price }) => {
  const { t } = useTranslation();

  return (
    <Block className={styles.sum}>
      <strong>{t("Всього")}: </strong>
      {price}
    </Block>
  );
};

export default Sum;
