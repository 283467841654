import { useAppSelector } from "@/hooks/redux";
import { UserTypeEnum } from "../types";

export const useIsLab = () => {
  const userType = useAppSelector((state) => state.userReducer.user?.userType);
  const isLab =
    userType?.userTypeUUID === UserTypeEnum["Employee"] ||
    userType?.userTypeUUID === UserTypeEnum["Lab's admin"];

  const isAdmin = userType?.userTypeUUID === UserTypeEnum["Lab's admin"];
  return {
    isLab,
    isAdmin,
  };
};
