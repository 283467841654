import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "antd";

interface AvailabilityStatusProps {
  available: boolean;
  setAvailable: (available: boolean) => void;
}

const AvailabilityStatus: FC<AvailabilityStatusProps> = ({
  available,
  setAvailable,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {available ? (
        <Button
          style={{ margin: "0 10px" }}
          onClick={() => setAvailable(false)}
        >
          {t('Встановити статус "Недоступний"')}
        </Button>
      ) : (
        <Button
          style={{ margin: "0 10px" }}
          onClick={() => setAvailable(false)}
        >
          {t('Встановити статус "Доступний"')}
        </Button>
      )}
    </>
  );
};

export default AvailabilityStatus;
