import { apiBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";
import { UserType } from "@/root/models/userType";

export const UserTypesApi = {
  getUserTypes: async (): Promise<UserType[]> => {
    const data = await makeRequest<UserType[]>({
      url: apiBaseurl + "/user-types",
    });
    return data;
  },
};
