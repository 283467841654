import React, { FC, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import cellStyles from "../../LaboratoryCaseForm.module.scss";

import { CaseFormState } from "@/components/CaseForm/CaseFormContext";
import PriceItem from "./PriceItem";

interface PricesProps {
  elements:
    | { id: string; price: number | null; isError: boolean }[]
    | undefined;
  job: CaseFormState.Job;
  onPriceChange: (
    jobID: string,
    elementID: string,
    price: number | null
  ) => void;
}

const Prices: FC<PricesProps> = ({ elements, job, onPriceChange }) => {
  const pricesRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<{ id: string; height: number }[]>();

  useEffect(() => {
    setTimeout(() => {
      if (!pricesRef.current || !elements) {
        return;
      }

      const cell = pricesRef.current.parentElement;
      const prevCell = cell?.previousElementSibling;
      const prevCellChildren = prevCell?.children[0];

      if (!prevCellChildren) {
        return;
      }
      setHeight(
        elements.map((el, i) => ({
          id: el.id,
          height: prevCellChildren.children[i]?.getBoundingClientRect().height,
        }))
      );
    });
  }, [pricesRef.current, job, elements]);

  return (
    <div
      ref={pricesRef}
      className={classNames(
        cellStyles.cell,
        "laboratory-case-cell--no-pudding"
      )}
    >
      {elements?.map((element) => (
        <PriceItem
          key={element.id}
          element={element}
          height={
            height
              ? height.find((el) => el.id === element.id)?.height
              : undefined
          }
          onPriceChange={(elementID, price) =>
            onPriceChange(job.jobID, elementID, price)
          }
        />
      ))}
    </div>
  );
};

export default Prices;
