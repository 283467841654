import { CSSProperties, FC, ReactNode } from "react";

import styles from "./Print.module.scss";

interface PrintProps {
  children: ReactNode;
  display?: boolean;
  style?: CSSProperties;
}

const Print: FC<PrintProps> = ({ children, style, display = false }) => {
  return (
    <div
      style={{ display: display ? "block" : "none", ...style }}
      className={styles.container}
    >
      {children}
    </div>
  );
};

export default Print;
