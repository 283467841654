import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, notification, Space } from "antd";
import { useForm } from "antd/es/form/Form";

import styles from "./Part.module.scss";

import { Part } from "@/root/models/part";
import { CreatePartPayload, PartsApi } from "@/api/admin/parts";
import { PartForm } from "@/components";
import { AddPartModal } from "@/modules/Parts/components";

interface PartProps {
  part: Part;
  onDelete: (part: Part) => void;
  onEdit: (part: Part) => void;
  onAdd: (part: Part) => void;
}

const { confirm } = Modal;

const PartItem: FC<PartProps> = ({
  part,
  onDelete,
  onEdit,
  onAdd,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editError, setEditError] = useState("");

  const handleEdit = async (values: CreatePartPayload) => {
    try {
      setEditLoading(true);
      setEditError("");
      const newPart = await PartsApi.edit(part.sparePartUUID, {
        ...values,
        parentSparePartUUID: part.parentSparePartUUID,
      });
      onEdit(newPart);
      setEditModal(false);
    } catch (e: any) {
      setEditError(e.message);
    } finally {
      setEditLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      await PartsApi.delete(part.sparePartUUID);
      onDelete(part);
    } catch (e: any) {
      notification.error({
        message: e.message,
      });
    } finally {
      setDeleteLoading(false);
    }
  };

  const editSubmit = () => {
    form.submit();
  };

  const openEditModal = () => {
    form.setFieldsValue({
      name: part.name,
      isGroup: part.isGroup,
    });
    setEditModal(true);
  };

  const openAddModal = () => {
    setAddModal(true);
  };

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  const handleAddPart = (part: Part) => {
    onAdd(part);
    setAddModal(false);
  };

  return (
    <div
      className={styles.part}
    >
      <span>{part.name}</span>
      <Space className="tree-actions">
        {part.isGroup && part.level < 5 && (
          <Button size="small" onClick={openAddModal}>
            {t("Додати")}
          </Button>
        )}
        <Button size="small" onClick={openEditModal}>
          {t("Редагувати")}
        </Button>
        <Button
          loading={deleteLoading}
          size="small"
          onClick={handleDeleteButtonClick}
        >
          {t("Видалити")}
        </Button>
      </Space>
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={
          <div style={{ marginBottom: 20 }}>{t("Редагування матеріалу")}</div>
        }
        open={editModal}
        onCancel={() => setEditModal(false)}
        onOk={editSubmit}
        okButtonProps={{ loading: editLoading }}
      >
        <PartForm
          form={form}
          handleSubmit={handleEdit}
          error={editError}
          groupDisabled={part.level === 4}
        />
      </Modal>
      {part.isGroup && part.level < 5 && (
        <AddPartModal
          part={part}
          open={addModal}
          onCancel={() => setAddModal(false)}
          onAdd={handleAddPart}
        />
      )}
    </div>
  );
};

export default PartItem;
