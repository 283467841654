import { clientBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";
import { WidgetCaseStatus } from "@/root/models/widgetCaseStatus";

export const WidgetCaseStatusApi = {
  getStatuses: async () => {
    const data = await makeRequest<WidgetCaseStatus[]>({
      url: clientBaseurl + "/dashboard/widget-case-status",
    });
    return data;
  },
};
