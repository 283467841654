import useFormAutoFocusInput from "@/hooks/useFormAutoFocusInput";
import { Alert, Form, Input, InputNumber } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { t } from "i18next";

interface UnitOfMeasureFormProps {
  form: FormInstance;
  handleSubmit: (values: any) => void;
  error: string;
}

const UnitOfMeasureForm = ({
  form,
  handleSubmit,
  error,
}: UnitOfMeasureFormProps) => {
  const focusInputRef = useFormAutoFocusInput();

  return (
    <Form
      requiredMark={false}
      form={form}
      onFinish={handleSubmit}
      labelCol={{ span: 6 }}
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
        name="name"
        label={t("Назва")}
      >
        <Input
          ref={focusInputRef}
          placeholder={t("Введіть значення") as string}
        />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
        name="code"
        label={t("Код")}
      >
        <Input placeholder={t("Введіть значення") as string} />
      </Form.Item>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
        name="packagingRatio"
        label={t("Коефіцієнт упаковки")}
      >
        <InputNumber
          style={{ width: "100%" }}
          placeholder={t("Введіть значення") as string}
        />
      </Form.Item>

      {!!error && <Alert message={error} type="error" />}
    </Form>
  );
};

export default UnitOfMeasureForm;
