import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "antd";
import { useForm } from "antd/es/form/Form";

import { CreateExtraPayload, ExtrasApi } from "@/api/admin/extras";
import { Extra } from "@/root/models/extra";
import { ExtraForm } from "@/components";

interface AddExtraModalProps {
  open: boolean;
  onCancel: () => void;
  onAdd: (extra: Extra) => void;
  extra?: Extra;
}

const AddExtraModal: FC<AddExtraModalProps> = ({
  open,
  extra,
  onCancel,
  onAdd,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleAdd = async (values: CreateExtraPayload) => {
    try {
      setLoading(true);
      setError("");
      const newExtra = await ExtrasApi.create({
        ...values,
        parentExtraUUID: extra ? extra.extraUUID : "",
      });
      onAdd(newExtra);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const addSubmit = () => {
    form.submit();
  };

  return (
    <Modal
      maskClosable={false}
      okText={t("Створити")}
      title={<div style={{ marginBottom: 20 }}>{t("Створення додаткової роботи або послуги")}</div>}
      open={open}
      onCancel={onCancel}
      onOk={addSubmit}
      okButtonProps={{ loading }}
    >
      <ExtraForm
        form={form}
        handleSubmit={handleAdd}
        error={error}
        groupDisabled={extra ? extra.level === 4 : false}
      />
    </Modal>
  );
};

export default AddExtraModal;
