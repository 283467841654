import { FC, memo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Collapse, notification, Space } from "antd";

import Photo from "@/components/TaskPhotos/Photo";
import { createPhoto, uploadPhoto } from "@/utils/cameraHelpers";
import { FilesApi } from "@/api/files";

const { Panel } = Collapse;

export interface TaskPhoto {
  localFileUrl?: string;
  fileUUID: string;
  filename: string;
  description: string;
}

interface TaskPhotosProps {
  taskUUID?: string;
  photos: TaskPhoto[];
  setPhotos: (photos: TaskPhoto[]) => void;
  takePhotoButton?: boolean;
  presentation?: boolean;
  opened?: boolean;
}

const TaskPhotos: FC<TaskPhotosProps> = ({
  taskUUID,
  photos,
  setPhotos,
  takePhotoButton = false,
  presentation = false,
  opened = false,
}) => {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState<"create" | "upload">();

  const handleDescriptionChange = (photo: TaskPhoto, description: string) => {
    setPhotos(photos.map((p) => (p === photo ? { ...p, description } : p)));
  };

  const handleUploadPhotos =
    (type: "create" | "upload") => async (files: FileList | null) => {
      if (!files) {
        return;
      }
      try {
        setUploading(type);
        const file = files[0];
        const { fileUUID } = await FilesApi.createFile(file);
        setPhotos([
          ...photos,
          {
            description: "",
            fileUUID,
            filename: file.name,
            localFileUrl: URL.createObjectURL(file),
          },
        ]);
      } catch (error: any) {
        notification.error({ message: error.message || t("Невідома помилка") });
      } finally {
        setUploading(undefined);
      }
    };

  const handlePhotoClick = async () => {
    createPhoto(handleUploadPhotos("create"));
  };

  const handleUploadPhotosClick = () => {
    uploadPhoto(handleUploadPhotos("upload"));
  };

  return (
    <Collapse defaultActiveKey={(opened && "photos") || undefined}>
      <Panel header={t("Фотографії")} key="photos">
        <Space direction="vertical" style={{ width: "100%", padding: 10 }}>
          {presentation && !photos.length && <div>{t("Фото не додано")}</div>}
          {photos.map((photo) => (
            <Photo
              key={photo.fileUUID}
              presentation={presentation}
              taskUUID={taskUUID}
              photo={photo}
              onDescriptionChange={(description) =>
                handleDescriptionChange(photo, description)
              }
              onDelete={() => setPhotos(photos.filter((p) => p !== photo))}
            />
          ))}
        </Space>
        <div
          style={{
            display: "flex",
            gap: 10,
            padding: 10,
          }}
        >
          {!presentation && (
            <>
              {takePhotoButton && (
                <Button
                  style={{ flexGrow: 1 }}
                  loading={uploading === "create"}
                  onClick={handlePhotoClick}
                >
                  {t("Зробити фото")}
                </Button>
              )}
              <Button
                style={{ flexGrow: 1 }}
                loading={uploading === "upload"}
                onClick={handleUploadPhotosClick}
              >
                {t("Додати фото")}
              </Button>
            </>
          )}
        </div>
      </Panel>
    </Collapse>
  );
};

export default memo(TaskPhotos);
