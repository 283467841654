import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  CouriersAvailabilityApi,
  EditCouriersAvailabilityPayload,
} from "@/api/couriersAvailability";
import { CouriersAvailability } from "@/root/models/couriersAvailability";

const useCouriersAvailability = ({
  courierUUID,
  onEditSuccess,
  courierAvailabilityStatusUUID,
}: {
  courierUUID?: string;
  onEditSuccess?: () => void;
  courierAvailabilityStatusUUID?: string;
}) => {
  const queryClient = useQueryClient();
  const {
    data: couriersAvailabilities,
    isFetching: getCouriersAvailabilitiesLoading,
    error: getCouriersAvailabilitiesError,
  } = useQuery({
    queryKey: [
      "courierAvailability",
      courierUUID,
      courierAvailabilityStatusUUID,
    ],
    queryFn: () => CouriersAvailabilityApi.getAvailabilities(courierUUID),
    refetchInterval: 60000,
    refetchOnWindowFocus: true,
  });

  const editCouriersAvailabilityMutation = useMutation({
    mutationFn: CouriersAvailabilityApi.edit,
    onSuccess: (couriersAvailability: CouriersAvailability[]) => {
      queryClient.setQueryData(
        ["courierAvailability", courierUUID, courierAvailabilityStatusUUID],
        () => {
          onEditSuccess?.();

          return couriersAvailability;
        }
      );
    },
  });

  const editCouriersAvailability = (
    payload: EditCouriersAvailabilityPayload
  ) => {
    editCouriersAvailabilityMutation.mutate(payload);
  };

  const editError = (editCouriersAvailabilityMutation.error as Error)
    ?.message as string;

  return {
    couriersAvailabilities,
    getCouriersAvailabilitiesLoading,
    getCouriersAvailabilitiesError,
    editCouriersAvailability,
    editLoading: editCouriersAvailabilityMutation.isLoading,
    editError,
  };
};

export default useCouriersAvailability;
