import { FC, memo, useEffect, useState } from "react";
import classNames from "classnames";

import Title from "antd/lib/typography/Title";

import styles from "./DCInfo.module.scss";

import { DCInfoApi, IDCInfo } from "@/api/dcInfo";
import { Phone } from "@/components";
import { apiBaseurl } from "@/api/baseurl";

interface DCInfoProps {
  centered?: boolean;
}

const DCInfo: FC<DCInfoProps> = memo(({ centered }) => {
  const [info, setInfo] = useState<IDCInfo | null>(null);

  useEffect(() => {
    (async () => {
      try {
        const dcInfo = await DCInfoApi.getInfo();
        setInfo(dcInfo);
      } catch (e) {}
    })();
  }, []);

  const phones = info?.phones.length ? info.phones.split(", ") : [];

  return (
    <div
      className={classNames(styles.container, { [styles.centered]: centered })}
    >
      <div className={styles.meta}>
        <img
          src={apiBaseurl + "/logo"}
          onError={(e) => {
            // @ts-ignore
            e.target.style.display = "none";
          }}
        />
        {info && <Title level={2}>{info?.welcomeText}</Title>}
        {info && !!phones?.length && (
          <div className={styles.phones}>
            {phones.map((phone) => (
              <Phone className={styles.phone} key={phone} phone={phone} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

export default DCInfo;
