import { Invoice } from "@/root/models/invoice";
import { Alert, Button, Form, FormInstance, Input, Select } from "antd";
import { t } from "i18next";
import styles from "../InvoicePanel.module.scss";
import { useEffect, useState } from "react";
import useAdminClients from "@/hooks/useAdminClients";
import { useIsLab } from "@/root/models/isLab";
import {
  InvoiceCreatePayloadProps,
  InvoiceEditPayloadProps,
} from "@/api/invoices";
import dayjs from "dayjs";
import { DATE_FORMAT } from "@/root/consts";
import { PaidCheckbox } from "./PaidCheckbox";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import "animate.css";
import { CustomCasesTable } from "@/components";
import useInvoiсesTable from "../hooks/useInvoiсesTable";

interface InvoiceViewFormProps {
  form: FormInstance;
  invoiceCasesView: Invoice.InvoiceCase[];
  type: "create" | "view" | "edit";
  paid?: boolean;
  handleInvoiceCreate?: (payload: InvoiceCreatePayloadProps) => void;
  handleEdit?: (values: InvoiceEditPayloadProps) => Promise<void>;
  applied?: boolean;
}
const { TextArea } = Input;
const { Option } = Select;

const InvoiceForm = ({
  form,
  type,
  paid = false,
  invoiceCasesView,
  handleInvoiceCreate,
  handleEdit,
}: InvoiceViewFormProps) => {
  const { isLab } = useIsLab();
  const [tableOpen, setTableOpen] = useState(false);

  const { adminClients, getAdminClientsLoading } = useAdminClients({});
  const [chosenClient, setChosenClient] = useState("");

  const {
    //First table
    invoiceCases,
    selectedInvoiceCases,
    setSelectedInvoiceCases,

    //Second table
    unpaidInvoiceCases,
    selectedUnpaidInvoiceCases,
    setSelectedUnpaidInvoiceCases,

    //Cases add
    unpdaidCasesAdd,
    //Cases remove
    invoiceCasesRemove,
  } = useInvoiсesTable(invoiceCasesView, chosenClient, form);

  useEffect(() => {
    if (type === "edit" && !chosenClient) {
      setChosenClient(form.getFieldValue("client"));
    }
  }, [type, chosenClient]);

  //Invoice create
  const handleSubmit = (values: any) => {
    handleInvoiceCreate &&
      handleInvoiceCreate({
        clientUUID: chosenClient,
        description: values.description || "",
        amount: values.amount,
        paid: false,
        dueDate: dayjs(new Date()).format(DATE_FORMAT),
        cases: invoiceCases.map((instanse) => ({
          caseUUID: instanse.case.caseUUID,
          rowUUID: instanse.rowUUID,
          sum: instanse.sum,
          description: "",
        })),
      });
  };

  //Recalculate sum on invoice cases remove
  useEffect(() => {
    form.setFieldValue(
      "amount",
      invoiceCases.reduce((prev, cur) => prev + cur.sum, 0)
    );
  }, [invoiceCases]);

  return (
    <>
      <Form
        className={styles.formItem_input}
        requiredMark={false}
        form={form}
        labelCol={{ span: 7 }}
        onFinish={type === "edit" ? handleEdit : handleSubmit}
      >
        <Form.Item name="invoiceNumber" label={t("Номер рахунку-фактури")}>
          <Input disabled />
        </Form.Item>
        {isLab ? (
          <Form.Item
            name="client"
            label={t("Контрагент")}
            rules={[{ required: true, message: t("Заповніть поле") as string }]}
          >
            <Select
              listHeight={1000}
              loading={getAdminClientsLoading}
              optionFilterProp="children"
              disabled={(type === "view" || paid) && true}
              onChange={(value: string) => setChosenClient(value)}
            >
              {adminClients.map((client) => (
                <Option key={client.clientUUID} value={client.clientUUID}>
                  {client.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        <Form.Item name="description" label={t("Опис")}>
          <TextArea disabled={type === "view"} rows={1} />
        </Form.Item>
        <Form.Item name="amount" label={t("Повна сума")}>
          <Input
            type="number"
            disabled={(type === "view" || !!invoiceCases.length) && true}
          />
        </Form.Item>
        {type !== "create" && (
          <PaidCheckbox
            type={type}
            disable={type === "view" || paid}
            paid={paid}
          />
        )}
      </Form>

      <Button
        onClick={() => setTableOpen(!tableOpen)}
        icon={tableOpen ? <UpOutlined /> : <DownOutlined />}
        style={{ marginBottom: 10 }}
      >
        {t("Деталі")}
      </Button>

      {tableOpen && (
        <div className={"animate__animated animate__fadeIn"}>
          <div
            style={{ fontWeight: 600, fontSize: "16px", marginBottom: "10px" }}
          >
            {t("Кейси рахунків-фактур")}
          </div>
          <CustomCasesTable
            tableInstancesArray={invoiceCases}
            selectedInstances={selectedInvoiceCases}
            setSelectedInstances={setSelectedInvoiceCases}
            btnLabel={t("Видалити вибране")}
            onClick={invoiceCasesRemove}
            type={type}
            paid={paid}
          />
          {(type === "create" || type === "edit") && (
            <div>
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "16px",
                  marginBottom: "10px",
                  marginTop: "40px",
                }}
              >
                {t("Неоплачені кейси")}
              </div>
              {!chosenClient ? (
                <Alert
                  type="info"
                  message={t("Оберіть спочатку контрагента")}
                  style={{ width: "50%" }}
                />
              ) : (
                <CustomCasesTable
                  tableInstancesArray={unpaidInvoiceCases}
                  selectedInstances={selectedUnpaidInvoiceCases}
                  setSelectedInstances={setSelectedUnpaidInvoiceCases}
                  btnLabel={t("Додати вибране")}
                  onClick={unpdaidCasesAdd}
                  type={type}
                  instance="unpaidInvoiceCases"
                  paid={paid}
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default InvoiceForm;
