import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Form, Select } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import styles from "./SearchPatient.module.scss";

import { CreatePatientModal } from "@/components";
import usePatients from "@/hooks/usePatients";
import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";

const { Option } = Select;

interface SearchPatientProps {}

const SearchPatient: FC<SearchPatientProps> = ({}) => {
  const { t } = useTranslation();
  const { patients, getPatientsLoading } = usePatients({});
  const [modal, setModal] = useState(false);
  const { state, dispatch } = useCaseFormContext();

  const handleSearch = (patientUUID: string, value: string) => {
    const patient = patients.find((p) => p.patientUUID === patientUUID);
    if (patient) {
      if (
        patient.name.toLowerCase().includes(value.toLowerCase()) ||
        patient.phone.toLowerCase().includes(value.toLowerCase()) ||
        patient.mail.toLowerCase().includes(value.toLowerCase())
      ) {
        return true;
      }
    }
    return false;
  };

  const handleSelect = (value: string) => {
    const patient = patients.find((e) => e.patientUUID === value);
    if (!patient) {
      return;
    }
    dispatch({
      type: ActionCreatorTypes.SetPatient,
      payload: patient,
    });
  };

  return (
    <div className={styles.container}>
      <Form.Item
        className={styles.formItem}
        name="patientUUID"
        labelCol={{ span: 10 }}
        initialValue={state.patient?.patientUUID}
        label={t("Пацієнт")}
        rules={[{ required: true, message: t("Заповніть поле") as string }]}
      >
        <Select
          listHeight={1000}
          showSearch
          loading={getPatientsLoading}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.value ? handleSearch(option.value as string, input) : false
          }
          onSelect={handleSelect}
          onClick={(e) => e.stopPropagation()}
        >
          {patients.map((patient) => (
            <Option key={patient.patientUUID} value={patient.patientUUID}>
              {patient.name}
            </Option>
          ))}
          {!patients.find(
            (e) => e.patientUUID === state.patient?.patientUUID
          ) &&
            state.patient && (
              <Option
                key={state.patient.patientUUID}
                value={state.patient.patientUUID}
              >
                {state.patient.name}
              </Option>
            )}
        </Select>
      </Form.Item>
      <Button
        className="add-item-button"
        icon={<PlusOutlined />}
        onClick={() => setModal(true)}
      />
      <CreatePatientModal open={modal} onCancel={() => setModal(false)} />
    </div>
  );
};

export default SearchPatient;
