import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Form, Select } from "antd";

import useClientEmployees from "@/hooks/useClientEmployees";

const { Option } = Select;

interface ClientEmployeesSelectProps {
  clientUUIDs: string[];
}

const ClientEmployeesSelect: FC<ClientEmployeesSelectProps> = ({
  clientUUIDs,
}) => {
  const { t } = useTranslation();
  const { clientEmployees, getClientEmployeesLoading } = useClientEmployees({
    clientUUID: clientUUIDs.join(","),
  });

  return (
    <Form.Item name="clientEmployees" label={t("Лікар")}>
      <Select
        style={{ width: 200 }}
        mode="multiple"
        loading={getClientEmployeesLoading}
        listHeight={1000}
        allowClear
      >
        {clientEmployees?.map((clientEmployee) => (
          <Option
            key={clientEmployee.clientsEmployeeUUID}
            value={clientEmployee.clientsEmployeeUUID}
          >
            {clientEmployee.name || " "}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default ClientEmployeesSelect;
