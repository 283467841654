import { useEffect, useState } from "react";
import { MetadataApi } from "@/api/metadata";
import { APP_VERSION } from "@/root/config";

const useVersionControl = () => {
  const [versionError, setVersionError] = useState<string | null>();

  useEffect(() => {
    (async () => {
      try {
        const data = await MetadataApi.getMetadata();
        console.log(data.version);
        if (data.version !== APP_VERSION) {
          setVersionError(data.version);
        }
      } catch (e) {}
    })();
  }, []);

  return {
    versionError,
  };
};

export default useVersionControl;
