import useFormAutoFocusInput from "@/hooks/useFormAutoFocusInput";
import { Alert, Form, Input } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { t } from "i18next";

interface WarehouseFormProps {
  form: FormInstance;
  handleSubmit: (values: any) => void;
  error: string;
}

const WarehouseForm = ({ form, handleSubmit, error }: WarehouseFormProps) => {
  const focusInputRef = useFormAutoFocusInput();

  return (
    <Form requiredMark={false} form={form} onFinish={handleSubmit}>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
        name="name"
        label={t("Назва")}
      >
        <Input
          ref={focusInputRef}
          placeholder={t("Введіть значення") as string}
        />
      </Form.Item>

      {!!error && <Alert message={error} type="error" />}
    </Form>
  );
};

export default WarehouseForm;
