import React, { FC, useEffect, useRef, useState } from "react";

import styles from "./LeftPanel.module.scss";

import { Details, Teeth } from "@/components/CaseForm/components";
import { Block } from "@/components/common";
import ChooseJaw from "@/components/CaseForm/components/ChooseJaw";
import { useCaseFormContext } from "@/components/CaseForm/CaseFormContext";

const PanelLeft: FC = () => {
  const { state } = useCaseFormContext();
  const panelLeftTopRef = useRef<HTMLDivElement>(null);
  const [detailsCaseHeight, setDetailsCaseHeight] = useState<number>();

  useEffect(() => {
    if (!panelLeftTopRef.current) {
      return;
    }
    setDetailsCaseHeight(panelLeftTopRef.current?.offsetHeight);
  }, [panelLeftTopRef.current, state.onlyDetailsEdit]);

  return (
    <div className={styles.panelLeft}>
      <div ref={panelLeftTopRef}>
        <Details />
      </div>
      <Block
        style={
          detailsCaseHeight
            ? { height: `calc(100% - ${detailsCaseHeight + 10}px` }
            : undefined
        }
        className={styles.teeth}
      >
        <ChooseJaw />
        <Teeth disabled={state.onlyDetailsEdit}/>
      </Block>
    </div>
  );
};

export default PanelLeft;
