import { CSSProperties, FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Progress, Table } from "antd";
import { ColumnsType } from "antd/es/table";

import styles from "../DashboardTable.module.scss";

import { Block, Pagination } from "@/components/common";
import useTasks from "@/hooks/useTasks";
import { Task } from "@/root/models/task";
import { CreateTaskModal } from "@/components";
import { TableActions } from "@/modules/Tasks/components";
import { useAppSelector } from "@/hooks/redux";
import { UserTypeEnum } from "@/root/types";
import useTableScrollHeight from "@/hooks/TableHooks/useTableScrollHeight";
import useTablePaginationRange from "@/hooks/TableHooks/useTablePaginationRange";
import useModalOpen from "../../../../hooks/useModalOpen";

import { GrDocument, GrDocumentVerified } from "react-icons/gr";

interface TasksTableProps {
  style?: CSSProperties;
  caseUUID: string;
  tabHeight: number;
}

const TasksTable: FC<TasksTableProps> = ({ caseUUID, style, tabHeight }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const userType = useAppSelector((state) => state.userReducer.user?.userType);
  const { tasks, pages, getTasksLoading } = useTasks({
    caseUUID,
    page,
    pageLimit: 25,
  });

  const [createTaskModal, setCreateTaskModal] = useState(false);

  const { paginationRange, tableRef } =
    useTablePaginationRange(getTasksLoading);

  const { tableScrollHeight, paginationRef } = useTableScrollHeight(tabHeight);

  const { modal, onHandleCancel, onEditSuccess, isModalOpen } = useModalOpen();

  const tasksColumns: ColumnsType<Task> = [
    {
      width: 60,
      dataIndex: "applied",
      key: "applied",
      render: (applied: Task["applied"]) => (
        <div style={{ paddingLeft: 10, paddingTop: 5 }}>
          {applied ? (
            <GrDocumentVerified size={20} />
          ) : (
            <GrDocument size={20} />
          )}
        </div>
      ),
    },
    {
      width: 88,
      title: t("Прогрес"),
      dataIndex: "taskStatus",
      key: "taskStatus",
      render: (status: Task["taskStatus"]) => (
        <Progress size={50} type="circle" percent={status.progress} />
      ),
    },
    {
      title: "№",
      dataIndex: "taskNumber",
      key: "taskNumber",
    },
    {
      width: 88,
      title: t("Дата завершення"),
      dataIndex: "dueDate",
      key: "dueDate",
      render: (date: Task["dueDate"]) => {
        if (date.slice(11) === "00:00:00") {
          return <span>{date.slice(0, 10)}</span>;
        }

        return (
          <div style={{ textAlign: "center" }}>
            <span>{date.slice(0, 10)}</span>
            <span style={{ fontSize: 13 }}>{date.slice(10)}</span>
          </div>
        );
      },
    },
    {
      title: t("Кур'єр"),
      dataIndex: "courier",
      key: "courier",
      render: (courier: Task["courier"]) => <span>{courier.name}</span>,
    },
    {
      title: t("Статус завдання"),
      dataIndex: "taskStatus",
      key: "taskStatus",
      render: (status: Task["taskStatus"]) => <span>{status.name}</span>,
    },
  ];

  if (
    userType?.userTypeUUID === UserTypeEnum["Employee"] ||
    userType?.userTypeUUID === UserTypeEnum["Lab's admin"]
  ) {
    tasksColumns.push({
      width: 35,
      title: "",
      key: "action",
      render: (_: any, task: Task) => (
        <TableActions
          direction="vertical"
          modal={modal}
          onCancel={onHandleCancel}
          onEditSuccess={onEditSuccess}
          onModalOpen={() => {
            isModalOpen(task.taskUUID);
          }}
          task={task}
          tasksQueryProps={{
            caseUUID,
            page,
            pageLimit: 25,
          }}
        />
      ),
    });
  }

  return (
    <>
      <Block style={style}>
        <div className={styles.container}>
          <Table
            size="small"
            scroll={{
              y: tableScrollHeight,
            }}
            style={{ userSelect: "none" }}
            ref={tableRef}
            columns={tasksColumns}
            dataSource={tasks.map((c) => ({ ...c, key: c.taskUUID }))}
            pagination={false}
            loading={getTasksLoading}
            onRow={(task) => ({
              onDoubleClick: () => {
                isModalOpen(task.taskUUID);
              },
            })}
          />
          <div
            ref={paginationRef}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Pagination
              currentPage={page}
              pages={pages || 1}
              paginationRange={paginationRange}
              setCurrentPage={setPage}
            />
          </div>
        </div>
        <CreateTaskModal
          open={createTaskModal}
          onCancel={() => setCreateTaskModal(false)}
          tasksQueryProps={{
            caseUUID,
            page,
            pageLimit: 25,
          }}
        />
      </Block>
    </>
  );
};

export default TasksTable;
