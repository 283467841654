import { useEffect, useState } from "react";

import { ParametersGroup } from "@/root/models/parameterGroup";
import { ProductsParametersGroupApi } from "@/api/productsParametersGroup";

const useGroups = () => {
  const [groups, setGroups] = useState<ParametersGroup[]>([]);
  const [getGroupsLoading, setGetGroupsLoading] = useState(false);
  const [getGroupsError, setGetGroupsError] = useState("");

  useEffect(() => {
    (async () => {
      try {
        setGetGroupsLoading(true);
        const groups = await ProductsParametersGroupApi.getGroups();
        setGroups(groups);
      } catch (e: any) {
        setGetGroupsError(e.message);
      } finally {
        setGetGroupsLoading(false);
      }
    })();
  }, []);

  const createGroup = (group: ParametersGroup) => {
    setGroups((prev) => [...prev, group]);
  };

  const deleteGroup = (id: string) => {
    setGroups((prev) =>
      prev.filter((p) => p.productsParametersGroupUUID !== id)
    );
  };

  const editGroup = (id: string, group: ParametersGroup) => {
    setGroups((prev) =>
      prev.map((prevGroup) => {
        if (prevGroup.productsParametersGroupUUID === id) {
          return group;
        }
        return prevGroup;
      })
    );
  };

  return {
    groups,
    getGroupsLoading,
    getGroupsError,
    createGroup,
    deleteGroup,
    editGroup,
  };
};

export default useGroups;
