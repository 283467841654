import { FC, FocusEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Checkbox, Modal } from "antd";
import { LockOutlined, UnlockOutlined } from "@ant-design/icons";

import {
  ActionCreatorTypes,
  CaseFormState,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import { Block } from "@/components/common";
import { Product } from "@/root/models/product";
import useClickOutside from "@/hooks/useClickOutside";
import { useAppDispatch } from "@/hooks/redux";
import { setActiveTeeth } from "@/store/reducers/activeProductTeethSlice";
import { setProductLock } from "@/store/reducers/productLockSlice";
import ProductCreateForm from "./ProductCreateForm";
import useClickOutsideProductItem from "@/hooks/useClickOutsideProductItem";

const { confirm } = Modal;

interface ProductProps {
  job: CaseFormState.Job;
  product?: CaseFormState.Job.Product;
  cutProduct?: CaseFormState.Job.Product;
  setCutProduct: (p?: CaseFormState.Job.Product) => void;
  withLock: boolean;
  onParamsBtnClick: (value: string) => void;
}

const ProductItem: FC<ProductProps> = ({
  job,
  product,
  cutProduct,
  setCutProduct,
  withLock,
  onParamsBtnClick,
}) => {
  const { state, dispatch } = useCaseFormContext();
  const { t } = useTranslation();
  const dispatchSelect = useAppDispatch();

  const activeProduct = job.products.find(
    (product) => product.jobProductUUID === job.activeProductID
  );

  const [productsSearchValue, setProductsSearchValue] = useState("");
  const [chooseProductOpen, setChooseProductOpen] = useState(false);

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => setChooseProductOpen(false), true);

  const isActive = product
    ? state.activeJobID === job.jobID && activeProduct === product
    : false;

  const [isLock, setIsLock] = useState(false);

  useEffect(() => {
    if (isActive && !isLock) {
      setIsLock(false);
      dispatchSelect(setProductLock(false));
    } else if (!isActive && !isLock) {
      setIsLock(true);
      dispatchSelect(setProductLock(true));
    }
  }, [isActive, isLock]);

  useEffect(() => {
    if (activeProduct && !!activeProduct?.teeth?.length) {
      dispatchSelect(setActiveTeeth(activeProduct.teeth));
    } else {
      dispatchSelect(setActiveTeeth([]));
    }
  }, [activeProduct, activeProduct?.teeth]);

  useEffect(() => {
    if (!product?.product?.name) {
      return;
    }
    setProductsSearchValue(product?.product?.name);
  }, [job]);

  const addProduct = (selected: {
    product: Product;
    teethColor: string | undefined;
  }) => {
    dispatch({
      type: ActionCreatorTypes.AddProduct,
      payload: {
        product: selected.product,
        teethColor: !!selected.product.color
          ? selected.product.color
          : selected.teethColor,
        activeJobID: job.jobID,
      },
    });
    setChooseProductOpen(false);
    setProductsSearchValue(selected.product.name);
  };

  const setProduct = (selected: {
    product: Product;
    teethColor: string | undefined;
  }) => {
    if (product) {
      dispatch({
        type: ActionCreatorTypes.SetProduct,
        payload: {
          productUUID: product.jobProductUUID,
          activeJobID: job.jobID,
          product: selected.product,
          teethColor: !!selected.product.color
            ? selected.product.color
            : selected.teethColor,
        },
      });
    }
    setChooseProductOpen(false);
    setProductsSearchValue(selected.product.name);
  };

  const handleProductSelect = (selected: {
    product: Product;
    teethColor: string | undefined;
  }) => {
    if (product) {
      setProduct(selected);
    } else {
      addProduct(selected);
    }
  };

  const handleProductActive = (value: string | undefined) => {
    if (!product) {
      return;
    }
    dispatch({
      type: ActionCreatorTypes.SetActiveProduct,
      payload: {
        jobUUID: job.jobID,
        productUUID: value,
      },
    });
  };

  const handleDeleteClick = () => {
    if (!product) {
      return;
    }

    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => {
        dispatch({
          type: ActionCreatorTypes.DeleteProduct,
          payload: {
            activeJobID: job.jobID,
            productUUID: product.jobProductUUID,
          },
        });
      },
    });
  };

  const handleQuantityChange = (quantity: number | null) => {
    if (!product) {
      return;
    }

    if (!quantity || quantity < 0) {
      return;
    }

    dispatch({
      type: ActionCreatorTypes.SetProductQuantity,
      payload: {
        activeProductUUID: product.jobProductUUID,
        quantity,
      },
    });
  };

  const handleCombinedProductCheck = (checked: boolean) => {
    if (!product) {
      return;
    }
    dispatch({
      type: ActionCreatorTypes.SetCombinedProductChecked,
      payload: {
        checked,
        activeJobID: job.jobID,
        activeProductID: product.jobProductUUID,
      },
    });
  };

  const handleInputFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (e.target.value === product?.product?.name) {
      setProductsSearchValue("");
    }
    setChooseProductOpen(true);
  };

  const pastProduct = () => {
    if (!cutProduct || !product) {
      return;
    }
    dispatch({
      type: ActionCreatorTypes.PasteProduct,
      payload: {
        activeJobID: job.jobID,
        sourceProduct: cutProduct,
        targetProduct: product,
      },
    });

    dispatch({
      type: ActionCreatorTypes.DeleteProduct,
      payload: {
        activeJobID: job.jobID,
        productUUID: cutProduct.jobProductUUID,
      },
    });

    setCutProduct();
  };

  useEffect(() => {
    if (!chooseProductOpen) {
      product?.product && setProductsSearchValue(product?.product?.name);
    }
  }, [chooseProductOpen]);

  const handleDeleteToothClick = (productUUID: string, tooth: string) => {
    dispatch({
      type: ActionCreatorTypes.SetTooth,
      payload: { activeProductID: productUUID, tooth },
    });
  };

  const hasParams = !!product?.productsParametersGroups?.length;
  const hasRequiredParams = product?.productsParametersGroups?.some((elem) =>
    elem.productsParameters.some((item) => item.required && !item.value)
  );

  const blockRef = useRef(null);

  useClickOutsideProductItem(blockRef, () => {
    handleProductActive(undefined);
  });

  return (
    <div ref={blockRef}>
      <Block
        style={{
          background: isActive ? "#e6f4ff" : undefined,
        }}
        onClick={() => {
          handleProductActive(product?.jobProductUUID);
        }}
      >
        <div ref={wrapperRef}>
          <ProductCreateForm
            ref={wrapperRef}
            isLock={isLock}
            chooseProductOpen={chooseProductOpen}
            productsSearchValue={productsSearchValue}
            handleProductSelect={handleProductSelect}
            setChooseProductOpen={setChooseProductOpen}
            setProductsSearchValue={setProductsSearchValue}
            handleInputFocus={handleInputFocus}
            product={product}
            isActive={isActive}
            handleQuantityChange={handleQuantityChange}
            handleDeleteToothClick={handleDeleteToothClick}
            handleDeleteClick={handleDeleteClick}
          />
        </div>

        {product && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 5,
            }}
          >
            {withLock && isLock ? (
              <Button
                style={{ marginTop: 5 }}
                size="middle"
                icon={<LockOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsLock(false);
                  handleProductActive(product.jobProductUUID);
                  dispatchSelect(setProductLock(false));
                }}
              />
            ) : (
              <Button
                style={{ marginTop: 5 }}
                size="middle"
                icon={<UnlockOutlined />}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsLock(true);
                  dispatchSelect(setProductLock(true));
                }}
              />
            )}
            <Checkbox
              disabled={isLock && true}
              checked={product.combinedProduct}
              onChange={(e) => handleCombinedProductCheck(e.target.checked)}
            >
              {t("Комбінований виріб")}
            </Checkbox>
            {hasParams && (
              <Button
                onClick={() => onParamsBtnClick(product.jobProductUUID)}
                style={{
                  backgroundColor: hasRequiredParams ? "#ff808d" : "#ffffff",
                }}
              >
                {t("Параметри")}
              </Button>
            )}

            {cutProduct ? (
              cutProduct.jobProductUUID === product.jobProductUUID ? (
                <Button size="small" onClick={() => setCutProduct()}>
                  {t("Скасувати")}
                </Button>
              ) : (
                <Button size="small" onClick={() => pastProduct()}>
                  {t("Вставити")}
                </Button>
              )
            ) : (
              <Button
                disabled={isLock && true}
                size="small"
                onClick={() => setCutProduct(product)}
              >
                {t("Вирізати")}
              </Button>
            )}
          </div>
        )}
      </Block>
    </div>
  );
};

export default ProductItem;
