import { createSlice } from "@reduxjs/toolkit";

interface InitialStateProps {
  activeTeeth: string[];
}

const initialState: InitialStateProps = {
  activeTeeth: [],
};

const activeProductTeethSlice = createSlice({
  name: "teethSelect",
  initialState,
  reducers: {
    setActiveTeeth: (state, action) => {
      state.activeTeeth = action.payload;
    },
  },
});

export const { setActiveTeeth } = activeProductTeethSlice.actions;

export default activeProductTeethSlice.reducer;
