import { apiBaseurl } from "@/api/baseurl";
import { Employee } from "@/root/models/employee";
import makeRequest from "@/api/makeRequest";

export const EmployeesApi = {
  getEmployees: async (): Promise<Employee[]> => {
    const data = await makeRequest<Employee[]>({
      url: apiBaseurl + "/employees",
    });
    return data;
  },
};
