import { apiBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";

export interface ApplyPayload {
  documentType: string;
  objectUUID: string;
  action: "apply" | "unapply";
}

export const ApplyApi = {
  apply: async ({
    payload,
  }: {
    payload: ApplyPayload;
  }): Promise<ApplyPayload> => {
    const data = await makeRequest<ApplyPayload>({
      url: `${apiBaseurl}/document-apply`,
      method: "put",
      data: payload,
    });
    return data;
  },
};
