import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Space, Table } from "antd";

import styles from "./AdminClients.module.scss";

import useAdminClients from "@/hooks/useAdminClients";
import { AdminClient } from "@/root/models/adminClient";
import {
  CreateAdminClient,
  TableActions,
} from "@/modules/AdminClients/components";
import { Block } from "@/components/common";
import { UserTypeEnum } from "@/root/types";
import { useAppSelector } from "@/hooks/redux";
import { ClientEmployees } from "@/modules";
import useModalOpen from "@/hooks/useModalOpen";

const AdminClients: FC = () => {
  const { t } = useTranslation();
  const { adminClients, getAdminClientsLoading } = useAdminClients({});
  const [chosenClient, setChosenClient] = useState<string>();
  const userType = useAppSelector((state) => state.userReducer.user?.userType);

  const { modal, onHandleCancel, onEditSuccess, isModalOpen } = useModalOpen();

  const clientsColumns = [
    {
      title: t("Ім'я.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Адреса"),
      dataIndex: "address",
      key: "address",
    },
  ];

  userType?.userTypeUUID === UserTypeEnum["Lab's admin"] &&
    clientsColumns.push({
      title: "",
      key: "action", // @ts-ignore
      render: (_: any, client: AdminClient) => (
        <TableActions
          modal={modal}
          onCancel={onHandleCancel}
          onEditSuccess={onEditSuccess}
          onModalOpen={() => {
            isModalOpen(client.clientUUID);
          }}
          client={client}
        />
      ),
    });

  return (
    <div style={{ display: "flex", gap: 8, minHeight: "calc(100vh - 62px)" }}>
      <Block style={{ flexGrow: 0 }}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <CreateAdminClient />
          <Table
            style={{ minWidth: 200 }}
            columns={clientsColumns}
            dataSource={adminClients.map((c) => ({ ...c, key: c.clientUUID }))}
            pagination={false}
            loading={getAdminClientsLoading}
            rowClassName={(adminClient) =>
              adminClient.clientUUID === chosenClient
                ? styles.activeRow
                : undefined
            }
            onRow={(adminClient) => {
              return {
                onClick: () => setChosenClient(adminClient.clientUUID),
                onDoubleClick: () => isModalOpen(adminClient.clientUUID),
              };
            }}
          />
        </Space>
      </Block>
      <Block style={{ flexGrow: 1 }} className={styles.clientEmployees}>
        {chosenClient && <ClientEmployees clientUUID={chosenClient} />}
      </Block>
    </div>
  );
};

export default AdminClients;
