import { FC } from "react";

import { Space } from "antd";
import dayjs from "dayjs";

import AdvancedTasksSearchForm, {
  AdvancedTaskSearchParameters,
} from "./AdvancedTasksSearchForm";
import { DATE_FORMAT } from "@/root/consts";

export interface AdvancedTasksSearchValues {
  taskStatusUUID?: string;
  courierUUID?: string;
  cases?: string;
  trackingNumber?: string;
  completionDate?: string;
  dueDate?: string;
}

interface AdvancedSearchProps {
  onSearch: (values: AdvancedTasksSearchValues) => void;
  full: boolean;
}

const AdvancedSearch: FC<AdvancedSearchProps> = ({ onSearch, full }) => {
  const handleSubmit = (values: AdvancedTaskSearchParameters) => {
    onSearch({
      taskStatusUUID: values.taskStatusUUID?.join(",") || undefined,
      courierUUID: values.courierUUID?.join(",") || undefined,
      cases: values.cases?.join(",") || undefined,
      trackingNumber: values.trackingNumber || undefined,
      completionDate:
        values.completionDate
          ?.map((d) => dayjs(new Date(d)).format(DATE_FORMAT))
          .join(",") || undefined,
      dueDate:
        values.dueDate
          ?.map((d) => dayjs(new Date(d)).format(DATE_FORMAT))
          .join(",") || undefined,
    });
  };

  return (
    <Space direction="vertical">
      <AdvancedTasksSearchForm full={full} handleSubmit={handleSubmit} />
    </Space>
  );
};

export default AdvancedSearch;
