import EvidentMark from "../../components/EvidentMark";
import WarningText from "../../components/WarningText";
import styles from "../InvoiceAccountSentToTheMail.module.scss";

const InvoiceToMailFooter = () => {
  return (
    <div
      style={{
        textAlign: "center",
        fontSize: "18px",
      }}
    >
      <div className={styles.footer_border_content}>
        <div style={{ paddingBottom: "15px" }}>
          <div>
            This is a patient specific dental device for the exclusive use of
            the patient.
          </div>
          <div>
            Conforms to the essential requirements as set out within Annex 1 of
            the Medical Devices Directive 93/42/EEC
          </div>
        </div>
        <div style={{ paddingBottom: "15px" }}>
          Warning! - Keep away from extreme heat or cold. Do not store Study or
          Reference Models in plastic bags.
        </div>
        <div>
          <WarningText />
        </div>
      </div>
      <div style={{ textTransform: "uppercase", marginBottom: "20px" }}>
        All products are made to the highest possible standard, but we cannot
        guarantee to supply a sterile product
      </div>
      <EvidentMark />
    </div>
  );
};

export default InvoiceToMailFooter;
