import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Alert, Form, FormInstance, Input } from "antd";

import { CreatePanPayload } from "@/api/admin/pans";
import useFormAutoFocusInput from "@/hooks/useFormAutoFocusInput";

interface PanFormProps {
  form: FormInstance<CreatePanPayload>;
  handleSubmit: (values: CreatePanPayload) => void;
  error: string;
}

const PanForm: FC<PanFormProps> = ({ form, handleSubmit, error }) => {
  const { t } = useTranslation();
  const focusInputRef = useFormAutoFocusInput();

  return (
    <Form requiredMark={false} form={form} onFinish={handleSubmit}>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Заповніть поле") as string,
          },
        ]}
        name="name"
        label={t("Назва")}
      >
        <Input
          ref={focusInputRef}
          placeholder={t("Введіть значення") as string}
        />
      </Form.Item>

      {!!error && <Alert message={error} type="error" />}
    </Form>
  );
};

export default PanForm;
