import { IMask } from "antd-mask-input";

export const pages = {
  login: {
    path: "/login",
  },
  client: {
    path: "/client",
    dashboard: {
      path: "/client/dashboard",
    },
    cases: {
      path: "/client/cases",
      create: {
        path: "/client/cases/create",
      },
      edit: {
        path: "/client/cases/edit",
      },
    },
    invoices: {
      path: "/client/invoices",
    },
    myCompany: {
      path: "/client/my-company",
    },
    reports: {
      path: "/client/reports",
    },
  },
  admin: {
    path: "/admin",
    dashboard: {
      path: "/admin/dashboard",
    },
    clients: {
      path: "/admin/clients",
    },
    employees: {
      path: "/admin/employees",
    },
    productCatalog: {
      path: "/admin/product-catalog",
    },
    parts: {
      path: "/admin/parts",
    },
    cases: {
      path: "/admin/cases",
      create: {
        path: "/admin/cases/create",
      },
      edit: {
        path: "/admin/cases/edit",
      },
    },
    invoices: {
      path: "/admin/invoices",
    },
    payments: {
      path: "/admin/payments",
    },
    tasks: {
      path: "/admin/tasks",
    },
    warehouses: {
      path: "/admin/warehouses",
    },
    settings: {
      path: "/admin/settings",
    },
    pricelist: {
      path: "/admin/pricelist",
    },
  },
  lab: {
    path: "/lab",
    dashboard: {
      path: "/lab/dashboard",
    },
    clients: {
      path: "/lab/clients",
    },
    cases: {
      path: "/lab/cases",
      create: {
        path: "/lab/cases/create",
      },
      edit: {
        path: "/lab/cases/edit",
      },
    },
    invoices: {
      path: "/lab/invoices",
    },
    payments: {
      path: "/lab/payments",
    },
    tasks: {
      path: "/lab/tasks",
    },
    warehouses: {
      path: "/lab/warehouses",
    },
    pricelist: {
      path: "/lab/pricelist",
    },
  },
  patientsPortal: {
    path: "/api/patients-portal",
  },
  caseWarranty: {
    path: "/api/case-warranty",
  },
  courier: {
    path: "/courier",
    dashboard: {
      path: "/courier/dashboard",
    },
    info: {
      path: "/courier/info",
    },
  },
};

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_2 = "DD-MM-YYYY";
export const TOOTH_COLOR = "transparent";

export const adultFormulaTeethIDs = [
  "FDI_11",
  "FDI_12",
  "FDI_13",
  "FDI_14",
  "FDI_15",
  "FDI_16",
  "FDI_17",
  "FDI_18",
  "FDI_21",
  "FDI_22",
  "FDI_23",
  "FDI_24",
  "FDI_25",
  "FDI_26",
  "FDI_27",
  "FDI_28",
  "FDI_31",
  "FDI_32",
  "FDI_33",
  "FDI_34",
  "FDI_35",
  "FDI_36",
  "FDI_37",
  "FDI_38",
  "FDI_41",
  "FDI_42",
  "FDI_43",
  "FDI_44",
  "FDI_45",
  "FDI_46",
  "FDI_47",
  "FDI_48",
  "FDI_AUJ",
  "FDI_ALJ",
];

export const childFormulaTeethIDs = [
  "FDI_51",
  "FDI_52",
  "FDI_53",
  "FDI_54",
  "FDI_55",
  "FDI_61",
  "FDI_62",
  "FDI_63",
  "FDI_64",
  "FDI_65",
  "FDI_71",
  "FDI_72",
  "FDI_73",
  "FDI_74",
  "FDI_75",
  "FDI_81",
  "FDI_82",
  "FDI_83",
  "FDI_84",
  "FDI_85",
  "FDI_CUJ",
  "FDU_CLJ",
];

export const jaws = ["FDI_AUJ", "FDI_ALJ", "FDI_CUJ", "FDI_CLJ"] as const;

type TeethMappingKeys =
  | (typeof adultFormulaTeethIDs)[number]
  | (typeof childFormulaTeethIDs)[number];

export const teethMapping: {
  [key in TeethMappingKeys]: {
    Palmer: number | string;
    Universal: number | string;
  };
} = {
  FDI_CUJ: {
    Palmer: "FDI_CUJ",
    Universal: "FDI_CUJ",
  },
  FDU_CLJ: {
    Palmer: "FDU_CLJ",
    Universal: "FDU_CLJ",
  },
  FDI_AUJ: {
    Palmer: "FDI_AUJ",
    Universal: "FDI_AUJ",
  },
  FDI_ALJ: {
    Palmer: "FDI_ALJ",
    Universal: "FDI_ALJ",
  },
  FDI_18: {
    Palmer: "UL8",
    Universal: 1,
  },
  FDI_17: {
    Palmer: "UL7",
    Universal: 2,
  },
  FDI_16: {
    Palmer: "UL6",
    Universal: 3,
  },
  FDI_15: {
    Palmer: "UL5",
    Universal: 4,
  },
  FDI_14: {
    Palmer: "UL4",
    Universal: 5,
  },
  FDI_13: {
    Palmer: "UL3",
    Universal: 6,
  },
  FDI_12: {
    Palmer: "UL2",
    Universal: 7,
  },
  FDI_11: {
    Palmer: "UL1",
    Universal: 8,
  },
  FDI_21: {
    Palmer: "UR1",
    Universal: 9,
  },
  FDI_22: {
    Palmer: "UR2",
    Universal: 10,
  },
  FDI_23: {
    Palmer: "UR3",
    Universal: 11,
  },
  FDI_24: {
    Palmer: "UR4",
    Universal: 12,
  },
  FDI_25: {
    Palmer: "UR5",
    Universal: 13,
  },
  FDI_26: {
    Palmer: "UR6",
    Universal: 14,
  },
  FDI_27: {
    Palmer: "UR7",
    Universal: 15,
  },
  FDI_28: {
    Palmer: "UR8",
    Universal: 16,
  },
  FDI_38: {
    Palmer: "LR8",
    Universal: 17,
  },
  FDI_37: {
    Palmer: "LR7",
    Universal: 18,
  },
  FDI_36: {
    Palmer: "LR6",
    Universal: 19,
  },
  FDI_35: {
    Palmer: "LR5",
    Universal: 20,
  },
  FDI_34: {
    Palmer: "LR4",
    Universal: 21,
  },
  FDI_33: {
    Palmer: "LR3",
    Universal: 22,
  },
  FDI_32: {
    Palmer: "LR2",
    Universal: 23,
  },
  FDI_31: {
    Palmer: "LR1",
    Universal: 24,
  },
  FDI_41: {
    Palmer: "LL1",
    Universal: 25,
  },
  FDI_42: {
    Palmer: "LL2",
    Universal: 26,
  },
  FDI_43: {
    Palmer: "LL3",
    Universal: 27,
  },
  FDI_44: {
    Palmer: "LL4",
    Universal: 28,
  },
  FDI_45: {
    Palmer: "LL5",
    Universal: 29,
  },
  FDI_46: {
    Palmer: "LL6",
    Universal: 30,
  },
  FDI_47: {
    Palmer: "LL7",
    Universal: 31,
  },
  FDI_48: {
    Palmer: "LL8",
    Universal: 32,
  },
  FDI_55: {
    Palmer: "FDI_55",
    Universal: "FDI_55",
  },
  FDI_54: {
    Palmer: "FDI_54",
    Universal: "FDI_54",
  },
  FDI_53: {
    Palmer: "FDI_53",
    Universal: "FDI_53",
  },
  FDI_52: {
    Palmer: "FDI_52",
    Universal: "FDI_52",
  },
  FDI_51: {
    Palmer: "FDI_51",
    Universal: "FDI_51",
  },
  FDI_61: {
    Palmer: "FDI_61",
    Universal: "FDI_61",
  },
  FDI_62: {
    Palmer: "FDI_62",
    Universal: "FDI_62",
  },
  FDI_63: {
    Palmer: "FDI_63",
    Universal: "FDI_63",
  },
  FDI_64: {
    Palmer: "FDI_64",
    Universal: "FDI_64",
  },
  FDI_65: {
    Palmer: "FDI_65",
    Universal: "FDI_65",
  },
  FDI_71: {
    Palmer: "FDI_71",
    Universal: "FDI_71",
  },
  FDI_72: {
    Palmer: "FDI_72",
    Universal: "FDI_72",
  },
  FDI_73: {
    Palmer: "FDI_73",
    Universal: "FDI_73",
  },
  FDI_74: {
    Palmer: "FDI_74",
    Universal: "FDI_74",
  },
  FDI_75: {
    Palmer: "FDI_75",
    Universal: "FDI_75",
  },
  FDI_85: {
    Palmer: "FDI_85",
    Universal: "FDI_85",
  },
  FDI_84: {
    Palmer: "FDI_84",
    Universal: "FDI_84",
  },
  FDI_83: {
    Palmer: "FDI_83",
    Universal: "FDI_83",
  },
  FDI_82: {
    Palmer: "FDI_82",
    Universal: "FDI_82",
  },
  FDI_81: {
    Palmer: "FDI_81",
    Universal: "FDI_81",
  },
};

export const timeMask = [
  {
    mask: "H:M",
    blocks: {
      H: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 24,
      },
      M: {
        mask: IMask.MaskedRange,
        from: 0,
        to: 60,
      },
    },
  },
];
