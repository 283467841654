import { useEffect, useRef, useState } from "react";

const useTableScrollHeight = (tabHeight: number) => {
  const [tableScrollHeight, setTableScrollHeight] = useState("");

  const headerRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);
  const paginationRef = useRef<HTMLDivElement>(null);
  const advancedSearchRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      const adjustHeight = 105 + (tabHeight || 0);
      setTableScrollHeight(
        `calc(100vh - ${headerRef.current?.clientHeight || 0}px - 
           ${selectRef.current?.clientHeight || 0}px  - ${
          advancedSearchRef.current?.clientHeight || 0
        }px - ${
          paginationRef.current?.clientHeight || 0
        }px - ${adjustHeight}px)`
      );
    });
  }, [
    selectRef.current,
    paginationRef.current,
    headerRef.current,
    advancedSearchRef.current,
    tabHeight,
  ]);

  return {
    tableScrollHeight,
    headerRef,
    paginationRef,
    selectRef,
    advancedSearchRef,
  };
};

export default useTableScrollHeight;
