import { useMutation, useQueryClient } from "@tanstack/react-query";

import { CreateProductPayload, ProductsApi } from "@/api/admin/products";
import { Product } from "@/root/models/product";

const useAddProduct = (onSuccess: () => void) => {
  const queryClient = useQueryClient();
  const createProductMutation = useMutation({
    mutationFn: ProductsApi.create,
    onSuccess: (product) => {
      queryClient.setQueryData(["products"], (prev) => {
        const prevProducts = (prev as Product[]) || [];
        if (product.level === 1) {
          return [...prevProducts, product];
        } else {
          const recursiveAdd = (products: Product[]): Product[] => {
            return products.map((prevProduct) => {
              const productCopy = { ...prevProduct };
              if (productCopy.productUUID === product.parentProductUUID) {
                if (productCopy.children) {
                  productCopy.children = [...productCopy.children, product];
                } else {
                  productCopy.children = [product];
                }
              } else {
                if (productCopy.children) {
                  productCopy.children = recursiveAdd(productCopy.children);
                }
              }
              return productCopy;
            });
          };

          return recursiveAdd(prevProducts);
        }
      });
      onSuccess();
    },
  });

  const addProduct = (payload: CreateProductPayload) => {
    createProductMutation.mutate(payload);
  };

  const error = (createProductMutation.error as Error)?.message as string;

  return { addProduct, loading: createProductMutation.isLoading, error };
};

export default useAddProduct;
