import { apiBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";
import { Metadata } from "@/root/types";

export const MetadataApi = {
  getMetadata: async (): Promise<Metadata> => {
    const data = await makeRequest<Metadata>({
      url: apiBaseurl + "/metadata",
    });
    return data;
  },
};
