import { Space, Typography } from "antd";
import { t } from "i18next";
import { Block } from "@/components/common";
import InvoicesTable from "./components/InvoicesTable";
import SearchInvoice from "./components/SearchInvoice";
import useInvoices from "@/hooks/useInvoices";
import { useEffect, useState } from "react";
import CreateInvoice from "./components/CreateInvoice";
import { useIsLab } from "@/root/models/isLab";

const { Title } = Typography;

export interface InvoiceSearchParamsGroup {
  adminClients: string | undefined;
  date: string | undefined;
  paid: undefined | boolean;
}

const InvoicesPanel = () => {
  const [page, setPage] = useState(1);

  const { isLab } = useIsLab();

  const [searchParams, setSearchParams] = useState<InvoiceSearchParamsGroup>({
    adminClients: "",
    date: "",
    paid: undefined,
  });

  const { invoices, pages, getInvoicesLoading, refetch } = useInvoices({
    page,
    clientUUID: searchParams.adminClients || undefined,
    date: searchParams.date || undefined,
    paid: searchParams.paid,
  });

  useEffect(() => {
    refetch();
  }, []);

  const onHandleSearch = ({
    adminClients = "",
    date = "",
    paid = undefined,
  }: InvoiceSearchParamsGroup) => {
    setSearchParams({
      adminClients,
      date,
      paid,
    });
  };

  return (
    <Block>
      <Space
        direction="vertical"
        style={{ justifyContent: "center", width: "50%" }}
      >
        <Title level={3} style={{ textAlign: "center" }}>
          {t("Рахунки-фактури")}
        </Title>

        <SearchInvoice onSearch={onHandleSearch} />
        {isLab && <CreateInvoice />}
      </Space>

      <InvoicesTable
        tabHeight={265}
        setPage={setPage}
        invoices={invoices}
        pages={pages}
        getInvoicesLoading={getInvoicesLoading}
        page={page}
      />
    </Block>
  );
};

export default InvoicesPanel;
