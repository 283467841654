import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Descriptions, Space } from "antd";

import { Loader } from "@/components/common";
import useCases from "@/modules/Cases/useCases";
import { EyeOutlined } from "@ant-design/icons";

interface TaskProps {
  id: string;
  onTaskWatch: (id: string) => void;
}

const TaskInfo: FC<TaskProps> = ({ id, onTaskWatch }) => {
  const { t } = useTranslation();

  const { cases, getCasesLoading, getCasesError } = useCases({
    caseNumber: id,
  });

  if (getCasesError) {
    return <span>{getCasesError as string}</span>;
  }

  const medCase = cases[0];

  return (
    <div>
      {getCasesLoading ? (
        <div style={{ marginTop: 30 }}>
          <Loader center />
        </div>
      ) : medCase ? (
        <Space direction="vertical" style={{ width: "100%" }}>
          <Descriptions
            bordered
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item label={t("Номер")}>
              {medCase.caseNumber}
            </Descriptions.Item>
            <Descriptions.Item label={t("Дата")}>
              {medCase.arrival.slice(0, 16)}
            </Descriptions.Item>
            <Descriptions.Item label={t("Дата завершення")}>
              {medCase.dueDate.slice(0, 16)}
            </Descriptions.Item>
            <Descriptions.Item label={t("Контрагент")}>
              {medCase.client.name}
            </Descriptions.Item>
            <Descriptions.Item label={t("Лікар")}>
              {medCase.clientsEmployee?.name}
            </Descriptions.Item>
            <Descriptions.Item label={t("Статус")}>
              {medCase.status.name}
            </Descriptions.Item>
            <Descriptions.Item label={t("Останнє завдання")}>
              <Space>
                {medCase.lastTask?.taskNumber}
                <Button
                  icon={<EyeOutlined />}
                  onClick={() =>
                    medCase.lastTask && onTaskWatch(medCase.lastTask.taskNumber)
                  }
                />
              </Space>
            </Descriptions.Item>
          </Descriptions>
        </Space>
      ) : (
        t("Замовлення не знайдено")
      )}
    </div>
  );
};

export default TaskInfo;
