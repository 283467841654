import React, { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";

import styles from "../../LaboratoryCase.module.scss";

import { Job } from "@/root/models/case";
import ProductsParametersGroup from "@/components/LaboratoryCase/components/Job/ProductsParametersGroup";

interface JobProps {
  job: Job;
}

const JobItem: FC<JobProps> = ({ job }) => {
  const { t } = useTranslation();

  return (
    <>
      {job.products.map((product) => (
        <Fragment key={product.jobProductUUID}>
          <div className={styles.row}>
            {t("Виріб")}: {product.product.name}
          </div>
          <div style={{ display: "block" }} className={styles.row}>
            {product.differentTeethParameters ? (
              product.teeth.map((tooth) => (
                <div key={tooth}>
                  <div>
                    {t("Зуб")}: {tooth.slice(4)}
                  </div>
                  <div>{t("Параметри")}:</div>
                  <div>
                    {product.productsParametersGroups.map((group) => (
                      <ProductsParametersGroup
                        key={
                          group.productsParametersGroup
                            .productsParametersGroupUUID
                        }
                        group={group}
                        tooth={tooth}
                      />
                    ))}
                  </div>
                </div>
              ))
            ) : (
              <div>
                <div>
                  {t("Зуби")}:{" "}
                  {product.teeth?.map((tooth) => tooth.slice(4)).join(", ")}
                </div>
                <div>{t("Параметри")}:</div>
                <div>
                  {product.productsParametersGroups.map((group) => (
                    <ProductsParametersGroup
                      key={
                        group.productsParametersGroup
                          .productsParametersGroupUUID
                      }
                      group={group}
                      tooth={null}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        </Fragment>
      ))}
    </>
  );
};

export default JobItem;
