import { createSlice } from "@reduxjs/toolkit";

const productLockSlice = createSlice({
  name: "teethSelect",
  initialState: {
    isLock: false,
  },
  reducers: {
    setProductLock: (state, action) => {
      state.isLock = action.payload;
    },
  },
});

export const { setProductLock } = productLockSlice.actions;

export default productLockSlice.reducer;
