import { Button, Modal, Space, notification } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { Invoice } from "@/root/models/invoice";
import { useForm } from "antd/es/form/Form";
import { useAppSelector } from "@/hooks/redux";
import { UserTypeEnum } from "@/root/types";
import { useEffect } from "react";
import useInvoices from "@/hooks/useInvoices";
import { InvoiceEditPayloadProps } from "@/api/invoices";
import InvoiceModal from "./InvoiceModal";

const { confirm } = Modal;

interface TableActionsProps {
  invoice: Invoice;
  modal: string | null;
  onCancel: () => void;
  onEditSuccess: () => void;
  onModalViewOpen: () => void;
  onModalEditOpen: () => void;
  modalType: "edit" | "view" | null;
}

const TableActions = ({
  invoice,
  modal,
  onCancel,
  onEditSuccess,
  onModalViewOpen,
  onModalEditOpen,
  modalType,
}: TableActionsProps) => {
  const userType = useAppSelector((state) => state.userReducer.user?.userType);

  const [form] = useForm();

  const submit = () => {
    form.submit();
  };

  const onDeleteError = (message: string) => {
    notification.error({
      message,
    });
  };

  const { deleteInvoice, editInvoice, editLoading, deleteLoading } =
    useInvoices({
      onEditSuccess,
      onDeleteError,
    });

  const onInvoiceEdit = () => {
    if (invoice.paid) {
      confirm({
        title: t("Рахунок-фактура вже сплачений!"),
        content: t("Ви дійсно хочете внести зміни?"),
        okText: t("Так"),
        okType: "danger",
        cancelText: t("No"),
        onOk: onModalEditOpen,
      });
    } else {
      onModalEditOpen();
    }
  };

  const handleDelete = async () => {
    await deleteInvoice(invoice.invoiceUUID);
  };

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  const handleEdit = async (values: InvoiceEditPayloadProps) => {
    const invoiceEditAction = () => {
      editInvoice(invoice.invoiceUUID, {
        description: values.description,
        amount: values.amount,
        clientUUID: invoice.client.clientUUID,
        dueDate: invoice.dueDate,
        paid: values.paid,
        cases: invoice.cases.map((elem) => ({
          caseUUID: elem.case.caseUUID,
          rowUUID: elem.rowUUID,
          sum: elem.sum,
        })),
      });
    };
    if (invoice.paid) {
      confirm({
        title: t("Рахунок-фактура вже сплачений!"),
        content: t("Ви дійсно хочете внести зміни?"),
        okText: t("Так"),
        okType: "danger",
        cancelText: t("No"),
        onOk: () => invoiceEditAction(),
      });
    } else {
      invoiceEditAction();
    }
  };

  useEffect(() => {
    if (modal) {
      form.setFieldsValue({
        invoiceNumber: invoice.invoiceNumber,
        client: invoice.client.clientUUID,
        description: invoice.description,
        amount: invoice.amount,
        paid: invoice.paid,
      });
    }
  }, [modal, modalType]);

  return (
    <>
      {userType?.userTypeUUID === UserTypeEnum["Employee"] ||
      userType?.userTypeUUID === UserTypeEnum["Lab's admin"] ? (
        <Space className="table-actions" direction="vertical">
          <Button
            size="small"
            icon={<EyeOutlined />}
            onClick={onModalViewOpen}
          />
          <Button
            size="small"
            icon={<EditOutlined />}
            onClick={onInvoiceEdit}
          />
          <Button
            size="small"
            icon={<DeleteOutlined />}
            danger
            loading={deleteLoading}
            onClick={handleDeleteButtonClick}
          />
        </Space>
      ) : (
        <Button size="small" icon={<EyeOutlined />} onClick={onModalViewOpen} />
      )}

      <InvoiceModal
        modal={modal}
        invoice={invoice}
        modalType={modalType}
        form={form}
        onCancel={onCancel}
        submit={submit}
        handleEdit={handleEdit}
        editLoading={editLoading}
      />
    </>
  );
};

export default TableActions;
