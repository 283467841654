import { Space } from "antd";
import DoctorInformation from "../../components/DoctorInformation";
import globalStyles from "../../InvoicesPdf.module.scss";
import styles from "../InvoiceAccountSentToTheMail.module.scss";
import InvoiceOrderForm from "./InvoiceOrderForm";

const InvoiceToMailMain = () => {
  return (
    <div style={{ marginBottom: "40px" }}>
      <Space
        style={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          className={globalStyles.font_bold}
          style={{ padding: "60px 80px", maxWidth: "500px" }}
        >
          <DoctorInformation />
        </div>
        <Space direction="vertical" style={{ gap: "60px", textAlign: "end" }}>
          <div
            style={{ textTransform: "uppercase", fontSize: "30px" }}
            className={globalStyles.font_bold}
          >
            Invoice
          </div>
          <div>
            <div>Pan: 210</div>
            <div>Invoice Number: 27701</div>
            <div>Route: /24 Jul 2023</div>
            <div>Date: 24 Jul 2023</div>
          </div>
        </Space>
      </Space>
      <Space
        style={{
          justifyContent: "space-around",
          width: "100%",
          gap: "250px",
          marginBottom: "25px",
        }}
      >
        <div className={styles.bordered_content}>
          <div className={styles.field_label}>Patient</div>
          <div>Joseph Day</div>
        </div>
        <div className={styles.bordered_content}>
          <div className={styles.field_label}>Reference</div>
        </div>
      </Space>
      <InvoiceOrderForm />
    </div>
  );
};

export default InvoiceToMailMain;
