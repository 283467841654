import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { Button, Modal, notification, Space } from "antd";
import { useForm } from "antd/es/form/Form";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { ClientPricelist, Pricelist } from "@/root/models/pricelist";
import {
  ClientPricelistsApi,
  CreateClientPricelistPayload,
} from "@/api/admin/clientPricelists";
import ClientPricelistForm from "@/components/PricelistForm/ClientPricelistForm";

const { confirm } = Modal;

interface TableActionsProps {
  pricelist: ClientPricelist;
  onDelete: (id: string) => void;
  onEdit: (id: string, pricelist: ClientPricelist) => void;
  modal: string | null;
  onCancel: () => void;
  onModalOpen: () => void;
  onEditSuccess: () => void;
}

const TableActions: FC<TableActionsProps> = ({
  pricelist,
  onDelete,
  onEdit,
  modal,
  onCancel,
  onEditSuccess,
  onModalOpen,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [editError, setEditError] = useState("");

  const handleDelete = async () => {
    try {
      setDeleteLoading(true);
      await ClientPricelistsApi.delete(pricelist.clientsPricelistUUID);
      onDelete(pricelist.clientsPricelistUUID);
    } catch (e: any) {
      notification.error({
        message: e.message,
      });
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleEdit = async (values: CreateClientPricelistPayload) => {
    try {
      setEditLoading(true);
      setEditError("");
      const newPricelist = await ClientPricelistsApi.edit(
        pricelist.clientsPricelistUUID,
        {
          ...values,
          activationDate: dayjs(values.activationDate).format("YYYY.MM.DD"),
        }
      );
      onEdit(pricelist.clientsPricelistUUID, newPricelist);
      onEditSuccess();
    } catch (e: any) {
      setEditError(e.message);
    } finally {
      setEditLoading(false);
    }
  };

  const submit = () => {
    form.submit();
  };

  const onHandleOpen = () => {
    onModalOpen();
  };

  useEffect(() => {
    if (modal) {
      form.setFieldsValue({
        ...pricelist,
        clientsPricelistUUID: pricelist.clientsPricelistUUID,
        pricelistTypeUUID: pricelist.pricelistType.pricelistTypeUUID,
        description: pricelist.description,
        clientUUID: pricelist.client.clientUUID,
        activationDate: dayjs(pricelist.activationDate),
        list: pricelist.list.map(({ price, item }) => ({
          itemUUID: Pricelist.List.GetItemUUID(item),
          price,
        })),
      });
    }
  }, [modal]);

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  return (
    <Space className="table-actions" size="middle">
      <Button size="small" icon={<EditOutlined />} onClick={onHandleOpen} />
      <Button
        size="small"
        icon={<DeleteOutlined />}
        loading={deleteLoading}
        danger
        onClick={handleDeleteButtonClick}
      />
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={
          <div style={{ marginBottom: 20 }}>{t("Редагування прайс листа")}</div>
        }
        open={modal === pricelist.clientsPricelistUUID}
        onCancel={onCancel}
        onOk={submit}
        okButtonProps={{ loading: editLoading }}
      >
        <ClientPricelistForm
          form={form}
          handleSubmit={handleEdit}
          error={editError}
          formType="edit"
        />
      </Modal>
    </Space>
  );
};

export default TableActions;
