import { Case } from "@/root/models/case";
import { CaseStatusEnum } from "@/root/types";

import { useEffect, useState } from "react";

const useSelectedCaseStatus = (cases: Case[]) => {
  const [caseStatus, setCaseStatus] = useState<CaseStatusEnum | undefined>(
    undefined
  );

  //Automatically fills Case status in Create task form according to added case status
  useEffect(() => {
    const getCaseStatus = () => {
      return cases.find(
        (elem) =>
          elem.status.caseStatusUUID === CaseStatusEnum["New"] ||
          elem.status.caseStatusUUID === CaseStatusEnum["Ready for pickup"]
      )
        ? setCaseStatus(CaseStatusEnum["Assigned to the Driver"])
        : cases.find(
            (elem) =>
              elem.status.caseStatusUUID === CaseStatusEnum["Done"] ||
              elem.status.caseStatusUUID ===
                CaseStatusEnum["Ready for delivery"]
          )
        ? setCaseStatus(CaseStatusEnum["Assigned to Driver for dental clinic"])
        : undefined;
    };
    getCaseStatus();
  }, [cases]);

  return { caseStatus };
};

export default useSelectedCaseStatus;
