import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Form, Input, Select } from "antd";

import styles from "./Paramaters.module.scss";

import { ProductParameter } from "@/root/models/productParameter";
import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import { getNestedFilters } from "@/components/ProductParameterForm";
import { Filter } from "@/root/models/filter";

const { Option } = Select;

interface ParameterInterface {
  parameter: ProductParameter;
  activeJobID: string | null;
  activeProductID: string | null;
  activeTooth: string | null;
  groupUUID: string;
  allFilters: Filter[];
  filter: { isFilter: boolean; value: string | undefined };
  required: boolean;
}

const Parameter: FC<ParameterInterface> = ({
  parameter,
  activeJobID,
  activeProductID,
  activeTooth,
  groupUUID,
  allFilters,
  filter,
  required,
}) => {
  const { t } = useTranslation();
  const { state, dispatch } = useCaseFormContext();

  const formParameter = useMemo(() => {
    const job = state.jobs.find((job) => job.jobID === activeJobID);
    const product = job?.products.find(
      (product) => product.jobProductUUID === activeProductID
    );
    const group = product?.productsParametersGroups?.find(
      (group) => group.productsParametersGroupUUID === groupUUID
    );
    return group?.productsParameters.find(
      (p) =>
        p.productsParameterUUID === parameter.productsParameterUUID &&
        (p.tooth || null) ===
          (product?.differentTeethParameters ? activeTooth : null)
    );
  }, [state]);

  const handleChange = (value: string[] | string) => {
    dispatch({
      type: ActionCreatorTypes.SetParameter,
      payload: {
        groupUUID,
        value,
        parameterUUID: parameter.productsParameterUUID,
        tooth: formParameter?.tooth || null,
        activeJobID: activeJobID || undefined,
        activeProductID: activeProductID || undefined,
      },
    });
  };

  const isList = parameter.type?.name.toLowerCase().includes("list");
  const isNumber =
    parameter.type?.name.toLowerCase().includes("number") && "number";
  const isFilter = parameter.type?.name.toLowerCase().includes("filter");

  const currentValue = formParameter?.value;

  const filteredParameters =
    isList &&
    parameter.list?.filter((item) => {
      return !(
        item.filtersValues.length &&
        !(
          filter.value &&
          getNestedFilters(
            allFilters,
            item.filtersValues.map((f) => f.filterUUID)
          )
            .map((f) => f.filterUUID)
            .includes(filter.value)
        )
      );
    });

  useEffect(() => {
    if (
      filteredParameters &&
      filteredParameters.length &&
      currentValue &&
      !filteredParameters.some((p) => p.value === currentValue)
    ) {
      dispatch({
        type: ActionCreatorTypes.SetParameter,
        payload: {
          groupUUID,
          value: parameter.defaultValue,
          parameterUUID: parameter.productsParameterUUID,
          tooth: formParameter?.tooth || null,
          activeJobID: activeJobID || undefined,
        },
      });
    }
  }, [filter.value]);

  return (
    <Form.Item
      className={styles.item}
      labelCol={{ span: 10 }}
      name={parameter.productsParameterUUID + new Date().toJSON()}
      required={true}
      rules={[
        {
          required,
          message: t("Заповніть поле") as string,
        },
      ]}
      initialValue={currentValue}
      label={
        <span>
          {required && <span style={{ color: "red" }}>*</span>} {parameter.name}
        </span>
      }
    >
      {isList ? (
        <Select
          disabled={state.onlyDetailsEdit}
          listHeight={1000}
          allowClear
          onClick={(e) => e.stopPropagation()}
          onSelect={(value) => handleChange(value)}
          onClear={() => handleChange("")}
        >
          {filteredParameters &&
            filteredParameters.map((item) => (
              <Option key={item.value} value={item.value}>
                {item.value}
              </Option>
            ))}
        </Select>
      ) : typeof parameter.defaultValue === "boolean" ? (
        <Select
          disabled={state.onlyDetailsEdit}
          listHeight={1000}
          onClick={(e) => e.stopPropagation()}
          onSelect={(value) => handleChange(value)}
          onClear={() => handleChange("")}
        >
          <Option key="yes" value={"true"}>
            {t("Так")}
          </Option>
          <Option key="no" value={"false"}>
            {t("Ні")}
          </Option>
        </Select>
      ) : isFilter ? (
        <Select
          disabled={state.onlyDetailsEdit}
          listHeight={1000}
          onClick={(e) => e.stopPropagation()}
          onChange={handleChange}
          onClear={() => handleChange("")}
        >
          {getNestedFilters(
            allFilters,
            parameter.filtersValues.map((f) => f.filterUUID)
          )?.map((filter) => (
            <Option key={filter.filterUUID} value={filter.filterUUID}>
              {filter.name}
            </Option>
          ))}
        </Select>
      ) : (
        <Input
          disabled={state.onlyDetailsEdit}
          type={isNumber || undefined}
          onClick={(e) => e.stopPropagation()}
          onBlur={(e) => handleChange(e.target.value)}
        />
      )}
    </Form.Item>
  );
};

export default Parameter;
