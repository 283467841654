import { Settings } from "@/root/models/settings";
import { apiBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";

export const SettingsApi = {
  getSettings: async (): Promise<Settings> => {
    const data = await makeRequest<Settings>({
      url: apiBaseurl + "/settings",
    });
    return data;
  },
};
