import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Alert, Form, FormInstance, Input, Select } from "antd";

import { CreateFilterPayload } from "@/api/admin/filters";
import useFormAutoFocusInput from "@/hooks/useFormAutoFocusInput";

interface FilterFormProps {
  form: FormInstance<CreateFilterPayload>;
  handleSubmit: (values: CreateFilterPayload) => void;
  error: string;
  groupDisabled?: boolean;
}

const { Option } = Select;

const FilterForm: FC<FilterFormProps> = ({
  form,
  handleSubmit,
  error,
  groupDisabled,
}) => {
  const { t } = useTranslation();
  const focusInputRef = useFormAutoFocusInput();

  return (
    <Form
      requiredMark={false}
      form={form}
      initialValues={groupDisabled ? { isGroup: false } : undefined}
      onFinish={handleSubmit}
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Введіть назву") as string,
          },
        ]}
        name="name"
        label={t("Назва")}
      >
        <Input ref={focusInputRef} placeholder={t("Введіть значення") as string} />
      </Form.Item>

      <Form.Item name="isGroup" label={t("Група")} initialValue={false}>
        <Select disabled={groupDisabled}>
          <Option key="1" value={true}>
            {t("Так")}
          </Option>
          <Option key="2" value={false}>
            {t("Ні")}
          </Option>
        </Select>
      </Form.Item>

      {!!error && <Alert message={error} type="error" />}
    </Form>
  );
};

export default FilterForm;
