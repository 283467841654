import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "antd";
import { useForm } from "antd/es/form/Form";

import { CreatePartPayload, PartsApi } from "@/api/admin/parts";
import { Part } from "@/root/models/part";
import { PartForm } from "@/components";

interface AddPartModalProps {
  open: boolean;
  onCancel: () => void;
  onAdd: (part: Part) => void;
  part?: Part;
}

const AddPartModal: FC<AddPartModalProps> = ({
  open,
  part,
  onCancel,
  onAdd,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleAdd = async (values: CreatePartPayload) => {
    try {
      setLoading(true);
      setError("");
      const newPart = await PartsApi.create({
        ...values,
        parentSparePartUUID: part ? part.sparePartUUID : "",
      });
      onAdd(newPart);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const addSubmit = () => {
    form.submit();
  };

  return (
    <Modal
      maskClosable={false}
      okText={t("Створити")}
      title={<div style={{ marginBottom: 20 }}>{t("Створення матеріалу")}</div>}
      open={open}
      onCancel={onCancel}
      onOk={addSubmit}
      okButtonProps={{ loading }}
    >
      <PartForm
        form={form}
        handleSubmit={handleAdd}
        error={error}
        groupDisabled={part ? part.level === 4 : false}
      />
    </Modal>
  );
};

export default AddPartModal;
