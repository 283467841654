import { FC, ReactNode } from "react";
import styles from "./IntroContainer.module.scss";
import { IntroCarousel } from "@/components";

interface IntroContainerProps {
  children: ReactNode;
}

const IntroContainer: FC<IntroContainerProps> = ({ children }) => {
  return (
    <div className={styles.container}>
      <IntroCarousel />
      <div className={styles.formContainer}>
        <div className={styles.form}>{children}</div>
      </div>
    </div>
  );
};

export default IntroContainer;
