import React, { FC, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from "react-beautiful-dnd";

import { Alert } from "antd";

import styles from "./Jobs.module.scss";

import "animate.css";

import {
  ActionCreatorTypes,
  CaseFormState,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import ProductItem from "./Product";
import SkeletonProduct from "@/components/CaseJobs/Product/SkeletonProduct";
import { Parameters } from "../CaseForm/components";
import classNames from "classnames";

interface JobProps {
  withParameters: boolean;
  job: CaseFormState.Job;
  onDelete: () => void;
  onEdit: (value: string) => void;
  withLock: boolean;
}

const Job: FC<JobProps> = ({ withParameters, job, withLock }) => {
  const { state, dispatch } = useCaseFormContext();
  const [cutProduct, setCutProduct] = useState<CaseFormState.Job.Product>();

  const [paramsBtnClick, setParamsBtnClick] = useState(false);
  const [productUUID, setProductUUID] = useState("");

  const onParamsBtnHandleClick = (value: string) => {
    setParamsBtnClick(true);
    setProductUUID(value);
  };

  const addNewProduct = () => {
    if (job.products.length && !job.products.at(-1)?.product) {
      return;
    }

    dispatch({
      type: ActionCreatorTypes.AddProduct,
      payload: { activeJobID: job.jobID },
    });
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    ...draggableStyle,
  });

  const onDragEnd: OnDragEndResponder = (result) => {
    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    dispatch({
      type: ActionCreatorTypes.ReplaceProducts,
      payload: {
        jobID: job.jobID,
        sourceIndex: result.source.index,
        destinationIndex: result.destination.index,
      },
    });
  };

  return (
    <>
      {productUUID && paramsBtnClick ? (
        job.products
          ?.filter((elem) => elem.jobProductUUID === productUUID)
          .map((product, index) => (
            <Parameters
              activeJobID={job.jobID}
              activeTooth={product.selectedTooth || null}
              product={product}
              setParamsBtnBackClick={setParamsBtnClick}
              productValue={product.product?.name as string}
            />
          ))
      ) : (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  className={classNames([
                    styles.products,
                    "animate__animated animate__fadeIn",
                  ])}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {job.products?.map((product, index) => (
                    <Draggable
                      isDragDisabled={state.onlyDetailsEdit}
                      key={product.jobProductUUID}
                      draggableId={product.jobProductUUID}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <ProductItem
                            job={job}
                            product={product}
                            cutProduct={cutProduct}
                            setCutProduct={setCutProduct}
                            withLock={withLock}
                            onParamsBtnClick={onParamsBtnHandleClick}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div
            style={{ marginTop: 8 }}
            onClick={(e: any) => {
              if (e.target.closest("div")) {
                addNewProduct();
              }
            }}
          >
            <SkeletonProduct />
          </div>
          {!!job.error && (
            <div className={styles.error}>
              <Alert type="error" message={job.error} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Job;
