import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Checkbox, Modal, Space, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined } from "@ant-design/icons";

import AddCase from "./AddCase";
import { Task } from "@/root/models/task";

const { confirm } = Modal;

interface TaskCasesEditProps {
  allCases: Task["cases"];
  editableCases: Task["cases"];
  setEditableCases: (cases: Task["cases"]) => void;
  withScan?: boolean;
}

const TaskCasesEdit: FC<TaskCasesEditProps> = ({
  allCases,
  editableCases,
  setEditableCases,
  withScan = false,
}) => {
  const { t } = useTranslation();

  const handleCheck = (medCase: Task.Case, checked: boolean) => {
    setEditableCases(
      editableCases.map((c) => {
        if (c.case === medCase) {
          c.use = checked;
        }
        return c;
      })
    );
  };

  const handleAddCase = (medCase: Task.Case) => {
    if (editableCases.some((c) => c.case.caseUUID === medCase.caseUUID)) {
      throw new Error(t("Замовлення вже додано") as string);
    }

    if (
      editableCases[0] &&
      editableCases[0].case.client.clientUUID !== medCase.client.clientUUID
    ) {
      throw new Error(
        t(
          "Одне завдання не може містити замовлення від різних контрагентів"
        ) as string
      );
    }

    setEditableCases([...editableCases, { case: medCase, use: true }]);
  };

  const handleDeleteCase = (caseUUID: string) => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => {
        setEditableCases(
          editableCases.filter((c) => c.case.caseUUID !== caseUUID)
        );
      },
    });
  };

  const editColumns: ColumnsType<{ case: Task.Case; use: boolean }> = [
    {
      key: "number",
      dataIndex: "case",
      title: t("Номер"),
      render: (c: Task.Case, { use }) => (
        <Space>
          <span style={{ color: use ? undefined : "#d4d2d2" }}>
            {c.caseNumber}
          </span>
          {!allCases.some(
            (taskCase) => taskCase.case.caseUUID === c.caseUUID
          ) && (
            <Button
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteCase(c.caseUUID)}
            />
          )}
        </Space>
      ),
    },
    {
      key: "status",
      dataIndex: "case",
      title: t("Статус"),
      render: (c: Task.Case, { use }) => (
        <span style={{ color: use ? undefined : "#d4d2d2" }}>
          {c.status?.name}
        </span>
      ),
    },
    {
      key: "used",
      dataIndex: "use",
      title: t("Використано"),
      render: (used: boolean, { case: medCase }) => (
        <Checkbox
          checked={used}
          onChange={(e) => handleCheck(medCase, e.target.checked)}
        />
      ),
    },
  ];

  return (
    <>
      <Table
        style={{ marginBottom: 8 }}
        pagination={false}
        dataSource={editableCases.map((c) => ({
          ...c,
          key: c.case.caseUUID,
        }))}
        columns={editColumns}
      />
      <AddCase onAdd={handleAddCase} withScan={withScan} />
    </>
  );
};

export default TaskCasesEdit;
