import { useQuery } from "@tanstack/react-query";

import { FiltersApi } from "@/api/admin/filters";
import { Filter } from "@/root/models/filter";

const useFilters = () => {
  const {
    data: filters,
    isLoading: getFiltersLoading,
    error: getFiltersError,
  } = useQuery({
    queryKey: ["filters"],
    queryFn: FiltersApi.getFilters,
  });

  const findFilterByID = (id: string) => {
    const recursiveSearch = (filters: Filter[]) => {
      let filter: Filter | undefined;
      for (const candidate of filters) {
        if (candidate.filterUUID !== id) {
          if (candidate.children) {
            const foundFilter = recursiveSearch(candidate.children);
            if (foundFilter) {
              filter = foundFilter;
              break;
            }
          }
        } else {
          filter = candidate;
          break;
        }
      }
      return filter;
    };

    return recursiveSearch(filters || []);
  };

  return {
    filters: filters || [],
    getFiltersLoading,
    getFiltersError,
    findFilterByID,
  };
};

export default useFilters;
