import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { Patient } from "@/root/models/patient";
import { CreatePatientPayload, PatientsApi } from "@/api/patients";

const usePatients = ({
  clientUUID,
  name,
  onCreateSuccess,
  onDeleteError,
  onEditSuccess,
}: {
  clientUUID?: string;
  name?: string;

  onCreateSuccess?: () => void;
  onEditSuccess?: () => void;
  onDeleteError?: (message: string) => void;
}) => {
  const queryClient = useQueryClient();
  const {
    data: patients,
    isFetching: getPatientsLoading,
    error: getPatientsError,
  } = useQuery({
    queryKey: ["patients", clientUUID, name],
    queryFn: () => PatientsApi.getPatients(clientUUID, name),
  });

  const createPatientMutation = useMutation({
    mutationFn: PatientsApi.create,
    onSuccess: (patient) => {
      queryClient.setQueryData(
        ["patients", clientUUID, name],
        (prev: Patient[] | undefined) => {
          if (!prev) {
            return [patient];
          }
          return [...prev, patient];
        }
      );
      onCreateSuccess?.();
    },
  });

  const createPatient = (payload: CreatePatientPayload) => {
    createPatientMutation.mutate(payload);
  };

  const createError = (createPatientMutation.error as Error)?.message as string;

  const deletePatientMutation = useMutation({
    mutationFn: PatientsApi.delete,
    onError: (error) => onDeleteError?.((error as Error).message),
    onSuccess: (_, patientUUID) => {
      queryClient.setQueryData(
        ["patients", clientUUID, name],
        (prev: Patient[] | undefined) =>
          (prev || []).filter((p) => p.patientUUID !== patientUUID)
      );
    },
  });

  const deletePatient = async (id: string) => {
    await deletePatientMutation.mutateAsync(id);
  };

  const deleteError = (deletePatientMutation.error as Error)?.message as string;

  const editPatientMutation = useMutation({
    mutationFn: PatientsApi.edit,
    onSuccess: (patient: Patient) => {
      queryClient.setQueryData(
        ["patients", clientUUID, name],
        (prev: Patient[] | undefined) => {
          onEditSuccess?.();
          return (prev || []).map((prevPatient) => {
            if (prevPatient.patientUUID === patient.patientUUID) {
              return patient;
            }
            return prevPatient;
          });
        }
      );
    },
  });

  const editPatient = (id: string, payload: CreatePatientPayload) => {
    editPatientMutation.mutate({ id, payload });
  };

  const editError = (editPatientMutation.error as Error)?.message as string;

  return {
    patients: patients || [],
    getPatientsLoading,
    getPatientsError,
    createPatient,
    deletePatient,
    editPatient,
    createLoading: createPatientMutation.isLoading,
    editLoading: editPatientMutation.isLoading,
    deleteLoading: deletePatientMutation.isLoading,
    editError,
    createError,
    deleteError,
  };
};

export default usePatients;
