import { Tabs, TabsProps } from "antd";
import { t } from "i18next";
import { useState } from "react";
import WarehouseTab from "./components/Warehouses/WarehouseTab";
import SuppliersTab from "./components/Suppliers/SuppliersTab";
import UnitsOfMeasureTab from "./components/UnitsOfMeasure/UnitsOfMeasureTab";

const WarehousesPanel = () => {
  const [tabKey, setTabKey] = useState("unitsOfMeasure");

  const items: TabsProps["items"] = [
    {
      key: "unitsOfMeasure",
      label: t("Одиниці виміру"),
      children: <UnitsOfMeasureTab />,
    },
    {
      key: "suppliers",
      label: t("Постачальники"),
      children: <SuppliersTab />,
    },
    {
      key: "warehouses",
      label: t("Склади"),
      children: <WarehouseTab />,
    },
  ];

  return (
    <Tabs type="card" items={items} activeKey={tabKey} onTabClick={setTabKey} />
  );
};

export default WarehousesPanel;
