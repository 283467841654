const InvoicesCurrentOrderTable = () => {
  return (
    <table style={{ width: "100%" }}>
      <thead style={{ fontSize: "20px", fontWeight: "bold" }}>
        <tr>
          <th>Qty</th>
          <th style={{ width: "60%", padding: "8px" }}>Description</th>
          <th>Each</th>
          <th>Price</th>
        </tr>
      </thead>
      <tbody style={{ textAlign: "center", fontSize: "18px" }}>
        <tr>
          <td style={{ padding: "4px" }}>1</td>
          <td>Tooth Addition to Existing Denture</td>
          <td>$190.00</td>
          <td>$190.00</td>
        </tr>
      </tbody>
    </table>
  );
};

export default InvoicesCurrentOrderTable;
