import { apiBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";
import { Task } from "@/root/models/task";

export const TaskStatusesApi = {
  getStatuses: async (): Promise<Task.TaskStatus[]> => {
    const data = await makeRequest<Task.TaskStatus[]>({
      url: apiBaseurl + "/task-statuses",
    });
    return data;
  },
};
