import { apiBaseurl } from "./baseurl";
import makeRequest from "./makeRequest";

export const CalendarCasesDangerDueDates = {
  getDates: async (startDate: string, endDate: string) => {
    return await makeRequest<string[]>({
      url: apiBaseurl + "/calendar-cases-due-date",
      params: {
        date: `${startDate},${endDate}`,
      },
    });
  },
};
