import { Button, notification } from "antd";
import { t } from "i18next";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
import useSuppliers from "@/hooks/useSuppliers";
import ActionSupplierModal from "./ActionSupplierModal";

const CreateSupplier = () => {
  const [form] = useForm();
  const [modal, setModal] = useState(false);

  const onCreateSuccess = () => {
    setModal(false);
    notification.success({
      message: t("Постачальник був успішно створений"),
    });
    form.resetFields();
  };

  const { addSupplier, createError, createLoading } =
    useSuppliers(onCreateSuccess);

  return (
    <div>
      <Button onClick={() => setModal(true)}>
        {t("Створити постачальника")}
      </Button>
      <ActionSupplierModal
        open={modal}
        onCancel={() => setModal(false)}
        type="create"
        error={createError}
        handleSubmit={addSupplier}
        loading={createLoading}
        form={form}
      />
    </div>
  );
};

export default CreateSupplier;
