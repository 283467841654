import { DepositApi } from "@/api/deposit";
import { useQuery } from "@tanstack/react-query";

const useClientDeposit = (clientUUID: string) => {
  const {
    data: deposit,
    isFetching: depositLoading,
    error: depositError,
    refetch,
  } = useQuery({
    queryKey: ["deposit", clientUUID],
    queryFn: () => DepositApi.getDeposit(clientUUID),
    enabled: false,
  });

  return {
    deposit: deposit || 0,
    depositLoading,
    depositError,
    refetch,
  };
};

export default useClientDeposit;
