import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { Modal } from "antd";
import { useForm } from "antd/es/form/Form";

import { ClientPricelist } from "@/root/models/pricelist";
import {
  ClientPricelistsApi,
  CreateClientPricelistPayload,
} from "@/api/admin/clientPricelists";
import ClientPricelistForm from "@/components/PricelistForm/ClientPricelistForm";

interface CreateClientPricelistModalProps {
  onCreate: (pricelist: ClientPricelist) => void;
  open: boolean;
  onCancel: () => void;
}

const CreateClientPricelistModal: FC<CreateClientPricelistModalProps> = ({
  onCreate,
  open,
  onCancel,
}) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleCreate = async (values: CreateClientPricelistPayload) => {
    try {
      setLoading(true);
      setError("");
      const pricelist = await ClientPricelistsApi.create({
        ...values,
        activationDate: dayjs(values.activationDate).format("YYYY.MM.DD"),
      });
      onCreate(pricelist);
      onCancel();
      form.resetFields();
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const submit = () => {
    form.submit();
  };

  return (
    <Modal
      maskClosable={false}
      destroyOnClose
      okText={t("Створити")}
      title={
        <div style={{ marginBottom: 20 }}>{t("Створення прайс листа")}</div>
      }
      open={open}
      onCancel={onCancel}
      onOk={submit}
      okButtonProps={{ loading }}
    >
      <ClientPricelistForm
        form={form}
        handleSubmit={handleCreate}
        error={error}
      />
    </Modal>
  );
};

export default CreateClientPricelistModal;
