import { FC, useRef } from "react";

import { DownOutlined, UpOutlined } from "@ant-design/icons";

import styles from "./Detailes.module.scss";

import { Block } from "@/components/common";
import {
  CaseDate,
  SearchClientEmployee,
  SearchPatient,
} from "@/components/CaseForm/components";
import { isLaptop } from "@/utils/isLaptop";
import SelectTeethFormulaType from "@/components/CaseForm/components/SelectTeethFormulaType";
import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import SelectTeethFormulaNumbering from "@/components/CaseForm/components/SelectTeethFormulaNumbering";
import useClickOutside from "@/hooks/useClickOutside";

const Details: FC = () => {
  const { state, dispatch } = useCaseFormContext();
  const containerRef = useRef<HTMLDivElement>(null);

  const clickOutside = () => {
    if (state.onlyDetailsEdit) {
      dispatch({
        type: ActionCreatorTypes.SetOnlyDetailsEdit,
        payload: false,
      });
    }
  };

  useClickOutside(containerRef, clickOutside, true);

  const setCollapsed = (collapse: boolean) => {
    if (!collapse) {
      dispatch({
        type: ActionCreatorTypes.SetOnlyDetailsEdit,
        payload: false,
      });
      return;
    }
    dispatch({
      type: ActionCreatorTypes.SetOnlyDetailsEdit,
      payload: true,
    });
  };

  const handleClick = () => {
    if (!state.onlyDetailsEdit) {
      dispatch({
        type: ActionCreatorTypes.SetOnlyDetailsEdit,
        payload: true,
      });
    }
  };

  return (
    <div ref={containerRef} className={styles.container}>
      <div className={styles.collapse}>
        {state.onlyDetailsEdit ? (
          <UpOutlined onClick={() => setCollapsed(false)} />
        ) : (
          <DownOutlined onClick={() => setCollapsed(true)} />
        )}
      </div>

      <Block className={styles.details} onClick={handleClick}>
        {state.onlyDetailsEdit ? (
          <>
            <SearchPatient />
            <SearchClientEmployee />
            <CaseDate />

            <div
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <SelectTeethFormulaType />
              <SelectTeethFormulaNumbering />
            </div>
          </>
        ) : (
          <SearchPatient />
        )}
      </Block>
    </div>
  );
};

export default Details;
