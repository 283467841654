import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Alert, Form, FormInstance, Input, Select, Switch } from "antd";

import { CreateProductsParametersGroupPayload } from "@/api/productsParametersGroup";
import useProductsParameters from "@/components/ProductsParametersGroupForm/useProductsParameters";
import useFormAutoFocusInput from "@/hooks/useFormAutoFocusInput";

interface SelectedProductsParameter {
  required: boolean;
  productsParameterUUID: string;
  name: string;
}

interface ProductsParametersGroupFormProps {
  form: FormInstance<CreateProductsParametersGroupPayload>;
  onSubmit: (value: CreateProductsParametersGroupPayload) => Promise<void>;
  error: string;
  defaultValue?: {
    name: string;
    selectedProductsParameters: SelectedProductsParameter[];
  };
}

const { Option } = Select;

const ProductsParametersGroupForm: FC<ProductsParametersGroupFormProps> = ({
  form,
  onSubmit,
  error,
  defaultValue,
}) => {
  const { t } = useTranslation();
  const { parameters, loading } = useProductsParameters();
  const [name, setName] = useState("");
  const [selectedParameters, setSelectedParameters] = useState<
    SelectedProductsParameter[]
  >([]);
  const focusInputRef = useFormAutoFocusInput();

  useEffect(() => {
    if (defaultValue) {
      setName(defaultValue.name);
      setSelectedParameters(defaultValue.selectedProductsParameters);
    }
  }, [defaultValue]);

  const handleSubmit = async () => {
    await onSubmit({
      name,
      productsParameters: selectedParameters.map((p) => ({
        productsParameterUUID: p.productsParameterUUID,
        required: p.required,
      })),
    });
    setSelectedParameters([]);
  };

  const handleParametersChange = (productsParameterUUIDs: string[]) => {
    const selectedParameters = productsParameterUUIDs.map((id) => {
      const name =
        parameters.find((p) => p.productsParameterUUID === id)?.name || "";
      return {
        name,
        required: false,
        productsParameterUUID: id,
      };
    });

    setSelectedParameters(selectedParameters);
  };

  const handleSwitch = (productsParameterUUID: string) => {
    setSelectedParameters((prev) =>
      prev.map((p) => {
        if (p.productsParameterUUID === productsParameterUUID) {
          p.required = !p.required;
        }
        return p;
      })
    );
  };

  return (
    <Form requiredMark={false} form={form} onFinish={handleSubmit}>
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Введіть назву") as string,
          },
        ]}
        label={t("Назва")}
      >
        <Input
          ref={focusInputRef}
          placeholder={t("Введіть значення") as string}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Form.Item>

      <Form.Item label={t("Параметри виробів")}>
        <Select
          listHeight={1000}
          loading={loading}
          mode="multiple"
          allowClear
          value={selectedParameters.map((p) => p.productsParameterUUID)}
          onChange={handleParametersChange}
        >
          {parameters.map((p) => (
            <Option
              key={p.productsParameterUUID}
              value={p.productsParameterUUID}
            >
              {p.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <div style={{ marginBottom: 24 }}>
        {selectedParameters.map((parameter) => (
          <div key={parameter.productsParameterUUID}>
            {parameter.name} -{" "}
            <Switch
              checked={parameter.required}
              checkedChildren={t("Обов'язковий")}
              unCheckedChildren={t("Необов'язковий")}
              onChange={() => handleSwitch(parameter.productsParameterUUID)}
            />
          </div>
        ))}
      </div>

      {!!error && <Alert message={error} type="error" />}
    </Form>
  );
};

export default ProductsParametersGroupForm;
