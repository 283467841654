import { Modal } from "antd";
import { t } from "i18next";
import { FormInstance } from "antd/lib/form/Form";
import SupplierForm from "./SupplierForm";

interface ActionSupplierModalProps {
  open?: boolean;
  modal?: string | null;
  supplierUUID?: string;
  onCancel: () => void;
  type: "create" | "edit";
  handleSubmit: (values: any) => void;
  error: string;
  loading: boolean;
  form: FormInstance;
}

const ActionSupplierModal = ({
  open,
  onCancel,
  type,
  handleSubmit,
  error,
  form,
  loading,
  supplierUUID,
  modal,
}: ActionSupplierModalProps) => {
  const submit = () => {
    form.submit();
  };

  return (
    <Modal
      maskClosable={false}
      okText={type === "create" ? t("Створити") : t("Редагувати")}
      title={
        <div style={{ marginBottom: 20 }}>
          {type === "create"
            ? t("Створити постачальника")
            : t("Редагувати постачальника")}
        </div>
      }
      // Open prop for Create and modal with supplierUUID for Edit (to open edit modal of a clicked supplier)
      open={supplierUUID ? modal === supplierUUID : open}
      onCancel={onCancel}
      onOk={submit}
      okButtonProps={{ loading }}
    >
      <SupplierForm form={form} handleSubmit={handleSubmit} error={error} />
    </Modal>
  );
};

export default ActionSupplierModal;
