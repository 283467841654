import React from "react";

const WarningText = () => {
  return (
    <>
      <div>
        After due date penalty interest of 2% monthly may be charged to your
        account.
      </div>
      <div>
        Après l'intérêt jour pénalité due de 2% par mois peuvent être portés à
        vorte compte.
      </div>
    </>
  );
};

export default WarningText;
