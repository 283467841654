import { useEffect, useState } from "react";

import { ProductParameter } from "@/root/models/productParameter";
import { ProductsParametersApi } from "@/api/admin/productsParameters";

const useProductsParameters = () => {
  const [parameters, setParameters] = useState<ProductParameter[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const parameters = await ProductsParametersApi.getParameters();
        setParameters(parameters);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return {
    parameters,
    loading,
  };
};

export default useProductsParameters;