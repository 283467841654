import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Button, Input, Modal, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { TaskPhoto } from "@/components/TaskPhotos/index";
import useFile from "@/hooks/useFile";
import { Loader } from "@/components/common";
import FullScreenPhoto from "./FullScreenPhoto";

interface PhotoProps {
  taskUUID?: string;
  photo: TaskPhoto;
  onDescriptionChange: (description: string) => void;
  onDelete: () => void;
  presentation?: boolean;
}

const { confirm } = Modal;

const Photo: FC<PhotoProps> = ({
  taskUUID,
  photo,
  onDescriptionChange,
  onDelete,
  presentation = false,
}) => {
  const { t } = useTranslation();

  const handleDeleteClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: onDelete,
    });
  };

  return (
    <Space style={{ width: "100%" }} direction={"vertical"}>
      <div>
        {photo.localFileUrl ? (
          <FullScreenPhoto
            fileName={photo.filename}
            imageUrl={photo.localFileUrl}
          />
        ) : (
          taskUUID && (
            <ServerImage
              fileName={photo.filename}
              taskUUID={taskUUID}
              fileUUID={photo.fileUUID}
            />
          )
        )}
      </div>
      <div style={{ display: "flex", gap: 8 }}>
        <Input
          style={{ flexGrow: 1 }}
          value={photo.description}
          disabled={presentation}
          onChange={(e) => onDescriptionChange(e.target.value)}
        />
        {!presentation && (
          <Button
            style={{ width: 50 }}
            icon={<DeleteOutlined />}
            onClick={handleDeleteClick}
          />
        )}
      </div>
    </Space>
  );
};

interface ServerImageProps {
  taskUUID: string;
  fileUUID: string;
  fileName: string;
}

const ServerImage: FC<ServerImageProps> = ({
  taskUUID,
  fileUUID,
  fileName,
}) => {
  const { file, getFileLoading } = useFile(taskUUID, fileUUID);

  return (
    <>
      {getFileLoading ? (
        <Loader center />
      ) : (
        file && (
          <FullScreenPhoto
            fileName={fileName}
            imageUrl={URL.createObjectURL(file)}
          />
        )
      )}
    </>
  );
};

export default Photo;
