import { Modal } from "antd";
import { t } from "i18next";
import { FormInstance } from "antd/lib/form/Form";
import UnitOfMeasureForm from "./UnitOfMeasureForm";

interface ActionUnitOfMeasureModalProps {
  open?: boolean;
  modal?: string | null;
  unitOfMeasureUUID?: string;
  onCancel: () => void;
  type: "create" | "edit";
  handleSubmit: (values: any) => void;
  error: string;
  loading: boolean;
  form: FormInstance;
}

const ActionUnitOfMeasureModal = ({
  open,
  onCancel,
  type,
  handleSubmit,
  error,
  form,
  loading,
  unitOfMeasureUUID,
  modal,
}: ActionUnitOfMeasureModalProps) => {
  const submit = () => {
    form.submit();
  };

  return (
    <Modal
      maskClosable={false}
      okText={type === "create" ? t("Створити") : t("Редагувати")}
      title={
        <div style={{ marginBottom: 20 }}>
          {type === "create"
            ? t("Створити одиницю виміру")
            : t("Редагувати одиницю виміру")}
        </div>
      }
      // Open prop for Create and modal with unitOfMeasureUUID for Edit (to open edit modal of a clicked unit of measure)
      open={unitOfMeasureUUID ? modal === unitOfMeasureUUID : open}
      onCancel={onCancel}
      onOk={submit}
      okButtonProps={{ loading }}
    >
      <UnitOfMeasureForm
        form={form}
        handleSubmit={handleSubmit}
        error={error}
      />
    </Modal>
  );
};

export default ActionUnitOfMeasureModal;
