import { useEffect, useState } from "react";
import AccountingTable from "./components/AccountingTable";
import { AccountingTotal } from "@/root/models/accounting";
import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import { v4 as uuidv4 } from "uuid";
import { Button, Space } from "antd";
import { t } from "i18next";
import SetDiscount from "./components/SetDiscount";
import DeleteDiscount from "./components/DeleteDiscount";
import AddItem from "./components/AddItem";

interface AccountingProps {
  accounting: AccountingTotal[];
}

const Accounting = ({ accounting }: AccountingProps) => {
  const [changebleAccountingArray, setChangebleAccountingArray] = useState<
    AccountingTotal[]
  >([]);

  useEffect(() => {
    if (!!accounting) {
      setChangebleAccountingArray(accounting);
    }
  }, []);

  const [selectedItem, setSelectedItem] = useState<
    AccountingTotal | undefined
  >();

  const { dispatch } = useCaseFormContext();

  //Clear selectItem state to not have possibility make actions without selected item
  useEffect(() => {
    const exists = changebleAccountingArray.some(
      (elem) => elem.rowUUID === selectedItem?.rowUUID
    );
    if (!exists) {
      setSelectedItem(undefined);
    }
  }, [changebleAccountingArray, selectedItem?.rowUUID]);

  //Updating selectedItem on discount set or delete
  useEffect(() => {
    if (selectedItem) {
      const indexToUpdate = changebleAccountingArray.findIndex(
        (item) => item.rowUUID === selectedItem?.rowUUID
      );
      const updatedArray = [
        ...changebleAccountingArray.slice(0, indexToUpdate),
        selectedItem,
        ...changebleAccountingArray.slice(indexToUpdate + 1),
      ];

      setChangebleAccountingArray(updatedArray);
    }
  }, [selectedItem]);

  //Update accounting
  useEffect(() => {
    if (changebleAccountingArray) {
      dispatch({
        type: ActionCreatorTypes.SetAccounting,
        payload: changebleAccountingArray,
      });
    }
  }, [changebleAccountingArray]);

  const copyItem = (record: AccountingTotal) => {
    const copiedRow = { ...record, rowUUID: uuidv4() };
    setChangebleAccountingArray((prevData) => {
      const index = prevData.indexOf(record);
      const newData = [...prevData];
      newData.splice(index + 1, 0, copiedRow);
      return newData;
    });
  };

  const deleteItem = (record: AccountingTotal) => {
    const newData = changebleAccountingArray.filter(
      (item) => item.rowUUID !== record.rowUUID
    );
    setChangebleAccountingArray(newData);
  };

  return (
    <Space direction="vertical" style={{ width: "100%", overflow: "scroll" }}>
      <Space>
        {/* <AddItem /> */}
        <Button
          onClick={() => selectedItem && copyItem(selectedItem)}
          disabled={(!selectedItem || !!selectedItem.invoices?.length) && true}
        >
          {t("Копіювати елемент")}
        </Button>
        <Button
          onClick={() => selectedItem && deleteItem(selectedItem)}
          disabled={(!selectedItem || !!selectedItem.invoices?.length) && true}
        >
          {t("Видалити елемент")}
        </Button>
        <SetDiscount
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
        <DeleteDiscount
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </Space>
      <AccountingTable
        accountingChangeArray={changebleAccountingArray}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
      />
    </Space>
  );
};

export default Accounting;
