import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal, notification, Space } from "antd";
import { useForm } from "antd/es/form/Form";

import styles from "./Filter.module.scss";

import { Filter } from "@/root/models/filter";
import { CreateFilterPayload } from "@/api/admin/filters";
import { FilterForm } from "@/components";
import { AddFilterModal } from "@/modules/FiltersCatalog/components";
import useEditFilter from "@/modules/FiltersCatalog/components/Filter/useEditFilter";
import useDeleteFilter from "@/modules/FiltersCatalog/components/Filter/useDeleteFilter";

interface FilterProps {
  filter: Filter;
}

const { confirm } = Modal;

const FilterItem: FC<FilterProps> = ({ filter }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const [editModal, setEditModal] = useState(false);
  const [addModal, setAddModal] = useState(false);

  const onSuccess = () => {
    form.resetFields();
    setEditModal(false);
  };

  const {
    editFilter,
    loading: editLoading,
    error: editError,
  } = useEditFilter(onSuccess);

  const onError = (message: string) => {
    notification.error({
      message: message,
    });
  };

  const { deleteFilter, loading: deleteLoading } = useDeleteFilter(onError);

  const handleEdit = async (values: CreateFilterPayload) => {
    editFilter(filter.filterUUID, {
      ...values,
      parentFilterUUID: filter.parentFilterUUID,
    });
  };

  const handleDelete = async () => {
    await deleteFilter(filter.filterUUID);
  };

  const editSubmit = () => {
    form.submit();
  };

  const openEditModal = () => {
    form.setFieldsValue({
      name: filter.name,
      isGroup: filter.isGroup,
    });
    setEditModal(true);
  };

  const openAddModal = () => {
    setAddModal(true);
  };

  const handleDeleteButtonClick = () => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: handleDelete,
    });
  };

  const handleAddFilter = () => {
    setAddModal(false);
  };

  return (
    <div className={styles.filter}>
      <span>{filter.name}</span>
      <Space className="tree-actions">
        {filter.isGroup && filter.level < 5 && (
          <Button size="small" onClick={openAddModal}>
            {t("Додати")}
          </Button>
        )}
        <Button size="small" onClick={openEditModal}>
          {t("Редагувати")}
        </Button>
        <Button
          loading={deleteLoading}
          size="small"
          onClick={handleDeleteButtonClick}
        >
          {t("Видалити")}
        </Button>
      </Space>
      <Modal
        maskClosable={false}
        okText={t("Зберегти")}
        title={
          <div style={{ marginBottom: 20 }}>{t("Редагування фільтру")}</div>
        }
        open={editModal}
        onCancel={() => setEditModal(false)}
        onOk={editSubmit}
        okButtonProps={{ loading: editLoading }}
      >
        <FilterForm
          form={form}
          handleSubmit={handleEdit}
          error={editError}
          groupDisabled={filter.level === 2}
        />
      </Modal>
      {filter.isGroup && filter.level < 5 && (
        <AddFilterModal
          filter={filter}
          open={addModal}
          onCancel={() => setAddModal(false)}
          onAdd={handleAddFilter}
        />
      )}
    </div>
  );
};

export default FilterItem;
