export const getAuthToken = (): { token: string; expires: string } => {
  const tokenJson = localStorage.getItem('token');
  const expiresJson = localStorage.getItem('expires');

  let token,
    expires = '';

  if (tokenJson && expiresJson) {
    token = JSON.parse(tokenJson);
    expires = JSON.parse(expiresJson);
  }

  return {
    token,
    expires,
  };
};

export const isTokenExpired = () => {
  const { token, expires } = getAuthToken();

  return (
    !token ||
    !expires ||
    !Date.parse(expires) ||
    new Date(expires) < new Date()
  );
};

export const saveAuthToken = (token: string, expires: string) => {
  localStorage.setItem('token', JSON.stringify(token));
  localStorage.setItem('expires', JSON.stringify(expires));
};

export const clearAuthToken = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expires');
};
