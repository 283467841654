import { Invoice } from "@/root/models/invoice";
import { Modal } from "antd";
import { t } from "i18next";
import styles from "../InvoicePanel.module.scss";
import InvoiceForm from "./InvoiceForm";
import { FormInstance } from "antd/lib/form/Form";
import classNames from "classnames";
import { InvoiceEditPayloadProps } from "@/api/invoices";

interface InvoiceModalProps {
  modal: string | null;
  invoice: Invoice;
  modalType: "view" | "edit" | null;
  form: FormInstance<any>;
  onCancel: () => void;
  submit?: () => void;
  handleEdit?: (values: InvoiceEditPayloadProps) => Promise<void>;
  editLoading?: boolean;
}

const InvoiceModal = ({
  modal,
  invoice,
  modalType,
  form,
  onCancel,
  submit,
  handleEdit,
  editLoading,
}: InvoiceModalProps) => {
  return (
    <>
      <Modal
        centered
        width={1000}
        open={modal === invoice.invoiceUUID}
        maskClosable={false}
        title={
          modalType === "view" ? (
            <div style={{ marginBottom: 20 }}>
              {t("Рахунок-фактура")}&nbsp;
              {`№${invoice.invoiceNumber}`}
            </div>
          ) : (
            <div>{t("Змінити замовлення")}</div>
          )
        }
        onCancel={onCancel}
        okText={t("Зберегти")}
        onOk={submit}
        okButtonProps={{ loading: editLoading }}
        className={
          modalType === "view"
            ? classNames(
                styles.invoiceModal_content,
                styles.modal_footer_hidden
              )
            : styles.invoiceModal_content
        }
      >
        {modalType === "view" ? (
          <InvoiceForm
            form={form}
            paid={invoice.paid}
            invoiceCasesView={invoice.cases}
            type="view"
          />
        ) : (
          <InvoiceForm
            form={form}
            paid={invoice.paid}
            invoiceCasesView={invoice.cases}
            type="edit"
            handleEdit={handleEdit}
          />
        )}
      </Modal>
    </>
  );
};

export default InvoiceModal;
