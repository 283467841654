import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useCaseFormContext } from "@/components/CaseForm/CaseFormContext";
import { TeethWidget } from "@/components";
import useProducts from "@/hooks/useProducts";
import { ChosenTooth } from "@/components/TeethWidget";
import CaseFormTooth from "./CaseFormTooth";
import { jaws } from "@/root/consts";
import { JawType, TeethFormulaTypeEnum } from "@/root/types";
import { getJawTeeth } from "@/utils/getJawTeeth";

interface TeethProps {
  disabled?: boolean;
}

const Teeth: FC<TeethProps> = ({ disabled }) => {
  const { t } = useTranslation();
  const { state } = useCaseFormContext();
  const { getParameterGroup } = useProducts();

  const { job } = useMemo(() => {
    const job = state.jobs.find((job) => job.jobID === state.activeJobID);
    const jobProduct = job?.products.find(
      (product) => product.jobProductUUID === job.activeProductID
    );
    return { job, jobProduct };
  }, [state.jobs, state.activeJobID]);

  const isChildFormula =
    state.teethFormulaTypeUUID === TeethFormulaTypeEnum.child;
  const isAdultFormula =
    state.teethFormulaTypeUUID === TeethFormulaTypeEnum.adult;

  const chosenTeeth =
    job?.products.reduce<ChosenTooth[]>((sum, product) => {
      product.teeth &&
        sum.push(
          ...product.teeth.flatMap((tooth) => {
            if (jaws.includes(tooth as JawType)) {
              return getJawTeeth(tooth as JawType).map((toothID) => ({
                toothID: toothID,
                isPoint: product.product?.isPoint || false,
                crown: !!product?.combinedProduct,
                color: product?.teethColor || null,
                group: product.jobProductUUID,
                selected: job.selectedTeeth?.includes(toothID) || false,
              }));
            }
            return {
              toothID: tooth,
              isPoint: product.product?.isPoint || false,
              crown: !!product?.combinedProduct,
              color: product?.teethColor || null,
              group: product.jobProductUUID,
              selected: job.selectedTeeth?.includes(tooth) || false,
            };
          })
        );

      return sum;
    }, []) || [];

  const products = Array.from(
    new Set(
      job?.products.map((product) =>
        product.product ? getParameterGroup(product.product) : null
      )
    )
  );

  const legends = products.map((product) => ({
    name: product?.name || "",
    color: product?.color || "",
  }));

  return state.teethFormulaTypeUUID && (isChildFormula || isAdultFormula) ? (
    <TeethWidget
      formulaNumbering={state.teethFormulaNumberingUUID || ""}
      toothRender={(tooth, teeth) => (
        <CaseFormTooth
          tooth={disabled ? { ...tooth, chainNode: undefined } : tooth}
          allTeeth={teeth}
          disabled={disabled}
        />
      )}
      formulaType={
        state.teethFormulaTypeUUID === TeethFormulaTypeEnum.child
          ? "child"
          : "adult"
      }
      chosenTeeth={chosenTeeth}
      legends={legends}
    />
  ) : (
    <span>{t("Оберіть тип формули")}</span>
  );
};
export default Teeth;
