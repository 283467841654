import { apiBaseurl } from "@/api/baseurl";
import makeRequest from "@/api/makeRequest";
import {
  ProductParameter,
  ProductParameterType,
} from "@/root/models/productParameter";

export interface CreateProductParameterPayload {
  name: string;
  productsParameterTypeUUID: string;
  filtersValues: string[];
  defaultValue: ProductParameter["defaultValue"];
  list: ProductParameter["list"];
}

export const ProductsParametersApi = {
  getParameters: async (): Promise<ProductParameter[]> => {
    const data = await makeRequest<ProductParameter[]>({
      url: apiBaseurl + "/products-parameters",
    });

    return data;
  },

  getParameterTypes: async (): Promise<ProductParameterType[]> => {
    const data = await makeRequest<ProductParameterType[]>({
      url: apiBaseurl + "/products-parameter-types",
    });

    return data;
  },

  create: async (
    payload: CreateProductParameterPayload
  ): Promise<ProductParameter> => {
    const data = await makeRequest<ProductParameter>({
      url: apiBaseurl + "/products-parameters",
      method: "post",
      data: payload,
    });
    return data;
  },

  edit: async (
    id: string,
    payload: CreateProductParameterPayload
  ): Promise<ProductParameter> => {
    const data = await makeRequest<ProductParameter>({
      url: apiBaseurl + `/products-parameters/${id}`,
      method: "put",
      data: payload,
    });
    return data;
  },

  delete: async (id: string): Promise<void> => {
    await makeRequest({
      url: apiBaseurl + `/products-parameters/${id}`,
      method: "delete",
    });
  },
};
