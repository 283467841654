import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Space, Table } from "antd";

import usePriceFormulas from "@/hooks/usePriceFormulas";
import {
  CreatePriceFormula,
  TableActions,
} from "@/modules/PriceFormulas/components";
import { PriceFormula } from "@/root/models/priceFormula";
import useModalOpen from "@/hooks/useModalOpen";

const PriceFormulas: FC = () => {
  const { t } = useTranslation();
  const { priceFormulas, getPriceFormulasLoading } = usePriceFormulas({});

  const { modal, onHandleCancel, onEditSuccess, isModalOpen } = useModalOpen();

  const priceFormulasColumn = [
    {
      title: t("Назва"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("Формула"),
      dataIndex: "formula",
      key: "formula",
    },
    {
      title: "",
      key: "action",
      render: (_: any, priceFormula: PriceFormula) => (
        <TableActions
          priceFormula={priceFormula}
          modal={modal}
          onEditSuccess={onEditSuccess}
          onCancel={onHandleCancel}
          onModalOpen={() => {
            isModalOpen(priceFormula.priceFormulaUUID);
          }}
        />
      ),
    },
  ];

  return (
    <Space direction="vertical">
      <CreatePriceFormula />
      <div style={{ display: "flex", gap: 10 }}>
        <Table
          style={{ userSelect: "none" }}
          columns={priceFormulasColumn}
          dataSource={priceFormulas.map((p) => ({
            ...p,
            key: p.priceFormulaUUID,
          }))}
          pagination={false}
          loading={getPriceFormulasLoading}
          onRow={(priceFormula) => ({
            onDoubleClick: () => isModalOpen(priceFormula.priceFormulaUUID),
          })}
        />
      </div>
    </Space>
  );
};

export default PriceFormulas;
