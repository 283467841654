import { CaseFormState } from "@/components/CaseForm/CaseFormContext";
import {
  Accounting,
  AccountingSum,
  AccountingTotal,
} from "@/root/models/accounting";
import { v4 as uuidv4 } from "uuid";

interface UseAccountingProps {
  jobs: CaseFormState.Job[];
  accounting: AccountingTotal[];
}

const useAccounting = ({ jobs, accounting }: UseAccountingProps) => {
  //The filter(Boolean) method will remove any falsy values, including undefined, from the array.
  const products = jobs?.map((elem) => elem.products).flat() || [];
  const extras = (jobs?.map((elem) => elem.extras).flat() || []).filter(
    Boolean
  );
  const parts = (jobs?.map((elem) => elem.spareParts).flat() || []).filter(
    Boolean
  );

  const accountingArray: Accounting[] = [
    ...products.map((item) => ({
      rowUUID: uuidv4(),
      itemType: "product",
      itemUUID: item.product!?.productUUID,
      itemName: item.product!?.name,
      teeth: item.teeth,
      quantity: item.quantity || 0,
      price: item.price || 0,
      discountPercent: 0,
      discountSum: 0,
      //Adding invoices to accounting array to show them on Accounting tab
      invoices: accounting.some(
        (elem) => elem.itemUUID === item.product?.productUUID
      )
        ? accounting.find((elem) => elem.itemUUID === item.product?.productUUID)
            ?.invoices
        : [],
    })),
    ...extras.map((item) => ({
      rowUUID: uuidv4(),
      itemType: "extra",
      itemName: item?.extra!?.name,
      itemUUID: item?.extra!?.extraUUID,
      teeth: [],
      price: item?.price || 0,
      quantity: item?.quantity || 1,
      discountPercent: 0,
      discountSum: 0,
    })),
    ...parts.map((item) => ({
      rowUUID: uuidv4(),
      itemType: "part",
      itemName: item?.sparePart!?.name,
      itemUUID: item?.sparePart!?.sparePartUUID,
      teeth: [],
      price: item?.price || 0,
      quantity: item?.quantity || 1,
      discountPercent: 0,
      discountSum: 0,
    })),
  ];

  const accountingArrayWithSum: AccountingSum[] = [
    ...accountingArray.map((item) => ({
      ...item,
      sum: item!?.price && item!?.quantity ? item?.price * item.quantity : 0,
    })),
  ];

  const accountingArrayWithTotal: AccountingTotal[] = [
    ...accountingArrayWithSum.map((item) => ({
      ...item,
      total: item.sum - item.discountSum,
    })),
  ];

  return { accountingArrayWithTotal };
};

export default useAccounting;
