import { FC, useState } from "react";

import { DownOutlined, UpOutlined } from "@ant-design/icons";

import styles from "@/components/CaseForm/components/Details/Detailes.module.scss";

import SelectTeethFormulaType from "@/components/CaseForm/components/SelectTeethFormulaType";
import SelectTeethFormulaNumbering from "@/components/CaseForm/components/SelectTeethFormulaNumbering";
import { Teeth } from "@/components/CaseForm/components";
import { Block } from "@/components/common";
import { isLaptop } from "@/utils/isLaptop";
import useTableScrollHeight from "@/hooks/TableHooks/useTableScrollHeight";

const Summary: FC = () => {
  const [inputsCollapsed, setInputsCollapsed] = useState(false);
  const { tableScrollHeight } = useTableScrollHeight(145);

  return (
    <>
      <Block
        style={{
          boxShadow: "none",
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          position: "relative",
        }}
      >
        <div className={styles.collapse}>
          {inputsCollapsed ? (
            <DownOutlined onClick={() => setInputsCollapsed(false)} />
          ) : (
            <UpOutlined onClick={() => setInputsCollapsed(true)} />
          )}
        </div>
        {!inputsCollapsed && (
          <>
            {isLaptop() ? (
              <>
                <SelectTeethFormulaType />
                <SelectTeethFormulaNumbering />
              </>
            ) : (
              <div style={{ display: "flex", width: "100%" }}>
                <SelectTeethFormulaType />
                <SelectTeethFormulaNumbering />
              </div>
            )}
          </>
        )}
      </Block>
      <Block
        style={{
          boxShadow: "none",
          marginTop: 8,
          height: tableScrollHeight,
        }}
      >
        <Teeth />
      </Block>
    </>
  );
};

export default Summary;
