import { RefObject, useEffect } from "react";

const useClickOutsideProductItem = (
  ref: RefObject<HTMLElement>,
  onClickOutside: (e?: any) => void
) => {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (
          event.target.closest(".Block_block__yn2Au") ||
          event.target.closest(".ant-tabs-tab") ||
          event.target.closest(".Detailes_collapse__xph3V")
        ) {
          return;
        }

        if (
          typeof event.target.className === "string" &&
          (event.target.className.includes("ant-select-item-option") ||
            event.target.className.includes("ant-picker"))
        ) {
          return;
        }

        onClickOutside();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onClickOutside]);
};

export default useClickOutsideProductItem;
