import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { Alert, Button, Modal, Space, Table, TreeSelect } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined } from "@ant-design/icons";

import styles from "../../LaboratoryCaseForm.module.scss";

import {
  ActionCreatorTypes,
  CaseFormState,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import useSpareParts from "@/hooks/useParts";
import Sum from "../Sum";
import Prices from "../Prices";
import { Part } from "@/root/models/part";

const { confirm } = Modal;

const SpareParts: FC = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useCaseFormContext();
  const { parts: allSpareParts, findPartByID } = useSpareParts();

  const parseTree = (allSpareParts: Part[]): any => {
    return allSpareParts.map((part) => ({
      key: part.sparePartUUID,
      title: part.name,
      value: part.sparePartUUID,
      selectable: !part.isGroup,
      children: part.children ? parseTree(part.children) : undefined,
    }));
  };

  const handleSparePartSelect = (jobID: string, sparePartUUID: string) => {
    const part = findPartByID(sparePartUUID);

    if (!part) {
      return;
    }

    dispatch({
      type: ActionCreatorTypes.AddJobSparePart,
      payload: { jobID, part },
    });
  };

  const handleSparePartDelete = (jobID: string, partID: string) => {
    confirm({
      title: t("Підтвердити видалення"),
      okText: t("Видалити"),
      okType: "danger",
      cancelText: t("Скасувати"),
      onOk: () => {
        dispatch({
          type: ActionCreatorTypes.DeleteJobSparePart,
          payload: { jobID, partID },
        });
      },
    });
  };

  const handlePriceChange = (
    jobID: string,
    partID: string,
    price: number | null
  ) => {
    dispatch({
      type: ActionCreatorTypes.EditSparePartPrice,
      payload: { jobID, partID, price },
    });
  };

  const columns: ColumnsType<CaseFormState.Job> = [
    {
      key: "job",
      dataIndex: "jobName",
      title: t("Робота"),
      render: (jobName: string, job) => (
        <Space direction="vertical">
          <span>{jobName}</span>
          {!!job.error && <Alert message={job.error} type="error" />}
        </Space>
      ),
    },
    {
      key: "spareParts",
      dataIndex: "spareParts",
      title: t("Матеріали"),
      render: (spareParts: CaseFormState.Job.Part[] | undefined, job) => (
        <div
          className={classNames(
            styles.cell,
            "laboratory-case-cell--no-pudding"
          )}
        >
          {spareParts?.map((sparePart, index) => (
            <div
              className={styles.partItem}
              key={sparePart.id}
              style={{
                padding: 10,
                marginBottom: index + 1 === spareParts?.length ? 52 : undefined,
                borderBottom: "1px solid #f0f0f0",
              }}
            >
              {sparePart.sparePart.name}
              <Button
                icon={<DeleteOutlined />}
                onClick={() => handleSparePartDelete(job.jobID, sparePart.id)}
              />
            </div>
          ))}
          <div className={styles.addCellItem}>
            <TreeSelect
              style={{ width: "100%" }}
              multiple
              treeDefaultExpandAll
              tagRender={() => <span />}
              value={[]}
              onSelect={(sparePart) =>
                handleSparePartSelect(job.jobID, sparePart)
              }
              treeData={parseTree(allSpareParts)}
            />
            <span className={styles.placeholder}>{t("Матеріали")}</span>
          </div>
        </div>
      ),
      width: "50%",
    },
    {
      key: "quantity",
      dataIndex: "spareParts",
      title: t("Кількість"),
      render: (spareParts: CaseFormState.Job.Part[] | undefined) => (
        <div>{spareParts?.length}</div>
      ),
    },
    {
      key: "price",
      dataIndex: "spareParts",
      title: t("Ціна"),
      render: (spareParts: CaseFormState.Job.Part[] | undefined, job) => (
        <Prices
          job={job}
          elements={spareParts?.map((sparePart) => ({
            id: sparePart.id,
            price: sparePart.price,
            isError: !!sparePart.error,
          }))}
          onPriceChange={handlePriceChange}
        />
      ),
      className: styles.cellBaseline,
      width: "200px",
    },
  ];

  const totalSum = state.jobs.reduce(
    (sum, job) =>
      sum +
      (job.spareParts?.reduce(
        (sum, sparePart) => (sparePart.price ?? 0) + sum,
        0
      ) || 0),
    0
  );

  return (
    <div>
      <Table
        bordered
        pagination={false}
        columns={columns}
        dataSource={state.jobs.map((job) => ({ ...job, key: job.jobID }))}
      />
      <Sum price={totalSum} />
    </div>
  );
};

export default SpareParts;
