import { InvoicesApi } from "@/api/invoices";
import { Invoice } from "@/root/models/invoice";
import { Payment } from "@/root/models/payment";
import { FormInstance } from "antd";
import { useEffect, useState } from "react";

const usePaymentTables = (
  paymentItems: Payment.Items[],
  chosenClient: string,
  form: FormInstance
) => {
  // First table

  //Change invoice key to invoices and convert it to array to match all other schemes
  const updatedPaymentItems: Invoice.InvoiceCase[] = paymentItems
    .map((elem) => ({
      ...elem,
      invoices: [elem.invoice],
    }))
    .map((obj) => {
      const { ["invoice"]: omittedKey, ...rest } = obj;
      return rest;
    });

  //This state is needed only to update cases in table on button click
  const [paymentInvoiceCases, setPaymentInvoiceCases] =
    useState<Invoice.InvoiceCase[]>(updatedPaymentItems);

  const [selectedPaymentInvoiceCases, setSelectedPaymentInvoiceCases] =
    useState<Invoice.InvoiceCase[]>([]);

  //Second table

  //This state is needed only to update cases in table on button click
  const [unpaidInvoices, setUnpaidInvoices] = useState<Invoice[]>();

  const [selectedUnpaidInvoiceCases, setSelectedUnpaidInvoiceCases] = useState<
    Invoice.InvoiceCase[]
  >([]);

  //Find unpaid invoices dependes on chosen clinic
  useEffect(() => {
    (async () => {
      try {
        if (chosenClient) {
          const invoices = await InvoicesApi.getInvoices({
            page: 1,
            clientUUID: chosenClient,
            paid: false,
          });
          setUnpaidInvoices(invoices.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [chosenClient]);

  //Add cases to payment table
  const unpdaidInvoiceCasesAdd = () => {
    if (!!unpaidInvoices?.length) {
      setPaymentInvoiceCases(
        paymentInvoiceCases?.concat(selectedUnpaidInvoiceCases)
      );

      setUnpaidInvoices(
        unpaidInvoices?.map((elem) => ({
          ...elem,
          cases: elem.cases.filter(
            (elem) =>
              !selectedUnpaidInvoiceCases.some(
                (item) => item.rowUUID === elem.rowUUID
              )
          ),
        }))
      );
      form.setFieldValue(
        "amount",
        paymentInvoiceCases.reduce((prev, cur) => prev + cur.sum, 0)
      );
      setSelectedUnpaidInvoiceCases([]);
    }
  };

  const paymentInvoiceCasesRemove = () => {
    if (!!paymentInvoiceCases?.length) {
      setUnpaidInvoices(
        unpaidInvoices?.map((elem) => ({
          ...elem,
          cases: elem.cases.concat(
            selectedPaymentInvoiceCases.filter(
              (item) => item.invoices[0].invoiceUUID === elem.invoiceUUID
            )
          ),
        }))
      );
      setPaymentInvoiceCases(
        paymentInvoiceCases.filter(
          (elem) => !selectedPaymentInvoiceCases.includes(elem)
        )
      );
      setSelectedPaymentInvoiceCases([]);
    }
  };

  return {
    //First table
    paymentInvoiceCases,
    setPaymentInvoiceCases,
    selectedPaymentInvoiceCases,
    setSelectedPaymentInvoiceCases,

    //Second table
    unpaidInvoices,
    setUnpaidInvoices,
    selectedUnpaidInvoiceCases,
    setSelectedUnpaidInvoiceCases,

    //Cases add
    unpdaidInvoiceCasesAdd,
    //Cases remove
    paymentInvoiceCasesRemove,
  };
};

export default usePaymentTables;
