import React, { FC } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Space } from "antd";

import styles from "./ChooseJaw.module.scss";

import {
  ActionCreatorTypes,
  useCaseFormContext,
} from "@/components/CaseForm/CaseFormContext";
import { TeethFormulaTypeEnum } from "@/root/types";

const ChooseJaw: FC = () => {
  const { t } = useTranslation();
  const { state, dispatch } = useCaseFormContext();

  const activeJob = state.jobs.find((job) => job.jobID === state.activeJobID);
  const activeProduct = activeJob?.products.find(
    (product) => product.jobProductUUID === activeJob.activeProductID
  );

  const handleJawClick = (jaw: "upper" | "lower") => {
    dispatch({
      type: ActionCreatorTypes.SetTooth,
      payload: {
        tooth:
          state.teethFormulaTypeUUID === TeethFormulaTypeEnum.adult
            ? jaw === "upper"
              ? "FDI_AUJ"
              : "FDI_ALJ"
            : jaw === "upper"
            ? "FDI_CUJ"
            : "FDI_CLJ",
      },
    });
  };

  const isUpperJawSelected =
    activeProduct?.teeth?.includes("FDI_AUJ") ||
    activeProduct?.teeth?.includes("FDI_CUJ");
  const isLowerJawSelected =
    activeProduct?.teeth?.includes("FDI_ALJ") ||
    activeProduct?.teeth?.includes("FDI_CLJ");

  return (
    <Space className={styles.jawButtons}>
      <div
        className={classNames(styles.tooth, {
          [styles.selected]: isUpperJawSelected,
          [styles.disabled]: state.onlyDetailsEdit,
        })}
        onClick={() => !state.onlyDetailsEdit && handleJawClick("upper")}
      >
        {t("Верхня щелепа")}
      </div>
      <div
        className={classNames(styles.tooth, {
          [styles.selected]: isLowerJawSelected,
          [styles.disabled]: state.onlyDetailsEdit,
        })}
        onClick={() => !state.onlyDetailsEdit && handleJawClick("lower")}
      >
        {t("Нижня щелепа")}
      </div>
    </Space>
  );
};

export default ChooseJaw;
