import { FC, useEffect, useMemo, useState } from "react";

import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";

import styles from "./ChooseProduct.module.scss";

import useProducts from "@/hooks/useProducts";
import { Product } from "@/root/models/product";
import { useCaseFormContext } from "@/components/CaseForm/CaseFormContext";
import ProductItem from "./Product";
import { t } from "i18next";
import { useProductSearch } from "@/hooks/useProductSearch";

interface ChooseProductProps {
  productsSearchValue?: string;
  onSelect: (props: {
    product: Product;
    teethColor: string | undefined;
  }) => void;
}

const ChooseProduct: FC<ChooseProductProps> = ({
  onSelect,
  productsSearchValue,
}) => {
  const { products, findProductByID, getParameterGroup } = useProducts();
  const { state } = useCaseFormContext();

  //Current selected product
  const [current, setCurrent] = useState<Product>();

  //Search feature
  const { filteredProducts, searchProducts } =
    useProductSearch(productsSearchValue);

  useEffect(() => {
    if (productsSearchValue?.length) {
      searchProducts(products, productsSearchValue);
      setCurrent(undefined);
    }
  }, [productsSearchValue]);

  //--//

  const { jobProduct } = useMemo(() => {
    const job = state.jobs.find((job) => job.jobID === state.activeJobID);
    const jobProduct = job?.products.find(
      (product) => product.jobProductUUID === job.activeProductID
    );
    return { job, jobProduct };
  }, [state.jobs, state.activeJobID]);

  useEffect(() => {
    if (
      jobProduct &&
      current?.productUUID !== jobProduct.product?.productUUID
    ) {
      setCurrent(
        jobProduct.product
          ? findProductByID(jobProduct.product.parentProductUUID)
          : undefined
      );
    }
  }, [jobProduct]);

  const handleSelect = (product: Product) => {
    if (product.isGroup) {
      setCurrent(product);
    } else {
      const group = getParameterGroup(product);
      const teethColor = group.color;
      onSelect({ product, teethColor });
    }
  };

  const back = () => {
    if (current?.parentProductUUID) {
      setCurrent(findProductByID(current.parentProductUUID));
    } else {
      setCurrent(undefined);
    }
  };

  return (
    <div className={styles.container}>
      {/* Current product with possibility go back inside a tree */}
      {current ? (
        <div>
          <div className={styles.buttonContainer}>
            <div className={styles.colorContainer}>
              <div
                style={{
                  background: getParameterGroup(current).color,
                  width: 20,
                  height: 20,
                  borderRadius: 20,
                }}
              />
            </div>
            <Button type="text" icon={<LeftOutlined />} onClick={back}>
              {current.name}
            </Button>
          </div>
          <div className={styles.products}>
            {current.children?.map((product) => (
              <ProductItem
                key={product.productUUID}
                product={product}
                onSelect={handleSelect}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className={styles.products}>
          {/* Filtered products */}
          {filteredProducts?.length ? (
            filteredProducts?.map((product) => (
              <div
                style={{
                  display: filteredProducts.find(
                    (elem) =>
                      elem.parent?.productUUID ===
                      product.children.find((elem) => elem.productUUID)
                        ?.productUUID
                  )
                    ? "none"
                    : "block",
                }}
              >
                {[product.parent].map(
                  (elem) =>
                    elem && (
                      <ProductItem
                        key={elem?.productUUID}
                        product={elem}
                        onSelect={handleSelect}
                      />
                    )
                )}

                {product.children.map((elem) => (
                  <ProductItem
                    key={elem.productUUID}
                    product={elem}
                    onSelect={handleSelect}
                  />
                ))}
              </div>
            ))
          ) : // If no search value and results array is empty show all product groups
          !filteredProducts?.length && !productsSearchValue ? (
            products
              .filter((elem) => elem.isGroup)
              .map((elem) => (
                <ProductItem
                  key={elem.productUUID}
                  product={elem}
                  onSelect={handleSelect}
                />
              ))
          ) : (
            <div>{t("Продукт не знайдено")}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default ChooseProduct;
