import { useState } from "react";
import styles from "./TaskPhotos.module.scss";
import { Button, Modal, Tooltip } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

interface FullScreenPhotoProps {
  imageUrl: string;
  fileName: string;
}

const FullScreenPhoto = ({ imageUrl, fileName }: FullScreenPhotoProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDownload = () => {
    const downloadLink = document.createElement("a");
    downloadLink.href = imageUrl;
    downloadLink.download = fileName ? fileName : "photo.jpg";
    downloadLink.click();
  };

  return (
    <>
      <Modal
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className={styles.fullscreen_container}
      >
        <img
          src={imageUrl}
          alt="Full Screen"
          className={styles.fullscreen_image}
        />
        <Tooltip title="Download image" placement="bottom" color="#1677ff">
          <Button
            type="primary"
            onClick={handleDownload}
            icon={<DownloadOutlined />}
            className={styles.download_btn}
          />
        </Tooltip>
      </Modal>
      <img
        src={imageUrl}
        alt="Thumbnail"
        className={styles.image}
        onClick={showModal}
      />
    </>
  );
};

export default FullScreenPhoto;
