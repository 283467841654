import { FC, useEffect, useState } from "react";

import Parameter from "@/components/CaseForm/components/Parameters/Parameter";
import { ParametersGroup } from "@/root/models/parameterGroup";
import { useCaseFormContext } from "@/components/CaseForm/CaseFormContext";
import { Filter } from "@/root/models/filter";
import { isParameterFiltered } from "@/utils/FilterParameter";

interface ParametersGroupItemProps {
  group: ParametersGroup;
  activeJobID: string | null;
  activeProductID: string | null;
  activeTooth: string | null;
  allFilters: Filter[];
}

const ParametersGroupItem: FC<ParametersGroupItemProps> = ({
  group,
  activeJobID,
  activeProductID,
  activeTooth,
  allFilters,
}) => {
  const { state } = useCaseFormContext();
  const [filter, setFilter] = useState<{
    isFilter: boolean;
    value: string | undefined;
  }>({ isFilter: false, value: undefined });

  useEffect(() => {
    const filterUUID = group.productsParameters.find(
      (p) => p.productsParameter.type.name === "Filter"
    )?.productsParameter.productsParameterUUID;
    if (filterUUID) {
      const job = state.jobs.find((job) => job.jobID === activeJobID);
      const product = job?.products.find(
        (product) => product.jobProductUUID === activeProductID
      );
      const group = product?.productsParametersGroups?.find(
        (group) =>
          group.productsParametersGroupUUID ===
          group.productsParametersGroupUUID
      );
      const formParameter = group?.productsParameters.find(
        (p) =>
          p.productsParameterUUID === filterUUID &&
          (p.tooth || null) ===
            (product?.differentTeethParameters ? activeTooth : null)
      );
      setFilter({
        isFilter: !!formParameter,
        value:
          typeof formParameter?.value === "string"
            ? formParameter.value
            : undefined,
      });
    }
  }, [state]);

  return (
    <>
      {filter &&
        group.productsParameters.map((parameter) => {
          if (isParameterFiltered(parameter, allFilters, filter)) {
            return null;
          }

          return (
            <Parameter
              key={parameter.productsParameter.productsParameterUUID}
              allFilters={allFilters}
              activeJobID={activeJobID}
              activeTooth={activeTooth}
              activeProductID={activeProductID}
              filter={filter}
              groupUUID={group.productsParametersGroupUUID}
              parameter={parameter.productsParameter}
              required={parameter.required}
            />
          );
        })}
    </>
  );
};

export default ParametersGroupItem;
