import { CaseFormState } from "@/components/CaseForm/CaseFormContext";
import {
  calculateProductPriceByFormula,
  CalculateProductPriceByFormulaContext,
} from "@/utils/calculateProductPriceByFormula";

export const calculateProductPrice = (
  context: CalculateProductPriceByFormulaContext
) => {
  if (context.product.product?.priceFormula) {
    const calcSum = calculateProductPriceByFormula(
      context,
      context.product.product.priceFormula.formula
    );

    if (calcSum) {
      return calcSum;
    }
  }

  return Number(context.product.price) * context.product.quantity;
};

export const calculateGrandTotal = (
  jobs: CaseFormState.Job[],
  teethFormulaTypeUUID: string
) =>
  jobs.reduce((sum, job) => {
    const jobPrice =
      job.products?.reduce((sum, product) => {
        const price = calculateProductPrice({
          product,
          productsCount: job.products.length,
          jobsCount: jobs.length,
          teethFormulaTypeUUID: teethFormulaTypeUUID as string,
        });
        return sum + price;
      }, 0) || 0;
    const extrasSum =
      job.extras?.reduce((sum, extra) => {
        if (extra.price) {
          return sum + Number(extra.price);
        }
        return sum;
      }, 0) || 0;
    const partsSum =
      job.spareParts?.reduce((sum, part) => {
        if (part.price) {
          return sum + Number(part.price);
        }
        return sum;
      }, 0) || 0;
    return sum + jobPrice + extrasSum + partsSum;
  }, 0);
