import { Button } from "antd";
import classNames from "classnames";
import { EyeOutlined } from "@ant-design/icons";
import styles from "../InvoicePanel.module.scss";
import { InvoicesApi } from "@/api/invoices";
import { useEffect, useState } from "react";
import { Invoice } from "@/root/models/invoice";
import InvoiceModal from "./InvoiceModal";
import useModalOpen from "@/hooks/useModalOpen";
import { useForm } from "antd/es/form/Form";

interface InvoiceColumnProps {
  invoiceInfo: {
    invoiceUUID: string;
    invoiceNumber: string;
    paid: boolean;
  };
}

const InvoiceColumn = ({ invoiceInfo }: InvoiceColumnProps) => {
  const [currentInvoice, setCurrentInvoice] = useState<Invoice>();
  const [loading, setLoading] = useState(false);

  const { modal, isModalOpen, setModalType, onHandleCancel } = useModalOpen();

  const [form] = useForm();

  useEffect(() => {
    if (currentInvoice) {
      setModalType("view");
      isModalOpen(currentInvoice.invoiceUUID);
      form.setFieldsValue({
        invoiceNumber: currentInvoice.invoiceNumber,
        client: currentInvoice.client.clientUUID,
        description: currentInvoice.description,
        amount: currentInvoice.amount,
        paid: currentInvoice.paid,
      });
    }
  }, [currentInvoice]);

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: 8,
          alignItems: "center",
        }}
      >
        {invoiceInfo.paid ? (
          <div
            className={classNames([styles.invoice_paid_status])}
            style={{ backgroundColor: "green" }}
          />
        ) : (
          <div
            className={classNames([styles.invoice_paid_status])}
            style={{ backgroundColor: "gray" }}
          />
        )}
        <div>{invoiceInfo.invoiceNumber}</div>
        <Button
          size="small"
          icon={<EyeOutlined />}
          disabled={loading}
          onClick={() => {
            (async () => {
              try {
                if (invoiceInfo.invoiceUUID) {
                  setLoading(true);
                  const foundInvoice = await InvoicesApi.getInvoice(
                    invoiceInfo.invoiceUUID
                  );
                  setCurrentInvoice(foundInvoice as Invoice);
                  setLoading(false);
                }
              } catch (error) {}
            })();
          }}
        />
      </div>
      {currentInvoice && (
        <InvoiceModal
          modal={modal}
          modalType="view"
          form={form}
          invoice={currentInvoice}
          onCancel={onHandleCancel}
        />
      )}
    </>
  );
};

export default InvoiceColumn;
