import { useQuery } from "@tanstack/react-query";

import { CaseStatusApi } from "@/api/caseStatus";

const useCaseStatuses = () => {
  const { data: statuses, isLoading: loading } = useQuery({
    queryKey: ["caseStatuses"],
    queryFn: CaseStatusApi.getStatuses,
  });

  return {
    statuses: statuses || [],
    loading,
  };
};

export default useCaseStatuses;
