import SeviceEvaluationFormTable from "./SeviceEvaluationFormTable";
import styles from "../InvoiceAccountForCurrentOrder.module.scss";
import globalStyles from "../../InvoicesPdf.module.scss";

interface ServiceEvaluationFormProps {
  width: string;
}
const ServiceEvaluationForm = ({ width }: ServiceEvaluationFormProps) => {
  return (
    <div style={{ width: width, fontSize: "18px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>Dr. Melanie Gilbert</div>
        <div> Patient: Joseph Day (24 Jul 2023)</div>
      </div>
      <div className={styles.form_border}>
        <div className={styles.form_header}>
          <div>Roshen Dental Services - Please return with your next case</div>
        </div>
        <div className={styles.form_body}>
          <div className={globalStyles.font_bold}>27701</div>

          {/* Table */}
          <SeviceEvaluationFormTable />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "40%",
              alignItems: "flex-end",
              marginRight: "15px",
              marginTop: "2px",
            }}
          >
            <div style={{ display: "flex", gap: "20px" }}>
              <div>On time?</div>
              <div>Yes No</div>
            </div>
            <div style={{ display: "flex", gap: "30px" }}>
              <div>
                <div>Insertion</div>
                <div>Time</div>
              </div>
              <div style={{ justifyContent: "center" }}>
                <div>____</div>
                <div>mins</div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ textAlign: "center", marginBottom: "3px" }}>
          we welcome additional comments, please use reverse side
        </div>
      </div>
    </div>
  );
};

export default ServiceEvaluationForm;
