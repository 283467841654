import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "@/hooks/redux";
import {
  clearAuthToken,
  getAuthToken,
  isTokenExpired,
} from "@/utils/tokenHelpers";
import { pages } from "@/root/consts";
import { me } from "@/store/reducers/user/asyncActionCreators";
import { LoadingStatus, UserTypeEnum } from "@/root/types";

const useAuth = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.userReducer.getMeLoading);
  const userType = useAppSelector((state) => state.userReducer.user?.userType);

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (
      pathname.startsWith(pages.patientsPortal.path) ||
      pathname.startsWith(pages.caseWarranty.path)
    ) {
      setIsReady(true);
      return;
    }

    if (isTokenExpired()) {
      navigate(pages.login.path);
      clearAuthToken();
      setIsReady(true);
    } else {
      const { token } = getAuthToken();
      if (token) {
        dispatch(me());
      }
    }
  }, []);

  useEffect(() => {
    if (loading === LoadingStatus.ERROR) {
      setIsReady(true);
      navigate(pages.login.path);
    } else if (loading === LoadingStatus.SUCCESS) {
      if (userType) {
        switch (userType.userTypeUUID) {
          case UserTypeEnum["Client's employee"]:
          case UserTypeEnum["Client's admin"]:
            navigate(pages.client.dashboard.path);
            break;
          case UserTypeEnum["Employee"]:
            navigate(pages.lab.dashboard.path);
            break;
          case UserTypeEnum["Lab's admin"]:
            navigate(pages.admin.dashboard.path);
            break;
        }
        setIsReady(true);
      }
    }
  }, [loading, userType]);

  return { isReady };
};

export default useAuth;
