import { useEffect, useRef } from "react";

import { InputRef } from "antd";

const useFormAutoFocusInput = () => {
  const focusInputRef = useRef<InputRef>(null);

  useEffect(() => {
    focusInputRef.current?.focus();
  }, []);
  
  return focusInputRef;
};

export default useFormAutoFocusInput;
