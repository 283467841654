import { Button, notification } from "antd";
import { t } from "i18next";
import { useState } from "react";
import { useForm } from "antd/es/form/Form";
import useUnitsOfMeasure from "@/hooks/useUnitsOfMeasure";
import ActionUnitOfMeasureModal from "./ActionUnitOfMeasureModal";

const CreateUnitOfMeasure = () => {
  const [form] = useForm();
  const [modal, setModal] = useState(false);

  const onCreateSuccess = () => {
    setModal(false);
    notification.success({
      message: t("Одиниця виміру була успішно створена"),
    });
    form.resetFields();
  };

  const { addUnitsOfMeasure, createError, createLoading } =
    useUnitsOfMeasure(onCreateSuccess);

  return (
    <div>
      <Button onClick={() => setModal(true)}>{t("Створити склад")}</Button>
      <ActionUnitOfMeasureModal
        open={modal}
        onCancel={() => setModal(false)}
        type="create"
        error={createError}
        handleSubmit={addUnitsOfMeasure}
        loading={createLoading}
        form={form}
      />
    </div>
  );
};

export default CreateUnitOfMeasure;
