import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HexColorPicker } from "react-colorful";

import {
  Alert,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  Tooltip,
} from "antd";

import { CreateProductPayload } from "@/api/admin/products";
import useProductsParametersGroups from "@/components/ProductForm/useProductsParametersGroups";
import useFormAutoFocusInput from "@/hooks/useFormAutoFocusInput";
import usePriceFormulas from "@/hooks/usePriceFormulas";

interface ProductFormProps {
  form: FormInstance<CreateProductPayload>;
  handleSubmit: (values: CreateProductPayload) => void;
  error: string;
  groupDisabled?: boolean;
}

const { Option } = Select;

const PipetteIcon = () => {
  return (
    <svg width={25} viewBox="0 0 24 24">
      <path d="m20.71 5.63-2.34-2.34a.9959.9959 0 0 0-1.41 0l-3.12 3.12-1.93-1.91-1.41 1.41 1.42 1.42L3 16.25V21h4.75l8.92-8.92 1.42 1.42 1.41-1.41-1.92-1.92 3.12-3.12c.4-.4.4-1.03.01-1.42zM6.92 19 5 17.08l8.06-8.06 1.92 1.92L6.92 19z" />
    </svg>
  );
};

const ProductForm: FC<ProductFormProps> = ({
  form,
  handleSubmit,
  error,
  groupDisabled,
}) => {
  const { t } = useTranslation();
  const { groups, loading } = useProductsParametersGroups();
  const focusInputRef = useFormAutoFocusInput();
  const { priceFormulas, getPriceFormulasLoading } = usePriceFormulas({});

  const [isGroup, setIsGroup] = useState(form.getFieldValue("isGroup"));

  const submit = (values: CreateProductPayload) => {
    handleSubmit({
      ...values,
      warrantyPeriod: values.warrantyPeriod || 0,
    });
  };

  const warrantyValue = form.getFieldValue("warrantyPeriod");

  useEffect(() => {
    if (warrantyValue === 0) {
      form.setFieldValue("warrantyPeriod", "");
    }
  });

  return (
    <Form
      requiredMark={false}
      form={form}
      initialValues={groupDisabled ? { isGroup: false } : undefined}
      onFinish={submit}
    >
      <Form.Item
        rules={[
          {
            required: true,
            message: t("Введіть назву") as string,
          },
        ]}
        name="name"
        label={t("Назва")}
      >
        <Input
          ref={focusInputRef}
          placeholder={t("Введіть значення") as string}
        />
      </Form.Item>

      <Form.Item name="isGroup" label={t("Група")} initialValue={false}>
        <Select disabled={groupDisabled} onSelect={setIsGroup}>
          <Option key="1" value={true}>
            {t("Так")}
          </Option>
          <Option key="2" value={false}>
            {t("Ні")}
          </Option>
        </Select>
      </Form.Item>

      {isGroup && (
        <Form.Item name="color" label={t("Колір групи")}>
          <Input
            placeholder={t("Введіть значення") as string}
            addonAfter={
              <Tooltip
                title={
                  <HexColorPicker
                    onChange={(color) => {
                      form.setFieldValue("color", color);
                    }}
                  />
                }
              >
                <span style={{ cursor: "pointer" }}>
                  <PipetteIcon />
                </span>
              </Tooltip>
            }
          />
        </Form.Item>
      )}

      {!isGroup && (
        <>
          <Form.Item
            name="productsParametersGroups"
            label={t("Групи параметрів")}
          >
            <Select
              listHeight={1000}
              loading={loading}
              mode="multiple"
              allowClear
            >
              {groups.map((p) => (
                <Option
                  key={p.productsParametersGroupUUID}
                  value={p.productsParametersGroupUUID}
                >
                  {p.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            initialValue={false}
            name="isPoint"
            label={t("Крапка")}
            rules={[
              {
                required: true,
                message: t("Заповніть поле") as string,
              },
            ]}
          >
            <Select>
              <Option key="1" value={true}>
                {t("Так")}
              </Option>
              <Option key="2" value={false}>
                {t("Ні")}
              </Option>
            </Select>
          </Form.Item>

          <Form.Item name="color" label={t("Колір продукту")}>
            <Input
              placeholder={t("Введіть значення") as string}
              addonAfter={
                <Tooltip
                  title={
                    <HexColorPicker
                      onChange={(color) => {
                        form.setFieldValue("color", color);
                      }}
                    />
                  }
                >
                  <span style={{ cursor: "pointer" }}>
                    <PipetteIcon />
                  </span>
                </Tooltip>
              }
            />
          </Form.Item>
          <Form.Item name="priceFormulaUUID" label={t("Цінова формула")}>
            <Select
              listHeight={1000}
              loading={getPriceFormulasLoading}
              allowClear
              onClear={() =>
                setTimeout(() => form.setFieldValue("priceFormulaUUID", ""))
              }
            >
              {priceFormulas.map((p) => (
                <Option key={p.priceFormulaUUID} value={p.priceFormulaUUID}>
                  {p.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </>
      )}

      <Form.Item name="warrantyPeriod" label={t("Гарантійний строк")}>
        <InputNumber />
      </Form.Item>

      {!!error && <Alert message={error} type="error" />}
    </Form>
  );
};

export default ProductForm;
